import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MafkidService } from 'src/app/services/mafkid.service';
import { UserService } from 'src/app/services/user.service';
import { ComponentFixtureAutoDetect } from '@angular/core/testing';
import { HistoryService } from 'src/app/services/history.service';
import { NamesService } from 'src/app/services/names.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';

@Component({
  selector: 'app-manegemafkidcount',
  templateUrl: './manegemafkidcount.component.html',
  styleUrls: ['./manegemafkidcount.component.css']
})
export class ManegemafkidcountComponent implements OnInit {
  //#region  bool
  b: boolean;
  idintbl
  PERMISSION;
  idmafkid: any;
  nametbl;
  iduser: any;
  id;
  future: boolean = false;;
  new: boolean;
  value1;
  listhis;
  listtotal;
  bool;
  listcoin: any;
  //#endregion
  constructor(private router: Router, private maf: MafkidService, private magar: MaagarMenageService, private his: HistoryService, private excel: ExcelService, private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      this.iduser = params.iduser;
      this.PERMISSION = params.id;
      this.id = params.mainid;
      this.nametbl = "Hafkadot"
      this.idmafkid = params.idmafkid;
      ;
    }
    )
  }
  ngOnInit() {
    this.nametbl = "Hafkadot"
    this.getcoins()
  }
  getcoins() {
    this.listcoin = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoin == null) {
      this.magar.GetCoins().then(res => {
        this.listcoin = res;
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));

        this.GetTotal();
      })
    }
    else {
      this.GetTotal();
    }

  }
  GetTotal() {
    this.maf.GetTotal(this.idmafkid).then(res => {
      this.listtotal = res;
      this.listtotal.forEach(element => {
        element['namecoin'] = this.listcoin && this.listcoin.find(a => a.Id == element.coin) ? this.listcoin.find(a => a.Id == element.coin).name : null;
        if (element.scum == null) {
          element.scum = 0;
        }

      });
      this.listtotal = this.listtotal.filter(a => a.scum != null)
    });
  }
  future1() {
    this.future = !this.future;
  }

  Calculate() {
    this.router.navigate(["/Tools/" + this.iduser, this.PERMISSION, this.idmafkid]);

  }
  valuechange(value: string) {
    this.value1 = value;
    if (this.value1 == "דוח לחשבון נוכחי") {
      this.maf.reports(false, this.idmafkid, this.future);
    }
    else {
      if (this.value1 == "דוח לכל חשבונות ההפקדה") {
        this.maf.reports(true, this.idmafkid, this.future);
      }
    }
  }
}

