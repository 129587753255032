import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Task } from '../classes/task';
import { NamesService } from '../services/names.service';


@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient) { }  
  delTask(id: number) {
    return this.http.get(NamesService.serverUrl +"/Tasks/DelTask?id="+id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
   filterbydate(d1: Date, d: Date): any {
    return this.http.put(NamesService.serverUrl +"/Tasks/filterbydate",{d3:d1, d2:d}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });}
    ViewTasksHaf(id:number) {
      return this.http.get(NamesService.serverUrl +"/Tasks/ViewTasksHaf?id="+id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
  }
  ViewTasksloan(id:number) {
    return this.http.get(NamesService.serverUrl +"/Tasks/ViewTasksloan?id="+id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  
  UpdateTaskall(task1: Task) {
    return this.http.put(NamesService.serverUrl +"/Tasks/PutTask",task1).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  ViewTasks() : any {
    {
     
        return this.http.get(NamesService.serverUrl +"/Tasks").toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });
        }}  
        FilterTasks1(value: any): any {
          return this.http.get(NamesService.serverUrl +"/Tasks/filter1?id="+ value).toPromise().then(
            response => {
              return response;
            })
          .catch(v => { return false; });  }
    
        UpdateTask(pro:Task): any {
          return this.http.put(NamesService.serverUrl +"/Tasks/PutTask?" ,pro).toPromise().then(
            response => {
              return true;
            })
          .catch(v => { return false; }); }
      addTask(pro:Task): any {
        ;
        return this.http.post(NamesService.serverUrl +"/Tasks/PostTask", pro).toPromise().then(
            response => {
              return true;
            })
          .catch(v => { return false; });
      }
       FilterTasks(value:string): any {
        return this.http.get(NamesService.serverUrl +"/Tasks/filter?id="+ value).toPromise().then(
          response => {
            return response;
          })
        .catch(v => { return false; });  }

    }

