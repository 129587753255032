export class Maslul {
    KodMaslul:number;
    ScumHafkada:number;
    yechidot:number;
    NumOfPayment:number;
    ScumHalvaa: number;
    OnePay:number
    Heczer:number;
    NumOfHeczerPayments:number;
    NumOfJoiners:number;
    Total:number;
remark:string;
  AllLoans: number;
  HafMonth: number;
  Hefresh: number=2;
  AmlaTotal: number;
  Age=20

    constructor()
    {
        this.Age=20
        this.KodMaslul=null; 
        this.ScumHafkada=null; 
        this.yechidot=null; 
        this.NumOfPayment=null; 
        this.ScumHalvaa=null; 
        this.Heczer=null; 
        this.NumOfHeczerPayments=null; 
        this.NumOfJoiners=null; 
        this.Total=null; 
        this.OnePay=0; 

        this.remark="הפקדה בלבד";
    }


}

