<app-main  [user]="PERMISSION" [iduser]="iduser" ></app-main>
<div id="mySidenav"style="position: relative; 
top:60px;"  class="sidenav">
  <a (click)="report2()" id="about">צפי תוכניות</a>
  <a (click)="loan()" id="blog"> הלוואות </a>
  <a (click)="loanhigh()" id="blog1">הלואות עם החזרים עודפים </a>
  <a (click)="loanlow()" id="blog2">הלואות שחסר החזרים </a>
  <a (click)="heczer()" id="projects">תשלומים</a>
  <a (click)="maf1()" id="projects1">חשבונות הפקדה</a>
  <a (click)="haf1()" id="projects2">הפקדות</a>
  <a (click)="hok1()" id="contact">הוראות קבע</a>
  <a (click)="hokhelps()" id="contact5">הוראות </a>

</div>
<!-- [hidden]="!reports1"  -->
<ag-grid-angular 
    style="width:1350px; height: 650px;position: absolute; left:450px;top:230px;" 
    class="ag-theme-balham"
    [rowData]="rowData" 
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs"
    [enableRtl]="true"
    [rowSelection]="rowSelection"
    (gridReady)="onGridReady($event)"
    (cellClicked)='viewdetails($event)' 
  
    [floatingFilter]="true">
</ag-grid-angular>

<img [hidden]="!reports1" src="/assets/1/excel.png" (click)="excel()" style="position: absolute;
width: 45px;
height: 45px;
left: 91%;
top: 18.5vh;">
<!-- <div [hidden]="!wait" style="position: absolute;top: 50%;left: 50%;;" class="loader">כבר יוצג.... סבלנות!!</div>   (cellEditingStarted)='cellEditingStarted($event)'
    (cellEditingStopped)="cellEditingStopped($event)"-->

<!-- <app-mafkidreports [hidden]="!reports"> </app-mafkidreports> -->