<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<ag-grid-angular 
    style="width: 450px; height: 200px; position: absolute; left: 437px; top: 298px;" 
    class="ag-theme-balham"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    [enableRtl]="true"

    (cellDoubleClicked)='viewdetails($event)'
    (cellClicked)='delMasav($event)'
    [rowSelection]="rowSelection"
    [floatingFilter]="true">
</ag-grid-angular>
<!--     [enableRtl]="true"
 -->
