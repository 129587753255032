<app-main [user]="PERMISSION" [iduser]="iduser"></app-main>
<div style=" height: 100vh;width: 100%;position: absolute;top: 5vh;left: 0;text-align: right;">
    <label style="position: absolute;top: 4vh;left:85%;"> בחר מסלול</label>
    <ng-select (change)="selectMaslul($event)" [(ngModel)]="this.M.KodMaslul" [ngModelOptions]="{standalone: true}"
        style="width: 25%; height: 30px; position: absolute;top:8vh;left: 74%;border-radius: 12px;direction: rtl;
border-radius: 4px;" [items]="listMasluls" bindValue="KodMaslul" #val bindLabel="remark" tabindex="2">
    </ng-select>
    <div fxLayout="column" fxLayoutAlign="space-between center"
        style="background-color: gray;border-radius: 12px;  width:25%;height:80vh;direction: rtl;position: absolute;top: 120px;left: 74%;;overflow-y: auto;  scroll-behavior: smooth;">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 60%;">
            <p>מס יחידות
            <p> סכום הפקדה</p>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <input type="number" (change)="ChangeNumOfYechidot()" min="0" [(ngModel)]="Yechidot"
                [ngModelOptions]="{standalone: true}">
            <input type="number" [(ngModel)]="this.M.ScumHafkada" [ngModelOptions]="{standalone: true}"
                (change)="ChangeTotalHaf()" style="width: 75%;">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 70%;">
            <p> מס חודשי הפקדה</p>
            <p> הפקדה חודשית</p>
            <svg (click)="AddMasHaf()" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-circle"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            </svg>

        </div>
        <div [hidden]="ExtraHAf" fxLayout="row" fxLayoutAlign="space-between center">

            <input type="number" (change)="ChangeNumOfYears1()" [(ngModel)]="this.M.NumOfPayment"
                [ngModelOptions]="{standalone: true}">
            <input type="number" [(ngModel)]="this.M.HafMonth" (change)="ChangeHafMonth()"
                [ngModelOptions]="{standalone: true}">

        </div>
        <div [hidden]="!ExtraHAf" fxLayout="row" fxLayoutAlign="space-between center"
            *ngFor="let item1 of ListHaf; let i = index">
            <input type="number" (change)="ChangeNumOfPaymentsExtra()" [(ngModel)]="this.ListHaf[i].Num"
                [ngModelOptions]="{standalone: true}">
            <input type="number" [(ngModel)]="this.ListHaf[i].hafmonth" (change)="ChangeHafMonthExtra()"
                [ngModelOptions]="{standalone: true}">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 70%;">
            <p> סכום חד"פ</p>
            <p> תאריך</p>
            <svg (click)="AddHafChadPaami()" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-circle"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        </svg>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center"  *ngFor="let item1 of ListHafChadpaami; let i = index">
            <input type="number" [(ngModel)]="this.ListHafChadpaami[i].Num" (change)="ChangeOnePay()"
                [ngModelOptions]="{standalone: true}">
            <input type="date" (ngModelChange)="ListHafChadpaami[i].date=$event"
            [ngModel]="ListHafChadpaami[i].date|date:'y-MM-dd'" [ngModelOptions]="{standalone: true}">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 70%;">
            <p> מס' החזרים</p>
            <p> החזר חודשי</p>
            <svg (click)="AddMasHac()" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-circle"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            </svg>
        </div>
        <div fxLayout="row" [hidden]="ExtraHAc" fxLayoutAlign="space-between center">
            <input type="number" (change)="ChangeNumOfHeczers()" [(ngModel)]="this.M.NumOfHeczerPayments"
                [ngModelOptions]="{standalone: true}">
            <input type="number" (change)="ChangeHeczer()" [(ngModel)]="this.M.Heczer"
                [ngModelOptions]="{standalone: true}">

        </div>
        <div fxLayout="row" [hidden]="!ExtraHAc" fxLayoutAlign="space-between center"
            *ngFor="let item1 of ListHac; let i = index">

            <input type="number" (change)="ChangeNumOfHeczersExtra()" [(ngModel)]="this.ListHac[i].Num"
                [ngModelOptions]="{standalone: true}">
            <input type="number" (change)="ChangeHeczerExtra()" [(ngModel)]="this.ListHac[i].heczer"
                [ngModelOptions]="{standalone: true}">

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 60%;">
            <p> מס' הלוואות </p>
            <p> הפרש שנים</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center end">
            <input type="number" (change)="ChangeNumOfLoans()" [(ngModel)]="this.M.yechidot"
                [ngModelOptions]="{standalone: true}">
            <input type="number" (change)="ChangeNumOfLoans()" [(ngModel)]="this.M.Hefresh"
                [ngModelOptions]="{standalone: true}">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 60%;">

            <p> סכום יחידה</p>
            <p> סכום כל ההלוואות</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">

            <input type="number" (change)="ChangeNumOfLoans()" [(ngModel)]="this.M.ScumHalvaa"
                [ngModelOptions]="{standalone: true}">
            <input type="number" (change)="ChangeScumOfLoans()" [(ngModel)]="this.M.AllLoans"
                [ngModelOptions]="{standalone: true}">
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="direction: rtl;">
            <p>גיל נישואין</p> <input type="number" style="width: 15%;" (change)="ChangeNumOfLoans()"
                [(ngModel)]="this.M.Age" [ngModelOptions]="{standalone: true}">
            <p> אל תדרוס</p>
            <input type="checkbox" style="zoom:2;;" id="55" (change)="dontChange()">
        </div>
        <input type="checkbox" checked="checked" id="S"  style="block-size: 17px;cursor: pointer;zoom: 1.4;"
            (click)="ChangeAutoClaculate()">
        <p>חישוב אוטומטי</p>
        <div fxLayout="row" fxLayoutAlign="center end">
            <img src="/assets/1/x-button.png" style="width:8%;;" (click)="reset()">

            <svg (click)='Save()' width="3em" height="2.5em" viewBox="0 0 16 16" class="bi bi-file-earmark-check-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zm1.854 2.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
            </svg>
            <img src="/assets/1/calculator.png" style="width:8%;;" (click)="Calculate()">
        <img src="/assets/1/calculate.png" style="width:8%;margin-right: 10px;"   (click)="CalculateNowState()">

        </div>
        <br>
    </div>
    <div style="position: absolute;left: 58%;background-color: gray;border-radius: 12px;top: 120px; width:15%;height:80vh;overflow-y: auto;overflow-x: hidden;  scroll-behavior: smooth; ;"
        class="container">

        <div *ngFor='let item of listNumLoans; let i = index' style="height: 154px;width:100%;direction: rtl;;"
        fxLayout="column" fxLayoutAlign="end end">
        <br>
        <br>

        <div fxLayout="row">
            <label style="width: 100px;"> גיל נישואין</label>

            <label style=" width: 100px;"> ת.לידה</label>
        </div>
        <div fxLayout="row">
            <input type="number" step="5000"  style=" width: 50%"  [(ngModel)]="listNumLoans[i].Age" (change)="changeAge(i)">
            <input type="date" style=" width: 50%" (change)="changeAge(i)"
                (ngModelChange)="listNumLoans[i].dateBorn=$event"
                [ngModel]="listNumLoans[i].dateBorn|date:'y-MM-dd'" [ngModelOptions]="{standalone: true}">
        </div>
        <div fxLayout="row">
            <label style="  width: 100px;"> סכום  {{i+1}} </label>
            <label style="    width:100px;"> תאריך  {{i+1}} </label>
        </div>
        <div fxLayout="row">
            <input type="number" step="5000" style=" width: 50%" [(ngModel)]="listNumLoans[i].Num">
            <input type="date" style=" width: 50%" (ngModelChange)="listNumLoans[i].date=$event"
                [ngModel]="listNumLoans[i].date|date:'y-MM-dd'" [ngModelOptions]="{standalone: true}">
        </div>
        <label>______________________________________________________</label>
    </div>
        <br>

    </div>
    <div
    style="overflow-y: auto;  scroll-behavior: smooth; border-radius: 12px; height: 80vh;width: 43%;background-color: lightgray; margin-left:14%;margin-top: 120px;">
        <div fxLayout="row" fxLayoutAlign=" center center "
        style="direction: rtl;width: 100%;top: 49px;height: 28px; direction: rtl;">

            <label style="width: 212px; text-align: right;"> הפקדה חודשית</label>
            <label style="width: 202px; text-align: right;">הלוואה</label>
            <label style="width: 202px; text-align: right;"> החזר חודשי</label>
            <label style="width: 202px; text-align: right;">מצב </label>
        </div>
        <div *ngFor='let item of listYears; let i = index' fxLayout="row" fxLayoutAlign="center center"
        style="  width: auto;height: 28px; direction: rtl; background-color: lightgray; flex-direction: row; box-sizing: border-box;   display: flex;     margin: auto; align-self: flex-end;">
            <!-- <span
                style="text-align: center;position: absolute;left: 146%;border-radius: 50%;width: 6%; background-color: #f44336;height: 25px;"
                *ngIf='(i)%12==0'>{{(i)/12+1}} </span> -->
            <label  style="width:60px;"> {{item.d|date:'M/yy'}}</label>
            <input type="number" style="width:29%;" step="0.01" [(ngModel)]="item.hafmonth" [ngModelOptions]="{standalone: true}">
            <input type="number"   style="width:29%;" [(ngModel)]="item.loam" [ngModelOptions]="{standalone: true}">
            <input type="number" style="width:29%;" [(ngModel)]="item.heczer" [ngModelOptions]="{standalone: true}">
            <input type="number"   style="width:29%;" step=".01" [(ngModel)]="item.total " [ngModelOptions]="{standalone: true}">

        </div>
    </div>
    <div class="label1">
        <span class="label-text">מחשבון כדאיות</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-between center"
        style="background-color: gray;border-radius: 12px;  width:12%;height:80vh;direction: rtl;position: absolute;top: 120px;left: 1%;overflow-y: auto;  scroll-behavior: smooth; ">

        <p> סך יתרות </p>
        <input type="number" [(ngModel)]="this.totalYtra" [ngModelOptions]="{standalone: true}">
        <p> שיעור מימון הכנסה</p>
        <input type="number" [(ngModel)]="Ribit" [ngModelOptions]="{standalone: true}">
        <p> שיעור מימון הוצאה</p>
        <input type="number" [(ngModel)]="this.RibitNank" [ngModelOptions]="{standalone: true}">
        <p> סיכון עלות מימון</p>
        <input type="number" [(ngModel)]="this.RibitNank*totalYtraMinus/numm" [ngModelOptions]="{standalone: true}">
        <p> הכנסת מימון צפויה </p>
        <input type="number" [(ngModel)]="this.Ribit*totalYtraPlus/numm" [ngModelOptions]="{standalone: true}">
        <p> רווח/הפסד מהלוואה</p>
        <input type="number" [(ngModel)]="this.Ribit*totalYtraPlus+this.RibitNank*totalYtraMinus"
            [ngModelOptions]="{standalone: true}">
        <p> הכנסות עמלה לתנועה</p>
        <input type="number" [(ngModel)]="Amla" [ngModelOptions]="{standalone: true}">
        <p> הוצאות עמלה לתנועה </p>
        <input type="number" [(ngModel)]="this.AmlaConst" [ngModelOptions]="{standalone: true}">
        <p> רווח מעמלות </p>
        <input type="number" [(ngModel)]="this.Amla*CountHeczer+this.Amla*M.NumOfPayment"
            [ngModelOptions]="{standalone: true}">
        <!-- <p> עלות יחידה </p>
        <input type="number"  [(ngModel)]="this.M.NumOfHeczerPayments"
            [ngModelOptions]="{standalone: true}"> -->
        <p> סיכום רווח מיחידה </p>
        <input type="number"
            [(ngModel)]="this.Ribit*totalYtraPlus/numm+this.RibitNank*totalYtraMinus/numm+this.Amla*CountHeczer+this.Amla*M.NumOfPayment"
            [ngModelOptions]="{standalone: true}">
        <br>
    </div>

</div>