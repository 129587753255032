<button (click)='viewhokhelp()' onclick="document.getElementById('id02').style.display='block'"
  style="width:150px;cursor: pointer;top: 230px;left: 550px;height: 50px;" name="1">הוספת הו"ק</button>
<div id="id02" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer" name="2">
      <span style="position: absolute;     left: 5px;
      top: 2px;
      width: 105px;" onclick="document.getElementById('id02').style.display='none'" class="close"
        title="Close Modal">&times;</span>
    </div>
    <div style="width: 35px;float:right">
    <!-- <button  (click)="ChangeStatusForAllHoks()">הפעל/בטל  את כל ההוק</button>
    <button  (click)="ChangeAutoCalculateForAllHoks()">הפעל חישוב אוטומטי לכל ההוק</button> -->
</div>
    <div style="    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: auto;
    height: 80%;
    width: 19%;
    margin-left: 81%;
    ">
      <a class="dot" ngDefaultControl style="width: 140px !important; color: red;
    border-radius: 40px;
    font-size: 15px;
    padding: 3px;
    " name="4" *ngFor='let item of listn' (click)="viewhokhelp1(item.MAINID)" id="about">
        {{item.FamilyName}} {{item.FirstName}}<br> <br></a>
    </div>
    <label [(ngModel)]="n.FamilyName" [ngModelOptions]="{standalone: true}" ngDefaultControl
      style="position: absolute;left: 420px;"> </label>
    <!--   עזרה חיצים מעבר הוק -->
    <!-- <button style="background-color: red;
        position: absolute;
        width: 10px;" (click)="merge()">אחד</button> -->
    <span style="position: absolute; 
 left: 120px;border-radius: 10px;font-size: 20px;background-color: gray; top: 140px;text-align: center" class="dot"
      id="d" (click)="showhokhelpdup(-1)">&laquo;</span>
    <span
      style="position: absolute;  left: 153px;border-radius: 10px;font-size: 20px;background-color: gray; top: 140px;text-align: center"
      class="dot" id="c" (click)="showhokhelpdup(1)">&raquo;</span>
    <!-- הוק עזרה מספרים -->
    <div style="position: absolute; top: 179px; left: 68px;font-size: 110%" [hidden]="new">
      <input class="input input1" [(ngModel)]="index" [ngModelOptions]="{standalone: true}" style="width: 80px"
        ngDefaultControl name="something1">/
      <input class="input input1" [(ngModel)]="numhokhelp" [ngModelOptions]="{standalone: true}"
        style="width: 80px;direction: ltr" ngDefaultControl name="something">
    </div>
    <!-- פרטי הוק עזרה -->
    <h6 style="text-align: center;    position: absolute; top: 112px; left: 547px;color: red;font-weight: bold;"
      name="6"> {{n.FamilyName}} {{n.FirstName}} </h6>
    <h1 style="text-align: center;    position: absolute; top: 60px; left: 357px;" name="6">הכנס הו"ק </h1>
    <div class="container" style="position: absolute;left: 345px; top: 118px;max-width: 350px;text-align: right;">
      <p>תאריך התחלה</p>
      <input type="date" class="form-control" (ngModelChange)="date1=$event" (change)="GetDateEnd()"
        [ngModel]=" date1| date:'yyyy-MM-dd'" [ngModelOptions]="{standalone: true}" #DateForiegnHAfkada>
      <p>תאריך סיום</p>
      <input type="date" class="form-control" (ngModelChange)="date=$event" [ngModel]="date| date:'yyyy-MM-dd'"
        [ngModelOptions]="{standalone: true}" #Dateend (change)="GetDateStart()">

        <p>מס תשלומים</p>
        <input type=" text" class="form-control" [(ngModel)]="Payments" (change)="mistashlumim(DateForiegnHAfkada.value)"
          ngDefaultControl [ngModelOptions]="{standalone: true}" value="{{hok2.MisTashlumim}}" #mis>
        <p>הפרש</p>
  
  
        <input type=" text" class="form-control" [(ngModel)]="Diffrence" ngDefaultControl
          value="{{hok2.DiffrenceMonthes}}" [ngModelOptions]="{standalone: true}">
  
        <p>סכום </p>
        <input type="number" class="form-control" [(ngModel)]="lAmount" [ngModelOptions]="{standalone: true}" 
          ngDefaultControl (change)="scum()" #Amount value="{{hok2.Scum}}">
        <p>עמלה</p>
        <input type="number" class="form-control" ngDefaultControl [(ngModel)]="amla1"
          [ngModelOptions]="{standalone: true}" #Amla>
        <p>סכום מצטבר</p>
        <input style="position: relative;" class="form-control" type="number" ngDefaultControl (change)="total()" 
          [(ngModel)]="TotalAmount" [ngModelOptions]="{standalone: true}">
<!--           
      <p>מס תשלומים</p>
      <input type=" text" class="form-control" [(ngModel)]="num5" (change)="mistashlumim(DateForiegnHAfkada.value)"
        ngDefaultControl [ngModelOptions]="{standalone: true}" value="{{hok2.MisTashlumim}}" #mis>
      <p>הפרש</p>


      <input type=" text" class="form-control" [(ngModel)]="Diffrence" ngDefaultControl
        value="{{hok2.DiffrenceMonthes}}" [ngModelOptions]="{standalone: true}">

      <p>סכום </p>
      <input type="number" class="form-control" [(ngModel)]="num6" [ngModelOptions]="{standalone: true}"
        ngDefaultControl (change)="scum()" #Amount value="{{hok2.Scum}}">
      <p>עמלה</p>
      <input type="number" class="form-control" ngDefaultControl [(ngModel)]="amla1"
        [ngModelOptions]="{standalone: true}" #Amla>
      <p>סכום מצטבר</p>
      <input style="position: relative;" class="form-control" type="number" ngDefaultControl (change)="total()"
        [(ngModel)]="num7" [ngModelOptions]="{standalone: true}"> -->
      <p>מטרה</p>
      <select #rr (change)="savetype(rr.value)" ngDefaultControl [(ngModel)]="hok2.typeop"
        [ngModelOptions]="{standalone: true}" *ngIf="type==false">
 
        <option>החזר </option>
        <option>תרומה </option>
      </select>
      <select #r (change)="savetype(r.value)" ngDefaultControl [(ngModel)]="hok2.typeop"
        [ngModelOptions]="{standalone: true}" *ngIf="type==true">
        <option>הפקדה </option>
        <option>תרומה </option>
      </select>

      <br>
      <!-- <input type="checkbox" checked="checked" [hidden]="!hok2.entereveryyear"
        style=" height: 17px; width: 17px;" (click)="everyyear()">   -->
<div style="display: flex;margin-left: 20%;">
  <div>
    <div class = "row" >
      <label style="font-size: 118%;font-weight: bold;position: absolute; right: 17px; top: 593px;">כל שנה
      <input type="checkbox"  [(ngModel)]='hok2.entereveryyear'  [ngModelOptions]="{standalone: true}" style=" height: 17px; width: 17px;"
        (click)="everyyear()"> </label>
      <br>
      <label style=" font-size: 118%;font-weight: bold; position:absolute; right: 128px; top: 593px; "> להכניס
      <input type="checkbox"  [(ngModel)]='hok2.pail'  [ngModelOptions]="{standalone: true}" style=" height: 17px; width: 17px;" #pail 
        (click)="pail1() ">
      </label>
      </div>
      <br>
      <label style="font-size: 118%;font-weight: bold;position: absolute;left: 183px; top: 622px;">חישוב אוטומטי 
      <input type="checkbox" style=" height: 17px; width: 17px;" [(ngModel)]="hok2.AutoCalculate" 
        [ngModelOptions]="{standalone: true}">
      </label>
      </div>
      <div style="margin-right: 2%;">
          <br>
          <label style="font-size: 118%;font-weight: bold; position: absolute; left: 200px; top: 651px;">ריבית שפיצר 
          <input type="checkbox" style=" height: 17px; width: 17px;" [(ngModel)]="hok2.shpitzer"
            [ngModelOptions]="{standalone: true}"></label>
            <br>
            <label style="font-size: 118%;">
            <input type="number" style=" height:33px; width: 47px;position: absolute; left: 142px; top: 650px;" [(ngModel)]="hok2.Rate"
            [ngModelOptions]="{standalone: true}">
             </label>
      
        <h1>{{x}}</h1>
      </div>
    
    </div>
      <!-- בחירת הוק -->
      <div style="position: absolute;top: 650px;">
        <p [hidden]="!edithok" style="position: absolute; left: -46px;  width: 90px; top: -389px; direction: rtl"> בחר
          הוק</p>
        <ng-select [(ngModel)]="mainid" [ngModelOptions]="{standalone: true}" [hidden]="!edithok"
          (change)='gethok($event)'
          style="width:284px;height: 32px; position: absolute;left: -240px; top: -348px; direction: rtl; background-color: gray; color: white"
          [items]="items4" bindValue="MAINID" #val bindLabel="Name">
        </ng-select>
        <label><b style="position: absolute; top: -126px; left: -58px; cursor: pointer;"> אשראי</b>
          <!-- <input *ngIf="ash==true"  style="position: absolute;top: -119px; left: 0px; cursor: pointer;" type="radio" checked="checked"
              name="radAnswer"> -->
          <input id="ash" (click)="fun()" style="position: absolute;top: -119px; left: 0px; cursor: pointer;"
            type="radio" checked="unchecked" name="radAnswer">
        </label>
        <label> <b style="position: absolute; top: -86px; left: -35px; cursor: pointer;">הוק</b>
          <!-- <input *ngIf="ho==true" style="position: absolute;top: -78px; left: -1px;" type="radio"
              checked="ho" name="radAnswer"> -->
          <input id="ho" (click)="fun1()" style="position: absolute; top: -78px; left: -1px;" type="radio"
            checked="unchecked" name="radAnswer">
        </label>
        <label> <b style="position: absolute; top: -52px; left: -52px; cursor: pointer;"> שיקים</b>
          <input id="check" checked="unchecked" (click)="fun2()" style="position: absolute; top: -44px; left: 0px;"
            type="radio" name="radAnswer">
          <!-- <input  *ngIf="check==true" checked="checked" style="position: absolute; top: -44px; left: 0px;" type="radio" name="radAnswer"> -->
        </label>
        <div style="text-align:center ;position: relative; top: -176px; left: -278px; width:10px;"
          *ngFor='let item of listbank'>
          <span class="dot1" id="aa" #v (click)="currentSlide(item)"><img id="img2"
              style="width: 90%;height: 90%;border-radius: 50%;" src="/assets/1/{{item[0]}}{{item[1]}}.jpg"></span>
        </div>
        <div style="text-align:center ;position: relative; top: -176px;left: -320px;  width:10px;"
          *ngFor='let item of listash'>
          <span id="bb" class="dot1" #v (click)="currentSlide1(item)"><img id="img2"
              style="width: 90%;height: 90%;border-radius: 50%;" src="/assets/1/{{item}}.jpg"></span>
        </div>
        <!-- חיצים מעבר הוק -->
        <span style="position: absolute; 
    left:-189px;border-radius: 10px;    ;font-size: 20px;background-color: gray; top: -215px;text-align: center"
          class="dot" id="b" (click)="showhokdup(-1)">&laquo;</span>
        <span
          style="position: absolute;  left: -154px;border-radius: 10px;font-size: 20px;background-color: gray; top: -215px;text-align: center"
          class="dot" id="a" (click)="showhokdup(1)">&raquo;</span>
        <!-- הוק מספרים -->
        <div style="position: absolute;top: -180px; left: -240px; font-size: 110%">
          <input class="input input1" [(ngModel)]="num0" [ngModelOptions]="{standalone: true}" style="width: 80px"
            ngDefaultControl name="something1">/
          <input class="input input1" [(ngModel)]="num3" [ngModelOptions]="{standalone: true}"
            style="width: 38px;direction: ltr" ngDefaultControl name="something">
          <span [hidden]='!deldegel'
            style="position : absolute; left: 130px; border-radius: 10px; width: 30px; height: 30px; font-size: 18px; top: -35px; text-align: center"
            class="dot" (click)="edit()"><img
              style="width: 90%; height: 80%;font-size:130%; position: absolute; left: 2px;top: 0px;"
              src="/assets/1/edit.png"></span>
        </div>
        <!-- הוק -->
        <div [hidden]="!ho" style="text-align: right;  position: absolute; top: -150px;border-radius: 18px;
              left: -218px;width: 150px; height: 150px;background-color: lightgray">
          <br>
          <label style="position: absolute; text-align: center;left: 47px; top: 8px;" #bank><b>:מס בנק</b>
            <br>{{hok1.BANK}} </label>
          <label style=" position: absolute; text-align: center;left:44px; top: 53px;"><b>:מס סניף</b>
            <br>{{hok1.SNIF}} </label>
          <label style="text-align: center;position: absolute; left: 38px; top: 101px;" #account><b> :מס
              חשבון</b><br>{{hok1.BANKACCOUNT}} </label>
        </div>
        <!-- אשראי -->
        <div [hidden]="!ash" style="text-align: right;  position: absolute; top: -150px;border-radius: 18px;
  left: -218px;width: 150px; height: 150px;   background-color: lightgray;">

          <!-- <br> -->
          <label style="position: absolute; text-align: center;left: 8px; top: 8px;"> <b>:מס'
              כרטיס</b><br>{{hok1.CardNumber}}</label>
          <label style=" position: absolute; text-align: center;left: 53px; top: 53px;"><b>:תוקף
            </b><br>{{hok1.Valid|date:'MM/yy'}} </label>
          <br>
          <label style="text-align: center;position: absolute; left: 57px; top: 101px;" [hidden]='!hok1.CVV'><b>:CVV
            </b> <br>{{hok1.CVV}} </label>
        </div>
        <div [hidden]="!check" style="text-align: right;  position: absolute; top: -150px;border-radius: 18px;
left: -218px;width: 150px; height: 150px;background-color: lightgray">
          <br>
          <b style="position: relative;height: 10px;left: -12px;">:שם בעל השיק</b>
          <input (change)="SaveNameCheck(v.value)" style="width: 120px;position: relative;height: 10px;left: -12px;"
            type="text" #v value="{{namecheck}} ">
        </div>
      </div>
      <label [hidden]="!BoolDateLimit" style="position: absolute;top: 663px;font-weight: bold;
        height: 8px;
        width: 245px;
        left: -141px;">מוגבל עד תאריך {{hok1.DateLimit|date:'MM/yy'}}</label>
      <label [hidden]="!BoolScumLimit" style="position: absolute; top: 664px; font-weight: bold;
              height: 8px;
              width: 195px;
              left: -306px;">מוגבל עד סכום {{hok1.ScumLimit}} </label>
      <button [disabled]="!savedegel" type="button" onclick="document.getElementById('id02').style.display='none'"
        class="button button1" (click)='saveall()'> הכנס </button>
      <button type="button" onclick="document.getElementById('id02').style.display='none'"
        class="cancelbtn">ביטול</button>
    </div>
    <span [hidden]='!deldegel' style="position: absolute; left: 194px;border-radius: 10px;width: 30px;
height: 30px;
font-size: 18px;top: 140px;text-align: center" class="dot" (click)="del()"><i
        style="width: 90%; height: 80%;font-size:130%; position: absolute; left: 2px;top: 3px;"
        class="fa fa-trash"></i></span>
    <span style="position: absolute; left:229px;border-radius: 10px;width: 30px;
height: 30px;
font-size: 18px;;top: 140px;text-align: center" class="dot" (click)="addmore()">+</span>
    <span style="position: absolute; left: 263px;border-radius: 10px;width: 30px;
 height: 30px;
 font-size: 18px;;top: 140px;text-align: center" class="dot"
      (click)="save(mis.value,Amount.value,DateForiegnHAfkada.value,Amla.value)">V</span>
  </form>
</div>