import { Component, OnInit, Input } from '@angular/core';
import { NamesService } from 'src/app/services/names.service';
import { WaitingService } from 'src/app/services/waiting.service';
import { WaitingLIst } from 'src/app/classes/waiting-list';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { Names } from 'src/app/classes/names';
import { Params, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-add-waiting',
  templateUrl: './add-waiting.component.html',
  styleUrls: ['./add-waiting.component.css']
})
export class AddWaitingComponent implements OnInit {

  constructor(private wait: WaitingService, private maagar: MaagarMenageService, private name: NamesService, private route: ActivatedRoute) { }
  //#region 
  @Input() mainid;
  @Input() add;
  today: Date = new Date();
  id1;
  m: boolean = true;
  waiting: WaitingLIst = new WaitingLIst();
  PERMISSION: any;
  iduser: any;
  listN: Names[];
  listN1: Names[];
  listN2: Names[];
  new: boolean;
  listN3: Names[];
  n: Names = new Names();
  n1: Names = new Names();
  n2: Names = new Names();
  n3: Names = new Names();
  listcoin;
  city1;
  coin2;
  exist: boolean;
  id;
  w: WaitingLIst = new WaitingLIst();
  listw;
  myDate: Date = new Date();
  //#endregion
  ngOnInit() {
    this.GetCoins();
  }
  show(MAINID: number) {
    
    this.mainid = MAINID;
    document.getElementById('id35').style.display = 'block';
    this.GetCoins();
    this.shownames();
    this.add = false;
  }
  changevalue() {
    ;
this.w.GetLoan=!this.w.GetLoan;
  }
  CStatusBakasha() {
this.w.StatusBakasha=!this.w.StatusBakasha;
  }
  shownames() {
    if (this.mainid != null) {
      this.m = true;
      this.listN = JSON.parse(sessionStorage.getItem('ListNames'));
      if (this.listN == null) {
      this.name.ShowNames().then(res => {
        this.listN = res;
        this.n = this.listN.find(a => a.MAINID == this.mainid)
        this.listN.forEach(element => {
          if (element['CellPhone1'] != null && element['FirstName'] != null) {
            element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
          }
          else {
            if (element['CellPhone2'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
            }
            else {
              if (element['Phone'] != null && element['FirstName'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
              }
              else {
                if (element['CellPhone1'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
                }
                else {
                  if (element['CellPhone2'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                  }
                  else {
                    if (element['Phone'] != null) {
                      element['Name'] = element['FamilyName'] + " " + element['Phone']
                    }
                    else {
                      element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName']

                    }
                  }
                }
              }
            }
          }
        }) })}
          
        this.showwait();

     
    }
    else {
      alert("לא נבחר שם");
      this.m = false;
    }
  }
  savedesire() {
    this.w.Coin = this.listcoin.find(a => a.name == this.coin2).Id;
    ;
    if (!this.exist) {
      this.w.MAINID = this.mainid;
      this.wait.addwaitdesire(this.w);
    }
    else {
      this.wait.update(this.w);
    }
  }
  //#region bool
  coin1(coin: string) {
    this.coin2 = coin;
  }
  //#endregion
  //#region show
  GetCoins() {
    this.listcoin = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoin == null) {
    this.maagar.GetCoins().then(res => {
      this.listcoin = res;
      sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));

      this.coin2 = this.listcoin[0].name
    })}
    else{
      this.coin2 = this.listcoin[0].name

    }
  }
  // PutWaitingok(id: number, date: Date, scum: number) {
  //   this.wait.PutWaitingok(id, date, scum, false);
  // }
  showwait() {
    this.wait.ShowWaitbymainid(this.mainid).then(res => {
      this.listw = res;
      this.listN1 = this.listN;
      this.listN2 = this.listN;
      this.listN3 = this.listN;
      if (this.listw.length > 0) {
        this.new = false;
        this.exist = true;
        this.listw.forEach(element => {
          element['coinname'] = this.listcoin.find(a => a.Id == element.Coin).name
        });
        this.w = this.listw[0]
        if(this.w.GetLoan==null)
        {
          this.w.GetLoan=false;
        }
        this.coin2 = this.listcoin.find(a => a.Id == this.w.Coin).name;
        if (this.w.MAINID1 != null && this.w.MAINID1 != 0) {
          ;;
          this.listN1.push(this.listN[0])
          this.listN1[0] = this.listN.find(a => a.MAINID == this.w.MAINID1);
        }
        if (this.w.MAINID2 != null && this.w.MAINID2 != 0) {
          this.listN2.push(this.listN[0]);
          ;;
          this.listN2[0] = this.listN.find(a => a.MAINID == this.w.MAINID2);
        }
        if (this.w.MAINID3 != null && this.w.MAINID3 != 0) {
          this.listN3.push(this.listN[0])
          this.listN3[0] = this.listN.find(a => a.MAINID == this.w.MAINID3);
        }
      }
      else {
        this.addmore();
      }
    })
  }
  save1(event) {
    this.w.MAINID2 = event.MAINID;
    this.n1 = event;
  }
  save(event) {
    this.w.MAINID1 = event.MAINID;
    this.n2 = event;

  }
  save2(event) {
    this.w.MAINID3 = event.MAINID;
    this.n3 = event;
  }
  addmore() {
    this.new = true;
    this.exist = false;
    this.w = new WaitingLIst();
    this.w.DateDesire = this.today;

  }
  del() {
    this.wait.Del(this.w.id);
  }
  //#endregion
}
