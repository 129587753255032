export class Excel {
    Id:number
idline: number; 
dateoperation: Date; 
SCUM: number;
SCUMO: number;
validate;
SCUMz: number;
Totalscum: number;  
Remark: string;
Mainid;
Status;
insertto;
notask:boolean

Constructor()
{
this.idline=null;
this.dateoperation =null;
this.SCUM =null;
this.SCUMz=null;
this.Totalscum =null;
this.Remark =null;
this.Mainid =null;
this.Status=null;
}
}
