import { Component, OnInit, Input } from '@angular/core';
import { LoansService } from 'src/app/services/loans.service';
// import { NamesService } from 'src/app/services/names.service';
import { Heczer } from 'src/app/classes/heczer';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { UserService } from 'src/app/services/user.service';
import { Names } from 'src/app/classes/names';
// import { isDefaultChangeDetectionStrategy } from '@angular/core/src/change_detection/constants';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { NamesService } from 'src/app/services/names.service';

@Component({
  selector: 'app-heczers',
  templateUrl: './heczers.component.html',
  styleUrls: ['./heczers.component.css']
})
export class HeczersComponent implements OnInit {

  //#region 
  @Input() iduser;
  @Input() idloan;
  @Input() PERMISSION;
  @Input() mainid;
  list;
  date1: boolean = false;
  id1;
  listcoin;
  listfuture;
  listwas;
  listcomeback;
  num: number = 0;
  num1: number = 0;
  namep;
  listn: Names[];
  MyDate: Date = new Date();
  getbyway1;
  getbyway2;
  val;
  heczer: Heczer = new Heczer();
  num2: number = 0;
  LIST2;
  h: Heczer = new Heczer();
  listop: any;
  degelrightclick: boolean;
  bool: boolean;
  date: Date;
  date2: Date;
  allbool: boolean;
  onebool: boolean;
  onefbool: boolean;
  allfbool: boolean;
  coin1;
  coin2;
  myDate: Date;
  amla1: boolean = true;
  columnDefs;
  gridOptions; defaultColDef;
  rowData: Array<any>;
  listreason: any;
  idr: number;
  reason: string;
  rowSelection: string;
  localeText: any;
  gridApi: any;
  num3: number = 0;
  num4: number = 0;
  items: any;
  mainidma: any;
  D: boolean;
  tzoverD: number;
  tzover: number;
  num8: number;
  amlatotal: number;
  amla2: number;
  rowClassRules;
  //#endregion
  constructor(private loan: LoansService, private maagar: MaagarMenageService, private hok: HokMenageService, private name: NamesService) { }
  ngOnInit() {
    document.getElementById('id018').style.display = 'none';

    this.myDate = new Date();
    this.getreason();

    this.GetCoins();
    if (this.PERMISSION == 3) {
      this.bool = true;
    }
    else {
      this.bool = false;
    }
    this.Getoptain();
    this.columnDefs = [
      { headerName: 'שם מחזיר', field: 'Name', sortable: true, filter: "agTextColumnFilter", width: 130, editable: true },
      {
        headerName: 'תאריך לועזי', field: 'DateForiegnHeczer', filter: "agTextColumnFilter", width: 130, editable: true, sortable: true, sort: 'asc', cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך החזר', field: 'DateHeczer', filter: "agTextColumnFilter", width: 130, editable: true },
      { headerName: 'סכום', field: 'Scum', filter: "agNumberColumnFilter", width: 100, editable: true },
      { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 100, editable: true },
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: "agNumberColumnFilter", width: 110, editable: true },
      { headerName: 'מטבע', field: 'CoinName', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'סטטוס החזר', field: 'StatusHeczer', filter: "agTextColumnFilter", width: 110, editable: true },
      { headerName: 'אמצעי תשלום', field: 'getbyway', filter: "agTextColumnFilter", width: 90 },

      { headerName: 'מטרה', field: 'TypeOperation', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'הערה', field: 'Notice', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'סיבת חזרה', field: 'reasonName', filter: "agTextColumnFilter", width: 100, editable: true },

      { headerName: 'אסמכתא', field: 'ASMACTA', filter: "agTextColumnFilter", width: 80, editable: true },
      { headerName: 'מס"ב', field: 'MASAV', filter: "agTextColumnFilter", width: 80, editable: true },
      //  {headerName: 'תאריך יצירה', field: 'CreateDat',filter: true ,width: 80,editable: true},
      //  {headerName: 'נוצר ע"י', field: 'Createby',filter: true ,width: 80,editable: true},
      //  {headerName: 'תאריך עדכון', field: 'UpdateDat',filter: true ,width: 80,editable: true},
      //  {headerName: 'עודכן ע"י', field: 'Updateby',filter: true ,width: 80,editable: true}
    ];
  }
  //#region //bool

  savecoin1(v: string) {
    this.coin1 = v;
  }
  saver(val: string) {
    this.reason = val;
  }
  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  Change() {
    this.h[0].SUMTOGVIA = this.h[0].Scum + this.h[0].Amla;
  }
  ShowDolars() {
    this.EditList(2);
  }
  ShowShekel() {
    this.EditList(1);
  }
  filterChanged() {
    this.LIST2 = new Array<Heczer>();
    this.gridApi.rowModel.rowsToDisplay.forEach(element => {
      this.LIST2.push(element.data)
    });
    this.EditList(this.list[0].Coin)

  }
  EditList(coin: number) {

    this.num1 = this.num2 = this.num3 = this.num4 = this.num = 0
    if (coin == 2) {
      this.D = true;
    }
    else {
      this.D = false;

    }
 
 
    this.listfuture = this.LIST2.filter(a => (a.StatusHeczer == "קבע-דחוי" || a.StatusHeczer == "שיק-דחוי" || a.StatusHeczer == "אשראי-דחוי") && a.Coin == coin);
    this.listwas = this.LIST2.filter(a => a.StatusHeczer == "בוצע" && a.Coin == coin);
    this.listcomeback = this.LIST2.filter(a => a.StatusHeczer == "חזר" && a.Coin == coin);
    this.num1 = 0; this.num2 = 0; this.num = 0; this.amla2 = 0; this.amlatotal = 0;
    this.listfuture.forEach(element => {
 
      if(element.TypeOperation != "תרומה")
        {
          this.num += element.Scum;
          this.num4 += element.Amla;
  
        }
        else{
       
        this.num4 +=element.Scum+element.Amla;
        }
    })
    this.listwas.forEach(element => {
      if(element.TypeOperation != "תרומה")
      {
        this.num1 += element.Scum;
        this.num3 += element.Amla;

      }
      else{
     
      this.num3 +=element.Scum+element.Amla;
      }
    })
    this.listcomeback.forEach(element => { this.num2 += 1; })
    this.num += this.num1;
    this.num4 += this.num3
    this.list.forEach(element => {
      element['CoinName'] = this.listcoin && this.listcoin.find(a => a.Id == element.Coin) ? this.listcoin.find(a => a.Id == element.Coin).name : '';
      element['SUMTOGVIA'] = element.Scum + element.Amla;
      element['reasonName'] = this.listreason && this.listreason?.find(a => a.Id == element.reason) ? this.listreason?.find(a => a.Id == element.reason).name : '';

      element['Name'] = this.listn && this.listn.find(a => a.MAINID == element.MAINID) ? this.listn.find(a => a.MAINID == element.MAINID)['Name'] : '';
    })
    this.rowData = this.list;
    this.buildgrid();
  }
  // filterChanged()
  // {

  //   this.num=0;
  //   this.num1=0;
  //   this.num2=0;
  //   this.num3=0;
  //   this.num4=0;
  //   this.listfuture=null;
  //   this.listcomeback=null;
  //   this.listwas=null;
  //   if(this.LIST2.length>0)
  //   {
  // this.list = this.gridApi.rowModel.rowsToDisplay;
  // this.listfuture=this.list.filter(a=>a.data.StatusHeczer=="קבע-דחוי"||a.data.StatusHeczer=="שיק-דחוי"||a.data.StatusHeczer=="אשראי-דחוי");
  // this.listwas=this.list.filter(a=>a.data.StatusHeczer=="בוצע");
  // this.listcomeback=this.list.filter(a=>a.data.StatusHeczer=="חזר");
  // this.listfuture.forEach(element => {  
  // this.num+=  element.data.Scum;
  // this.num4+=  element.data.Amla;

  // })
  // this.listwas.forEach(element => {  
  // this.num1+=  element.data.Scum;
  // this.num3+=  element.data.Amla;
  // })
  // this.listcomeback.forEach(element => {
  // this.num2+=  element.data.Scum;})    

  // this.num+=this.num1;
  // this.num4+=this.num3;

  // }
  // this.EditList(this.list[0].Coin)
  // }
  savedate(d: Date) {
    this.date2 = d;
  }
  savecoin(v: string) {
    this.coin2 = v;

  }
  amla() {
    this.amla1 = !this.amla1;
  }
  SelectMachzir(event) {
    ;
    this.mainidma = event.MAINID;
  }
  changeval(v: string) {
    this.val = v;
    ;;
    if (this.val == "בתאריך אחר") {
      this.date1 = true;
    }
  }
  getbyway(v1: string) {
    ;
    this.getbyway1 = v1;
  }
  getbyway3(v1: string) {
    this.getbyway2 = v1;
  }
  //#endregion
  //#region  del
  deletehaf() {
    if (this.allbool) {
      if (window.confirm('האם ברצונך לאפס הלוואה זו?')) {
        this.loan.DeleteAllhafkadot(this.idloan).then(res => {

          this.showHeczers(this.idloan)

        });;
      }
    }
    else {
      if (this.onebool) {
        ;

        if (window.confirm('האם ברצונך למחוק החזרים שכבר בוצעו?')) {
          this.loan.DeleteJustOnehafkadot(this.idloan, this.gridApi.rowModel.rowsToDisplay.data.idhokhelp).then(res => {

            this.showHeczers(this.idloan)

          });;
        }

      }
      else {
        if (this.allfbool) {
          ;;
          this.loan.DeleteAllFuturehafkadot(this.idloan).then(res => {

            this.showHeczers(this.idloan)

          });;

        }
        else {
          ;
          this.loan.DeleteJustOneFuturehafkadot(this.idloan, this.gridApi.rowModel.rowsToDisplay.data.idhokhelp).then(res => {

            this.showHeczers(this.idloan)

          });;

        }
      }
    }
  }
  delete() {
    this.degelrightclick = false;
    this.loan.DeleteHafkada(this.heczer.Id).then(res => {

      this.showHeczers(this.idloan)

    });;
  }
  justone() {
    this.onebool = true;
    this.allbool = false;
    this.onefbool = false;
    this.allfbool = false;
  }
  all() {
    this.allbool = true;
    this.onebool = false;
    this.onefbool = false;
    this.allfbool = false;

  }
  justonef() {
    this.onefbool = true;
    this.onebool = false;
    this.allbool = false;
    this.allfbool = false;
  }
  allf() {
    this.allfbool = true;
    this.onebool = false;
    this.allbool = false;
    this.onefbool = false;

  }
  //#endregion
  //#region optain
  StopHafkadot(num: number) {
    if (this.heczer.StatusHeczer == "קבע-דחוי" || this.heczer.StatusHeczer == "אשראי-דחוי" || this.heczer.StatusHeczer == "שיק-דחוי") {
      this.degelrightclick = false;
      this.loan.StopHafkadot(this.heczer.Id, num).then(res => {

        this.showHeczers(this.idloan)

      });;
    }
    else {
      alert("ניתן להשהות רק תשלומים דחויים");
    }
  }
  pay() {
    if (this.heczer.StatusHeczer == "קבע-דחוי" || this.heczer.StatusHeczer == "אשראי-דחוי" || this.heczer.StatusHeczer == "שיק-דחוי") {
      this.degelrightclick = false;

      this.loan.pay(this.heczer.Id, this.getbyway2).then(res => {

        this.showHeczers(this.idloan)

      });;
    }
    else {
      alert("לא ניתן לסמן כשולם");
    }
  }
  hokreturn() {
    // if (this.heczer.StatusHeczer == "בוצע") {
      this.degelrightclick = false;
      this.idr = this.listreason.find(a => a.name == this.reason).Id;
      this.loan.HokReturn(this.heczer.Id, this.val, this.amla1, this.iduser, this.date2, this.idr).then(res => {

        this.showHeczers(this.idloan)

      });

    // }
    // else {
    //   alert("לא ניתן לסמן כחזר");
    // }

  }
  excel() {
    
    this.gridApi.exportDataAsCsv(this.gridApi.rowModel.rowsToDisplay);
  }
  RestartBalance()
  {
    this.loan.RestartBalance(this.idloan);

  }
  addheczer() {
    this.h[0].Coin = this.listcoin && this.listcoin.find(a => a.Id == this.h[0]['CoinName']) ? this.listcoin.find(a => a.Id == this.h[0]['CoinName']).Id : 1;
    this.loan.updateheczer(this.h[0]);
  }
  save(d: Date) {

    this.h.IdLoan = this.idloan;
    this.h.DateForiegnHeczer = d;
    if (this.mainidma == null) {
      this.h.MAINID = this.mainid;
    }
    else {
      this.h.MAINID = this.mainidma;

    }
    this.h.getbyway = this.getbyway1;
    this.h.Coin = this.listcoin && this.listcoin.find(a => a.name == this.coin2) ? this.listcoin.find(a => a.name == this.coin2).Id : 1;
    this.h.Updateby = this.iduser;
    this.h.Createby = this.iduser;

    this.h.StatusHeczer = "בוצע";
    this.h.TypeOperation = "החזר";
    this.h.IdLoan = this.idloan;
    this.h.TypeHok = this.getbyway1;
    this.loan.addheczer(this.h);
    this.h = new Heczer();
  }
  fun(event) {
    this.date = event.data.DateForiegnHeczer;
    this.degelrightclick = !this.degelrightclick;
    this.heczer = event.data;
  }
  shekel() {
  }
  dollar() { }

  view(event) {

    this.h = this.LIST2.filter(a => a.Id == event.data.Id);
    this.h[0]['CoinName'] = this.listcoin && this.listcoin.find(a => a.Id == this.h[0].Coin) ? this.listcoin.find(a => a.Id == this.h[0].Coin).name : '';
    this.h[0]['SUMTOGVIA'] = this.h[0].Scum + this.h[0].Amla;

    document.getElementById('id018').style.display = 'block';
  }
  //#endregion
  //#region getdata
  viewnameslist() {
       this.items = JSON.parse(sessionStorage.getItem('ListNames'));
       this.listn = this.items;
    if (this.items == null) {
    this.name.ShowNames().then(res => {
      this.items = res;
      this.listn = res;
    })}
      this.items?.forEach(element => {
        element['Name1'] = element['FamilyName'] + " " + element['FirstName']
        if (element['CellPhone1'] != null && element['FirstName'] != null) {
          element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
        }
        else {
          if (element['CellPhone2'] != null && element['FirstName'] != null) {
            element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
          }
          else {
            if (element['Phone'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
            }
            else {
              if (element['CellPhone1'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
              }
              else {
                if (element['CellPhone2'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                }
                else {
                  if (element['Phone'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['Phone']
                  }
                  else {
                    element['Name'] = element['FamilyName'] + " " + element['FirstName']
                  }
                }
              }
            }
          }
        }
      })
  this.showHeczers(this.idloan);

  }
  buildgrid() {
    this.rowClassRules = {
      'status1': function (params) { return params.data.StatusHeczer == "חזר"; },

      'status2': function (params) { return params.data.StatusHeczer == "בוצע"; }
    },
      this.localeText = LOCALE_TEXT;
    this.rowSelection = 'single';

    this.rowData = this.list;
    this.columnDefs = this.columnDefs;
    ;
  }
  GetCoins() {
    this.listcoin = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoin == null) {
      this.maagar.GetCoins().then(res => {
        this.listcoin = res;
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));

        if (this.idloan != null) {
          this.viewnameslist();
        }
      })
    }
    else {
      if (this.idloan != null) {
        this.viewnameslist();
      }
    }
  }
  getreason() {
    {
      this.hok.getreason().then(res => {
        this.listreason = res;
        this.reason = this.listreason[0].name;
      })
    }
  }
  showHeczers(id: number) {

    this.idloan = id;
    this.loan.ViewHeczers(id).then(res => {
      this.list = res;
      ;
      this.LIST2 = res;
      ;
      this.EditList(1);
    })
  }
  showHeczers1() {
    this.list = null;
    this.rowData = this.list;
    this.buildgrid();
  }
  Getoptain() {
    this.hok.getlistoptains().then(res => {
      this.listop = res;
      this.val = this.listop[0];

    })
  }
  // GetNames()
  // {
  // this.loan.getlistNames(this.idloan).then(res=>{
  //  this.listn=res;
  //  this.listn.forEach(element => {
  //   element['Name'] = element['FamilyName']+" "+element['FirstName'];
  // })
  // ;;
  //  if(this.listn.length>1)
  //  {
  //  this.listn.push(this.listn[0]); this.listn[0]=new Names(); }
  //  })}
  //#endregion

}

