import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core'
import { MafkidService } from 'src/app/services/mafkid.service'
import { HokHelp } from 'src/app/classes/hok-help'
import { HokMenageService } from 'src/app/services/hok-menage.service'
import { NamesService } from 'src/app/services/names.service'
import { Names } from 'src/app/classes/names'
import { Hok } from 'src/app/classes/hok'
import { LoansService } from 'src/app/services/loans.service'

import { HokHelpService } from 'src/app/services/hok-help.service'
import { HafkadotService } from 'src/app/services/hafkadot.service'

import { MaagarMenageService } from 'src/app/services/maagar-menage.service'
import { Loans } from 'src/app/classes/loans'
import { MAfkidCount } from 'src/app/classes/mafkid-count'
import { Maslul } from 'src/app/classes/maslul'
import { ActivatedRoute, Params, Router } from '@angular/router'
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  constructor(private cd: ChangeDetectorRef, private hokh: HokMenageService, private route: ActivatedRoute, private router: Router, private maagar: MaagarMenageService, private name: NamesService, private hafk: HafkadotService, private maf: MafkidService, private loan: LoansService, private hokhelp: HokHelpService) {
    this.route.params.subscribe((params: Params) => {
      this.iduser = params.iduser
      this.PERMISSION = params.id
      this.id = params.mainid
      this.nametbl = "Hafkadot"
      this.idmafkid = params.idmafkid
      if (params.index != null) {
        this.index = params.index
      }
    }
    )
  }
  //#region //params  
  id1
  BoolDateLimit: boolean
  Diffrence: number = 1
  BoolScumLimit: boolean
  bool: boolean
  x: string
  nametbl: string
  new1: boolean
  iduser: any
  PERMISSION: any
  id: any
  m: Maslul = new Maslul()
  trumatype: boolean
  n: Names = new Names()
  namecheck: string
  listtypes: Array<string> = new Array<string>()
  @Input() idloan

  @Input() idmafkid
  @Input() hafkada
  listhok1
  numhokhelp: number
  index: number
  Payments: number = 1
  lAmount: number = 1
  TotalAmount: number = 1
  num1: number = 1
  amla: number = 0
  amla1: number
  listash: string[]
  listash1: any
  listbank1: any
  ashrai: any
  num3: number
  num0: number
  date1: Date
  boolhafhaf: boolean = false
  edithok: boolean = false
  boolhaft: boolean = false
  list
  typeopeeation
  mainid
  list3
  mydate: Date = new Date()
  mydate1: Date = new Date()
  listn
  list1: Array<Hok> = new Array<Hok>()
  listhok
  pail: boolean
  deldegel: boolean = false
  savedegel: boolean = true
  list2
  num: number = 1
  ash: boolean = false
  ho: boolean
  year: boolean = false
  listbank
  new: boolean = true
  bank
  check
  type: boolean
  boolhaf: boolean
  date: Date
  items4: Names[]
  hok: HokHelp = new HokHelp()
  hok2: HokHelp = new HokHelp()
  total1: number = 0
  hoklist: HokHelp[]
  hok1: Hok = new Hok()
  loan1: Loans = new Loans()
  mafkid1: MAfkidCount = new MAfkidCount()

  //#endregion
  ngOnInit() {
    this.n.FamilyName = ""
    this.n.FirstName = ""
    if (this.idmafkid != null) {
      this.new1 = false
      this.type = true
      this.boolhaf = true
      this.typeopeeation = "הפקדה"
      this.id1 = this.idmafkid
      this.nametbl = "HokHelp"
    }
    else {
      this.new1 = false
      this.trumatype = false
      this.id1 = this.idloan
      this.typeopeeation = "החזר"
      this.type = false;
      this.boolhaf = false
    }
    this.ho = false
  }
  //#region //hokshow
  viewnameslist() {
    this.name.ShowNameswithhok().then(res => {
      this.items4 = res

      if (this.mainid != null) {
        this.items4.push(this.items4[0])
        this.items4[0] = this.items4.find(a => a.MAINID == this.mainid)
      }
      this.items4.forEach(element => {
        if (element['CellPhone1'] != null && element['FirstName'] != null) {
          element.Name = element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
        }
        else {
          if (element['CellPhone2'] != null && element['FirstName'] != null) {
            element.Name = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
          }
          else {
            if (element['Phone'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
            }
            else {
              if (element['CellPhone1'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
              }
              else {
                if (element['CellPhone2'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                }
                else {
                  if (element['Phone'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['Phone']
                  }
                  else {
                    element['Name'] = element['FamilyName'] + " " + element['FirstName']

                  }
                }
              }
            }
          }
        }
      })
    })
  }
  gethok(event) {
    this.mainid = event.MAINID
    this.n = event

    document.getElementById('a').style.backgroundColor = '#f44336'
    document.getElementById('b').style.backgroundColor = '#f44336'
    this.hokh.GetHoksById(this.mainid).then(response => {
      this.list = response
      this.hok1 = this.list[0]
      if (this.hok1.DateLimit != null) {
        this.BoolDateLimit = true
      }
      else {
        this.BoolDateLimit = false

      }
      if (this.hok1.ScumLimit != null) {
        this.BoolScumLimit = true
      }
      else {
        this.BoolScumLimit = false

      }
      if (this.hok1 == null) {
        this.hok1 = new Hok()
      }
      this.listbank = new Array<string>()
      this.listash = new Array<string>()

      this.listash1 = this.list.filter(a => a.CardNumber != null)
      if (this.listash1[0] != null) {
        this.listash.push(this.listash1[0].kodtypeash)
        this.fun()
        this.currentSlide1(this.listash[0])
      }
      this.listbank1 = this.list.filter(a => a.BANK != null)
      if (this.listbank1[0] != null) {
        this.fun1()
        this.listbank.push(this.listbank1[0].BANK)
        this.currentSlide(this.listbank[0])
      }
      if (this.listash1[0] != null) {
        this.listash1.forEach(element => {
          if (this.listash.find(a => a == element.kodtypeash) == null) {
            this.listash.push(element.kodtypeash)
          }
        })
      }
      if (this.listbank1[0] != null) {
        this.listbank1.forEach(element => {

          if (this.listbank.find(a => a == element.BANK) == null) {

            this.listbank.push(element.BANK)
          }
        })
      }
    })

  }
  gethok1(hok: Hok) {
    this.list1 = new Array<Hok>()
    this.mainid = hok.MAINID

    document.getElementById('a').style.backgroundColor = '#f44336'
    document.getElementById('b').style.backgroundColor = '#f44336'
    this.hokh.GetHoksById(this.mainid).then(response => {
      this.list = response
      this.list1.push(hok)
      this.list.forEach(element => {
        if (this.list1.find(a => a.ID == element.ID) == null) {
          this.list1.push(element)
        }
      })
      this.hok1 = hok
      this.listbank = new Array<string>()
      this.listash = new Array<string>()

      this.listash1 = this.list.filter(a => a.BANK == null)
      if (this.listash1[0] != null) {
        this.listash.push(this.listash1[0].kodtypeash)

      }
      this.listbank1 = this.list.filter(a => a.BANK != null)
      if (this.listbank1[0] != null) {
        this.listbank.push(this.listbank1[0].BANK)
      }
      if (this.hok1.TypeHok != "אשראי") {
        this.fun1()
        this.list2 = this.list1.filter(a => a.BANK == this.hok1.BANK)
        this.ashrai = null
        this.num = 0
        this.bank = this.hok1.BANK
      }
      else {
        this.fun()
        this.ashrai = this.hok1.kodtypeash
        this.bank = null
        this.list2 = this.list1.filter(a => a.kodtypeash == this.hok1.kodtypeash)
      }
      this.num3 = this.list2.length
      this.num0 = 1
      if (this.listash1[0] != null) {
        this.listash1.forEach(element => {
          if (this.listash.find(a => a == element.kodtypeash) == null) {
            this.listash.push(element.kodtypeash)
          }
        })
      }
      if (this.listbank1[0] != null) {
        this.listbank1.forEach(element => {

          if (this.listbank.find(a => a == element.BANK) == null) {

            this.listbank.push(element.BANK)
          }
        })
      }
    })

  }
  showhokdup(n: number) {

    if (this.bank != null) {
      this.ho = true
      this.list2 = this.list.filter(a => a.BANK == this.bank)
    }
    else {
      this.ash = true
      this.list2 = this.list.filter(a => a.kodtypeash == this.ashrai)
    }
    this.num3 = this.list2.length
    if (this.num + n >= 0 && this.num + n < this.num3) {
      if (this.num3 == 1) {
        document.getElementById('a').style.backgroundColor = 'lightgray'
        document.getElementById('b').style.backgroundColor = 'lightgray'
        this.hok1 = this.list2[0]
      }
      else {
        this.hok1 = this.list2[this.num + n]
        if (this.num + n == 0) {
          document.getElementById('b').style.backgroundColor = 'lightgray'
        }
        else {
          if (this.num + n + 1 == this.num3) {
            document.getElementById('a').style.backgroundColor = 'lightgray'
          }
        }
      }
      this.num += n
      this.num0 = this.num + 1
    }
    else {
      if (this.num + n <= 0) {
        document.getElementById('b').style.backgroundColor = 'lightgray'
        this.num = 0
        this.num0 = this.num + 1

        this.hok1 = this.list2[0]
      }
      else {
        document.getElementById('a').style.backgroundColor = 'lightgray'
        this.hok1 = this.list2[this.num3 - 1]

        this.num = this.num3
        this.num0 = this.num
      }
    }
    if (this.hok1.DateLimit != null) {
      this.BoolDateLimit = true
    }
    else {
      this.BoolDateLimit = false

    }
    if (this.hok1.ScumLimit != null) {
      this.BoolScumLimit = true
    }
    else {
      this.BoolScumLimit = false

    }
  }
  currentSlide(value: string) {
    this.ho = true
    HokMenageService.update = true
    this.bank = value
    this.ashrai = null
    this.ash = false
    this.num = 0
    this.hok1 = this.list.find((a) => a.BANK == value) ? this.list.find((a) => a.BANK == value) : null
    this.showhokdup(0)
  }
  currentSlide1(value: string) {

    this.ho = false
    this.bank = null
    this.ashrai = value
    this.ash = true
    this.num = 0
    this.hok1 = this.list.find(a => a.kodtypeash == value) ? this.list.find(a => a.kodtypeash == value) : null
    this.showhokdup(0)
  }
  fun() {
    debugger
    (document.getElementById('ash') as HTMLInputElement).checked = true;
    (document.getElementById('ho') as HTMLInputElement).checked = false;
    (document.getElementById('check') as HTMLInputElement).checked = false;
    this.ash = true
    this.ho = false
    this.check = false
    this.num = 0
    this.num1 = 0
  }
  fun1() {
    debugger
    (document.getElementById('ash') as HTMLInputElement).checked = false;
    (document.getElementById('ho') as HTMLInputElement).checked = true;
    (document.getElementById('check') as HTMLInputElement).checked = false;
    this.ash = false
    this.ho = true
    this.check = false
    this.num = 0
    this.num1 = 0
  }
  fun2() {
    debugger
    (document.getElementById('ash') as HTMLInputElement).checked = false;
    (document.getElementById('ho') as HTMLInputElement).checked = false;
    (document.getElementById('check') as HTMLInputElement).checked = true;
    this.ash = false
    this.ho = false
    this.check = true
    debugger
    this.namecheck=this.n.FamilyName+' '+this.n.FirstName;
  }
  SaveNameCheck(value: string) {
    this.namecheck = value
  }
  //#endregion
  //#region //calculate
  GetDateEnd() {

    this.hokhelp.GetDateEnd(this.date1, this.Payments, this.Diffrence).then(response => {
      this.date = response
    })
  }
  GetDateStart() {

    this.hokhelp.GetDateStart(this.date, this.Payments, this.Diffrence).then(response => {
      this.date1 = response
    })
  }
  getmis() {

    this.hokhelp.GetMistashlumim(this.date1, this.date, this.Diffrence).then(response => {
      this.Payments = response
    })
  }
  getmisafterChangestart() {
    this.hokhelp.getmisafterChangestart(this.date1, this.date).then(response => {
      this.Payments = response
    })
  }
  mistashlumim(date: Date) {
    if (this.amla != 0) {
      this.amla1 = this.amla / this.Payments
    }
    if (this.TotalAmount != 1) {
      this.lAmount = this.TotalAmount / this.Payments
    }
    else {
      if (this.lAmount != 1) {
        this.TotalAmount = this.lAmount * this.Payments
      }
    }
    this.GetDateEnd()


  }
  scum() {
    if (this.TotalAmount != 1 && this.TotalAmount != 0 && this.TotalAmount != null) {
      this.Payments = this.TotalAmount / this.lAmount
    }
    else {
      if (this.Payments != 1 && this.Payments != 0 && this.Payments != null) {
        this.TotalAmount = this.lAmount * this.Payments
      }
    }
    this.GetDateEnd()
  }
  total() {
    if (this.Payments != 1 && this.Payments != 0 && this.Payments != null) {
      var x = this.lAmount * this.Payments
      x = this.TotalAmount / x
      if (this.hok2.Amla != null && this.hok2.Amla != 0) {
        this.amla1 = this.hok2.Amla * this.hok2.MisTashlumim * x / this.Payments
        this.lAmount = this.TotalAmount / this.Payments
      }
      else {
        this.amla1 = this.amla1 * x / this.Payments
        this.lAmount = this.TotalAmount / this.Payments
      }
    }
    else {
      if (this.lAmount != 1 && this.lAmount != 0 && this.lAmount != null) {
        var x = this.lAmount * this.Payments

        x = this.TotalAmount / x
        if (this.hok2.Amla != null && this.hok2.Amla != 0) {
          this.Payments = this.TotalAmount / this.lAmount

          this.amla1 = this.hok2.Amla * this.hok2.MisTashlumim * x / this.Payments
        }
        else {
          this.Payments = this.TotalAmount / this.lAmount
          this.amla1 = this.amla1 * x / this.Payments
        }
      }
    }
  }
  //#endregion
  //#region //hokhelpshow
  GetNames() {
    debugger;
    if (this.boolhaf == true) {
      this.maf.getlistNames1(this.idmafkid).then(res => {
        this.listn = res
        // const a = new Set(this.listn.map(a => a.MAINID))
        // this.listn=a
        debugger
      })
    }
    else {
      this.loan.getlistNames1(this.idloan)
        .then(res => {
          this.listn = res
        })
    }
  }
  showhokhelpdup(n: number) {

    if (n + this.index >= 0 && this.index + n - 1 < this.numhokhelp) {
      this.index += n
      this.hok2 = this.listhok1[this.index - 1]
    }
    if (this.numhokhelp == 1) {
      document.getElementById('c').style.backgroundColor = 'lightgray'
      document.getElementById('d').style.backgroundColor = 'lightgray'
      // this.hok2 = this.listhok[0]
      // this.year = this.hok2.entereveryyear
      this.index = 1
      if (this.index + n <= 0) {
        document.getElementById('d').style.backgroundColor = 'lightgray'
        // this.hok2 = this.listhok[0]
        // this.year = this.hok2.entereveryyear
        // this.index = 1
      }
    }
    this.viewhokhelp2(this.hok2)
  }
  viewhokhelp() {
    this.GetNames()
    if (this.boolhaf == true) {
      this.maf.GetHokhelp(this.idmafkid).then(res => {
        this.listhok = res
        this.checklist()
      })
      this.maf.getmafkid(this.idmafkid).then(res => { this.mafkid1 = res })
      this.GetTotal1()
    }
    else {
      this.loan.GetHokhelpHECZER(this.idloan).then(res => {

        this.listhok = res
        this.checklist()

      })
      this.GetTotal()
      this.loan.getloans(this.idloan).then(res => { this.loan1 = res })
    }
    this.viewnameslist()
  }
  viewhok(id: number) {
    this.idloan = id
    this.viewhokhelp()

  }
  checklist() {

    if (this.listhok.length == 0) {
      this.addmore()
    }
    else {
      this.listhok1 = this.listhok.filter(a => a.mainid == this.listhok[0].mainid)
      this.numhokhelp = this.listhok1.length
      this.index = 1
      this.viewhokhelp2(this.listhok[0])
      this.deldegel = true
      this.edithok = false
    }
  }
  viewhokhelp1(mainid1: number) {

    this.n = this.listn.find(a => a.MAINID == mainid1)
    this.edithok = false
    this.new = false
    this.listhok1 = this.listhok.filter(a => a.mainid == mainid1)
    this.numhokhelp = this.listhok1.length
    this.index = 1
    this.hok2 = this.listhok1[0]
    this.pail = this.hok2.pail
    if (this.pail == null) {
      this.pail = false
    }
    this.deldegel = true
    if (this.hok2.typeop == "הפקדה")
      this.type = true
    else {
      if (this.hok2.typeop == "תרומה") {
        this.type = false
        this.trumatype = true
      }
      else {
        if (this.hok2.typeop == "החזר") {
          this.trumatype = false
        }
      }
    }
    this.date1 = this.hok2.StartDate
    this.date = this.hok2.enddate
    this.Payments = this.hok2.MisTashlumim
    this.lAmount = this.hok2.Scum
    this.TotalAmount = this.hok2.total
    this.amla1 = this.hok2.Amla
    //this.typeopeeation=this.hok2.typeop

    if (this.hok2.kodhokpay != null) {
      this.hokh.GetHoksById(mainid1).then(response => {

        this.list = response
        this.hok1 = this.list.find(a => a.ID == this.hok2.kodhokpay)
        this.gethok1(this.hok1)
        this.num3 = 1
        this.num0 = 1
        if (this.hok1.TypeHok == "אשראי") {
          this.fun()
        }
        else {
          if (this.hok1.TypeHok == "הוק") {
            if (this.hok1.DateLimit != null) {
              this.BoolDateLimit = true
            }
            else {
              this.BoolDateLimit = false

            }
            if (this.hok1.ScumLimit != null) {
              this.BoolScumLimit = true
            }
            else {
              this.BoolScumLimit = false

            }
            this.fun1()
          }
        }
      })
    }
    else {
      this.listash = new Array<string>()
      this.listbank = new Array<string>()

      this.fun2()
    }
  }
  merge() {
    this.hokhelp.merge(this.idmafkid);
  }
  viewhokhelp2(hok2: HokHelp) {

    if (this.listn != null) {
      this.n = this.listn.find(a => a.MAINID == hok2.mainid)
    }
    this.listtypes = new Array<string>()
    this.new = false
    this.date1 = hok2.StartDate
    this.date = hok2.enddate
    this.Payments = hok2.MisTashlumim
    this.lAmount = hok2.Scum
    this.TotalAmount = hok2.total
    this.year = hok2.entereveryyear
    this.hok2 = hok2
    this.amla1 = this.hok2.Amla
    this.Diffrence = hok2.DiffrenceMonthes;
    if (this.Diffrence == null)
      this.Diffrence = 1
    this.pail = hok2.pail
    if (this.pail == null) {
      this.pail = false
    }
    this.namecheck = hok2.namecheck
    if (this.hok2.typeop == "הפקדה")
      this.type = true
    else {
      if (this.hok2.typeop == "תרומה") {
        this.type = false
        this.trumatype = true
      }
      else {
        if (this.hok2.typeop == "החזר") {
          this.trumatype = false
        }
      }
    }
    if (hok2.kodhokpay != null) {
      this.hokh.GetHokById(hok2.kodhokpay).then(response => {
        this.hok1 = response

        this.gethok1(this.hok1)
        this.num3 = 1
        this.num0 = 1
        if (this.hok1.TypeHok == "אשראי") {
          this.fun()
        }
        else {
          if (this.hok1.DateLimit != null) {
            this.BoolDateLimit = true
          }
          else {
            this.BoolDateLimit = false

          }
          if (this.hok1.ScumLimit != null) {
            this.BoolScumLimit = true
          }
          else {
            this.BoolScumLimit = false

          }
          this.fun1()
        }
      })
    }
    else {

      // if (hok2.namecheck != null)
      this.fun2()
    }
  }
  GetTotal() {
    this.loan.GetTotal(this.idloan).then(response => {
      this.TotalAmount = response
    })
  }
  GetTotal1() {
    this.maf.GetTotal1(this.idmafkid).then(response => {
      this.TotalAmount = response[0]
      this.amla = response[1]
      this.amla1 = response[1] / this.Payments

    })
  }
  //#endregion
  //#region //boolparams
  truma() {

    this.type = false
    this.trumatype = true
  }
  haf() {

    this.type = true
  }
  heczer() {

    this.trumatype = false
  }
  everyyear() {
    this.year = !this.year
  }

  pail1() {

    this.pail = !this.pail
  }
  edit() {
    this.edithok = true
  }
  //#endregion
  //#region //save add and del || this.bool)&& this.date>this.date1)
  save(misTashlumim: number, scum: number, startDate: Date, amla: number) {

    var time = new Date().getTime() - new Date(this.date1).getTime()
    var time1 = new Date(this.date).getTime() - new Date(this.date1).getTime()
    if (time1 >= 0 && time > 0) {
      // if (confirm("תאריך התחלה קודם לתאריך נוכחי האם להכניס את הגביה החל מחודש זה?")) {
      if (true) {
        this.bool = true
      }
    }
    if (time <= 0 || this.pail == false || this.bool) {

      this.bool = false
      if (this.hok2.MisTashlumim != this.Payments || this.hok2.Scum != this.lAmount || this.hok2.StartDate != this.date1 || this.hok2.Amla != this.amla1 || this.hok2.namecheck != this.namecheck) {
        this.hok2.updatehok = true
      }
      else {
        this.hok2.updatehok = false
      }
      if (this.ho || this.ash) {
        this.hok2.kodhokpay = this.hok1.ID
      }
      else {
        if (this.check) {
          this.hok2.namecheck = this.namecheck
          if (this.namecheck == null) {
            this.namecheck = this.n.Name
          }
          this.hok2.mainid = this.mainid
        }
      }
      this.savedegel = true
      this.hok2.Amla = amla
      if (amla == null) {
        this.hok2.Amla = 0

      }
      if (this.new && (this.hok2.KodHok == null || this.hok2.KodHok == 0)) {
        this.hok2.KodHok = -1
      }
      this.hok2.MisTashlumim = misTashlumim
      this.hok2.Scum = scum
      this.hok2.DiffrenceMonthes = this.Diffrence

      //this.hok2.entereveryyear = this.year
      //  this.hok2.typeop = this.typeopeeation
      if (this.hafkada == true) {
        this.hok2.Kodmafkidcount = this.idmafkid
        // if (this.type)
        // {
        // 
        //   this.hok2.typeop = "הפקדה"
        // }
        // else {
        //   if (this.trumatype = false && this.type == false) {
        //     this.hok2.typeop = "החזר"

        //   }
        //   else {
        //     this.hok2.typeop = "תרומה"

        //   }
        // }
      }
      else {
        this.hok2.kodloan = this.idloan
        // this.hok2.typeop = "החזר"
      }
      this.hok2.StartDate = startDate
      this.hok2.total = this.TotalAmount
      this.hok2.enddate = this.date
      //this.hok2.pail = this.pail
      this.deldegel = true

      this.hokh.addHafkadot(this.hok2).then(
        res => {
          this.hok2.KodHok = res;


        }
      )
    }
    else {


      alert("תאריך אחרון קודם לראשון לא ניתן לעדכן")
    }

  }
  savetype(type) {
    this.typeopeeation = type
  }
  saveall() {
    if (this.boolhaf) {
      this.maf.enterhaf(this.idmafkid).then(res => {
        if (res) {
          if (this.index != null) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(["/Hafkadotall1/" + this.iduser, this.PERMISSION, this.id, this.idmafkid, this.index])
            })
          }
          else {

            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.id, this.idmafkid])
            })
          }

        }
      })
    }
    else {
      this.loan.enterhaf(this.idloan).then(res => {
        if (this.index != null) {

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(["/loans1/" + this.iduser, this.PERMISSION, this.id, this.idloan, this.index])
          })
        }
        else {

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(["/loans1/" + this.iduser, this.PERMISSION, this.id, this.idloan])
          })
        }
      })
    }
  }
  d: Date
  del() {
    if (this.hok2.KodHok != -1)
      this.n = this.listn.find(a => a.MAINID == this.hok2.mainid)
    this.hokh.delhokhelp(this.hok2).then(res => {
      if (res) {
        // alert("נמחק")
        this.new = true

        this.viewhokhelp()

      }
      else {
        alert("יש הוק פעילות שקשורות")
      }
    })
  }
  addmore() {
    this.Diffrence = 1
    this.hok2 = new HokHelp()
    this.hok1 = new Hok()
    this.hok = new HokHelp()
    if (!this.hafkada) {
      this.hok2.typeop="החזר"
      this.loan.GetTotal(this.idloan).then(response => {
        if (response > 0) {
          this.TotalAmount = response
          this.Payments = this.loan1.Payments
          this.lAmount = this.TotalAmount / this.loan1.Payments
        }
        else {
          this.x = "   אין צורך בעוד החזרים סכום הפער הינו " + response
          this.TotalAmount = 1
          this.Payments = 1
          this.lAmount = 1

        }
      })
      this.date1 = new Date()
      this.hokhelp.GetDateEnd(this.date1, this.loan1.Payments, this.Diffrence).then(response => {
        this.date = response
      })
    }
    else {
      this.hok2.typeop="הפקדה"
      if (this.mafkid1.IdMaslul != null && this.mafkid1.IdMaslul != 0) {
        this.maf.GetTotal1(this.idmafkid).then(response => {
          if (response[0] < 0) { this.TotalAmount == 0 }
          else { this.TotalAmount = response[0] }
          if (response[1] > 0) {
            this.amla = response[1]
          }
          else {
            this.amla = 0
          }
          this.maf.GetMaslulById(this.mafkid1.IdMaslul).then(res => {
            this.m = res

            this.Payments = this.m.NumOfPayment
            this.lAmount = this.TotalAmount / this.m.NumOfPayment
            if (this.amla / this.m.NumOfPayment > 0) {
              this.amla1 = this.amla / this.m.NumOfPayment
            }
            this.date1 = new Date()
            this.hokhelp.GetDateEnd(this.date1, this.m.NumOfPayment, this.Diffrence).then(response => {
              this.date = response
            })
          })
        })
      }
      else {
        this.Payments = 0
        this.lAmount = 0
        this.TotalAmount = 0
        this.amla1 = 0
        this.date1 = new Date()
        this.hokhelp.GetDateEnd(this.date1, 12, this.Diffrence).then(response => {
          this.date = response
        })
      }
    }
    this.hok2.AutoCalculate=true;
    this.hok2.pail=true;

    this.year = false
    this.boolhaft = false
    this.edithok = true
    this.savedegel = false
    this.new = true

    this.pail = true
    
    this.boolhafhaf = true
    this.listbank = new Array<string>()
    this.listash = new Array<string>()
    this.list1 = new Array<Hok>()
    this.deldegel = false
  }
  ChangeStatusForAllHoks()
  {
    debugger;
    var pail=this.listhok[0]?.pail
   this.listhok= this.listhok.forEach( h=>h.pail==!pail)
  }
  ChangeAutoCalculateForAllHoks()
  {
    console.log(   this.listhok )
    debugger
    this.listhok= this.listhok.forEach( h=>h.AutoCalculate==true)
  }
  //#endregion
}
