<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-main [user]="PERMISSION" [iduser]="iduser" ></app-main>
<div id="mySidenav"style="position: relative; 
top:60px;"  class="sidenav">
  <a (click)="Hoks()" id="blog"> גבית הוק</a>
  <a (click)="Ashrai()" id="projects">אשראי</a>
  <a (click)="Checks()" id="contact">שיקים</a>
  <a (click)="MASAV()" id="MASAV">מסב</a>
  <!-- -->
</div>
<app-viewhoktgvia  [PERMISSION]="PERMISSION" [iduser]="iduser" [hidden]="!BOOLHOK"  style="position: relative;left:400px; top:80px"></app-viewhoktgvia>
<app-checks [PERMISSION]="PERMISSION" [iduser]="iduser" class="col-10" *ngIf='boolchecks==true' style="position: relative;left:400px; top:80px"></app-checks>
<app-viewashraygvia  [PERMISSION]="PERMISSION" [iduser]="iduser" *ngIf='boolash==true' style="position: relative;left:400px; top:80px"></app-viewashraygvia>
<app-masav  [PERMISSION]="PERMISSION" [iduser]="iduser" *ngIf='BOOLmasav==true' style="position: relative;left:400px; top:80px"></app-masav>
