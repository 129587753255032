import { Component, OnInit } from '@angular/core';
import { HokgviaService } from 'src/app/services/hokgvia.service';
import { Hafkadot } from 'src/app/classes/hafkadot';
import { Route, Router, ActivatedRoute, Params } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-hok-gvia',
  templateUrl: './hok-gvia.component.html',
  styleUrls: ['./hok-gvia.component.css']
})
export class HokGviaComponent implements OnInit {
  //#region 
listhafhok;
listhalhok;
listhafash;
listcheck;
boolchecks:boolean;
boolash:boolean;
listcheckhaf;
BOOLmasav:Boolean;
BOOLHOK:Boolean=true;
listhalash;
dated:Date;
listhok:Array<string>;
  PERMISSION: any;
  iduser: any;
  MasavFiles: boolean;
  //#endregion
  constructor(private gvia:HokgviaService,private router:Router,private route:ActivatedRoute) {      
    this.route.params.subscribe((params:Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }
  ngOnInit() {
  }
MASAV()
{
  this. boolchecks=false;
  this.BOOLHOK=false;
  this.boolash=false;
  this.BOOLmasav=true;
  this.MasavFiles=false;

}
Checks()
{
 this. boolchecks=true;
 this.BOOLHOK=false;
 this.boolash=false;
 this.BOOLmasav=false;
 this.MasavFiles=false;

}

Hoks()
{
this.BOOLHOK=true;
this. boolchecks=false;
this.boolash=false;
this.BOOLmasav=false;
this.MasavFiles=false;


}
Ashrai()
{
  this. boolchecks=false;
  this.BOOLHOK=false;
  this.boolash=true;
  this.BOOLmasav=false;
  this.MasavFiles=false;


}
MASAVFiles()
{
  this. boolchecks=false;
  this.BOOLHOK=false;
  this.boolash=false;
  this.BOOLmasav=false;
  this.MasavFiles=true;

}
}
