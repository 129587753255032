import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Excel } from '../classes/excel';
import { Excel1 } from '../classes/Excel1';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  PostExcelOne(excel1: Excel1) {
    return this.http.post(NamesService.serverUrl +"/Excel/PostExcelOne",excel1).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }

 
  static bool: boolean;
  ShowAllPeulot(num:number, mont:number): any {
    return this.http.post(NamesService.serverUrl +"/Excel/GetAll ",{year:num, month:mont}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  INSERTEXCEL() :any{
    return this.http.get(NamesService.serverUrl +"/Excel/INSERTEXCEL").toPromise().then(
      response => {
        ;
       return  response;
      })
    .catch(v => { return false; });  }
  GetStatus():any {
    return this.http.get(NamesService.serverUrl +"/Excel/GetStatus").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });}
  constructor(private http: HttpClient) { }
  ShowPeulot(): any {
    return this.http.get(NamesService.serverUrl +"/Excel").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });

}
filter(datestart:Date, dateend:Date,want:string,want1:string,scum:number,kod:number): any {
  return this.http.post(NamesService.serverUrl +"/Excel/Filter",{d:datestart, d1:dateend, w:want,  w1:want1,s:scum,kodcat:kod}).toPromise().then(
    response => {
     return  response;
    })
  .catch(v => { return false; });

}
ViewExcel(id:number): any 
{
  return this.http.get(NamesService.serverUrl +"/Excel/ViewExcel?id="+id).toPromise().then(
    response => {
      return true;
    })
  .catch(v => { return false; });
}
PostExcel(pro:Excel1):any
{
  return this.http.post(NamesService.serverUrl +"/Excel/PostExcel",pro).toPromise().then(
    response => {
     return  response;
    })
  .catch(v => { return false; });
}
 addexcelP(pro:Excel): any {
      return this.http.post(NamesService.serverUrl +"/Excel", pro).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; });
        }}
      
