import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';

import { ExcelService } from 'src/app/services/excel.service';
import { Names } from 'src/app/classes/names';
import { NamesService } from 'src/app/services/names.service';
import { Excel1 } from 'src/app/classes/Excel1';
// import { e, v, st } from '@angular/core/src/render3';
import { ShowhafkadotcountComponent } from '../showhafkadotcount/showhafkadotcount.component';
import { ShowloansComponent } from '../showloans/showloans.component';
import { Excel } from 'src/app/classes/excel';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { RowNodeBlockLoader } from 'ag-grid-community';
import { MafkidService } from 'src/app/services/mafkid.service';

@Component({
  selector: 'app-excelmenage',
  templateUrl: './excelmenage.component.html',
  styleUrls: ['./excelmenage.component.css']
})
export class ExcelmenageComponent implements OnInit {
  //#region 
  list;
  list1;
  mainid;
  status;
  degelhaf: boolean = false;
  degelhal: boolean = false;
  e: Excel = new Excel();
  items: Names[];
  liststatus;
  changeText = false
  PERMISSION: any;
  iduser: any;
  wait: boolean;
  listcat: any;
  listexcel: Array<Excel> = new Array<Excel>();
  cat: number;
  e1: Excel1 = new Excel1();
  listexcel1: Array<Excel> = new Array<Excel>();
  catname: any;
  listAccounts1: any;
  listloans: any;
  listAccounts: any;
  ASK: boolean = false;
  num1: number = 3;
  list5: Array<number> = new Array<number>();
  OneExcel: Excel;
  listNew: Array<Excel> = new Array<Excel>(); index: any;
  ;
  ;
  //#endregion
  constructor(private cd: ChangeDetectorRef, private excel: ExcelService, private name: NamesService, private router: Router, private route: ActivatedRoute, private maagar: MaagarMenageService, private maf: MafkidService) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }
  ngOnInit() {
    this.viewnameslist();
    this.GetStatus();
    this.Addrows1(3);
    this.getcategories();
    this.wait = true;
  }
  viewnameslist() {
    this.items = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.items == null) {
    this.name.ShowNames().then(res => {
      this.items = res;
      sessionStorage.setItem('ListNames', JSON.stringify(this.items));
    })
      this.items.forEach(element => {
        if (element['CellPhone1'] != null && element['FirstName'] != null) {
          element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
        }
        else {
          if (element['CellPhone2'] != null && element['FirstName'] != null) {
            element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
          }
          else {
            if (element['Phone'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
            }
            else {
              if (element['CellPhone1'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
              }
              else {
                if (element['CellPhone2'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                }
                else {
                  if (element['Phone'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['Phone']
                  }
                  else {
                    element['Name'] = element['FamilyName'] + " " + element['FirstName']
                  }
                }
              }
            }
          }
        }
      }
      )
    }
    this.showPeolot()
  }
  GetStatus() {
    this.excel.GetStatus().then(res => {
      this.liststatus = res;
    });
  }
  save(Id: Excel) {
    ;
    this.e1.Id = Id.idline;
    this.e1.Remark = Id.Remark;
    this.e1.C_SCUMChova_ = Id.SCUM;
    this.e1.C_SCUMZcut_ = Id.SCUMz;
    this.e1.C_dateoperation = Id.dateoperation;
    if (this.mainid == null) {
      this.e1.Mainid = Id.Mainid;
    }
    else {
      this.e1.Mainid = this.mainid;
      this.mainid = null;
    }
    if (this.cat != null) {
      this.e1.insertto = this.cat;
      this.cat = null;
    }
    else {
      this.e1.insertto = Id.insertto;
    }
    let index = this.list.findIndex(a => a.idline == Id.idline);
    let index1 = index;
    index += 100000000;
    var s = (document.getElementById(index) as HTMLInputElement);
    this.e1.notask = s.checked
    this.excel.PostExcel(this.e1).then(res => {
      this.e1.validate = res
      this.e1 = new Excel1()
      if (res) {
        document.getElementById(index1).style.backgroundColor = "green"
        document.getElementById(index1).setAttribute('disabled', 'disabled');
      }
      else {
        document.getElementById(index1).style.backgroundColor = "red"
      }
    });
  }
  getcategories() {
    this.maagar.Showcat("אקסל").then(res => {
      this.listcat = res;
    });
  }
  DONTASK() {
    this.ASK = !this.e1.notask;
  }
  Addrows(item: Excel) {
    this.OneExcel = item;
  }
  Addrows1(item: number) {
    ;
    this.num1 = item;
    for (let i = 0; i < this.num1; i++) {
      this.list5.push(i);
      this.listNew.push(new Excel());

    }
    if (this.list5.length > 5) {
      document.getElementById('88').style.overflow = 'auto';
    }
  }
  showPeolot() {
    this.excel.ShowPeulot().then(res => {
      this.list = res;
      ;
      this.listexcel = JSON.parse(sessionStorage.getItem('excel'));
      if (this.list != null) {
        this.list.forEach(element => {
          element.validate = null
          if (element.insertto == null && element.Status != null) {
            element.insertto = element.Status;
          }
          // if (this.listexcel != null) {
          //   if (this.listexcel.find(a => a.idline == element.idline)) {
          //     this.e = this.listexcel.find(a => a.idline == element.idline);
          //     if (this.e.Mainid != null) {
          //       element.Mainid[0] = this.e.Mainid;
          //     }
          //     if (this.e.insertto != null) {
          //       element.insertto = this.e.insertto;
          //     }

          //   }
          // }
          // else {
          //   this.listexcel = this.list;
          //   sessionStorage.setItem('excel', JSON.stringify(this.listexcel));
          // }
        });
      }
      this.wait = false;
    })
  }
  insert() {
    ;
    this.listexcel = JSON.parse(sessionStorage.getItem('excel'));
    this.listexcel.forEach(element => {
      this.listexcel1.push(element)
    });
    // this.excel.PostExcel(this.listexcel);
    this.listexcel1 = new Array<Excel>();
    this.listexcel = null
    sessionStorage.clear();

  }
  changevalue(event) {
    if (event != null) {
      this.mainid = event.MAINID;
    }
  }
  changevalue2(event) {
    if (event != null) {
      this.cat = event.CategoryId;
    }
  }
  changevalue1(event) {
    if (event != null) {
      this.status = event.Id;
    }
    // if (this.status == "הפקדה") {
    //   this.degelhaf = true;
    //   this.appShowhafkadotcount.showhafkadot(1);
    // }
    // else {
    //   if (this.status == "החזר הלוואה") {
    //     this.degelhal = true;

    //     this.appShowloans.showloans(1);
    //   }
    // }
  }
  over(event) {
    this.changeText = true;
    console.log(event);
  }
  ShowMafkids(MAINID: number) {


    this.maf.GetTotalBymainid(MAINID).then(res => {
      this.listAccounts1 = res
        ;
      this.maf.showhafkadacount(MAINID).then(res => {
        this.listAccounts = res;
        ;
        this.listAccounts.forEach(element => {
          element['Totalhafkada'] = this.listAccounts1 && this.listAccounts1.find(a => a.idmafkid == element.Id && a.type == "הפקדה") ? this.listAccounts1.find(a => a.idmafkid == element.Id && a.coin == 1 && a.type == "הפקדה").scum : '';
          element['Totaltruma'] = this.listAccounts1 && this.listAccounts1.find(a => a.idmafkid == element.Id && a.type == "תרומה") ? this.listAccounts1.find(a => a.idmafkid == element.Id && a.coin == 1 && a.type == "תרומה").scum : '';
        })
      })
    })

  }
  addRow(i: number) {
    ;
    var root = document.getElementById('mytab').getElementsByTagName('tbody')[i];
    var rows = root.getElementsByTagName('tr');
    var clone = this.cloneEl(rows[rows.length - 1]);
    root.appendChild(clone);
  }
  cloneEl(el) {
    var clo = el.cloneNode(true);
    return clo;
  }
  save1(index: number) {
    this.e = this.listNew[index];
    this.e.Id = 0;
    this.e.idline = this.OneExcel.Id
    this.e.dateoperation = this.OneExcel.dateoperation
    this.e.Remark = this.OneExcel.Remark
    if (this.OneExcel.SCUM > 0) {
      this.e.SCUM = this.e.SCUMO
    }
    else {
      this.e.SCUMz = this.e.SCUMO
    }
    this.e.Totalscum = this.OneExcel.Totalscum
    this.save(this.e)
  }

  CooseLoan(id: number) {
    ;
    this.index = this.listloans.findIndex(x => x.Id == id);
    const url = this.router.serializeUrl(this.router.createUrlTree(['/loans1/' + this.iduser, this.PERMISSION, this.mainid, id, this.index]));
    window.open(url, '_blank');
  }
  ShowLoans(MAINID: number) {
    this.name.Showoans(MAINID).then(res => {
      this.listloans = res
        ;
    })
  }
  CooseMafkis(id: number) {
    ;
    this.index = this.listAccounts.findIndex(x => x.Id == id);
    const url = this.router.serializeUrl(this.router.createUrlTree(['/Hafkadotall1/' + this.iduser, this.PERMISSION, this.mainid, id, this.index]));
    window.open(url, '_blank');
  }
  Open(id: Excel) {

    this.mainid = id.Mainid;
    this.listloans = null;
    this.listAccounts = null
    this.listAccounts1 = null

      ;
    if (this.mainid != null) {

      this.catname = this.listcat.find(res => res.CategoryId == id.insertto).NameCategory
      if (this.catname.includes("הפקדה") || this.catname.includes("פקדון")) {
        this.degelhaf = true;
        this.degelhal = false;
        this.ShowMafkids(this.mainid);
      }
      else {
        if (this.catname.includes("הלוואה")) {
          this.degelhal = true;
          this.degelhaf = false;
          this.ShowLoans(this.mainid);

        }
      }

    }
  }
}
