
<input type="date"  #date style="position: relative;left: 1385px; top: 688px;" value="{{ myDate | date: 'yyyy-MM-dd' }}"   (change) =" showhafHOK(date.value)">
<input type="checkbox"   style="position: absolute;left: 150%;top: 635%;    block-size: 17px;
cursor: pointer;"  checked="checked"  (change) ="showheczer()">
<input type="checkbox"   style="position: absolute;left: 658%;top:620%;    block-size: 17px;
cursor: pointer;" checked="checked"   (change) =" showhaf()">
<app-masav-files  [PERMISSION]="PERMISSION" [iduser]="iduser" 
    style="position: absolute;
width: 90px;left: -650px;top: -350px;"></app-masav-files>
 <img [hidden]="zero" src="/assets/1/s.png"  style="   width: 32px;
 position: absolute;
 left: 299px;
 height: 32px;
 top: 54px;   border-radius: 5px;" >
<button (click)='NotShowmasav()'  [hidden]="!Showmasav"  style="position: absolute;left: 212%;top:3%;background-color: #f44336;">עבור לגביה</button>
<label style="position: absolute; top: 97px; font-size: 17px; width: 112px; left: 1233px; font-weight: 700;cursor: pointer;">כולל שגויים</label>
<input type="checkbox" id="e"  style="position: absolute; left: 885px; top: 64px; block-size: 17px; " (change) =" showAlsoerrors(date.value)">
<button [hidden]="Showmasav" (click)='send(date.value)' style="position: absolute;  left: 1387px;  width: 172px;  top: 722px; background-color: #f44336; border-radius: 2px;">צור קובץ למסב</button>
<h1 style="position: absolute; left: 648px; top: 26px; width: 15px;font-size: xx-large;">:סה"כ</h1>
<h1 style="position: absolute; left: 537px;  top: 29px; width: 15px;;font-size: xx-large;">{{totalheczer+totalhaf| number}}</h1>
<img [hidden]="zerohec" src="/assets/1/s.png"  style="   width: 32px;
position: absolute;
left: -10px;
height: 32px;
top: 130px;   border-radius: 5px;" >
<div style="display: flex;"></div>
<!-- 1306px -->
<img (click)="excel1()" style="position: relative; left: 1299px; top: 20px;"  src="/assets/1/excel.png">
<img (click)="excel()" style="position: relative; left: 1207px; top: 20px;" src="/assets/1/excel.png">

<h1 style="position: absolute;left: 1020px; top: 146px;width: 60px; font-size: x-large;">:הפקדות</h1>
<!-- <h1 style="position: relative; left: 990px;top: 20px;width: 15px;">סה"כ</h1> -->
<h1 style="position: absolute; left: 940px; top: 147px;width: 15px;font-size: x-large;">{{totalhaf| number}}</h1>
<img [hidden]="zerohaf" src="/assets/1/s.png"  style="   width: 32px;
position: absolute;
left: 850px;
height: 32px;
top: 130px;   border-radius: 5px;" >
<div class="bg">
    <div style="position: absolute; margin-top: -3%; left: 86px;">
      <div class="chiller_cb">
        <input id="myCheckbox" style="    position: absolute; left: 828px;top: 60px;" type="checkbox"  > 
        <label for="myCheckbox" style="position: absolute;left: 1178px; top: 86px; width: 100px; font-weight: 700;cursor: pointer;"> הפקדות</label>
      <span></span>
    </div>
    <div class="chiller_cb">
      <label for="myCheckbox2" style="position: absolute; left: 1177px; top: 59px; font-weight: 700;">הלוואות</label>
      <input id="myCheckbox2" type="checkbox" style="position: absolute; left: 828px; top: 42px; font-weight: 400;cursor: pointer;">
      <span></span>
    </div>
    <!-- </div> -->
    

        <ag-grid-angular 
       style="width:770px; height: 580px;position: absolute; left: 500px; top: 117px; direction: rtl;" 
        class="ag-theme-balham"
        [rowData]="rowData" 
        [columnDefs]="columnDefs"
        [enableRtl]="true"
        [floatingFilter]="true" 
        [rowSelection]="rowSelection"
        (gridReady)="onGridReady1($event)"

        (cellDoubleClicked)='openmafkid($event)'>
    </ag-grid-angular>
    <h1 style="position: absolute;left: 302px; top: 83px; width: 15px;font-size: x-large;">:החזרים</h1>
    <!-- <h1 style="position: absolute; left: -120px;top: 70px;width: 15px;">סהכ</h1> -->
<h1 style="position: absolute; left: 224px; top: 85px; width: 15px;font-size: x-large;">{{totalheczer| number}}</h1>
    <ag-grid-angular 
    style="width:770px; height: 580px;position: absolute; left: -300px; top: 117px; direction: rtl;" 
    class="ag-theme-balham"
    [rowData]="rowData1" 
    [columnDefs]="columnDefs1"
    [enableRtl]="true"
    [rowSelection]="rowSelection"
 
    (gridReady)="onGridReady($event)"
    [floatingFilter]="true" 
    (cellClicked)='openloan($event)'>
</ag-grid-angular>
