import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
//  encapsulation: ViewEncapsulation.None

})
export class MainComponent implements OnInit {
  constructor(private router:Router) { 
  }
  //#region 
@Input() user;
@Input() iduser;
bool;
bool1;
iduser1;
//#endregion
  ngOnInit() {  
    ;
    this.iduser1=this.iduser;
    if(this.user==2){
        this.bool=false;
    }
    else
    {
        if(this.user==1)
          {
            this.bool=true;

          }
         else
         {
         this.bool1=true;
        }
  }
  }
  showreport()
  {
  this.router.navigate(["/report/"+this.iduser,this.user]);
  }
  showsetting()
  {
    this.router.navigate(["/setting/"+this.iduser,this.user]);

  }
  shownames()
{
  this.router.navigate(["/name/"+this.iduser,this.user]);

}
gviot()
{
  this.router.navigate(["/gvia/"+this.iduser,this.user]);

}
kupa()
{
  this.router.navigate(["/kupa/"+this.iduser,this.user]);

}
excel()
{
  this.router.navigate(["/excel/"+this.iduser,this.user]);
}
task()
{
  this.router.navigate(["/task/"+this.iduser,this.user]);

}
waiting()
{
  this.router.navigate(["/waitinglist/"+this.iduser,this.user]);

}
logout()
{
  
  this.router.navigate(["/login"]);

}
}
