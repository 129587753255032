 
 <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
   <ag-grid-angular 
    style=" position: relative; left: 215px; top: 684px;height: 615px;width: 1250px;" 
    class="ag-theme-balham"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    (cellClicked)='view($event)'
    [enableRtl]="true"
    [localeText]="localeText"
    (cellContextMenu)="fun($event)"  
    (gridReady)="onGridReady($event)"
    [rowClassRules]="rowClassRules"
    (filterChanged)="filterChanged()"
    [floatingFilter]="true" >
    
</ag-grid-angular>
<div   style="position: absolute;background-color: rgb(253, 251, 251);left: 1193px;top: 504px;width: 270px;
height: 170px; border: gray 3px solid;border-radius: 10px;direction: rtl;background-color: lightgrey;overflow-x: hidden; overflow-y: auto;  scroll-behavior: smooth;">

<div class="my-class">
<ng-select (change)="SelectMachzir($event)" style="width: 181px; height: 30px; position: absolute; top: 69px; direction: rtl;margin: 7px 35px;border: black 1px solid;
border-radius: 4px;" 
 [items]="items" bindValue="MAINID"  #val bindLabel="Name"   tabindex="2">
</ng-select>
 </div>
<input type="date" style="position: absolute;top:31px;height: 30px;width: 180px;margin: 7px 35px;padding: 8px;" value="{{ myDate | date: 'yyyy-MM-dd' }}"#d  tabindex="1">
<br>
 <input type="number"  [(ngModel)]="h.Scum" [ngModelOptions]="{standalone: true}" style="position: absolute; top: 108px; width: 180px;height: 30px;margin: 7px 35px;padding: 8px;" placeholder="סכום" #scum tabindex="3">
 <br>
 <br>
 <input type="number"  [(ngModel)]="h.Amla" [ngModelOptions]="{standalone: true}" style="position: absolute; top: 144px; width: 180px;height: 30px;margin: 7px 35px;padding: 8px;" placeholder="עמלה" #amla tabindex="4" >
 <br>
<input type="text"  [(ngModel)]="h.Notice" [ngModelOptions]="{standalone: true}" style="position: relative;width: 180px;height: 30px;top: 154px;margin: 7px 35px;padding: 8px;" placeholder="הערה" #n tabindex="7">
<br>     

<select  style="position: absolute; top: 180px;width: 180px;height: 30px;border: solid 1px gray;margin: 7px 35px;" (change)="savecoin(Coin1.value)"  #Coin1 tabindex="5"> 
  <option *ngFor='let item of listcoin'>{{item.name}}</option></select>
<select  #v (change)="getbyway(v.value)" style="position: absolute;background-color: white;border: solid 1px gray;width: 180px;color: grey;height: 30px;top: 215px;
margin: 7px 35px;" tabindex="6"> <option> העברה</option> <option> שיק</option><option> מזומן</option><option> אשראי</option></select>
<label style="position: absolute; left: 75px; top: 5px;font-size: 111%;font-weight: bold;text-align: center">הוספת החזר</label> 
<button style="position: absolute;width: 180px; border-radius: 6px; height: 31px; top: 286px; background: #f44336;margin: 7px 35px;border: black 1px solid;" (click)="save(d.value)" tabindex="8"> שמור</button>

</div> 
<div style="position: relative; top: -1392%; left: -57%;">
 
  <label>
 <img for="dollar"  style="position: absolute;left: 1044px; top: 605px; width: 25px; height: 25px;cursor: pointer;" src="/assets/1/dollar.png"> 
 <input type="radio" name="coin" id="dollar" style="cursor: pointer;position: absolute;left: 1076px; top: 612px;" (click)="ShowDolars()" >
</label>
<label>
 <img for="shekel" style="position: absolute;left: 980px; top: 603px; height: 30px; width: 30px;cursor: pointer;" src="/assets/1/s.png"> 
 <input type="radio"name="coin" checked="checked" id="shekel" style="cursor: pointer;position: absolute; top: 612px; left: 1011px;" (click)="ShowShekel()">
</label>
 <b style="position: absolute;left: 1153px; top: 649px;"> :סה"כ עמלות</b>
<input [(ngModel)]="num3" [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something4" style="background-color: transparent ;border: none;width: 90px;font-size: 110%;direction: ltr;position: absolute;left: 1106px;top: 666px;color: #f44336;" disabled>
<b style="position: absolute; top: 667px; left: 1198px;">/</b>
<input [(ngModel)]="num4" [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something5" style="background-color: transparent ;border: none;width: 90px;font-size: 110%;direction: ltr;position: absolute;left: 1208px;top:666px;text-align: left;color: #f44336;" disabled>
<b style="position: absolute;left: 1146px;top: 590px;">:סכום ההחזרים</b>
<input [(ngModel)]="num1" [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something1" style="background-color:transparent ;border: none;width:90px;font-size: 110%;direction: ltr; position: absolute;left: 1103px;top: 610px;color: #f44336;" disabled >
<b style="position: absolute; top: 611px; left: 1194px;">/</b>
<input [(ngModel)]="num" [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something2" style="background-color: transparent ;border: none;width: 90px;font-size: 110%;direction: ltr;position: absolute;left: 1202px;top: 610px;text-align: left;color: #f44336;"disabled>
<b style="position: absolute;left: 975px; top: 638px;"> :ההחזרים שחזרו</b>
<input [(ngModel)]="num2" [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something3" style="background-color: transparent ;border: none;width: 90px;font-size: 110%;direction: ltr;position: absolute;left:989px; top: 666px;color: #f44336;text-align: center;" disabled>
</div>
<app-addtask [idcountloan]="idloan" [mainid]="mainid" class="col-2" title="הוסף משימה" style="position: absolute;
left: 638px;
top: 545px;;cursor: pointer;"></app-addtask>
<img src="/assets/1/excel.png" (click)="excel()" style="position: absolute;
width: 45px;
height: 45px;
left: 698px;
    top: 625px
">
<img src="/assets/1/reset.jpg" title="איפוס" (click)="RestartBalance()" style="position: absolute;
width: 45px;
height: 45px;
left: 39.8%;
    top: 620px;">
<button  style="position: absolute;left:369px;width: 50px;height: 50px;top: 613px;" title="מחיקה"  onclick="document.getElementById('id09').style.display='block'" class="btn"><i class="fa fa-trash"></i></button>
<!-- מחיקת החזרים -->
<div id="id09" class="modal">
    <form class="modal-content animate" action="/action_page.php">  
      <div class="imgcontainer">
        <span style="position: absolute; left: 9px; top: -5px;" onclick="document.getElementById('id09').style.display='none'" class="close" title="סגור">&times;</span>
      </div>
  <!-- מחיקת החזרים -->
      <div class="container" style="position: relative;top: 14px !important; left: 0Px; " >   
                  <label style="position: absolute; top: 39px; left: 261px;cursor: pointer;font-weight: 600;" >את כל ההחזרים
                      <input type="radio" (click)="all()" style="position: relative; top:1px;left: 4px;"  name="o">
                    </label>
                    <label style="position: absolute;top: 78px; left: 243px;cursor: pointer;font-weight: 600;">מחק את כל דחויים 
                        <input type="radio" (click)="allf()" style="position: relative; top: 1px; left: 4px;" name="o">
                      </label> <label   style="position: absolute;top: 39px;  left: 90px;cursor: pointer;font-weight: 600;">רק של מחזיר זה
                          <input type="radio" (click)="justone()" style="position: relative; top:1x;left: 2px;" name="o">
                        </label > <label style="position: absolute;top: 78px; left: 6px;cursor: pointer;font-weight: 600;" >מחק דחויים רק של מחזיר זה
                            <input type="radio" (click)="justonef()" style="position: relative; top:1px;left: 2px;" name="o">
                          </label> 
         <button onclick="document.getElementById('id09').style.display='none'" style="position: absolute; top: 115px; left: 77px; height: 30px;font-size: 100%" (click)="deletehaf()" class="btn">מחק</button> 
       </div>
    </form>
</div>

<!-- לחיצה ימנית -->
<div *ngIf='bool==false'>
  <div class="row"     [hidden]="!degelrightclick" style="width:20%; position: relative;height: 40%; top:20%;left: 33%;text-align: center;">
      <div class="column" style="background-color:rgb(51, 53, 53);width:100%;height: 35px;">
    <p  style="color: white ; width: 100%; height: 100%;">{{date|date:"dd/MM/yy"}} </p>
      </div>  
      <!-- בחר אופציה לדחיה -->
    <div class="column" style="background-color:#aaa;width:100%;height:128px;">
    <input type="date" #D (change)='savedate(D.value)' [hidden]='!date1' style="position: relative; left: -10px;top: 0px; width:30%;background-color: white;color: gray;height: 30px;  border-radius: 25px; ">
        <select style="position: relative; left: -3px;top: 0px; width:60%;background-color: white;color: gray;height: 30px;  border-radius: 25px; "
         #value (change)="changeval(value.value)">
            <div class="custom-select" ></div>
          <option *ngFor='let item1 of listop'>{{item1}}</option>
          </select><p style="width:12%; position: absolute; top: 44px;  left: 321px;color: white  ;  height: 30px;
          border-radius: 25px; ">בחר</p>
                 <p style="width:12%; position: absolute; top: 131px;font-weight: 600; left: 324px; color: white  ;  height: 30px;  border-radius: 25px; ">סיבה</p>
                 <select (change)='saver(va.value)'  #va  style=" width: 60%;position: absolute; top: 124px; left: 70px; height: 34px;  border-radius: 25px;" >  <option *ngFor='let item1 of listreason'>{{item1.name}}</option></select>     
  <br>
  <button class="button button5"  (click)="StopHafkadot( numid.value)" style="width:60%;position: absolute;top: 199px; left: 72px;"><img src="/assets/1/stop.png" style=" width:20px ;height: 20px; ">
  </button>
          <p style=" position: absolute; top: 198px;border-radius: 25px;color: white  ;  height: 30px;width: 15%; left: 85%; top: 87px;font-weight: 600;" >עמלה</p>
    <input checked="checked" type="checkbox" style=" position: absolute; left: 318px; top: 92px; height: 17px;width: 17px;"(click)=amla()>
        <button  style="width:60%;position: absolute;  top: 79px;  left: 68px;" class="button button1 "(click)="hokreturn()"   >סמן כחזר</button>
    </div>
    <div class="column" style="background-color:#bbb;width:100%;height: 85px;">
      <label style="position: absolute;left: 177px; top: 168px;">מס חודשי השהייה</label>
        <input type="number" style="width:15%; position: absolute; left: 100px; top: 168px;" #numid value="1">
              <select  style=" width: 60%;position: absolute; top: 250px; left: 72px;
              border-radius: 25px;" #val5 (change)="getbyway3(val5.value)"> <option> מזומן</option> <option> שיק</option><option> העברה</option>   <option> אשראי</option>
        <option> הוק</option></select>
    <p style="width:19%; position: absolute; top: 257px;  left: 310px; font-weight: 600; color: white  ;  height: 30px;
     border-radius: 25px; ">:התקבל ב</p>
        <button (click)="pay()" style="width:60%;position: absolute; top: 290px; left: 72px;">שולם מראש </button>
    </div>
    <div class="column" style="background-color:#aaa;width:100%;height: 89px;">
        <button onclick="document.getElementById('id03').style.display='block'" style="position: absolute; left: 71px; width: 60%; top: 356px;" ><i class="fa fa-trash"></i></button>
    </div>
    <div class="column" style="background-color:#bbb;width:100%;height: 85px;"></div>
  </div>
  <!-- מחיקה -->
  <div id="id03" class="modal" style="position: absolute; left: 269px; top: 778px; height: 200px;">
  
    <form class="modal-content animate" action="/action_page.php">
      <div class="imgcontainer">
        <span style="position: relative;left: -383px; top: -23px;" onclick="document.getElementById('id03').style.display='none'" class="close" title="סגור">&times;</span>   
      </div> 
      <div >
         <p  style="position: relative; top: -32px; width: 347px;text-align: center;left: 35px; font-size: 24px;background-color: white;color: black;"
      >?האם אתה בטוח שברצונך למחוק</p>
         <button onclick="document.getElementById('id03').style.display='none'" style="position: relative; top: -1px;  left: -117px; font-size: 17px; width: 173px;" (click)="delete()" class="btn">מחק</button>       
            </div>           
          </form>
        </div>
  </div>
<meta name="viewport" content="width=device-width, initial-scale=1">
<div id="id018" class="modal1" style="display: block">
  <form class="modal-content1" action="/action_page.php">
 <div  name="2"> 
      <span style="position: absolute; left: -637px; top: 5px;" onclick="document.getElementById('id018').style.display='none'" class="close1"
       title="סגור">&times;</span>
 </div>
    <div class="container" style="text-align: center;">
      <div class="row">
      <div  class="titel"  *ngIf="h[0]"  >
      <h6>Update heczers</h6>
            <br>
            <br>
            <br>
            <br>
            <b   style="position: relative; left: -46px; top: 2px;">תאריך לועזי</b>
            <input  class="form-control right" type="date" (ngModelChange)="h[0].DateForiegnHeczer= $event" [ngModel]="h[0].DateForiegnHeczer|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"    #DateForiegnHeczer >
            <br>
            <br>
            <b class="left" style="position: absolute; top: 98px; left: -308px;">תאריך החזר</b>
            <input  class="form-control left " type="text"  [(ngModel)]="h[0].DateHeczer" [ngModelOptions]="{standalone: true}" style="position: absolute; top: 120px; left: -424px;"  disabled #DateHeczer>
            <br>
            <br>    
            <b style="position: relative; left: -21px; top: 1px;">סכום</b>
            <input  class="form-control right" type="number" (change)="Change()" [(ngModel)]="h[0].Scum" [ngModelOptions]="{standalone: true}"     #Scum >
            <br> 
            <br> 
            <b style="position: absolute; left: -261px; top: 245px;">עמלה</b>
            <input class="form-control left" type="number"  style="position: absolute; top: 268px;"  (change)="Change()" [(ngModel)]="h[0].Amla" [ngModelOptions]="{standalone: true}" #Amla >
            <br>
            <br>
            <b  style="position: relative;  left: -36px; top: 2px;">אסמכתא</b>
            <input class="form-control right" type="number"  [(ngModel)]="h[0].ASMACTA" [ngModelOptions]="{standalone: true}" disabled #ASMACTA> 
            <br>
            <br> 
            <b style="position: absolute; left: -310px; top: 393px;">סטטוס החזר</b>
            <input class="form-control left" type="text" style="position: absolute;top: 416px;"  [(ngModel)]="h[0].StatusHeczer" [ngModelOptions]="{standalone: true}"   #StatusHeczer disabled> 
            <br>
            <br>
            <b   style="position: relative; left: -24px; top: 2px;">מטרה </b>
            <input class="form-control right" type="text" [(ngModel)]="h[0].TypeOperation" [ngModelOptions]="{standalone: true}"    #TypeOperation>
            <br>
            <br>
             <b style="position: absolute; left: -302px;top: 542px;">סכום לגביה</b>
            <input class="form-control left" type="number" style="position: absolute;top: 564px;" [(ngModel)]="h[0].SUMTOGVIA" [ngModelOptions]="{standalone: true}"  disabled #SUMTOGVIA >
            <br>
            <br>
            <div>
              <b style="position: relative;left: -24px; top: 5px;"> מטבע</b>            
              <select class="form-control right"  [(ngModel)]="h[0].CoinName" [ngModelOptions]="{standalone: true}"   (change)="savecoin1(Coin.value)" style="border: solid 0.5px lightgrey;  direction: rtl;" #Coin> 
                <option *ngFor='let item of listcoin'>{{item.name}}</option>              
              </select>
              </div>
             <br>
             <br>
             <b style="position: absolute; left: -259px; top: 693px;"> הערה</b>
            <input type="text" class="form-control left" style="position: absolute;top: 716px;" [(ngModel)]="h[0].Notice" [ngModelOptions]="{standalone: true}"   #Notice >
            <br>
            <br>
            <!-- <b style="position: relative; left: -49px; top: 2px;">אמצעי תשלום</b>
             <input type="text" class="form-control right"  [(ngModel)]="h[0].getbyway" [ngModelOptions]="{standalone: true}"   disabled #Notice> -->
             <b style="position: relative; left: -49px; top: 2px;">שם מחזיר</b>
             <ng-select  style="position: relative;right: 105px;"
               [items]="items" bindValue="MAINID" placeholder="החזר של" [(ngModel)]="h[0].MAINID" [disabled]="h[0].idhokhelp!=null"
               [ngModelOptions]="{standalone: true}" ngDefaultControl bindLabel="Name">
             </ng-select>
             <br>
             <br> 
            <b style="position: absolute; left: -310px;  top: 842px;">תאריך יצירה</b>
            <input class="form-control left" type=" date" style="position: absolute;top: 864px;" (ngModelChange)="h[0].CreateDat= $event" [ngModel]="h[0].CreateDat|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"   disabled #CreateDat>
            <br>
            <br>
            <b style="position: relative; left: -32px; top: 1px;">נוצר ע"י</b>
            <input class="form-control right" type="text"  [(ngModel)]="h[0].Createby" [ngModelOptions]="{standalone: true}"   disabled #Createby >
            <br>
            <br> 
            <b style="position: absolute;  left: -312px; top: 989px;"> תאריך עידכון</b>
            <input class="form-control left" type=" date" style="position: absolute;top: 1012px;" (ngModelChange)="h[0].UpdateDat= $event" [ngModel]="h[0].UpdateDat|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"    disabled #UpdateDat> 

            <br>
            <br>

      <div class="clearfix">
        <button type="button" onclick="document.getElementById('id018').style.display='none'" class="cancelbtn">ביטול</button>
        <button  type="submit" onclick="document.getElementById('id018').style.display='none'" class="ok" (click)='addheczer()'>אישור</button>
    
      </div>
    </div>
</div>
</div>
</form>
</div>
