<!-- <body style=" max-width: 800px;max-height: 400px;"> -->
<ag-grid-angular 
style="width:470px; height: 320px;position: relative; left:290vh;top:30%;direction: rtl;" 
 class="ag-theme-balham"
 [rowData]="rowData" 
 [columnDefs]="columnDefs"
 [enableRtl]="true"
 [floatingFilter]="true" 
 (rowClicked)='openWorker($event)'>
</ag-grid-angular>
<img (click)="Add()" style="position: relative; left: 268px; top:0;  text-align: center;height: 30px;width: 30px;" src="/assets/1/Add.png">
<!--    fxLayout="column" fxLayoutAlign="center"   style="position: absolute; " -->
<div style="position: relative; left: 258vh; top:-31vh;  text-align: center;background-color: gray;height: 300px;;width: 190px;" >
    <label>שם משתמש</label>
    <input type="text" [(ngModel)]="n2.LastName" [ngModelOptions]="{standalone: true}">
    <br>    
    <label>שם </label>

    <input type="text" [(ngModel)]="n2.FirstName" [ngModelOptions]="{standalone: true}">   
     <br>   
    <label>שם משתמש</label>

    <input type="text" [(ngModel)]="n2.UserPassword" [ngModelOptions]="{standalone: true}">   
    <br> 
    <label>הרשאה</label>

    <input type="text"  [(ngModel)]="n2.permission" [ngModelOptions]="{standalone: true}">
    <br>     <br> 

    <button (click)="Save()" style="background-color: #f44336;border-radius: 12px;width: 100%;"> שמור</button>
</div>
<!-- </body> -->