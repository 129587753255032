import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HokHelp } from '../classes/hok-help';
import { Hok } from '../classes/hok';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class HokMenageService {
  CheckedBankAccount(hok2: Hok) :any{
    return this.http.post(NamesService.serverUrl +"/Hok/CheckedBankAccount",hok2).toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });
  }
 
public static hafkada:boolean;
public static update:boolean;

  constructor(private http: HttpClient) { }
 delhok(hok2: Hok):any {
  return this.http.post(NamesService.serverUrl +"/Hok/DelHok",hok2).toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });}  
  getreason():any {
    return this.http.get(NamesService.serverUrl +"/Hok/getreason").toPromise().then(
      response => {
        return response;
      })
  }
  GetHoksAll() :any{
    return this.http.get(NamesService.serverUrl +"/Hok/GetHoksAll").toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });  }  
  delhokhelp(hok2: HokHelp):any {
    return this.http.post(NamesService.serverUrl +"/HokHelp/delhokhelp",hok2).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });}  
  addHafkadot(pro:HokHelp): any {
    
    return this.http.post(NamesService.serverUrl +"/HokHelp/PostHokHelp",{hokh: pro}).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });}
      addHok(hok1:Hok): any {
        return this.http.post(NamesService.serverUrl +"/Hok/PostHok", hok1).toPromise().then(
            response => {
              return true;
            })
          .catch(v => { return false; });}
          updateHok(hok2: Hok) {
            return this.http.put(NamesService.serverUrl +"/Hok/PutHok", hok2).toPromise().then(
              response => {
                return true;
              })}
    UpdateListHok(listhokhafkadot: any, s:string) {
      return this.http.post(NamesService.serverUrl +"/Gviot/UpdateListHokhaf",{list:listhokhafkadot,asmacta:s} ).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; });}
 
  GetHokById(id:number): any {
    return this.http.get(NamesService.serverUrl +"/Hok/GetHokById?id="+id).toPromise().then(
      res => { return res; })
      .catch(err => { return false;})}

Getlisthok(): any {
  return this.http.get(NamesService.serverUrl +"/Hok/Getlisthok").toPromise().then(
      response => {
        return response;
      })
      
    .catch(v => { return false; });}
    GetHoksById(id:number):any
    {
      return this.http.get(NamesService.serverUrl +"/Hok/GetHoksById?id="+id).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });}
    GetHoksUnikById(id:number):any{
      
        return this.http.get(NamesService.serverUrl +"/Hok/GetHoksUnikById?id="+id).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });}
    
  
Getlistmasav(): any {
  return this.http.get(NamesService.serverUrl +"/Gviot/GetMasav").toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });}
 
  Updatelistmasav(list:any): any {
    return this.http.put(NamesService.serverUrl +"/Gviot/UpdateMasav",list).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });}
    
    getlistoptains(): any {
      return this.http.get(NamesService.serverUrl +"/Gviot/Getoptain").toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });}
   
      showhafkadacount(id:number) : any {
        {
         
            return this.http.get(NamesService.serverUrl +"/Hok/GethokByName?id="+id).toPromise().then(
              
              response => {
               return  response;
              })
            .catch(v => { return false; });
          }}
          Showoans(id:number): any {
            return this.http.get(NamesService.serverUrl +"/Hok/GethokBylName?id="+id).toPromise().then(
              response => {
               return  response;
              })
            .catch(v => { return false; });
          }
         
    //updaetehokcomback() 
   } 
   