<div
style="overflow-y: auto;  scroll-behavior: smooth; border-radius: 12px; height: 80vh;width: 100%;background-color: lightgray; margin-left:0%;margin-top: 120px;">
    <div fxLayout="row" fxLayoutAlign=" center center "
    style="direction: rtl;width: 100%;top: 49px;height: 28px; direction: rtl;">
    <label style="width: 452px; text-align: right;"> תאריך</label>

        <label style="width: 212px; text-align: right;"> הפקדות</label>
        <label style="width: 212px; text-align: right;"> 1 הפקדות</label>

        <label style="width: 202px; text-align: right;">החזרים</label>
        <label style="width: 202px; text-align: right;"> 1 החזרים</label>

        <label style="width: 202px; text-align: right;">תרומות </label>
        <label style="width: 202px; text-align: right;"> 1תרומות </label>
 
        <label style="width: 202px; text-align: right;"> הלוואות </label>
        <label style="width: 202px; text-align: right;"> 1הלוואות </label>

        <label style="width: 202px; text-align: right;">יתרה </label>

    </div>
    <div *ngFor='let item of listYears; let i = index' fxLayout="row" fxLayoutAlign="center center"
    style="  width: auto;height: 28px; direction: rtl; background-color: lightgray; flex-direction: row; box-sizing: border-box;   display: flex;     margin: auto; align-self: flex-end;">
        <!-- <span
            style="text-align: center;position: absolute;left: 146%;border-radius: 50%;width: 6%; background-color: #f44336;height: 25px;"
            *ngIf='(i)%12==0'>{{(i)/12+1}} </span> -->
        <label  style="width:180px;"> {{item.d|date:'M/yy'}}</label>
        <input type="number" style="width:29%;" step="0.01" [(ngModel)]="item.hafmonth" [ngModelOptions]="{standalone: true}">
        <input type="number" style="width:29%;" step="0.01" [(ngModel)]="item.haff" [ngModelOptions]="{standalone: true}">

        <input type="number" style="width:29%;" [(ngModel)]="item.heczer" [ngModelOptions]="{standalone: true}">
        <input type="number" style="width:29%;" [(ngModel)]="item.heczerf" [ngModelOptions]="{standalone: true}">

        <input type="number" style="width:29%;" [(ngModel)]="item.Truma" [ngModelOptions]="{standalone: true}">
        <input type="number" style="width:29%;" [(ngModel)]="item.Trumaf" [ngModelOptions]="{standalone: true}">

        <input type="number"   style="width:29%;" [(ngModel)]="item.loam" [ngModelOptions]="{standalone: true}">
        <input type="number"   style="width:29%;" [(ngModel)]="item.loanf" [ngModelOptions]="{standalone: true}">

        <input type="number"   style="width:29%;" step=".01" [(ngModel)]="item.total " [ngModelOptions]="{standalone: true}">

    </div>
</div>