<table >
    <tr style="font-weight: bold;text-align: center;background-color: white !important;border: none; border-block-end-color: white;" > 
       
        סה"כ
      
       </tr> 
  <tr>
     <td>  <thead>יתרת הלוואה שקל</thead></td>
     <td>  <thead>הפקדה שקל</thead></td>
     <td>  <thead> הלוואה דולר</thead></td>
     <td>  <thead> הפקדה דולר</thead></td>

     </tr>
     
   <tr >
     
 <td>
   {{loans}}
 </td>
 <td>
  {{loans1}}

 </td>
 <td>
   {{haf}}
 </td>
 <td>
 
   {{haf1}}
 </td>

   </tr>
</table>
 