import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { element } from 'protractor';
import { HAfExtra } from 'src/app/classes/HAfExtra';
import { HecExtra } from 'src/app/classes/HecExtra';
import { LoanClass } from 'src/app/classes/LoanClass';
import { MAfkidCount } from 'src/app/classes/mafkid-count';
import { Maslul } from 'src/app/classes/maslul';
import { YearClass } from 'src/app/classes/YearClass';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { MafkidService } from 'src/app/services/mafkid.service';
@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.css']
})
export class CalculateComponent implements OnInit {
  //#region 
  RibitNank: number = 1.76;
  Ribit: number = 1.76;
  numm = 1200
  Amla: number = 0;
  AmlaConst: number = 3
  listYears: Array<YearClass> = new Array<YearClass>();
  listNumLoans: Array<LoanClass> = new Array<LoanClass>();
  year: YearClass = new YearClass()
  ListHaf: Array<HAfExtra> = new Array<HAfExtra>()
  listMasluls: any;
  M: Maslul = new Maslul();
  ExtraHAf: boolean = false;
  ExtraHAc: boolean = false;
  Yechidot: number = 1
  NumOfLoans
  send_date = new Date();
  total: number = 0;
  LoanClass: LoanClass;
  PERMISSION: any;
  iduser: any;
  AutoClaculate: boolean = true;
  ListHac: Array<HecExtra> = new Array<HecExtra>();
  CountHeczer: number = 0;
  NetoLoan: number;
  totalYtra: number = 0;
  totalYtraMinus: number = 0;
  totalYtraPlus: number = 0;
  kodmaslul: any;
  TzoverHeczer: number = 0;
  MR: any;
  ListHafChadpaami: Array<LoanClass> = new Array<LoanClass>();
  dontChangeLoans: boolean = false;
  idmaf: any;
  listhamafkid: any;
  listwas: any;
  listout: any;
  mafkid: MAfkidCount;
  BoolMaf: boolean;
  counthaf: number = 0;
  listloans: any;
  sub: string;
  //#endregion
  constructor(private maslul: MaagarMenageService, private route: ActivatedRoute, private maf: MafkidService) {
    // this.route.queryParams.subscribe(
    //   params => console.log('queryParams', params['list']));
    //   this.sub = this.route.snapshot.queryParamMap.get('list');
    //   ;

    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
      this.idmaf = params.idmaf

    })
  }
  ngOnInit() {
    if (this.idmaf < 0) {
      this.ListHafChadpaami.push(new LoanClass)
      this.ShowMaslul();
      this.BoolMaf = false
    }
    else {
      this.ShowMasluls()
      this.BoolMaf = true
    }
  }
  ShowMasluls() {
    this.maslul.ShowMaslul().then(res => {
      this.listMasluls = res;
      this.ShowMafkid1()
    })
  }
  ShowMafkid1() {
    this.maf.getmafkid(this.idmaf).then(res => {
      this.mafkid = res
      this.maf.ShowLOanstoMafkid(this.mafkid.Id).then(res => {
        this.listloans = res
        ;
       
        this.maf.ViewHafkadot(this.idmaf).then(res => {
          this.listhamafkid = res
          this.ShowMafkid()
        })
      })
    })
  }
  ShowMafkid() {
    (document.getElementById('55') as HTMLInputElement).checked = true;
    (document.getElementById('S') as HTMLInputElement).checked = false;
    ;
    this.Yechidot = this.mafkid.NUMYACHIDOT;
    this.M = this.listMasluls.find(a => a.KodMaslul == this.mafkid.IdMaslul);
    this.M.HafMonth = this.M.ScumHafkada / this.M.NumOfPayment;
    this.M.AllLoans = this.M.ScumHalvaa * this.M.yechidot;
    this.M.Hefresh = 2;
    this.MR = Object.assign({}, this.M);
    this.CountHeczer = 0
    this.TzoverHeczer = 0
    this.totalYtra = 0
    this.totalYtraMinus = 0
    this.totalYtraPlus = 0
    this.NetoLoan = (this.M.NumOfHeczerPayments * this.M.Heczer - this.M.ScumHafkada - this.CountHeczer * this.M.Heczer) / this.M.Heczer
    var a = Math.round(this.M.NumOfPayment + this.M.NumOfHeczerPayments)
    this.listYears = new Array<YearClass>((a) * 2)
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    let i = 0
    this.listhamafkid.forEach(element => {
      this.counthaf += element.Amount
      this.listYears[i] = new YearClass()
      this.listYears[i].id = i
      this.listYears[i].heczer = 0
      this.listYears[i].hafmonth = element.Amount
      this.listYears[i].total = 0
      this.listYears[i].loam = 0
      var f = this.FunRound(i)
      // var c = new Date(year, month + Math.round(i) + f, day);
      this.listYears[i].d =new Date( element.DateForiegnHAfkada)
      i++
    });
    for (; i < a * 2; i++) {
      this.listYears[i] = new YearClass()
      this.listYears[i].id = i
      this.listYears[i].heczer = 0
      this.listYears[i].hafmonth = 0
      this.listYears[i].total = 0
      this.listYears[i].loam = 0
      var f = this.FunRound(i)
      var c = new Date(year, month +i, day);
      this.listYears[i].d =new Date( c)
    }
    if (this.counthaf != this.M.ScumHafkada * this.Yechidot) {
      this.Yechidot = this.counthaf / this.M.ScumHafkada
    }
    ;
    this.NumOfLoans = new Array<LoanClass>()
    this.listloans.forEach(element => {
      this.LoanClass = new LoanClass()
      this.LoanClass.Num = element.scum
      this.LoanClass.date =new Date( element.date)
      this.listNumLoans.push(this.LoanClass)
    });
    this.listNumLoans.forEach(element => {
      var mydate = new Date(element.date);
      ;
      this.year = this.listYears.find(a => a.d.getFullYear() == mydate.getFullYear() && a.d.getMonth() == mydate.getMonth())
      if (this.year != null) {
        this.year.loam = element.Num;
        // this.EnterHeczers(this.year.id + 1, i);
      }
    }
     )
    this.CalculateTotal()

  }
  changeAge(i: number) {
    var cc = new Date(this.listNumLoans[i].dateBorn);
    ;
    var x = this.listNumLoans[i].Age % 1
    var ff = this.FunRound(this.listNumLoans[i].Age)
    x = x * 10
    var c = new Date(cc.getFullYear() + Math.round(this.listNumLoans[i].Age) - ff , cc.getMonth() + x, cc.getDate())
    this.listNumLoans[i].date = c;
  }
  CalculateNowState()
  {

  }
  //#region  data
  selectMaslul(event) {

    this.kodmaslul = event.KodMaslul
    this.reset()
  }
  reset() {
    this.ListHafChadpaami = new Array<LoanClass>();
    this.ListHafChadpaami.push(new LoanClass())
    this.ListHac = new Array<HecExtra>();
    this.ListHaf = new Array<HAfExtra>();
    this.dontChangeLoans = false;
    (document.getElementById('55') as HTMLInputElement).checked = false
    this.ExtraHAf = false;
    this.ExtraHAc = false;
    this.ShowMaslul()
  }
  ShowMaslul() {
    this.maslul.ShowMaslul().then(res => {
      this.listMasluls = res;
      if (this.kodmaslul == null) {
        this.kodmaslul = 1;
      }
      this.M = this.listMasluls.find(a => a.KodMaslul == this.kodmaslul);
      this.M.HafMonth = this.M.ScumHafkada / this.M.NumOfPayment;
      this.M.AllLoans = this.M.ScumHalvaa * this.M.yechidot;
      this.M.Age=20;
      this.M.Hefresh = 2;
      this.MR = Object.assign({}, this.M);
      this.Yechidot = 1

      this.M.OnePay = 0
      this.Insert()
    })

  }
  Insert() {
    this.ChangeNumOfLoans();
    this.Calculate()
  }
  Save() {

  }
  //#endregion
  //#region  calculate fun
  SaveLoans() {
  }
  Calculate() {
    this.CountHeczer = 0
    this.TzoverHeczer = 0
    this.totalYtra = 0
    this.totalYtraMinus = 0
    this.totalYtraPlus = 0
    this.NetoLoan = (this.M.NumOfHeczerPayments * this.M.Heczer - this.M.ScumHafkada - this.CountHeczer * this.M.Heczer) / this.M.Heczer
    var a = Math.round(this.M.NumOfPayment + this.M.NumOfHeczerPayments)
    this.listYears = new Array<YearClass>((a) * 2)
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    this.year.hafmonth = this.M.HafMonth;
    for (let i = 0; i < (this.M.NumOfPayment + this.M.NumOfHeczerPayments) * 2; i++) {
      this.listYears[i] = new YearClass()
      this.listYears[i].id = i
      this.listYears[i].heczer = 0
      this.listYears[i].hafmonth = 0
      this.listYears[i].total = 0
      this.listYears[i].loam = 0
      var f = this.FunRound(i)
      var c = new Date(year, month + Math.round(i) + f, day);
      this.listYears[i].d = c
      if (!this.ExtraHAf) {
        var f = this.FunRound(this.M.NumOfPayment)
        if (i < this.M.NumOfPayment && i != Math.round(this.M.NumOfPayment) - f) {
          ;
          this.listYears[i].hafmonth = this.M.HafMonth;
        }
        else {
          if (i == (Math.round(this.M.NumOfPayment) - f) && this.M.NumOfPayment % 1 != 0) {
            this.listYears[i].hafmonth = this.M.HafMonth * (this.M.NumOfPayment % 1);

          }
        }
      }
    }
    ;
    let index = 0;
    if (this.ExtraHAf) {
      this.M.NumOfPayment = 0;
      this.ListHaf.forEach(element => {
        this.M.NumOfPayment += element.Num
        for (let i = 0; i < element.Num; i++) {
          this.listYears[index++].hafmonth = element.hafmonth;
        }
      })
      this.ChangeNumOfLoans()
    }
    this.year = new YearClass()
    var i = 0;
    this.ListHafChadpaami.forEach(element => {
      if (element.date == null) {
        if (element.Num! > 0 && element.Num != null) {
          element.date = new Date()
        }
      }
      if (element.date != null) {
        var mydate = new Date(element.date);
        this.year = this.listYears.find(a => a.d.getFullYear() == mydate.getFullYear() && a.d.getMonth() == mydate.getMonth())
        if (this.year != null) {
          if (this.year.hafmonth == null) {
            this.year.hafmonth = 0;
          }
          this.year.hafmonth += element.Num;
        }
      }
    })
    this.listNumLoans.forEach(element => {
      var mydate = new Date(element.date);
      this.year = this.listYears.find(a => a.d.getFullYear() == mydate.getFullYear() && a.d.getMonth() == mydate.getMonth())
      if (this.year != null) {
        this.year.loam = element.Num;
        this.EnterHeczers(this.year.id + 1, i);
      }
      i++;
    }
    )
    this.CalculateTotal()
  }
  EnterHeczers(id: number, index: number) {
    let i;
    var f = this.FunRound(this.M.NumOfHeczerPayments / this.M.yechidot)
    for (i = 0; i < this.M.NumOfHeczerPayments / this.M.yechidot - f && i < this.listYears.length && this.CountHeczer + 1 < this.NetoLoan; i++) {
      while (this.listYears[id + i].heczer != null && this.listYears[id + i].heczer != 0) {
        id++;
      }
      this.listYears[id + i].heczer = this.M.Heczer;
      this.CountHeczer++;
      this.TzoverHeczer += this.M.Heczer;
    }
    var a = this.M.NumOfHeczerPayments / this.M.yechidot;
    if (i == Math.round(a) - f && (a) % 1 != 0) {
      ;;
      this.listYears[id + i].heczer = this.M.Heczer * (a % 1);
      this.CountHeczer += (a % 1);
      this.TzoverHeczer += this.listYears[id + i].heczer;
    }

    var a = this.NetoLoan;
    var f = this.FunRound(a)
    var l = this.FunRound(this.CountHeczer)

    if (Math.round(this.CountHeczer) - l == Math.round(a) - f && (a) % 1 != 0 && index == this.listNumLoans.length - 1) {

      if ((this.NetoLoan * this.M.Heczer) - this.TzoverHeczer > 0 && this.NetoLoan * this.M.Heczer - this.TzoverHeczer <= this.M.Heczer) {
        this.listYears[id + i].heczer = (this.NetoLoan * this.M.Heczer) - this.TzoverHeczer;
        this.CountHeczer += ((this.NetoLoan * this.M.Heczer) - this.TzoverHeczer) / this.M.Heczer;
        this.TzoverHeczer += this.M.Heczer;
      }
    }
    if ((this.NetoLoan * this.M.Heczer) - this.TzoverHeczer > 1 && (index == this.listNumLoans.length - 1 || index == this.listNumLoans.length - 2)) {
      ;
      this.listYears[id + i].heczer = (this.NetoLoan * this.M.Heczer) - this.TzoverHeczer;
      this.CountHeczer += ((this.NetoLoan * this.M.Heczer) - this.TzoverHeczer) / this.M.Heczer;
      this.TzoverHeczer += this.M.Heczer;
    }
  }
  FunRound(a: number) {
    if (a % 1 >= 0.5) {
      return 1;
    }
    return 0;
  }
  CalculateTotal() {
    for (let i = 0; i < (this.M.NumOfPayment + this.M.NumOfHeczerPayments) * 2; i++) {
      if (i > 0) {
        this.listYears[i].total = (this.listYears[i].hafmonth + this.listYears[i].heczer - this.listYears[i].loam + this.listYears[i - 1].total);
      }
      else {
        this.listYears[i].total = (this.listYears[i].hafmonth + this.listYears[i].heczer - this.listYears[i].loam);
      }
      if (this.listYears[i].total < 1 && this.listYears[i].total > 0 || this.listYears[i].total > -1 && this.listYears[i].total < 0) {

        this.listYears[i].total = 0;
      }
      this.totalYtra += this.listYears[i].total;
      if (this.listYears[i].total < 0) {
        this.totalYtraMinus += this.listYears[i].total;
      }
      else {
        this.totalYtraPlus += this.listYears[i].total;

      }

    }
    ;
    this.Amla = this.M.AmlaTotal / (this.M.NumOfPayment + this.NetoLoan)
  }
  ChangeOnePay() {
    this.M.OnePay = 0;
    this.ListHafChadpaami.forEach(element => {
      this.M.OnePay += element.Num
    })
    if (this.AutoClaculate == true) {
      this.M.NumOfPayment = (this.M.ScumHafkada - this.M.OnePay) / this.M.HafMonth;
      this.ChangeNumOfLoans()

    }
  }
  ChangeNumOfYechidot() {
    if (this.AutoClaculate == true) {

      const MR = Object.assign({}, this.MR);

      this.M.HafMonth = this.Yechidot * MR.HafMonth;
      this.M.AllLoans = this.Yechidot * MR.AllLoans;
      this.M.Heczer = this.Yechidot * MR.Heczer;
      this.M.ScumHalvaa = this.Yechidot * MR.AllLoans / this.M.yechidot;
      this.M.ScumHafkada = this.Yechidot * MR.ScumHafkada;
      this.ChangeNumOfLoans()
      this.ChangeNumOfYears1();
    }
  }
  ChangeTotalHaf() {
    if (this.AutoClaculate == true) {
      ;
      const MR = Object.assign({}, this.MR);
      this.Yechidot = this.M.ScumHafkada / MR.ScumHafkada;
      this.ChangeNumOfYechidot()
    }
  }
  ChangeAutoClaculate() {
    this.AutoClaculate = !this.AutoClaculate;
  }
  ChangeHafMonth() {
    if (this.AutoClaculate == true) {
      ;
      this.M.NumOfPayment = (this.M.ScumHafkada - this.M.OnePay) / this.M.HafMonth;
      this.ChangeNumOfLoans()

    }
  }
  ChangeNumOfYears1() {
    if (this.AutoClaculate == true) {
      ;

      this.M.HafMonth = (this.M.ScumHafkada - this.M.OnePay) / this.M.NumOfPayment;
      this.ChangeNumOfLoans()
    }
  }
  ChangeScumOfLoans() {
    if (this.AutoClaculate == true) {
      ;
      const MR = Object.assign({}, this.MR);

      this.Yechidot = this.M.AllLoans / MR.AllLoans;
      this.ChangeNumOfYechidot()
    }
  }
  ChangeNumOfHeczers() {
    if (this.AutoClaculate == true) {
      this.M.Heczer = this.M.AllLoans / this.M.NumOfHeczerPayments;
    }
  }
  ChangeHeczer() {
    if (this.AutoClaculate == true) {
      this.M.NumOfHeczerPayments = this.M.AllLoans / this.M.Heczer;
    }
  }
  ChangeScumOfLoan() {
    // const MR = Object.assign({}, this.listMasluls[0]);
    var d = new Date();
    var a = (this.M.NumOfPayment) % 1 == 0 ? this.M.NumOfPayment : this.M.NumOfPayment + 1
    var year = d.getFullYear() + a / 12;
    var month = d.getMonth() + a % 12;
    var day = d.getDate();
    this.listNumLoans = new Array<LoanClass>()
    this.M.yechidot = this.M.AllLoans / this.M.ScumHalvaa;
    for (let i = 0; i < this.M.yechidot; i++) {
      this.LoanClass = new LoanClass()
      var c = new Date(year + i * this.M.Hefresh, month, day);
      this.LoanClass.date = c;
      this.LoanClass.Num = this.M.ScumHalvaa;
      this.listNumLoans.push(this.LoanClass);
    }
  }
  dontChange() {
    this.dontChangeLoans = !this.dontChangeLoans;
  }

  ChangeNumOfLoans() {
    if (!this.dontChangeLoans) {
      var d = new Date();
      var a = (this.M.NumOfPayment) % 1 == 0 ? this.M.NumOfPayment : this.M.NumOfPayment + 1
      var day = d.getDate();
      this.listNumLoans = new Array<LoanClass>()
      this.M.ScumHalvaa = this.M.AllLoans / this.M.yechidot;
      let i;
      var f = this.FunRound(this.M.Hefresh)
      for (i = 0; i < this.M.yechidot - 1; i++) {
        this.LoanClass = new LoanClass()
        if (i == 0) {
          var x = 0;
          var ff = 0
          var year = d.getFullYear() + a / 12;
          var month = d.getMonth() + a % 12;
          var year1 = year
        }
        else {
          x = this.M.Hefresh % 1
          ff = x * 12
          ff = Math.round(ff)
          month = this.listNumLoans[i - 1].date.getMonth();
          year = this.listNumLoans[i - 1].date.getFullYear();
          var s = Math.round(this.M.Hefresh)
          var year1 = year + (s - f)
        }
        ;
        var c = new Date(year1, month + ff, day);
        this.LoanClass.date = c;
        this.LoanClass.Age = this.M.Age;
        var x = this.LoanClass.Age  % 1
        var ff = this.FunRound(this.LoanClass.Age )
        x = x * 10
        var c2 = new Date(year1 -Math.round( this.LoanClass.Age)+ff, month -x, day);
        this.LoanClass.dateBorn = c2;
        this.LoanClass.Num = this.M.ScumHalvaa;
        this.listNumLoans.push(this.LoanClass);
      }
      //#region  last loan
      this.LoanClass = new LoanClass()
      var x = this.M.Hefresh % 1
      var ff = x * 12
      ff = Math.round(ff)
      var month = this.listNumLoans[i - 1].date.getMonth();
      year = this.listNumLoans[i - 1].date.getFullYear();
      var s = Math.round(this.M.Hefresh)
      var year1 = year + (s - f)
      var c = new Date(year1, month + ff, day);
      this.LoanClass.date = c;
      this.LoanClass.Age = this.M.Age;
      var x = this.LoanClass.Age  % 1
      var ff = this.FunRound(this.LoanClass.Age )
      x = x * 10
      var c2 = new Date(year1 -Math.round( this.LoanClass.Age)+ff, month -x, day);
      this.LoanClass.dateBorn = c2;
      if (this.M.yechidot % 1 == 0) {
        this.LoanClass.Num = this.M.ScumHalvaa;
      }
      else {
        this.LoanClass.Num = this.M.ScumHalvaa * (this.M.yechidot % 1);

      }
      ;
      this.listNumLoans.push(this.LoanClass);
      //#endregion
      // sessionStorage.setItem('loans', JSON.stringify(this.listNumLoans));
    }
  }
  //#endregion
  //#region  Extra
  AddHafChadPaami() {
    this.ListHafChadpaami.push(new LoanClass)
  }
  AddMasHac() {
    this.ExtraHAc = true;
    this.ListHac.push(new HecExtra())
    this.ListHac.push(new HecExtra())
  }
  AddMasHaf() {
    this.ExtraHAf = true;

    this.ListHaf.push(new HAfExtra())
    this.ListHaf.push(new HAfExtra())
  }
  ChangeNumOfHeczersExtra() {
    if (this.AutoClaculate == true) {
      this.M.Heczer = this.M.AllLoans / this.M.NumOfHeczerPayments;
    }
  }
  ChangeHeczerExtra() {
    if (this.AutoClaculate == true) {
      this.M.NumOfHeczerPayments = this.M.AllLoans / this.M.Heczer;
    }
  }
  ChangeNumOfPaymentsExtra() {

  }
  ChangeHafMonthExtra() {

  }
  //#endregion

}
