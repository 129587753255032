import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
//  encapsulation: ViewEncapsulation.None


})
export class LoginComponent implements OnInit {
  constructor(private user:UserService,private router: Router) { }
  ngOnInit() {
   
    } 
    getValue(name1: string, pass1: string) {
      this.user.logIn(name1, pass1).then(res => { 
 if (res!=null) 
  {
         if (res.permission=='מזכירה')
         {
        this.router.navigate(["/main/"+ res.IdUser,2  ]);
         }
        else{
        if(res.permission=='מנהל')
       {
        this.router.navigate(["/main/"+ res.IdUser,1]);
       }
          else  {
       this.router.navigate(["/main/"+ res.IdUser,3]);
        }
        }
        }
        else
         {
            alert("שם משתמש או סיסמא אינם תואמים");
         }
    
     })}


    }
