import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AshrayType } from '../classes/ashray-type';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class AshraiService {
  
  constructor(private http: HttpClient) { }

ShowAllAshray(): any {
  return this.http.get(NamesService.serverUrl +"/Kupa/Ashrai").toPromise().then(
    response => {
     return  response;
    })
  .catch(v => { return false; });}
  
  SavePrice(ash:AshrayType) {
    return this.http.post(NamesService.serverUrl +"/Kupa/UpdateAshrai",ash).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }

}