import { BrowserModule } from '@angular/platform-browser';
import { routesConfiguration } from './routes';
import { NgModule } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { ViewNamesComponent } from './components/view-names/view-names.component';
import { AddNameComponent } from './components/add-name/add-name.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AddComponent } from './components/add/add.component';
import { ExcelmenageComponent } from './components/excelmenage/excelmenage.component';
import { LoansComponent } from './components/loans/loans.component';
import { FunctionsOnNamesComponent } from './components/functions-on-names/functions-on-names.component';
import { HeczersComponent } from './components/heczers/heczers.component';
import { TassksComponent } from './components/tassks/tassks.component';
import { HokGviaComponent } from './components/hok-gvia/hok-gvia.component';
import { ViewHafkadotComponent } from './components/view-hafkadot/view-hafkadot.component';
import { RouterModule, Router } from '@angular/router';
import { HOKComponent } from './components/hok/hok.component';
import { AddHafkadaCashComponent } from './components/add-hafkada-cash/add-hafkada-cash.component';
import { MaslulComponent } from './components/maslul/maslul.component';
import { ChartsComponent } from './components/charts/charts.component';
import { ReportsComponent } from './components/reports/reports.component';
// import { ChartsModule } from 'ng2-charts';
import {FlexLayoutModule} from '@angular/flex-layout';

import { Charts1Component } from './components/charts1/charts1.component';
import { KupaComponent } from './components/kupa/kupa.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { AddWaitingComponent } from './components/add-waiting/add-waiting.component';
import { ExcellistComponent } from './components/excellist/excellist.component';
import { NamesComponent } from './components/names/names.component';
import { ShowloansComponent } from './components/showloans/showloans.component';
import { ShowhafkadotcountComponent } from './components/showhafkadotcount/showhafkadotcount.component';
import { HafkadacountComponent } from './components/hafkadacount/hafkadacount.component';
import { MasavComponent } from './components/masav/masav.component';
import { ChecksComponent } from './components/checks/checks.component';
import { KupamenageComponent } from './components/kupamenage/kupamenage.component';
import { ManegemafkidcountComponent } from './components/manegemafkidcount/manegemafkidcount.component';
import { Chartmonth1Component } from './components/chartmonth1/chartmonth1.component';
import { Chartmonth2Component } from './components/chartmonth2/chartmonth2.component';
import { ShowhokComponent } from './components/showhok/showhok.component';
import { AddHeczersComponent } from './components/add-heczers/add-heczers.component';
import { TaskComponent } from './components/task/task.component';
import { AshraytypesComponent } from './components/ashraytypes/ashraytypes.component';
import { SearchnamesComponent } from './components/searchnames/searchnames.component';
 import { AgGridModule } from 'ag-grid-angular';
 import { NgSelectModule } from '@ng-select/ng-select';
import { AddtaskComponent } from './components/addtask/addtask.component';
import { HistoryComponent } from './components/history/history.component';
import { LogoComponent } from './components/logo/logo.component';
import { Main1Component } from './components/main1/main1.component';
import { FilternamesComponent } from './components/filternames/filternames.component';
import { AddlistofheczersComponent } from './components/addlistofheczers/addlistofheczers.component';
import { DirectoroptainsComponent } from './components/directoroptains/directoroptains.component';
import { NgbModule , NgbDatepickerModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { SendemailComponent } from './components/sendemail/sendemail.component';
import { AddChecksComponent } from './components/add-checks/add-checks.component';
import { ViewhoktgviaComponent } from './components/viewhoktgvia/viewhoktgvia.component';
import { ViewashraygviaComponent } from './components/viewashraygvia/viewashraygvia.component';
import { ShowbalanceComponent } from './components/showbalance/showbalance.component';
import { ViewexcelComponent } from './components/viewexcel/viewexcel.component';
import { ExcelloanComponent } from './components/excelloan/excelloan.component';
import { ArevtotalscumComponent } from './components/arevtotalscum/arevtotalscum.component';
import { NetzigimComponent } from './components/netzigim/netzigim.component';
import { AddContactComponent } from './components/add-contact/add-contact.component';
import { LeadersComponent } from './components/leaders/leaders.component';
import { ShownameComponent } from './components/showname/showname.component';
import { WorkersComponent } from './components/workers/workers.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { LoansreportComponent } from './components/loansreport/loansreport.component';
import { MafkidreportsComponent } from './components/mafkidreports/mafkidreports.component';
import { MasavFilesComponent } from './components/masav-files/masav-files.component';
import { DropDownEditorComponent } from './components/drop-down-editor/drop-down-editor.component';
import { CalculateComponent } from './components/calculate/calculate.component';
import { FutureSimulatorComponent } from './components/future-simulator/future-simulator.component';
import { CalcuterComponent } from './components/calcuter/calcuter.component';
import { PniotComponent } from './pniot/pniot.component';
// import { NumNotRoundPipeComponent } from './components/num-not-round-pipe/num-not-round-pipe.component';


@NgModule({
    declarations: [
      DropDownEditorComponent,
    AppComponent,
    LoginComponent,
    MainComponent,
    ViewNamesComponent,
    AddNameComponent,
    AddComponent,
    ExcelmenageComponent,
    LoansComponent,
    FunctionsOnNamesComponent,
    HeczersComponent,
    TassksComponent,
    HokGviaComponent,
    ViewHafkadotComponent,
    HOKComponent,
    AddComponent,
    AddHafkadaCashComponent,
    MaslulComponent,
    ChartsComponent,
    ReportsComponent,
    Charts1Component,
    KupaComponent,
    WaitingComponent,
    AddWaitingComponent,
    ExcellistComponent,
    NamesComponent,
    ShowloansComponent,
    ShowhafkadotcountComponent,
    HafkadacountComponent,
    MasavComponent,
    ChecksComponent,
    KupamenageComponent,
    ManegemafkidcountComponent,
    Chartmonth1Component,
    Chartmonth2Component,
    ShowhokComponent,
    AddHeczersComponent,
    TaskComponent,
    AshraytypesComponent,
    SearchnamesComponent,
    AddtaskComponent,
    HistoryComponent,
    LogoComponent,
    Main1Component,
    FilternamesComponent,
    AddlistofheczersComponent,
    DirectoroptainsComponent,
    SendemailComponent,
    AddChecksComponent,
    ViewhoktgviaComponent,
    ViewashraygviaComponent,
    ShowbalanceComponent,
    ViewexcelComponent,
    
    ExcelloanComponent,
    
    ArevtotalscumComponent,
    
    NetzigimComponent,
    
    AddContactComponent,
    
    LeadersComponent,
    
    ShownameComponent,
    
    WorkersComponent,
    
    AddUserComponent,
    
    LoansreportComponent,
    
    MafkidreportsComponent,
    
    MasavFilesComponent,
    
    DropDownEditorComponent,
    
    CalculateComponent,
    
    FutureSimulatorComponent,
    
    CalcuterComponent,
    
    PniotComponent,
    
    // NumNotRoundPipeComponent,

    // MatSelectModule,MatOptionModule,MatFormFieldModule
  ],
  imports: [
    NgSelectModule,
  //  AgGridModule.withComponents( [DropDownEditor]) ,

    NgbModule,NgbDatepickerModule, NgbButtonsModule,
    FormsModule,
    RouterModule.forRoot(routesConfiguration),
    ReactiveFormsModule,
    BrowserModule,
    FlexLayoutModule,
    HttpClientModule, 
    // ChartsModule,
    AgGridModule.withComponents([   DropDownEditorComponent  
    ])

  ],
  exports: [FlexLayoutModule],
  providers: [], 

  bootstrap: [AppComponent]
})
export class AppModule { }
