<app-main  [user]="PERMISSION" [iduser]="iduser" ></app-main>
<h6> Hafkada-Count</h6>
<img src="/assets/1/calculator.png" title="חישוב כדאיות" (click)="Calculate()"
style="position: absolute;
  width: 40px;
  height: 40px;
  left: 52.8%;
    top: 25.5vh;">
<table style="position: absolute; left: 299px; top: 432px; width: 300px; font-size: 110%; border-radius: 4px;; table-layout:fixed">
  <tr class="thead1" style="background: gray; color: white;">
      <td class='col-2' style="position: relative;width: 35px;"><b> <thead>הפקדה/תרומה</thead></b> </td>  
      <td  class='col-3' style="position: relative; width: 50px;"><b> <thead >מטבע</thead></b> </td>
      <td  class='col-1' style="width: 40px;"> <b> <thead>סכום</thead></b></td>
      </tr>      
      <tbody class="tbody"  >

    <tr   *ngFor='let item of listtotal'>
    <td  class='col-2'>
      {{item.type}}
    </td>
    <td  class='col-3'>
      {{item.namecoin}}
    </td>
    <td  class='col-1'> 
      {{item.scum}}
    </td>
    </tr></tbody>
</table>
<img style="position: absolute;left: 903px; top: 231px; block-size: 62px;cursor: pointer; "
  onclick="document.getElementById('id022').style.display='block'" class="btn" src="/assets/1/send.png" >
<div id="id022" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 11px; top: -25px;font-size: 2.1rem; cursor: pointer;"
        onclick="document.getElementById('id022').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <div class="container">
      <div style="position: absolute;left: 8rem;">
       <label style="position: relative; left: -45px;  top: 45px; font-size: 15px;" > <input  type="checkbox" style=" position: absolute; left: 143px; top: 5px; width: 13px; height: 13px; font-weight: bold;" (click)="future1()">כולל הפקדות עתידיות</label>
        <p style="position: absolute; left: -58px; top: -21px; direction: rtl ;text-align: right; height: 32px;font-size: 18px;"> <b>בחר דוחות לשליחה:</b></p>
        <select  #select  style="position: absolute;border: solid 1px lightgrey;  left: -85px; top: 9px; text-align: center; height: 32px; width: 199px;border-radius: 4px; ">
            <option >דוח לכל חשבונות ההפקדה</option>
            <option >דוח לחשבון נוכחי</option>
            <option >אישור בנק</option>

            </select>  
        <button  class="bb" style= " position: absolute;left: -83px;  top: 71px; height: 31px;   color: white; width: 198px !important; border: solid 1px black; border-radius: 4px;padding: 4px ;"(click)='valuechange(select.value)'   onclick="document.getElementById('id022').style.display='none'" class="next" id="b1">שלח</button>
        </div>
    </div>
  </form>
</div>
<app-history [nametbl]="nametbl"[id]="id" [idmafkid]="idmafkid" [idintbl]="idintbl"[hafkada]="true" class="col-2" style="position: absolute;    left:515px; top: 235px;width: 10px;height: 10px;"></app-history>
<app-view-hafkadot [id]="id" [idmafkid]="idmafkid"[iduser]="iduser" [PERMISSION]="PERMISSION" class="col-8" style="position: absolute;left: 750px;top:80px;height: 15px;"></app-view-hafkadot>
<app-add [id]="id" [idmafkid]="idmafkid" style="position: absolute;left: 0px;top:180px;"></app-add>   
 <app-task [id]="id" [idmafkid]="idmafkid"  [hafkada]="true" [hidden]="bool" style="position: absolute;left: 90px;top:0px;"></app-task>
 <app-showname  [id]="id" [name]="false" class="col-2" style='position: absolute; top: 58px;left:-76px;'></app-showname>
