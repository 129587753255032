export class YearClass {
    id:number
    d:Date
    total: number;
    loam: number;
    // Yechidot:number=1;
    hafmonth:number;
    // FirstAmount:number=1;
    // totalloan:number=240000
    heczer:number;
  Truma: any;
  loanf;
  haff;
  heczerf;
  Trumaf: any;

    // numofheczers:number=300;
}
