<meta name="viewport" content="width=device-width, initial-scale=1.0">
<img src="/assets/1/logo.jpg" style="width: 80%; height: 90%;z-index: -1  ; position: absolute;left: 0%;top: 50px;">
<div class="container" style=" width: 100%;background-color: transparent;">

<div class="container" style="position: relative;left:19%;      left: 19%;
top: 10vh !important;
width: 29vh;
height: 30vh;
direction: rtl;
text-align: center;
border-radius: 50%;
;text-align: center;border-radius: 50%;background-color: gray;">
                <form action="/action_page.php">
                  <label for="usrname">שם משתמש</label>
                  <input type="text"   id="usrname" name="usrname" required #namep >   
                  <label for="psw">סיסמא</label>
                  <input type="password" id="psw" #pass name="psw" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                  <input type="submit" (click)='getValue(namep.value,pass.value)' value="התחבר">
                </form>
              </div>
              
              <div id="message">
                <h3>Password must contain the following:</h3>
                <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
                <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
                <p id="number" class="invalid">A <b>number</b></p>
                <p id="length" class="invalid">Minimum <b>8 characters</b></p>
              </div>
