<div  [hidden]="name"  style="background-color: #f44336; width: 180px; height: 100px; font-size: small;text-align: center;    
position: absolute;
border-radius: 25px;
left: 85px;;
top: 20px;">
<br>
  <b > {{n.FamilyName}}   {{n.FirstName}}</b> <br>
 <b *ngIf='n.CellPhone1!=null'>
 {{n.CellPhone1}}<br></b>

<b *ngIf='n.CellPhone2!=null'>
    {{n.CellPhone2}}  <br></b>
  
<b *ngIf='n.Adress!=null'> {{n.Adress}}</b>
</div>
<div  [hidden]="!name"  style="background-color: #f44336; width: 145px; height: 100px; font-size: small;text-align: center;    
position: absolute;
border-radius: 25px;
left: -75px;;
top: 30px;">
<br>
  <b > {{n.FamilyName}}   {{n.FirstName}}</b> <br>
 <b *ngIf='n.CellPhone1!=null'>
 {{n.CellPhone1}}<br></b>

<b *ngIf='n.CellPhone2!=null'>
    {{n.CellPhone2}}  <br></b>
  
<b *ngIf='n.Adress!=null'> {{n.Adress}}</b>
</div>
