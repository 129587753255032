export class cash {
         Id :number
         Amount :number
         Details :string
         Coin:number;
         Category :number
         UnderCategory :number
         group:number;
         DateOperation: Date
         AmountEnter:number
        AmountFuture:number
        kodamla:number;
        mainid:number;
        Moneycameoutfuture :number;
        Moneycameout:number;
         kodkupa:number;
         futture :boolean;
  groupname: any
  namecat: any
  UnderCategoryName: any
  CoinName: any;
        constructor(){
            this.Id=null;
            this.Amount=null;
            this.Details=null; 
            this.Category=null;
            this.DateOperation=null;
            this.AmountEnter=null; 
            this.AmountFuture=null;

        }
}
