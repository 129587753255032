import { Component, OnInit, Input } from '@angular/core';
import { HokgviaService } from 'src/app/services/hokgvia.service';
import { fstatSync } from 'fs';
import { MAfkidCount } from 'src/app/classes/mafkid-count';
import { MafkidService } from 'src/app/services/mafkid.service';
import { Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans.service';
import { NamesService } from 'src/app/services/names.service';
import { Loans } from 'src/app/classes/loans';
import { LOCALE_TEXT } from 'src/app/classes/constance';
@Component({
  selector: 'app-viewashraygvia',
  templateUrl: './viewashraygvia.component.html',
  styleUrls: ['./viewashraygvia.component.css']
})
export class ViewashraygviaComponent implements OnInit {
  listnames: any;
  localeText: any;
  rowSelection: string;
  gridApi: any;
  gridApi1: any;
  listloans: any;
  listmafkids: any;


  constructor(private gvia: HokgviaService, private maf: MafkidService, private router: Router, private name: NamesService, private l: LoansService) { }
  //#region 

  listhokheczer;
  totalhaf: number = 0;
  totalheczer: number = 0;
  zero: boolean;
  zerohec: boolean;
  listhokhafkadot;
  zerohaf: boolean;
  loan: Loans;
  rowData: Array<any>;
  gridOptions1;
  gridOptions;
  columnDefs;
  columnDefs1;
  mafkid: MAfkidCount = new MAfkidCount();
  defaultColDef1;
  @Input() PERMISSION;
  @Input() iduser;
  rowData1: Array<any>;
  hafkadac: boolean = true;
  heczerc: boolean = true;
  myDate: Date = new Date();
  myDate1: Date = new Date();
  //#endregion
  ngOnInit() {
    this.myDate1 = new Date();
    this.columnDefs = [

      { headerName: 'שם מפקיד', field: 'Name', width: 160, filter: "agTextColumnFilter", editable: true,sort: 'asc'},
      // {headerName: 'עבור חשבון', field: 'NameM',width: 160,filter: "agTextColumnFilter",editable: true},
      { headerName: 'עבור', field: 'NameL', sortable: true, filter: "agTextColumnFilter", sort: 'asc', width: 160 },

      {
        headerName: 'תאריך לועזי', field: 'DateForiegnHAfkada', filter: true, width: 100, editable: true, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך הפקדה', field: 'DateHafkada', filter: true, width: 100, editable: true },
      { headerName: 'סכום ההפקדה', field: 'Amount', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'מטרה', field: 'TypeOperation', filter: "agTextColumnFilter", width: 90, editable: true },
      { headerName: 'סוג מטבע', field: 'Coin', filter: true, width: 80, editable: true },
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: true, width: 100, editable: true },
      { headerName: 'סטטוס הפקדה', field: 'StatusHafkada', filter: "agTextColumnFilter", width: 90, editable: true },
      { headerName: 'עמלה', field: 'Amla', filter: true, width: 100, editable: true },
      { headerName: 'אסמכתא', field: 'ASMACTA', filter: true, width: 100, editable: true },
      { headerName: 'הערה', field: 'Notice', filter: true, width: 100, editable: true },
      { headerName: 'מס"ב', field: 'MASAV', filter: true, width: 60, editable: true },
      { headerName: 'סוג הו"ק', field: 'TypeHok', filter: true, width: 100, editable: true },
      { headerName: 'נוצר ע"י', field: 'Createby', filter: true, width: 100, editable: true },
      { headerName: 'עודכן ע"י', field: 'Updateby', filter: true, width: 100, editable: true },
      { headerName: 'פלאפון', field: 'Phone', filter: true, width: 100, editable: true },
      { headerName: 'פלאפון', field: 'Phone2', filter: true, width: 100, editable: true },
      { headerName: 'פלאפון', field: 'Phone3', filter: true, width: 100, editable: true }],

    this.columnDefs1 = [

      { headerName: 'שם מחזיר', field: 'Name', sortable: true, filter: "agTextColumnFilter", width: 160, editable: true },
      // {headerName: 'עבור הלוואה', field: 'NameM',width: 160,filter: "agTextColumnFilter",editable: true},
      { headerName: 'שם לווה', field: 'NameL', sortable: true, filter: "agTextColumnFilter", sort: 'asc', width: 160 },

      {
        headerName: 'תאריך לועזי', field: 'DateForiegnHeczer', filter: true, width: 110, editable: true, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך החזר', field: 'DateHeczer', filter: true, width: 110, editable: true },
      { headerName: 'סכום', field: 'Scum', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'עמלה', field: 'Amla', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'סטטוס החזר', field: 'StatusHeczer', filter: true, width: 110, editable: true },
      { headerName: 'צורת החזר', field: 'TypeOperation', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: true, width: 110, editable: true },
      { headerName: 'סוג מטבע', field: 'Coin', filter: true, width: 100, editable: true },
      { headerName: 'הערה', field: 'Notice', filter: true, width: 100, editable: true },
      { headerName: 'פלאפון', field: 'Phone', filter: true, width: 100, editable: true },
      { headerName: 'פלאפון', field: 'Phone2', filter: true, width: 100, editable: true }

    ];
    this.shownames();
  }
  showHaf(Date) {
if(!this.listmafkids)
{


    this.maf.GetAllMaf().then(res => {
      this.listmafkids = res;
      this.showHafkadotAshrai(Date);
    })
  }
  else{
    this.showHafkadotAshrai(Date);

  }
  }
  shownames() {
    this.name.ShowNames().then(res => {
      this.listnames = res
      this.fun(this.myDate1);
    })
  }
  buildgrid() {

    this.localeText = LOCALE_TEXT,
      this.columnDefs = this.columnDefs,

      this.rowSelection = 'single',
      this.rowData = this.listhokhafkadot;
    if (this.totalhaf > 0) {
      this.zerohaf = false;
      this.zero = false;
    }
    else {
      this.zerohaf = true;
    }
  }
  openmafkid(event) {
    ;;
    this.maf.getmafkid(event.data.MafkidID).then(res => {
      this.mafkid = res
      const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.mafkid.MAINID, event.data.MafkidID]));
      window.open(url, '_blank');;
    })
  }
  openloan(event) {
    ;;
    this.l.getloans(event.data.IdLoan).then(res => {
      this.loan = res;
      const url = this.router.serializeUrl(this.router.createUrlTree(["/loans/" + this.PERMISSION, this.iduser, this.loan.MAINID, event.data.IdLoan]));
      window.open(url, '_blank');
      // this.router.navigate(["/loans/"+this.PERMISSION,this.iduser,this.loan.MAINID,event.data.IdLoan]);

    });
  }
  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  onGridReady1(event: any) {
    this.gridApi1 = event.api;
  }
  excel() {
    ;
    this.gridApi.exportDataAsCsv(this.gridApi.rowModel.rowsToDisplay);
  }
  excel1() {
    ;
    this.gridApi1.exportDataAsCsv(this.gridApi1.rowModel.rowsToDisplay);
  }
  buildgrid1() {

    this.localeText = LOCALE_TEXT,
      this.columnDefs = this.columnDefs,

      this.rowSelection = 'single',
      this.rowData1 = this.listhokheczer;
    ;
    if (this.totalhaf > 0) {
      this.zero = false;
      this.zerohec = false
    }
    else {
      this.zerohec = true
    }
  }
  heczer() {
    this.heczerc = !this.heczerc;
  }
  hafkada() {
    this.hafkadac = !this.hafkadac;
  }
  fun(date: Date) {
    if (this.hafkadac == true && this.heczerc == true) {
      this.showHaf(date);
      this.showloans(date);
    }
    else {
      if (this.hafkadac == true && !this.heczerc) {
        this.showHaf(date);
      }
      else {
        if (!this.hafkadac && this.heczerc) {
          this.showloans(date);
        }
      }
    }
  }
  showHafkadotAshrai(date) {
    this.totalhaf = 0;
    this.gvia.ViewHafkadotAshrai(date).then(res => {
      this.listhokhafkadot = res;
      this.listhokhafkadot.forEach(element => {
        this.totalhaf += element.Amount + element.Amla;
        //           this.maf.getmafkid(element.MafkidID).then(res1=>{var b=res1 
        // ;
        //           element['NameM']=this.listnames&&this.listnames.find(a=>a.MAINID==b.MAINID)?this.listnames.find(a=>a.MAINID==b.MAINID).FamilyName +' '+this.listnames.find(a=>a.MAINID==b.MAINID).FirstName:'';
        element['SUMTOGVIA'] = element.Amount + element.Amla;

        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        element['Phone'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).CellPhone1 : '';
        element['Phone2'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).CellPhone2 : '';
        element['Phone3'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).Phone : '';

        let s = this.listmafkids?.find(b => b.Id == element.MafkidID)?.MAINID
        if (s) {
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';
        }
      });
      this.buildgrid();

    })
  }
  send(date: Date) {
    if (this.hafkadac && this.heczerc) {
      this.gvia.SubmitJsonData(date);
    }
    else {
      if (this.hafkadac) {
        this.gvia.SubmitJsonDataHaf(date);
      }
      else {
        this.gvia.SubmitJsonDataHEC(date);

      }
    }
  }
  updatesend(date: Date) {

    if (this.hafkadac && this.heczerc) {
      this.gvia.updatesend(date);
    }
    // else
    // {
    //   if(this.hafkadac)
    //   {
    //   this.gvia.SubmitJsonDataHaf(date);
    //   }
    //   else{
    //   this.gvia.SubmitJsonDataHEC(date);

    //   }
    // }
  }
  showloans(Date) {
    ;
    if (!this.listloans) {


      this.l.getloansAll().then(res => {
        this.listloans = res;
        this.showHeczerAshrai(Date);
      })
    }
    else {
      this.showHeczerAshrai(Date);

    }
  }
  Stop(date)
  {
    this.gvia.Stop(date)
  }
  showHeczerAshrai(date) {
    this.totalheczer = 0;
    this.gvia.ViewHeczerAshrai(date).then(res => {
      this.listhokheczer = res;
      this.listhokheczer.forEach(element => {
        this.totalheczer += element.Scum + element.Amla;
        element['SUMTOGVIA'] = element.Scum + element.Amla;

        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        let s = this.listloans?.find(b => b.ID == element.IdLoan)?.MAINID

        if (s) {
          
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';

        }

      });
      this.buildgrid1();
    })
  }
}
