export class Heczer {
     Id:number
    HokId :number
   IdLoan :number
   idhokhelp:number;
    Scum:number
     Amla:number
    ASMACTA :number
    StatusHeczer:string
    Updateby:string
    Createby:string
    MAINID :number    
   TypeOperation :string
     Notice :string
   SUMTOGVIA :number
    Coin :number
    MASAV:number
     UpdateDat:Date
     CreateDat:Date
     DateForiegnHeczer:Date
    DateHeczer :string
    TypeHok: string;
  getbyway: any;

 Constructor()
{
this.Id=null;
this.HokId=null;
this.IdLoan=null;
this.Scum=null;
this. idhokhelp=null;
this.Amla=null;
this.ASMACTA=null;
this.StatusHeczer=null;
this.Updateby=null;
this.Createby=null;
this.MAINID =null;    
this.TypeOperation =null;
this. Notice =null;
this.SUMTOGVIA =null;
this.Coin =1;
this.MASAV=null;
this.UpdateDat=null;
this.CreateDat=null;
this.DateForiegnHeczer=null;
this.DateHeczer =null;
    }
}
