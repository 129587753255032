import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { cash } from '../classes/cash';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class KupaService {
  ShowOpConnect() {
    throw new Error('Method not implemented.');
  }
 
  
  constructor(private http: HttpClient) { }
  filter(datestart:Date, dateend:Date,want:string,want1:string,scum:number, kod:number): any {
    return this.http.post(NamesService.serverUrl +"/Kupa/Filter",{d:datestart, d1:dateend, w:want,  w1:want1,s:scum , kodcat:kod}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  
  }
  UpdateKupa(cash: cash) {
    return this.http.put(NamesService.serverUrl +"/Kupa/Put",cash).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });   }
  showbalance(id1: Date):any {
    return this.http.post(NamesService.serverUrl +"/Kupa/showbalance",{id:id1}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
 
  AddKupa(cash:cash) {
    return this.http.post(NamesService.serverUrl +"/Kupa",cash).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  ShowKupaOperation(year:number, month:number): any {
    return this.http.post(NamesService.serverUrl +"/Kupa/Getlistkupa",{year1:year, month1:month}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  ShowKupaOperationall(year:number, month:number): any {
    return this.http.post(NamesService.serverUrl +"/Kupa/Getlistkupaall",{year1:year, month1:month}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
 getkupa(): any {
  return this.http.get(NamesService.serverUrl +"/Kupa/Getkupa").toPromise().then(
    response => {
     return  response;
    })
} }