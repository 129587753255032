import { Component, Input, OnInit } from '@angular/core';
import { getPreEmitDiagnostics } from 'typescript';
import { Pniot } from '../pniot';
import { NamesService } from '../services/names.service';

@Component({
  selector: 'app-pniot',
  templateUrl: './pniot.component.html',
  styleUrls: ['./pniot.component.css']
})
export class PniotComponent implements OnInit {

  constructor(private namesService:NamesService) { }
  @Input() mainid;
PniotList:Array<Pniot>=new Array<Pniot>();
  ngOnInit(): void {
   
  }
  Press()
  {
    if(this.mainid!=null)
    {
   this.getPniot()
   document.getElementById('id36').style.display = 'block';

    }
    else
    alert("לא נבחר שם")
  }
  getPniot() {
     this.namesService.GetPniot(this.mainid).then(res => {
      this.PniotList = res;})
  }
  savepniot()
  {
   this.PniotList.forEach(element => {
      element.MAINID=this.mainid
      
    });
    this.namesService.AddPniot(this.PniotList)

  }
  addmore()
  {
    this.PniotList.push(new  Pniot())
  }
}
