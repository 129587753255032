import { Component, OnInit, Input } from '@angular/core';
import { Hok } from 'src/app/classes/hok';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { NamesService } from 'src/app/services/names.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Names } from 'src/app/classes/names';
@Component({
  selector: 'app-hok',
  templateUrl: './hok.component.html',
  styleUrls: ['./hok.component.css']
})
export class HOKComponent implements OnInit {
  //#region 
  list;
  listBankTypes: Array<string> = new Array<string>();
  hok1: Hok = new Hok();
  list2;
  listhoks;
  listbank: Array<string> = new Array<string>();
  listash: Array<string> = new Array<string>();
  listbank1;
  listash1;
  bank;
  ashrai;
  h: boolean;
  new: boolean;
  idintbl;
  f: Names;
  id: number;
  ash: boolean;
  num3: number = 0;
  hok2: Hok = new Hok();
  num: number = 0;
  num1: number;
  bool: boolean;
  statushok: boolean;
  PERMISSION: any;
  @Input() iduser;
  @Input() mainid;
  b: number;
  num5: number = 0;
  nametbl: string;
  i: number = 0;
  num10: number;
  Snif: number;
  mode: number;
  ErrorAccount: boolean;
  //#endregion
  constructor(private hok: HokMenageService, private route: Router, private name: NamesService) {
    this.nametbl = "Hok";
    this.id = this.mainid;
  }
  ngOnInit() {
    this.nametbl = "Hok"
    this.id = this.mainid;
    this.i = 1;
    this.listash = null;
    this.listash1 = null;
    this.listbank = null;
    this.listbank1 = null;
    this.EnterBanks();
    this.showhoks();
    if (this.mainid != null) {
      this.showhok();
      this.new = true;
      this.id = this.mainid;
    }
    this.ash = false;
    this.h = true;
  }
  EnterBanks() {
    this.listBankTypes.push("4");
    this.listBankTypes.push("9");
    this.listBankTypes.push("10");
    this.listBankTypes.push("11");
    this.listBankTypes.push("12");
    this.listBankTypes.push("13");
    this.listBankTypes.push("14");
    this.listBankTypes.push("17");

    this.listBankTypes.push("20");
    this.listBankTypes.push("31");
    this.listBankTypes.push("46");

    this.listBankTypes.push("52");
    this.listBankTypes.push("54");
  }
  showhoks() {
    this.hok.GetHoksAll().then(res => this.listhoks = res)
  }
  show(MAINID: any) {
    if (this.mainid == null) {
      this.mainid = MAINID;
    }
    this.listash = null;
    this.listash1 = null;
    this.listbank = null;
    this.listbank1 = null;
    this.ash = false;
    this.h = true;

    this.showhok();
  }
  showhok() {
    this.id = this.mainid;
    this.listbank = new Array<string>();
    this.listash = new Array<string>();
    if (this.mainid != null) {
      this.hok.GetHoksById(this.mainid).then(response => {
        this.list = response;
        if (this.list.length > 0) {
          document.getElementById('a').style.backgroundColor = '#f44336';
          document.getElementById('b').style.backgroundColor = '#f44336';
          this.hok2 = this.list[0];

          this.listbank = new Array<string>();
          this.listash = new Array<string>();
          ;;
          this.listash1 = this.list.filter(a => a.BANK == null);
          if (this.listash1[0] != null) {
            this.new = false;
            this.listash.push(this.listash1[0].kodtypeash);
            this.currentSlide1(this.listash[0]);
          }
          this.listbank1 = this.list.filter(a => a.BANK != null);
          if (this.listbank1[0] != null) {
            this.new = false;

            this.listbank.push(this.listbank1[0].BANK);
            let elem = document.createElement('img') as HTMLImageElement

            this.currentSlide(this.listbank[0]);
          }
          if (this.listash1[0] != null) {
            this.listash1.forEach(element => {
              if (this.listash.find(a => a == element.kodtypeash) == null) {
                this.listash.push(element.kodtypeash);
              }
            })
          }
          if (this.listbank1[0] != null) {
            this.listbank1.forEach(element => {
              this.i = this.i + 1;
              if (this.listbank.find(a => a == element.BANK) == null) {

                this.listbank.push(element.BANK);
                let elem = document.createElement('img') as HTMLImageElement

              }
            })
          }
        }
        else {
          this.new = true;
          this.hok2 = new Hok();
          this.hok2.Accepted=true
          document.getElementById('a').style.backgroundColor = 'lightgray';
          document.getElementById('b').style.backgroundColor = 'lightgray';
        }
      })
    }
  }
  hokshow() {
    this.ash = false;
    this.h = true;
    if (!this.new) {
      alert("נא לשים לב  שבשינוי אמצעי תשלום ימחק האמצעי הקודם")
    }
  }
  ashshow() {

    this.ash = true;
    this.h = false;
    if (!this.new) {
      alert("נא לשים לב  שבשינוי אמצעי תשלום ימחק האמצעי הקודם")
    }

  }
  showhokdup(n: number) {

    if (this.bank != null) {
      this.h = true;
      this.list2 = this.list.filter(a => a.BANK == this.bank);
    }
    else {
      this.h = false;
      this.list2 = this.list.filter(a => a.kodtypeash == this.ashrai);
    }
    this.num3 = this.list2.length;
    if (this.num + n >= 0 && this.num + n < this.num3) {
      if (this.num3 == 1) {
        document.getElementById('a').style.backgroundColor = 'lightgray';
        document.getElementById('b').style.backgroundColor = 'lightgray';
        this.hok2 = this.list2[0]
        this.bool = this.hok2.Accepted;

      }
      else {
        this.hok2 = this.list2[this.num + n]
        this.bool = this.hok2.Accepted;
        if (this.num + n == 0) {
          document.getElementById('b').style.backgroundColor = 'lightgray';
        }
        else {
          if (this.num + n + 1 == this.num3) {
            document.getElementById('a').style.backgroundColor = 'lightgray';
          }
        }
      } if (this.num + n <= this.num3) {
        this.num += n;
        this.num5 = this.num + 1;
      }
      else {
        this.num = this.num3;
        this.num5 = this.num3;
      }
    }
    else {
      if (this.num + n <= 0) {
        document.getElementById('b').style.backgroundColor = 'lightgray';
        this.num = 0;
        this.num5 = this.num + 1;
        this.hok2 = this.list2[0];
        this.bool = this.hok2.Accepted;

      }
      else {
        if (this.num + n == this.num3) {
          document.getElementById('a').style.backgroundColor = 'lightgray';
          this.hok2 = this.list2[this.num3 - 1];
          this.bool = this.hok2.Accepted;
          this.num = this.num3 - 1;
          this.num5 = this.num3;
        }
      }
    }
  }
  changevalue() {
    this.bool = !this.bool;
  }
  currentSlide(value: string) {
    this.h = true;

    HokMenageService.update = true;
    this.bank = value;
    this.ashrai = null;
    this.ash = false;

    this.num = 0;
    this.hok2 = this.list.find((a) => a.BANK == value) ? this.list.find((a) => a.BANK == value) : null;
    this.showhokdup(0);
  }
  currentSlide1(value: string) {
    this.h = false;
    this.bank = null;
    this.ashrai = value;
    this.ash = true;
    this.num = 0;
    this.hok2 = this.list.find(a => a.kodtypeash == value) ? this.list.find(a => a.kodtypeash == value) : null;
    this.showhokdup(0);
  }
  //#region 
  addhok1() {
    this.bool = false;
    this.new = true;
    this.hok2 = new Hok();
  }
  addHokash(MAINID: number) {

    if (this.new) {
      if (this.listhoks.find(a => a.CardNumber == this.hok2.CardNumber) == null) {
        this.hok1.Notice = this.hok2.Notice;
        this.hok1.MAINID = MAINID;
        this.hok1.CardNumber = this.hok2.CardNumber;
        this.hok1.Valid = this.hok2.Valid;
        this.hok1.CreateBy = this.iduser;
        this.hok1.CVV = this.hok2.CVV;
        this.hok1.Accepted = this.bool;
        this.hok.addHok(this.hok1).then(res => {
          if (res) {
            alert("התוסף בהצלחה");
          }
        })
      }
      else {
        this.name.GetName(this.listhoks.find(a => a.CardNumber == this.hok2.CardNumber).MAINID).then(res => {
          this.f = res
          alert("  כרטיס קיים עש" + this.f.FamilyName + " " + this.f.FirstName);
        })
      }
    }
    else {
      this.hok2.UpdateBy = this.iduser;
      if (this.hok2.CardNumber != null && this.hok2.Valid != null) {
        this.hok2.BANK = null
        this.hok2.SNIF = null
        this.hok2.BANKACCOUNT = null
      }
      this.hok2.Accepted = this.bool;
      this.hok2.UpdateDate = new Date();
      this.hok.updateHok(this.hok2);
    }
    this.hok2 = new Hok;
    this.showhok();
  }
  del() {
    this.hok.delhok(this.hok2).then(res => {
      if (res) {
        alert("נמחק בהצלחה");
        this.showhok();

      }
      else {
        alert(" קיימות פעולות שקשורות להוק זו לא ניתן למחוק");

      }
    })
  }
  addHok(MAINID: number) {
;
    if (this.new) {
      // if (this.listhoks.find(a => a.BANKACCOUNT == this.hok2.BANKACCOUNT && a.BANK.includes(this.hok2.BANK) && a.SNIF.includes(this.hok2.SNIF)) == null) {
        this.hok1.BANK = this.hok2.BANK;
        this.hok1.SNIF = this.hok2.SNIF;
        this.hok1.BANKACCOUNT = this.hok2.BANKACCOUNT;
        this.hok1.Notice = this.hok2.Notice;
        this.hok1.DateLimit = this.hok2.DateLimit;
        this.hok1.ScumLimit = this.hok2.ScumLimit;
        this.hok1.MAINID = MAINID;
        this.hok1.Accepted = this.hok2.Accepted;
        this.hok1.CreateBy = this.iduser;
        this.hok.addHok(this.hok1).then(res => {
          if (res) {
            alert("התוסף בהצלחה");
          }
        })
      // }
      // else {
      //   this.name.GetName(this.listhoks.find(a => a.BANKACCOUNT == this.hok2.BANKACCOUNT && a.BANK.includes(this.hok2.BANK) && a.SNIF.includes(this.hok2.SNIF)).MAINID).then(res => {
      //     this.f = res
      //     alert("חשבון קיים עש" + this.f.FamilyName + " " + this.f.FamilyName);
      //   })
      // }
    }
    else {
      if (this.hok2.BANKACCOUNT != null && this.hok2.BANK != null) {
        this.hok2.CardNumber = null
        this.hok2.Valid = null
      }
      this.hok2.SNIF = this.hok2.SNIF.trim();
      this.hok2.BANKACCOUNT = this.hok2.BANKACCOUNT.trim();
      this.hok2.UpdateBy = this.iduser;
      this.hok2.UpdateDate = new Date();
      this.hok.updateHok(this.hok2);
    }
    // this.hok2= new Hok();
    this.showhok();

  }
  //#endregion
  //#region  checkaccount
  checkedpaagi(Hok: Hok) {


    var tzover = 0;
    this.num10 = Number(Hok.BANKACCOUNT);
    for (this.i = 0; Math.round(this.num10 / 10) != 0; this.i++) {
      tzover += this.i * (this.num10 % 10);
      this.num10 = Math.round(this.num10 / 10)

    }
    tzover += this.i * this.num10;

    if (tzover % 10 == 0)
      return true;
    return false;
  }

  checkedLaumi(hok: Hok) {
    var tzover = 0;
    this.num10 = Number(hok.BANKACCOUNT);
    this.Snif = Number(hok.SNIF);
    this.mode = this.num10 % 100;
    this.num10 /= 100;
    this.Snif *= 1000000;
    this.num10 += this.Snif;

    for (var i = 2; i < 11; i++) {
      tzover += i * this.num10 % 10;
      this.num10 = this.num10 / 10;
    }
    if ((tzover + this.mode % 100 == 90) || (tzover + this.mode % 100 == 60) || (tzover + this.mode % 100 == 70) || (tzover + this.mode % 100 == 72) || (tzover + this.mode % 100 == 20))
      return true;
    return false;
  }
  CheckedBankAccount() {

    this.hok.CheckedBankAccount(this.hok2).then(res => {
      if (!res) {
        this.ErrorAccount = true
      }
      else {
        this.ErrorAccount = false
      }
    })
    // switch (this.hok2.BANK)
    // {
    //     case "10":
    //         if( this.checkedLaumi(this.hok2)==false){this.ErrorAccount=true};
    //     case "52":
    //       if( this.checkedpaagi(this.hok2)==false){this.ErrorAccount=true};
    //     case "12":
    //       if( this.checkedPoalim(this.hok2)==false){this.ErrorAccount=true};
    //     case "46":
    //       if( this.checkedPoalim(this.hok2)==false){this.ErrorAccount=true};
    //     case "11":
    //       if( this.checkedDiskont(this.hok2)==false){this.ErrorAccount=true};
    //     case "17":
    //       if( this.checkedDiskont(this.hok2)==false){this.ErrorAccount=true};
    //     case "4":
    //       if( this.checkedYaav(this.hok2)==false){this.ErrorAccount=true};
    //     case "9":
    //       if( this.checkedHdoar(this.hok2)==false){this.ErrorAccount=true};
    //     case "31":
    //       if( this.checkedpaagi(this.hok2)==false){this.ErrorAccount=true};
    //     case "13":
    //       if( this.checkedLaumi(this.hok2)==false){this.ErrorAccount=true};
    //     case "20":
    //       if( this.checkedMizrachi(this.hok2)==false){this.ErrorAccount=true};
    //     default: return false;
    // }
  }


  checkedHdoar(hok: Hok) {
    var tzover = 0;
    this.num10 = Number(hok.BANKACCOUNT);
    for (var i = 1; this.num10 / 10 != 0; i++) {
      tzover += i * this.num10 % 10;
      this.num10 = this.num10 / 10;
    }
    if (tzover % 10 == 0)
      return true;
    return false;
  }

  checkedMizrachi(hok: Hok) {
    var tzover = this.HelpBank(hok);
    if (tzover % 11 == 0 || tzover % 11 == 2 || tzover % 11 == 4)
      return true;
    return false;
  }

  checkedDiskont(hok: Hok) {
    var tzover = 0;
    this.num10 = Number(hok.BANKACCOUNT);

    for (var i = 1; i < 10; i++) {
      tzover += i * this.num10 % 10;
      this.num10 = this.num10 / 10;
    }
    if (tzover % 11 == 0 || tzover % 11 == 2 || tzover % 11 == 4)
      return true;
    return false;
  }

  checkedYaav(hok: Hok) {
    var tzover = this.HelpBank(hok);
    if (tzover % 11 == 0 || tzover % 11 == 2)
      return true;
    return false;
  }

  checkedPoalim(hok: Hok) {
    var tzover = this.HelpBank(hok);
    if (tzover % 11 == 0 || tzover % 11 == 2 || tzover % 11 == 4 || tzover % 11 == 6)
      return true;
    return false;
  }
  HelpBank(hok: Hok) {
    var tzover = 0;
    this.num10 = Number(hok.BANKACCOUNT);

    this.Snif = Number(hok.SNIF);
    if (hok.BANK == "20") {
      if (this.Snif > 400) {
        this.Snif -= 400;
      }
    }

    this.Snif *= 1000000;
    this.num10 += this.Snif;
    for (var i = 1; i < 10; i++) {
      tzover += i * this.num10 % 10;
      this.num10 = this.num10 / 10;
    }
    return tzover;
  }
  //#endregion
}

