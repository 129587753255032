import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-directoroptains',
  templateUrl: './directoroptains.component.html',
  styleUrls: ['./directoroptains.component.css'],
//  encapsulation: ViewEncapsulation.None

})
export class DirectoroptainsComponent implements OnInit {
seting:boolean;
contacts:boolean;
email:boolean;
worker:boolean;

//contacts:boolean;

  PERMISSION: any;
  iduser: any;
  constructor(private route :ActivatedRoute) { 
    this.route.params.subscribe((params:Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }

  ngOnInit() {
  }
  setting()
  {
this.seting=true;
this.contacts=false;
this.email=false;
this.worker=false;
}
Workers()
{
  this.seting=false;
  this.contacts=false;
  this.email=false;
this.worker=true;

}
sendemail()
{
  this.seting=false;
  this.contacts=false;
  this.email=true;
this.worker=false;

}
  
  ShowContacts()
  {
this.contacts=true;
this.seting=false;
this.email=false;
this.worker=false;

  }
}
