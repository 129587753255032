import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, ContentChild, ElementRef, TemplateRef, QueryList, Input } from '@angular/core';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { HokgviaService } from 'src/app/services/hokgvia.service';
import { ViewhoktgviaComponent } from '../viewhoktgvia/viewhoktgvia.component';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { MafkidService } from 'src/app/services/mafkid.service';
import { Router } from '@angular/router';
import { NamesService } from 'src/app/services/names.service';
import { LoansService } from 'src/app/services/loans.service';

@Component({
  selector: 'app-masav-files',
  templateUrl: './masav-files.component.html',
  styleUrls: ['./masav-files.component.css']
})
export class MasavFilesComponent implements OnInit {
  columnDefs:any
  localeText: any;
  rowSelection: string;
  rowData: any;
  list: any;
  @Input() iduser;
  @Input()  PERMISSION;
  // @ContentChild('foo', { }) foo!: ElementRef;
  // @ViewChildren(ViewhoktgviaComponent) childrenComponent: QueryList<ViewhoktgviaComponent>;

  // public ngAfterViewInit(): void
  // {
  //   this.childrenComponent.changes.subscribe((comps: QueryList<ViewhoktgviaComponent>) =>
  //   {
  //     // Now you can access the child component
  //   });
  // }
  // If you need to access it in ngOnInit hook
  // @ViewChild(TemplateRef, { }) foo!: TemplateRef;
  constructor(private hok:HokgviaService,private cd :ChangeDetectorRef,private hok1:HokMenageService,private maf:MafkidService,private router:Router,private name:NamesService,private l:LoansService) { }
  // @ViewChild (ViewhoktgviaComponent) app: ViewhoktgviaComponent;
  ngOnInit() {
    this.ShowListMasav();
    this.columnDefs = [

      {headerName: 'תאריך יצירה', field: 'DATECREATE',sortable: true,sort: 'asc' ,width: 100,filter: "agTextColumnFilter",  cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString() : '';}},
      {headerName: 'תאריך שליחה', field: 'DATESEND',sortable: true ,filter: "agTextColumnFilter",width: 100,  cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString() : '';}},
      {headerName: 'קוד מוסד', field: 'Masav',width: 100,filter: "agTextColumnFilter"},
      {headerName: 'סכום', field: 'Scum',width: 100,filter: "agTextColumnFilter"},
      {headerName: 'אסמכתא' , field: 'Asmacta',filter: "agTextColumnFilter",width: 100,editable: true},
      {headerName: 'מחק' ,width: 70,field: 'del',    
     cellRenderer: params => {
        return `<button style="width:100%;height:100%; text-align:center;  border: none;    background-color:transparent;
        outline:none;" >  <svg   style="width: 100%;height:100%;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
      </svg></button>`;}},
        {headerName: 'עדכן ' ,width: 70,  field: 'update'  ,cellRenderer: params => {
          return `<button style="width:100%;height:100%;   border: none;    background-color:transparent;
          outline:none;" > <svg style=";width: 100%;height: 100%;" viewBox="0 0 16 16" class="bi bi-file-earmark-check-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zm1.854 2.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
        </svg></i></button>`;}},
        // return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;}},
  ];
}
delMasav(event)
{
  ;
  if (event.column.getColId() === 'del') {
if(event.data.Asmacta==null){
  if(window.confirm("האם הינך בטוח שברצונך לבטל פעולה זו?"))
 {
   this.hok.DelMasav(event.data.id);
}
 }
 else{
  alert("לא ניתן לבטל לאחר מילוי אסמכתא");

 }
}
 else{
  if (event.column.getColId() === 'update') {
    ;
    if(event.data.Asmacta!=null&& event.data.DATESEND==null){
    
    this.hok.updatemasav(event.data);}
    else{
      if(event.data.Asmacta==null)
      {
      alert("נא למלא אסמכתא");}
      else{
        alert("הקובץ נשלח כבר")
      }
    }

 }
 
}
}
  ShowListMasav() {
    this.hok.ViewListMasav().then(res=>{
      this.list=res;
      this.buildgrid();
    })
  }
  buildgrid()
  {
   this.localeText = LOCALE_TEXT,
   this.rowSelection= 'single',
   this.rowData = this.list;
    this. columnDefs= this.columnDefs }  


viewdetails(event)
{
  ;

  // this,this.childrenComponent.changes;
//   this.app=new ViewhoktgviaComponent(this.hok,this.hok1,this.maf,this.router,name,this.l);
//   if(this.app!=null)
//   {
// this.app.showhafMasav(event.data.id);
// this.cd.detectChanges();
// }
if (event.column.getColId() !== 'Asmacta') {
this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  this.router.navigate(["/gvia1/" + this.iduser, this.PERMISSION, event.data.id]);
})}
}
viewdetails1(event)
{
;
}


}
