import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  
  constructor(private http: HttpClient) { }
  SendEmail(who: any, subject: string, notice: string) {
    return this.http.put(NamesService.serverUrl +"/Help/sendemail",{}).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });   }

}
