export class AshrayType {
    Id :number
     name :string
    price :number
    constructor()
    {
        this.Id=null;
      this.name =null;
      this.price =null;
    }
}
