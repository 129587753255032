import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { WaitingService } from 'src/app/services/waiting.service';
// import "ag-grid-enterprise";
import { ShowloansComponent } from '../showloans/showloans.component';
import { AddWaitingComponent } from '../add-waiting/add-waiting.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NamesService } from 'src/app/services/names.service';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { LOCALE_TEXT } from 'src/app/classes/constance';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.css'],

})
export class WaitingComponent implements OnInit {
  //#region 
  list;
  list2;
  columnDefs;
  gridOptions;
  defaultColDef;
  rowData: Array<any>;
  PERMISSION: any;
  getloan: boolean = false;
  iduser: any;
  mainid
  listnames: any;
  listc: any;
  localeText: any;
  rowClassRules: any;
  rowSelection: any
  //#endregion
  constructor(private cd: ChangeDetectorRef, private wait: WaitingService, private maagar: MaagarMenageService, private name: NamesService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }
  @ViewChild(AddWaitingComponent) app: AddWaitingComponent;

  ngOnInit() {
    this.getcoin();
    this.columnDefs = [
      { headerName: 'שם פרטי', field: 'fname', sortable: true, filter: "agTextColumnFilter", width: 100 },
      { headerName: 'שם משפחה', field: 'name', width: 105, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'פלאפון', field: 'cell', width: 100, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'רחוב', field: 'adress', filter: "agTextColumnFilter", width: 100, editable: true },
      {
        headerName: 'תאריך בקשה', field: 'DateDesire', filter: "agTextColumnFilter", width: 110, editable: true, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'סכום', field: 'Amont', filter: "agTextColumnFilter", width: 90, editable: true },
      { headerName: 'מס תשלומים', field: 'NumOfPayment', filter: "agTextColumnFilter", width: 110, editable: true },
      { headerName: 'דירוג', field: 'derugp', filter: "agNumberColumnFilter", width: 110, editable: true },
      { headerName: 'מטבע', field: 'Coin', filter: "agTextColumnFilter", width: 100, editable: true },
      {
        headerName: 'סטטוס בקשה', field: 'StatusHafkada', filter: "agTextColumnFilter", width: 115, editable: true, cellEditor: "agRichSelectCellEditor",

        cellRenderer: params => {
          return `<input type='checkbox' disabled ${params.value ? 'checked' : ''} />`;
        }
      },
      { headerName: 'הערות', field: 'Remarks', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'אסמכתא', field: 'ASMACTA', filter: "agTextColumnFilter", width: 90, editable: true },
      {
        headerName: 'מאושר לתאריך', field: 'OkToDate', filter: "agTextColumnFilter", width: 120, editable: true, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      {
        headerName: 'סכום מאושר', field: 'OKAmount', filter: "agTextColumnFilter", width: 110, editable: true
      },
      {
        headerName: 'להכניס הלוואה?', field: 'GetLoan', width: 125, editable: true,

        cellRenderer: params => {
          return `<input type='checkbox'disabled ${params.value ? 'checked' : ''} />`;
        }

      },
      {
        headerName: 'הלוואה התקבלה?', field: 'GetLoanmaybe', filter: "agTextColumnFilter", width: 150, editable: true,
        cellEditor: "agRichSelectCellEditor", cellRenderer: params => {
          return `<input type='checkbox' disabled ${params.value ? 'checked' : ''} />`;
        }
      },
    ];
  }
  getcoin() {
    this.listc = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listc == null) {
      this.maagar.GetCoins().then(res1 => {
        this.listc = res1
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listc));
        this.showNames();
      })
    }
    else{
      this.showNames();
    }
  }
  viewdetails(event) {
    this.app.show(event.data.MAINID)
    this.cd.detectChanges();
  }
  buildgrid() {
    this.rowClassRules = {
      'status0': function (params) { return params.data.derugp == "0"; },
      'status1': function (params) { return params.data.derugp == "1"; },
      'status2': function (params) { return params.data.derugp == "2"; },
      'status3': function (params) { return params.data.derugp == "3"; },
      'status4': function (params) { return params.data.derugp == "4"; },
      'status5': function (params) { return params.data.derugp == "5"; },
      'status6': function (params) { return params.data.derugp == "6"; },
      'status7': function (params) { return params.data.derugp == "7"; },
      'status8': function (params) { return params.data.derugp == "8"; },
      'status9': function (params) { return params.data.derugp == "9"; },
      'status10': function (params) { return params.data.derugp == "10"; }


    },
      this.localeText = LOCALE_TEXT,
      this.rowSelection = 'single',
      this.rowData = this.list;
    this.columnDefs = this.columnDefs
    //  this.rowClassRules= {
    //   function(params){ params.value!=null? {backgroundColor: 'rgb('+params.value*25+', '+params.value*25+','+params.value*25+')'}:null}  
    //   }
  }
  showNames() {
    this.listnames = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.listnames == null) {
    this.name.ShowNames().then(res => {
      this.listnames = res  })}
      this.wait.ShowWaiting().then(res => {
        this.list = res;
        if(this.listnames!=null)
        {
        this.list.forEach(element => {
          element['derugp'] = this.listnames.find(a => a.MAINID == element.MAINID).derug
          element['name'] = this.listnames.find(a => a.MAINID == element.MAINID).FamilyName
          element['fname'] = this.listnames.find(a => a.MAINID == element.MAINID).FirstName
          element['cell'] = this.listnames.find(a => a.MAINID == element.MAINID).CellPhone
          element['adress'] = this.listnames.find(a => a.MAINID == element.MAINID).Adress
          element['Coin'] = this.listc.find(a => a.Id == element.Coin).name
        });
        this.list2 = this.list;
        this.rowData = this.list;
        this.buildgrid();}
    })
  }
  adddesire() {
    this.router.navigate(["/waiting"]);
  }
  viewgetloan() {
    this.getloan = !this.getloan;
    if (this.getloan) {
      this.list = this.list2;
      this.list = this.list.filter(a => a.GetLoan == true);
    }
  }
  filter() {
    if (this.getloan)
      this.list.filter(a => a.GetLoan == true)
  }
  // updatewaitingok(id:number, scum:number,date:Date)
  // {
  //   this.wait.PutWaitingok(id,date,scum,false);
  // }
  // insertloan(id:number, scum:number,date:Date)
  // {
  //   this.wait.PutWaitingok(id,date,scum,true);
  // }
}
