import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Hafkadot } from '../classes/hafkadot';
import { Shiabud } from '../classes/shiabud';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class HafkadotService {
  
   constructor(private http: HttpClient) { }

  View(i:number, da: Date,big:boolean):any {
    return this.http.post(NamesService.serverUrl +"/Hafkadot/View",{id:i,d:da,b:big} ).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });  }
    Shiabud(shiabud1: Shiabud) {
      return this.http.put(NamesService.serverUrl +"/Mafkid/Shiabud",shiabud1).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; }); 
    }

    UpdateHaf(h: Hafkadot) {
      return this.http.put(NamesService.serverUrl +"/Hafkadot/PutHAfkada",h).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });  }
  showHAfkadot() {
    return this.http.delete(NamesService.serverUrl +"/Hafkadot/showHAfkadot").toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; })  }
addHafkadot(pro:Hafkadot): any {
  ;
  return this.http.post(NamesService.serverUrl +"/Hafkadot/PostHafkadot", pro).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; });
}
DeleteHafkada(id: any): any {
  return this.http.delete(NamesService.serverUrl +"/Hafkadot/DeleteHafkada?id="+id).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; })} 
      DeleteAllhafkadot(id: any) {
        return this.http.delete(NamesService.serverUrl +"/Hafkadot/DeleteallHafkadot?id="+id).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; })  }
  DeleteJustOnehafkadot(id1: any, arg1: any) {
    return this.http.put(NamesService.serverUrl +"/Hafkadot/DeleteHafkadotfromonehok",{id:id1,idhok:arg1}).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; })  }
    DeleteAllFuturehafkadot(id: any) {
      return this.http.delete(NamesService.serverUrl +"/Hafkadot/DeleteallFutureHafkadot?id="+id).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; })  }
DeleteJustOneFuturehafkadot(id1: any, arg1: any) {
  return this.http.put(NamesService.serverUrl +"/Hafkadot/DeleteFutureHafkadotfromonehok",{id:id1,idhok:arg1}).toPromise().then(
    response => {
      return true;
    })
  .catch(v => { return false; })  }
}
