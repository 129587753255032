import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Maslul } from '../classes/maslul';
import { Coin } from '../classes/coin';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class MaagarMenageService {

  

  constructor(private http: HttpClient) { }
  getIdByNameCity(name: String): any {
    return this.http.get(NamesService.serverUrl +"/Maagar/GetCityid?id="+name).toPromise().then(
      res => { 
        return res; 
       })
      .catch(err => { return false; })}
      GetGroups():any {
        return this.http.get(NamesService.serverUrl +"/Neztigim/GetGroups").toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });  }
      Undercat() :any {
        return this.http.get(NamesService.serverUrl +"/Maagar/Undercat").toPromise().then(
          res => { 
            return res; 
           })
          .catch(err => { return false; })      }
      Showcathaf():any {
        return this.http.get(NamesService.serverUrl +"/Maagar/Showcathaf").toPromise().then(
          res => { 
            return res; 
           })
          .catch(err => { return false; })
      }
      getTypesLoan():any {
            return this.http.get(NamesService.serverUrl +"/Maagar/getTypesLoan").toPromise().then(
          res => { 
            return res; 
           })
          .catch(err => { return false; })      }
    
      Showcatloan() :any{
        return this.http.get(NamesService.serverUrl +"/Maagar/Showcatloan").toPromise().then(
          res => { 
            return res; 
           })
          .catch(err => { return false; })
      }       
      DollarController (): any {
        return this.http.get(NamesService.serverUrl +"/DollarController").toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });
      }
       GetCoins():any {
        return this.http.get(NamesService.serverUrl +"/Coin/getcoins").toPromise().then(
          res => { 
            return res; 
           })
          .catch(err => { return false; })}
       Showcat(name:string): any {
      return this.http.get(NamesService.serverUrl +"/Maagar/GetCatlistbynamet?id="+name).toPromise().then(
            response => {
             return  response;
            })
          .catch(v => { return false; });}
      addCity(pro:String): any {
        return this.http.post(NamesService.serverUrl +"/Maagar/PostCity", pro).toPromise().then(
            response => {
              return response;
            })
          .catch(v => { return false; });
      }
      ShowMaslul(): any {
        return this.http.get(NamesService.serverUrl +"/Maagar/GetMaslul").toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });}
        addmaslul(pro:Maslul): any {
          return this.http.post(NamesService.serverUrl +"/Maagar", pro).toPromise().then(
              response => {
                return true;
              })
            .catch(v => { return false; });
        }
        ShowCity(): any {
          return this.http.get(NamesService.serverUrl +"/Maagar/GetCities").toPromise().then(
            response => {
             return  response;
            })
          .catch(v => { return false; });
        }
}
