<div *ngFor='let item of list'>
<p>קוד מסלול</p>
<input type="text"   value={{item.KodMaslul}} class="id" #IdLoan  disabled>
<p> סכום הפקדה חודשי</p>
<input type="text" class="DateLoan" value={{item.ScumHafkada}}  #DateLoan>
<p>מס יחידות </p>
<input type="number"  value={{item.yechidot}}  #DateforienLoan>
<p>מס תשלומים</p>
<input type="number" class="LoanAmount" value={{item.NumOfPayment}}  #LoanAmount>
<p>סהכ הלוואה </p>
<input type="number" class="Payments"  value={{item.ScumHalvaa}} #Payments>
<p>סכום החזר חודשי</p>
<input type="number" class="Pay" value={{item.Heczer}}  #Pay>
<p>מס תשלומים החזר</p>
<input type="number" class="Balance" value={{item.NumOfHeczerPayments}}  #Balance>
<!-- <button  class="btn btn-primary btn-lg" (click)='ShowMaslul(IdLoan.value,DateLoan.value,DateforienLoan.value,LoanAmount.value,Payments.value,Pay.value,Balance.value,Coin.value,loanDateHeczer.value,loanDateforienHeczer.value,Arev1.value,Arev2.value,Arev3.value,Arev1cell1.value,Arev1cell2.value,Arev1cell3.value,HockReturn.value,notice.value,UpdateDate.value,UpdateBy.value,CreateDate.value,CreateBy.value)'></button> -->
</div>
<router-outlet></router-outlet>
