export class HokHelp {
    KodHok:Number;
    StartDate:Date;
    MisTashlumim:number;
    Scum:number;
    Amla:number;
    Kodmafkidcount:number;
    kodloan :number;
    kodhokpay:number;
    pail :boolean;
    updatehok:boolean;
    mainid:Number;
    entereveryyear:boolean;
    enddate :Date;
    CreateDat:Date;
    UpdateDat:Date;
    dayinmonth :number;
    typeop :string;
    oldid :number;
    total :number;
    namecheck :string;
    shpitzer:boolean
    Rate:number
DiffrenceMonthes:number
AutoCalculate:boolean
    public HokHelp()
    {
        this.DiffrenceMonthes=1;
        this.KodHok = -1;
        this.StartDate = null;
        this.MisTashlumim = 1;
        this.Scum = 0;
        this.Amla = 0;
        this.total=0;
        this.UpdateDat=null;
        this.Kodmafkidcount=null;
        this.pail=true;
        this.kodhokpay=null;
        this.kodloan=null;
        this.dayinmonth=20;
        this.oldid=null;
        this.typeop=null;
        this.mainid=null;
        this.updatehok=false;
        this.CreateDat=null;
        this.entereveryyear=false;
        this.updatehok=true;
    }
}
