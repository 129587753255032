import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAfkidCount } from '../classes/mafkid-count';
import { Transfer } from '../classes/transfer';
import { Hafkadot } from '../classes/hafkadot';
import { NamesService } from '../services/names.service';


@Injectable({
  providedIn: 'root'
})
export class MafkidService {
  reportsToOneRow(h2: Hafkadot, arg1: boolean) {
    return this.http.post(NamesService.serverUrl +"/Mafkid/reportsToOneRow",h2).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; }); 
  }
  
  resettotalcount(idmafkid: any) {
    return this.http.get(NamesService.serverUrl +"/Mafkid/resettotalcount?id="+idmafkid).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });  }
  ShowLOanstoMafkid(Id: number) :any{
    return this.http.get(NamesService.serverUrl +"/Mafkid/getListLoans?id="+Id).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });   }
 
  GetLoansShiabud(mainid: any):any {
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetLoansShiabud?id="+mainid).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });    }
    SaveTransfer(Transfer: Transfer) {
      return this.http.put(NamesService.serverUrl +"/Mafkid/SaveTransfer",Transfer).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });      }
    GETShiabud(idmaf: any) {
      return this.http.get(NamesService.serverUrl +"/Mafkid/GETShiabud?id="+idmaf).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });    }
    
  static totald: number;
  static total: number;
  constructor(private http: HttpClient) { }
  GetAllMaf():any {
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetAllMaf").toPromise().then(
      response => {
        debugger

        return response;
      })
}
getListHafkadot():any {
  return this.http.get(NamesService.serverUrl +"/Mafkid/getListHafkadot").toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });  
}

  delMaf(Id:number) :any{
    return this.http.get(NamesService.serverUrl +"/Mafkid/DelMafkid?id="+Id).toPromise().then(           
      response => {
       return  response;
      })
    .catch(v => { return false; });  }

  update(mafkid: MAfkidCount) {
    return this.http.put(NamesService.serverUrl +"/Mafkid/PutMafkid",mafkid).toPromise().then(           
      response => {
       return  response;
      })
    .catch(v => { return false; }); }  
  showhafkadacountcontact(id: number) {
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetAcountsByContact?id="+id).toPromise().then(
              
      response => {
       return  response;
      })
    .catch(v => { return false; }); }

    GetMaslulById(IdMaslul: number):any {
      return this.http.get(NamesService.serverUrl +"/Mafkid/GetMaslulById?id="+IdMaslul).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });  
  }
  GetTotalBymainid(id: any) :any{
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetTotalBymainid?id="+id).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; });
  }
 getmasluls() :any {
  return this.http.get(NamesService.serverUrl +"/Mafkid/GetMaslul").toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });  }
 GetHokhelp(id1: any): any {
  return this.http.get(NamesService.serverUrl +"/HokHelp/GetHokhelp?id="+id1).toPromise().then(
    response => {
     return  response;
    })  } 
    
    GetTotalContact(id: number): any  {
      return this.http.get(NamesService.serverUrl +"/Mafkid/GetTotalContact?id="+id).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });
  }
    GetTotal(id: number): any  {
      return this.http.get(NamesService.serverUrl +"/Mafkid/GetTotal?id="+id).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });
  }
  enterhaf(id:Number): any {
    return this.http.get(NamesService.serverUrl +"/Mafkid/EnterHok?id="+id).toPromise().then(
      response => {
       return  true;
      })
    .catch(v => { return false; });

  }
 GetTotal1(id: number) :any{
  return this.http.get(NamesService.serverUrl +"/Mafkid/GetTotal1?id="+id).toPromise().then(
    response => {
      return response;
    })
  .catch(v => { return false; });  }
    bankreports(id: any): any {
      return this.http.get(NamesService.serverUrl +"/Mafkid/bankreport?id="+id).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; }); 
    }
  reports(value1: boolean, id: any ,future:boolean): any {
    return this.http.post(NamesService.serverUrl +"/Mafkid/report", {id:id, report:value1, f:future, s:value1}).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; });  }
  HokReturn(id: number, hokreturn1: string,amla:boolean,iduser1:number,date1:Date,idr:number): any {
    if(hokreturn1==null)
    {
      hokreturn1="חודש הבא כפול"
    }
    if(date1==null)
    {
      date1=new Date();
    }
    return this.http.put(NamesService.serverUrl +"/hafkadot/puthokreturn", {id:id, s:hokreturn1,a:amla,iduser:iduser1,date:date1,r:idr}).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; });
  }
  pay(id: any, r:string): any {
    return this.http.put(NamesService.serverUrl +"/hafkadot/putpay", {id:id,remark:r}).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; });
  }
  getlistNames(id:number):any
  {
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetNamesMafkidim?id="+id).toPromise().then(
      response => {
       return  response;
      })
  }
  getlistNames1(id:number)
  {
    return this.http.get(NamesService.serverUrl +"/Mafkid/GetNamesMafkidim?id="+id).toPromise().then(
      response => {
       return  response;
      })
  }
  addName(pro:MAfkidCount): any {
    return this.http.post(NamesService.serverUrl +"/Mafkid", pro).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });
  }
  Stophafkadot(id: number, num:number):any {
 
     this.http.put(NamesService.serverUrl +"/Hafkadot/putstophafkada",{idm: id ,num1:num}).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; });
}
  ViewHafkadot(id:number) : any {
    {
     
        return this.http.get(NamesService.serverUrl +"/Hafkadot/GetHafkadotById?id="+id).toPromise().then(
          
          response => {
           return  response;
          })
        .catch(v => { return false; });
      }}
      showhafkadacount(id:number) : any {
        {
         
            return this.http.get(NamesService.serverUrl +"/Mafkid/GetAcountsByName?id="+id).toPromise().then(
              
              response => {
               return  response;
              })
            .catch(v => { return false; });
          }}
      getmafkid(id:number) : any {
        {
         
            return this.http.get(NamesService.serverUrl +"/Mafkid/GetAcountByid?id="+id).toPromise().then(
              response => {
               return  response;
              })
            // .catch(v => { return false; });
          }}
        }
