
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<app-main [user]="PERMISSION" [iduser]="iduser" ></app-main>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<span  class="label">{{month}}/{{year}}</span>
<br>
<br>
<button type="button" class="button button1" (click)='filterlist()' style="position: relative ;left: 860px;top: 1px;height: 38px;" >סנן</button>
<h6 >cash</h6>
<div [hidden]="!filter" style="height:300px;width: 300px;border-bottom-color: black; position: relative; top:140px;left:1500px; background-color: lightgray" >
    <span class="label1" style="position:relative; left:218px; top: 0px;width: 100% !important;font-size: 15px;  border: 0.8px solid white">:מתאריך</span> 
    <input type="date"#d class="input special" style="position:relative; left:-71px">  
    <br>
    <span class="label1" style="position:relative; top:33px;font-size: 13px; left: 216px;border: 0.8px solid white;">:עד תאריך</span> 
   <br>
   <input type="date"#d1 class="input special" formtarget=" dd/MM/yy"> 
  <br>
  <br>
  <select class="custom-select" (change)='valuechange1(val.value)' #val style="background-color:gray;border: 0.8px solid white; direction: rtl;text-align: right;position: relative; left:212px; color: white;  width: 80px ; ">
  <option >יותר מ</option>
  <option >שווה ל</option>
  <option > פחות מ</option>
  </select>
  <br>
  <br>
  <input type="number"#num placeholder="0"  step="1000" style="position:relative; top: -64px"> 
  <br>
  <br>
  <select class="custom-select" #select1 (change)='valuechange2(select1.value)' style="background-color:gray;position: relative;direction: rtl; border: 0.8px solid white; left:3px;top:-60px;text-align: center; color: white;  width: 180px ; ">
    <option *ngFor='let item of list2'  >{{item.NameCategory}}</option>
    </select>
  <select class="custom-select" #select (change)='valuechange(select.value)' style="background-color:gray;position: relative;direction: rtl;border: 0.8px solid white; left:30px;top:-60px;text-align: center; color: white;  width: 80px ; ">
  <option >זכות</option>
  <option >חובה</option>
<option>חובה עתיד</option>
<option>זכות עתיד</option>
  </select>
  <br>
  <br>
  <button type="button" class="button"  style="position:relative;width: 300px; top: -60px ;left: 1px; border: 0.8px solid white">הצג</button>
  </div>
  <div style=" position: absolute; top:200px;left:10px;">
    <div class="danger">
      <p ><strong style="position: relative; left: -50px;">     <input #date1 type="date" (change) =" showbalance(date1.value)" value="{{ myDate | date: 'yyyy-MM-dd' }}" style="width: 140px; background-color: gray;    border-radius: 30px; height: 30px;font-size: 15px;">
      </strong>:הצג מאזן לתאריך</p>
    </div>
      <div *ngIf="s==true">

<div class="danger">
  <p ><strong> {{kupacount.futureZcut}}</strong>:סה"כ עתיד להכנס</p>
</div>

<div  class="danger">
  <p ><strong>{{kupacount.futureminus}} </strong> :סכום שעומד לצאת</p>
</div>
<div class="danger">
  <p ><strong>{{  kupacount.balance}} </strong> : מאזן</p>
</div>
      </div>
<div *ngIf="dolar==true">
<div class="danger">
    <p ><strong> {{kupacount.futureZcutdolar}}</strong>:   סה"כ עתיד להכנס דולר</p>
  </div>
  
  <div class="danger">
    <p ><strong>{{kupacount.futureminusdolar}} </strong> :סכום שעומד לצאת דולר</p>
  </div>
  <div class="danger">
    <p  ><strong>{{  kupacount.balancedolar}} </strong> :מאזן דולר</p>
  </div>
</div></div>
<div style="width: 400px; position: absolute;left: 0;top:580px;">
  <div>
    <div class="chart" *ngIf='kupacount' >
      <!-- <canvas baseChart
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [colors]="pieChartColors"
        [legend]="pieChartLegend">
      </canvas> -->
    </div>
  </div>
</div>
 <img style="width:43px;    position: absolute;top: 68.7%;left: 10px;" (click)="dollar()" src="/assets/1/dollar.png">
 <img style="width: 48px;    position: absolute;top: 68.7%; left: 60px;"(click)="sh()" src="/assets/1/shekel.png"> 
<div style="text-align:center; position: absolute;top: 120px ;left: 420px;">

  <span class="dot" (click)="currentSlide(12)">12</span> 
  <span class="dot" (click)="currentSlide(11)">11</span> 
  <span class="dot" (click)="currentSlide(10)">10</span> 
  <span class="dot" (click)="currentSlide(9)">9</span> 
  <span class="dot" (click)="currentSlide(8)">8</span> 
  <span class="dot" (click)="currentSlide(7)">7</span> 
  <span class="dot" (click)="currentSlide(6)">6</span> 
  <span class="dot" (click)="currentSlide(5)">5</span>          
  <span class="dot" (click)="currentSlide(4)">4</span> 
  <span class="dot" (click)="currentSlide(3)">3</span> 
  <span class="dot" (click)="currentSlide(2)">2</span> 
  <span class="dot" (click)="currentSlide(1)">1</span> 
</div>
<button (click)="Add()" class="btn btn1" onclick="document.getElementById('id08').style.display='block'"  style="position: absolute ;left: 900px;top:160px;width: 68px;height: 38px;"><i > +</i></button>

  <ag-grid-angular 
  style="  width:62%;height: 500px;
  position: absolute;
  left: 450px;
  top:300px;direction: rtl;" 
  class="ag-theme-balham"
  [rowData]="rowData" 
  [columnDefs]="columnDefs"
  [enableRtl]="true"
  [localeText]="localeText" 
  [rowSelection]="rowSelection"
  [floatingFilter]="true"  
  (cellClicked)='view($event)'

 >
  </ag-grid-angular>
  <img src="/assets/1/v.jpg" style="position: absolute;       left: 868px;
  top: 174px;    width: 30px; height: 30px;" (click)="showdiv()" >

  <div class="slideshow-container" style="text-align:center; position: absolute;top: 850px "> 
    <a class="prev"  style="position: relative;top: -150px ;left:1680px" (click)="plusSlides(-1)">&#10095;</a>
    <a class="next" style="position: relative;top: -150px ; left:340px" (click)="plusSlides(1)">&#10094;</a>
  </div>
  <br> 
 <html>
    <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    <body>

<div id="id08" class="modal" >
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute; left:-580px;top: -32px;" onclick="document.getElementById('id08').style.display='none'" class="close" title="Close Modal">&times;</span> 
    </div>
    <div id="mySidenav"style="position: relative; top:0px;"  class="sidenav">
</div>
<h1 style="text-align: center;position: relative;top:-20px">פרטי תנועה</h1>
<div class="container" style="position: relative; left:400px;">        
</div>
<p style="position: relative;top: -20px;left:464px;direction: rtl"  >קטגוריה</p>
<select #v  [(ngModel)]="cash.namecat"  [ngModelOptions]="{standalone: true}" class="form-control" style="position: relative;top: -30px; left: 338px; width: 180px;direction: rtl"><option *ngFor='let item of list2'>{{item.NameCategory}}</option></select>
<p style="position: relative;top: -95px;left:203px" >פרטים</p>
<input type="text" id="b" class="form-control"  style="position: relative;top: -106px" placeholder="פרטים"  #Details [(ngModel)]="cash.Details"  [ngModelOptions]="{standalone: true}">
<p style="position: relative;top: -81px;left: 433px" >תאריך פעולה</p>
<input class="form-control" id="a"  style="position: relative;top: -90px;" type="date" [ngModel]="cash.DateOperation|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}" (ngModelChange)="cash.DateOperation=$event"
 placeholder="תאריך"  #DateOperation>
<p style="position: relative;top: -160px;left:217px" >סכום</p>
  <input type="number" id="b"  class="form-control" style="position: relative;top: -169px;"  [(ngModel)]="cash.Amount"  [ngModelOptions]="{standalone: true}" placeholder="סכום"  #Amount>
<p  style="position: relative;top: -140px;left: 417px">סכום שהתקבל</p>
<input  type="number" style="position: relative;top: -150px;"  class="form-control" id="a"[(ngModel)]="cash.AmountEnter"  [ngModelOptions]="{standalone: true}"   placeholder="סכום שהתקבל"  #AmountEnter>
<p  style="position: relative;top: -216px;left:128px">סכום שיתקבל</p>
<input  type="number" class="form-control" id="b" style="position: relative;top: -227px;"[(ngModel)]="cash.AmountFuture"  [ngModelOptions]="{standalone: true}"  placeholder="סכום שעתיד להכנס"  #AmountFuture>
<p  style="position: relative;top: -200px;left: 417px">סכום שיצא</p>
<input  type="number" style="position: relative;top: -170px;"  class="form-control" id="a"[(ngModel)]="cash.Moneycameout"  [ngModelOptions]="{standalone: true}"   placeholder="סכום שיצא" >
<p  style="position: relative;top: -250px;left:128px">סכום שיצא בעתיד</p>
<input  type="number" class="form-control" id="b" style="position: relative;top: -250px;"[(ngModel)]="cash.Moneycameoutfuture"  [ngModelOptions]="{standalone: true}"  placeholder="סכום שיצא בעתיד" >
<p style="position: relative;top: -220px;left:464px;direction: rtl"  >קטגוריה תת</p>
<select #v  class="form-control" style="position: relative;top: -190px; left: 338px; width: 180px;direction: rtl" [(ngModel)]="cash.UnderCategoryName"  [ngModelOptions]="{standalone: true}"><option *ngFor='let item of listUndercat'>{{item.name}}</option></select>
<p style="position: relative;top: -220px;left:64px;direction: rtl"  >קבוצה</p>
<select #v  class="form-control" style="position: relative;top: -240px; left: 138px; width: 180px;direction: rtl" [(ngModel)]="cash.groupname"  [ngModelOptions]="{standalone: true}"><option *ngFor='let item of listGroups'>{{item.name}}</option></select>
<p style="position: relative;top: -280px;left:64px;direction: rtl"  >מטבע</p>
<select #v  class="form-control" style="position: relative;top: -270px; left: 138px; width: 180px;direction: rtl" [(ngModel)]="cash.CoinName"  [ngModelOptions]="{standalone: true}"><option *ngFor='let item of listcoins'>{{item.name}}</option></select>
<button  type="button" onclick="document.getElementById('id08').style.display='none'" style="position: absolute;left:2px;top: 676px;  background-color: gray;width: 80%"  class="button button1" (click)='saveall()'> אישור </button>
<button type="button" style="width: 19%;position: absolute;
height: 48px;
left: 437px;
top: 674px;" onclick="document.getElementById('id08').style.display='none'" class="cancelbtn">ביטול</button>
</form>
</div>
</body>
</html>
  
  
  
  
  
  
  

