<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<ag-grid-angular
  style="width:1220px; height: 580px;position: absolute; left:-110px;top:220px;direction: rtl;border: gray 1px solid; border-radius: 3px;"
  class="ag-theme-balham" [rowData]="rowData" [columnDefs]="columnDefs" [enableRtl]="true" [suppressCsvExport]="false"
  [floatingFilter]="true" [localeText]="localeText" (gridReady)="onGridReady($event)" [rowSelection]="rowSelection"
  (cellContextMenu)="fun($event)" (filterChanged)="filterChanged()" [rowClassRules]="rowClassRules"
  (cellClicked)='update($event)'>
</ag-grid-angular>
<!-- הוספת הפקדה/משיכה -->
<div
  style="position: absolute;background-color: rgb(253, 251, 251);left: -459px; top: 482px;  width: 325px; height: 320px; border:black 2px solid; background-color: gray; border-radius: 5px;">
  <label style="position: absolute;left: 228px;top: 160px;cursor: pointer;"> <b> משיכה </b> <input type="radio" name="p"
      style="cursor: pointer;" (click)="drag()"></label>
  <br>
  <label style="position: absolute;left: 228px;top: 109px;cursor: pointer;"> <b>הפקדה </b> <input type="radio" name="p"
      style="cursor: pointer;" (click)="hafkada()"></label>
  <br>
  <ng-select
    style="width: 180px; left: 6px; height: 30px; position: absolute;  top: 44px; direction: rtl;  color: black;"
    (change)='changeva2($event)' [items]="items" bindValue="MAINID" placeholder="הפקדות של" [(ngModel)]="h3.MAINID"
    [ngModelOptions]="{standalone: true}" ngDefaultControl #val bindLabel="Name">
  </ng-select>
  <input type="date" style="position: absolute; top: 7px;  width: 180px; padding: 4px 7px;height: 30px;"
    value="{{ myDate | date: 'yyyy-MM-dd'}}" #d>
  <br>
  <input type="number" style="position: absolute; top: 75px; width: 180px;direction: rtl;height: 30px;"
    placeholder="סכום" [(ngModel)]="h3.Amount" [ngModelOptions]="{standalone: true}" ngDefaultControl>
  <input type="number" style="position: absolute; top: 115px; width: 180px;direction: rtl;height: 30px;"
    placeholder="עמלה" [(ngModel)]="h3.Amla" [ngModelOptions]="{standalone: true}" ngDefaultControl>
  <br>
  <input type="text" style="position: relative;width: 180px;height: 30px;top: 137px;border: black;" placeholder="הערה"
    [(ngModel)]="h3.Notice" [ngModelOptions]="{standalone: true}" ngDefaultControl>
  <br>
  <select #Coin1 (change)="savecoin(Coin1.value)"
    style="position: absolute; ;top: 193px;width: 180px;height: 30px;border: black;">
    <option *ngFor='let item of listcoin'>{{item.name}}</option>
  </select>
  <select
    style="background-color: white;border: solid 1px lightrey;width: 180px;color: black;height: 30px;position: absolute;top: 154px;"
    #v (change)="getbyway(v.value)">
    <option> אשראי</option>

    <option> מזומן</option>
    <option> שיק</option>
    <option> העברה</option>
    <!-- <option> אשראי</option> -->
  </select>
  <button
    style="width: 180px;position: absolute;;border-radius: 8px;height: 30px;top:276px;left: 0.3rem;border: black 1px solid; padding: 2px 2px;"
    (click)="save(d.value)"> שמור</button>
</div>
<!-- הצגת הפקדות לפי תאריך -->
<div
  style="position: absolute;background-color: rgb(253, 251, 251);left: 956px !important; background: #989696; top: 116px; border: gray 1px solid;border-radius: 5px;height: 96px;width: 153px;">
  <label style="width: 150px; text-align: center;font-weight: bold;">הצג סה"כ לתאריך</label>
  <br>
  <label style="position: absolute;left: 89px; top: 28px; font-weight: 500; font-size: 15px; cursor: pointer;"
    for="less">לפני</label><input (click)="less()" id="less"
    style="position: absolute;left: 121px; top: 34px; cursor: pointer" type="radio" name="a">
  <label style="position: absolute;left: 28px; top: 27px; font-weight: 500; font-size: 15px; cursor: pointer;"
    for="big">אחרי</label><input (click)="big()" id="big"
    style="position: absolute;left: 64px; top: 34px;  cursor: pointer;" type="radio" name="a">
  <input type="date" (change)="view()" (ngModelChange)="DateH= $event" [ngModel]="DateH| date:'yyyy-MM-dd'"
    [ngModelOptions]="{standalone: true}"
    style="position: absolute;top:63px;left: 0;text-align: center; width: 150px;color: #f44336;">
</div>
<!-- הצגת סכומי הפקדה/עמלה -->
<div>
  <input type="radio" style="position: absolute;cursor: pointer;left: -380px; top: 165px; " [checked]="D"
    (click)="ShowDolars()" name="e"><img style="position: absolute;left: -410px; top: 159px; width: 25px; height: 25px;"
    src="/assets/1/dollar.png">
  <input type="radio" style="cursor: pointer;position: absolute;left: -210px; top: 165px; " [checked]="!D"
    (click)="ShowShekel()" name="e"><img style="position: absolute;left: -240px; top: 155px; height: 30px; width: 30px;"
    src="/assets/1/s.png">
  <div style="position: absolute; left: -42.5rem; top: 10.9rem;">
    <input style="background-color:transparent; border:none;width: 90px;font-size: 110%;
          position: relative; left: 370px; top: 67px;color: #f44336;" [ngModel]="num1|number:'1.1-1'"
      (ngModelChange)="num1=$event" [ngModelOptions]="{standalone: true}" ngDefaultControl disabled>
    <label style=" position: relative;left: 372px; top: 65px; font-weight: bold; ">/</label>
    <label style="position: relative;left: 313px; top: 42px; font-size: 110%; font-weight: 600; ">:סכום הפקדות</label>
    <input [ngModel]="num|number:'1.1-1'" [ngModelOptions]="{standalone: true}" ngDefaultControl name="something"
      style="background-color: transparent ;border: none;width: 90px;font-size: 110%;position: relative;left: 263px;  top: 67px;text-align: left; color: #f44336;"
      disabled>
    <label style="position: relative;left: -66px; top: 41px; font-size: 110%;font-weight: 600; ">:הפקדות שחזרו</label>
    <input [ngModel]="num2|number:'1.1-1'" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl
      name="something2"
      style="background-color: transparent ;border: none;width:90px;font-size: 110%; position: relative; left: -174px; top: 64px; text-align: center; color: #f44336; ">
    <label style="position: absolute;left: 237px; top: 96px; font-size: 110%;font-weight: 600; ">:זמין למשיכה</label>
    <input [ngModel]="num8|number:'1.1-1'" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl
      name="something2"
      style="background-color: transparent ;border: none;width:90px;font-size: 110%; position: absolute; left: 241px; top: 122px; text-align: center; color: #f44336; ">
    <label
      style="top: 96px; left: 437px; position: absolute; font-size: 110%; font-weight: 600; font-size: 110%;font-weight: 600; ">:עמלות</label>
    <!-- <input [(ngModel)]="amlatotal" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl name="something3" style="background-color: transparent ;border: none;width:90px;font-size: 110%; position: relative; left: -174px; top: 109px; text-align: center; color: #f44336; "> -->
    <input
      style="background-color:transparent; border:none;width: 90px;font-size: 110%; position: absolute;left: 369px; top: 121px; color: #f44336;"
      [ngModel]="amla1|number:'1.1-1'" [ngModelOptions]="{standalone: true}" ngDefaultControl disabled>
    <label style=" position: absolute; left: 460px; top: 122px; font-weight: bold; ">/</label>
    <input [ngModel]="amlatotal|number:'1.1-1'" [ngModelOptions]="{standalone: true}" ngDefaultControl name="something"
      style="background-color: transparent ;border: none;width: 90px;font-size: 110%;position: absolute; left: 470px; top: 121px; text-align: left; color: #f44336;"
      disabled>
  </div>
</div>
<!-- מחיקת הפקדות -->
<button style="position: absolute;left: 41px; width: 60px;   top: 154px;  height: 50px;"
  onclick="document.getElementById('id09').style.display='block'" class="btn"><i class="fa fa-trash"></i></button>
<div id="id09" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 9px; top: -30px;"
        onclick="document.getElementById('id09').style.display='none'" class="" title="סגור">&times;</span>
    </div>
    <div class="container">
      <label style="position: absolute; top: 44px; left: 261px;">את כל ההפקדות
        <input type="radio" (click)="all()" style="position: relative; top:1px;left: 2px;" name="o">
      </label>
      <label style="position: absolute;top: 83px; left: 248px;">מחק את כל דחויים
        <input type="radio" (click)="allf()" style="position: relative; top:1px;left: 2px;" name="o">
      </label> <label style="position: absolute;top: 44px;  left: 89px;">רק של מפקיד זה
        <input type="radio" (click)="justone()" style="position: relative; top:1x;left: 2px;" name="o">
      </label> <label style="position: absolute;top: 83px; left: 6px;">מחק דחויים רק של מפקיד זה
        <input type="radio" (click)="justonef()" style="position: relative; top:1px;left: 2px;" name="o">
      </label>
      <button onclick="document.getElementById('id09').style.display='none'"
        style="position: absolute; top: 118px; left: 77px;  height: 30px; font-size: 100%;" (click)="deletehaf()"
        class="btn">מחק</button>
    </div>
  </form>
</div>
<svg onclick="document.getElementById('id032').style.display='block'" style="position: absolute;
width: 45px;
height: 45px;
left: 8.8%;
top: 17.5vh;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-tools" fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd"
    d="M0 1l1-1 3.081 2.2a1 1 0 0 1 .419.815v.07a1 1 0 0 0 .293.708L10.5 9.5l.914-.305a1 1 0 0 1 1.023.242l3.356 3.356a1 1 0 0 1 0 1.414l-1.586 1.586a1 1 0 0 1-1.414 0l-3.356-3.356a1 1 0 0 1-.242-1.023L9.5 10.5 3.793 4.793a1 1 0 0 0-.707-.293h-.071a1 1 0 0 1-.814-.419L0 1zm11.354 9.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708-.708l-3-3z" />
  <path fill-rule="evenodd"
    d="M15.898 2.223a3.003 3.003 0 0 1-3.679 3.674L5.878 12.15a3 3 0 1 1-2.027-2.027l6.252-6.341A3 3 0 0 1 13.778.1l-2.142 2.142L12 4l1.757.364 2.141-2.141zm-13.37 9.019L3.001 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z" />
</svg>
<!-- שיעבוד -->
<!-- <div id="id024" class="modal2" style="display: none;">
  <form class="modal-content2" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 11px; top: -37px;font-size: 2.1rem; cursor: pointer;"
        onclick="document.getElementById('id024').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <div class="container">
      <div style="position: absolute;left: 8rem;direction: rtl;">
        <button (click)="showolderloans()">הצג הלוואות משועבדות</button>
        <button (click)="new()">+</button>
        <p  [hidden]="ShowOlder"
          style="position: relative; left: -58px; top: -120px; direction: rtl ;text-align: right; height: 32px;width:480px; font-size: 18px;">
          <b  [hidden]="ShowOlder">בחר שם לווה:</b></p>
        <ng-select [hidden]="ShowOlder" style="position: relative; left: 10px;top:-45px;width: 50%;height: 40px;border-radius: 4px;"
          (change)='ShowLoans($event)' [items]="items" bindValue="MAINID" aria-placeholder="vfbx ao" #val
          bindLabel="Name"></ng-select>
        <input [hidden]="ShowOlder" type="number" max="{{num1}}" placeholder="סכום שיעבוד" #SCUM8>
        <p [hidden]="ShowOlder"
          style="position: relative; left: -58px; top: 10px; direction: rtl ;text-align: right; height: 32px;width: 100%; font-size: 18px;">
          <b [hidden]="ShowOlder">בחר הלוואה:</b></p>
        <table  [hidden]="ShowOlder"style="position: relative; left: -58px; top:5px; overflow: visible;">

          <tr>
            <td>
              <thead> לשעבד?</thead>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> יתרה</thead>
            </td>
            <td>
              <thead>תאריך </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listloans' (click)='viewdetails(item.ID)'>

            <td>
              <input type="radio" (change)="AddToList(item.ID)">
            </td>
            <td>
              {{item.LoanAmount}}
            </td>
            <td>

              {{item.Balance}}
            </td>
            <td>
              {{item.loanDateforien|date:"dd/MM/yy"}}
            </td>

          </tr>
        </table>
        <table [hidden]="!ShowOlder" style="position: relative; left: -58px; top:5px; overflow: visible;">

          <tr>
            <td>
              <thead>שם לווה</thead>
            </td>
            <td>
              <thead>סכום  ראשוני</thead>
            </td>
            <td>
              <thead> לאחר החזרים</thead>
            </td>
            <td>
              <thead>סכום הלוואה </thead>
            </td>
        
          </tr>

          <tr *ngFor='let item of loanshiabud' (click)='viewdetails(item.ID)'>

        
            <td>
              {{item.Name}}
            </td>
            <td>

              {{item.Scum}}
            </td>
            <td>

              {{item.ScumAfter}}
            </td>
            <td>
              {{item.LoanAmount}}
            </td>

          </tr>
        </table>
        <button style="width: 80%;" (click)="shiabud(SCUM8.value)">שמור</button>
      </div>
    </div>
  </form>
</div> -->
<!-- שיעבוד -->
<div id="id024" class="modal2" style="display: none;">
  <form class="modal-content2" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  position: absolute; left: -7px; top: -28px; font-size: 2.2rem; cursor: pointer;"
        onclick="document.getElementById('id024').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <div class="container">
      <div style="position: absolute;left: 8rem;direction: rtl;">
        <button style="width: 100%;" [hidden]="ShowOlder" (click)="showolderloans()">הצג הלוואות משועבדות</button>
        <button style="position: absolute;left: -23%; top: 2%; width: 18%; color: black;" (click)="new()">+</button>
        <p [hidden]="ShowOlder"
          style="position: absolute; left: 359px; top: -20px; direction: rtl; text-align: right; height: 32px; width: 180px; font-size: 19px; background-color: transparent;">
          <b [hidden]="ShowOlder">בחר שם לווה:</b>
        </p>
        <ng-select [hidden]="ShowOlder" class="custom"
          style="position: absolute; left: 359px; top: 11px;  width: 109%; border-radius: 4px;text-align: right;direction: rtl;"
          (change)='ShowLoans($event)' [items]="items" bindValue="MAINID" aria-placeholder="הכנס שם" #val
          bindLabel="Name"></ng-select>
        <input class="form-control" [hidden]="ShowOlder"
          style="position: absolute; left: 195%;  top: 90%; height: 35px;" type="number" max="{{num1}}"
          placeholder="סכום שיעבוד" #SCUM8>
        <p [hidden]="ShowOlder"
          style="position: absolute; background-color: transparent; font-size: 20px; margin: 25% -69% 14%;">
          <b [hidden]="ShowOlder">בחר הלוואה:</b>
        </p>
        <table [hidden]="ShowOlder" class="table table-hover shiabud "
          style=" position: absolute;width: 170%;  margin: -6% -116%; overflow: scroll; border:solid 0px gray; ">
          <tr class="row" style="color: white;background-color:#989898;	">
            <td class="col-3">
              <thead style="text-align: center !important;"> לשעבד?</thead>
            </td>
            <td class="col-3">
              <thead> סכום</thead>
            </td>
            <td class="col-3">
              <thead> יתרה</thead>
            </td>
            <td class="col-3">
              <thead> תאריך </thead>
            </td>
          </tr>

          <tr class="row" style="font-size: 110%;" *ngFor='let item of listloans' (click)='viewdetails(item.ID)'>

            <td class="col-3">
              <input type="radio" (change)="AddToList(item.ID)">
            </td>
            <td class="col-3">
              {{item.LoanAmount}}
            </td>
            <td class="col-3">

              {{item.Balance}}
            </td>
            <td class="col-3">
              {{item.loanDateforien|date:"dd/MM/yy"}}
            </td>

          </tr>
        </table>
        <table [hidden]="!ShowOlder" style="position: relative; left: 67px; top: 72px; overflow: visible;">

          <tr style="font-weight: bold;">
            <td>
              <thead>שם לווה</thead>
            </td>
            <td>
              <thead>סכום ראשוני</thead>
            </td>
            <td>
              <thead> לאחר החזרים</thead>
            </td>
            <td>
              <thead>סכום הלוואה </thead>
            </td>

          </tr>

          <tr *ngFor='let item of loanshiabud' (click)='viewdetails(item.ID)'>


            <td>
              {{item.Name}}
            </td>
            <td>

              {{item.Scum}}
            </td>
            <td>

              {{item.ScumAfter}}
            </td>
            <td>
              {{item.LoanAmount}}
            </td>

          </tr>
        </table>
        <button style="width: 187%; position: absolute; margin-top: 227% !important;margin-right: -224% !important;"
          (click)="shiabud(SCUM8.value)">שמור</button>
      </div>
    </div>
  </form>
</div>
<!-- העברה פנימית -->
<!-- <div id="id026" class="modal2" style="display: none;">
  <form class="modal-content2" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 11px; top: -37px;font-size: 2.1rem; cursor: pointer;"
        onclick="document.getElementById('id026').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <div class="container">
      <div style="position: absolute;left: 8rem;direction: rtl;">
        <p
          style="position: relative; left: 58px; top: 20px; direction: rtl ;text-align: right; height: 32px;width:480px; font-size: 18px;">
          <b>עבור מי</b></p>
        <ng-select style="position: relative; left: 10px;top:-45px;width: 50%;height: 40px;border-radius: 4px;"
          (change)='ShowLoansORMafkids($event)' [items]="items" bindValue="MAINID" aria-placeholder="vfbx ao" #val
          bindLabel="Name"></ng-select>
        <input type="radio" (click)="isloan()" name="a"> הלוואה
        <input type="radio" (click)="isloan1()" name="a">הפקדה
        <input type="number" placeholder="סכום להעביר" #SCUM5>
        <select type="text" class="form-control right" #Coin5>
          <option *ngFor='let item of listcoin'>{{item.name}}</option>
        </select> -->
<!-- loans -->
<!-- <p [hidden]="!loans1"
        style="position: relative; left: -58px; top: 10px; direction: rtl ;text-align: right; height: 32px;width: 100%; font-size: 18px;">
          <b>בחר הלוואה:</b></p>
        <table [hidden]="!loans1" style="position: relative; left: -58px; top:5px; overflow: visible;">

          <tr>
            <td></td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> יתרה</thead>
            </td>
            <td>
              <thead>תאריך </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listloans'>
            <td>
              <input type="radio" (click)='CooseLoan(item.ID)' name="b">
            </td>
            <td>
              {{item.LoanAmount}}
            </td>
            <td>
              {{item.Balance}}
            </td>
            <td>
              {{item.loanDateforien|date:"dd/MM/yy"}}
            </td>
          </tr>
        </table>
        hafkadot 
        <p [hidden]="loans1"
          style="position: relative; left: -58px; top: 10px; direction: rtl ;text-align: right; height: 32px;width: 100%; font-size: 18px;">
          <b>בחר חשבון הפקדה:</b></p>
        <table [hidden]="loans1" style="position: relative; left: -58px; top:5px; overflow: visible;">
          <tr>
            <td>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> תרומה</thead>
            </td>
            <td>
              <thead>הערה </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listAccounts'>
            <td>
              <input type="radio" (click)='CooseMafkis(item.Id)' name="b">
            </td>
            <td class="shrink">
              {{item.Totalhafkada}}
            </td>
            <td class="shrink">
              {{item.Totaltruma}}
            </td>
            <td class="expand" style="width: 100%;">
              {{item.Remark  | slice:0:40}}

            </td>
          </tr>
        </table>
        <button style="width: 80%;" (click)="SaveTransfer(SCUM5.value,Coin5.value)">שמור</button>
      </div>
    </div>
  </form>
</div> -->
<!-- העברה פנימית -->
<div id="id026" class="modal2" style="display: none;">
  <form class="modal-content2" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;position: absolute; left: -7px;   top: -28px; font-size: 2.2rem; cursor: pointer;"
        onclick="document.getElementById('id026').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <div class="container">
      <div style="position: absolute;position: absolute;  left: 12%; top: 11%;">
        <div class="row" style="margin-top: 27%;">
          <p style="position: absolute; left: 290%;  top: 9%; background: transparent; width: 40%;"> <b>עבור מי</b></p>
          <ng-select style="position: absolute; left: 247%; top: 36%; width: 122%; border-radius: 4px;direction: rtl;"
            (change)='ShowLoansORMafkids($event)' [items]="items" bindValue="MAINID" aria-placeholder="vfbx ao" #val
            bindLabel="Name"></ng-select>
          <div style="position: absolute; right: -269%; top: -48%;  font-size: larger; font-weight: 600;">
            <label style="margin-left: -8%; margin-top: -81%;">
              <input id="loan" type="radio" (click)="isloan()" name="a">
              <label for="loan" style="margin-right: 5px;">הלוואה</label>
            </label>
            <label style="position: absolute;  left: -142%;">
              <input id="hafkad" type="radio" (click)="isloan1()" name="a">
              <label for="hafkad" style="margin-right: 5px;">הפקדה</label>
            </label>
          </div>
          <b style="position: absolute;left: 157%; top: 8%;">סכום</b>
          <input type="number" style="position: absolute; left: 100%;  top: 26%;" class="form-control"
            placeholder="סכום להעביר" #SCUM5>
          <b style="position: absolute;left: 37%; top: 8%;">מטבע</b>
          <select type="text" style="position: absolute; left: 18%; top: 26%; width: min-content !important;"
            class="form-control right" #Coin5>
            <option *ngFor='let item of listcoin'>{{item.name}}</option>
          </select>
        </div>
        <!-- loans -->
        <p [hidden]="!loans1"
          style="position: absolute; left: 206%; top: 130%;  direction: rtl;   width: 100%; font-size: 18px; background-color: transparent; color: red;">
          <b>בחר הלוואה:</b>
        </p>
        <table [hidden]="!loans1" class="table table-hover shiabud "
          style="overflow: visible; margin: auto 50%; position: relative;   top: 107px;overflow: scroll; border:solid 0px gray;">

          <tr style="color: white;background-color:#989898; 	">
            <td></td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> יתרה</thead>
            </td>
            <td>
              <thead>תאריך </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listloans'>
            <td>
              <input type="radio" (click)='CooseLoan(item.ID)' name="b">
            </td>
            <td>
              {{item.LoanAmount}}
            </td>
            <td>
              {{item.Balance}}
            </td>
            <td>
              {{item.loanDateforien|date:"dd/MM/yy"}}
            </td>
          </tr>
        </table>
        <!-- hafkadot -->
        <p [hidden]="loans1"
          style="position: relative; left: 326px;  background-color: transparent; color: red;   top: 65px; text-align: right;width: 100%; font-size: 18px;">
          <b>בחר חשבון הפקדה:</b>
        </p>
        <table [hidden]="loans1" class="table table-hover shiabud "
          style="position: absolute;  left: 48%; top: 182%; overflow: visible;  width: 230%">
          <tr style="color: white;background-color:#989898	">
            <td>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> תרומה</thead>
            </td>
            <td>
              <thead>הערה </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listAccounts'>
            <td>
              <input type="radio" (click)='CooseMafkis(item.Id)' name="b">
            </td>
            <td class="shrink">
              {{item.Totalhafkada}}
            </td>
            <td class="shrink">
              {{item.Totaltruma}}
            </td>
            <td class="expand" style="width: 100%;">
              {{item.Remark | slice:0:40}}

            </td>
          </tr>
        </table>
        <button style="width: 215%;  position: absolute;left: 60%;  top: 528%;"
          (click)="SaveTransfer(SCUM5.value,Coin5.value)">שמור</button>
      </div>
    </div>
  </form>
</div>
<br>
<div id="id032" class="modalIcons">
  <form class="modal-contentIcons" action="/action_page.php">
    <div class="container">
      <span style="position: absolute;  left: 11%; top:1vh;font-size: 2.1rem; cursor: pointer;"
        onclick="document.getElementById('id032').style.display='none'" class="close" title="סגור">&times;</span>
      <app-addtask [mainid]="id" [idcounthaf]="idmafkid " [idcountloan]="id5" class="col-2"
        style="position: absolute; left: 640px; width: 10px; top:0vh; height: 50px;"></app-addtask>
      <img src="/assets/1/excel.png" (click)="excel()" style="position: absolute;
      width: 45px;
      height: 45px;
      left: 25.8%;
      top: 8vh;">
      <img src="/assets/1/l.png" (click)="GetShiabud()" title="שיעבוד הפקדה"
        onclick="document.getElementById('id032').style.display='none'"
        onclick="document.getElementById('id024').style.display='block'" style="position: absolute;
      width: 45px;
      height: 45px;
      left: 70.8%;
      top: 8vh;">
      <img src="/assets/1/T.png" title="העברה פנימית" onclick="document.getElementById('id032').style.display='none'"
        onclick="document.getElementById('id026').style.display='block'" style="position: absolute;
        width: 45px;
        height: 45px;
        left: 70.8%;
        top: 2vh;">
      <img src="/assets/1/reset.jpg" title="איפוס" (click)="resettotalcount()" style="position: absolute;
           width: 45px;
           height: 45px;
           left: 48.8%;
           top: 2vh;">
    </div>
  </form>
</div>
<!-- //אופציות ללחיצה ימנית -->
<div *ngIf='bool==false'  [hidden]="!degelrightclick"
>
  <div class="row" 
    style="width:30%; position: relative; top:280px;left: 320px;text-align: center;">
    <div class="column" style="background-color:black;width:100%;height: 35px;">
      <p style="color: white ; width: 100%; height: 100%;">{{date|date:"dd/MM/yy"}} </p>
    </div>
    <div class="column" style="background-color:#aaa;width:100%;height:128px;">
      <input type="date" #D (change)='savedate(D.value)' [hidden]='!date1'
        style="position: relative; left: -10px;top: 0px; width:30%;background-color: white;color: gray;height: 30px;  border-radius: 25px; ">

      <select
        style="position: relative; left: -3px;top: 0px; width:60%;background-color: white;color: gray;height: 30px;  border-radius: 25px; "
        #value (change)="changeval(value.value)">
        <div class="custom-select"></div>
        <option *ngFor='let item1 of listop'>{{item1}}</option>
      </select>
      <p style="width:12%; position: absolute; top: 44px;  left: 321px;color: white  ;  height: 30px;
        border-radius: 25px; ">בחר</p>
      <!-- <p
        style="width:12%; position: absolute; top: 125px; left: 324px; color: white  ;  height: 30px;  border-radius: 25px; ">
        סיבה</p> -->
      <select #value1 (change)='saver(value1.value)'
        style=" width: 58%;position: absolute; top:78px; left: 73px; height: 34px;  border-radius: 25px;">
        <option *ngFor='let item1 of listreason'>{{item1.name}}</option>
      </select>
      <br>
      <p
        style=" position: absolute; top: 198px;border-radius: 25px;color: white  ;  height: 30px;width: 15%; left: 85%;top:87px">
        עמלה</p>
      <input type="checkbox" checked="checked"
        style=" position: absolute; left: 300px; top: 95px; height: 17px;width: 17px;" (click)=amla()>
      <button style="width:60%;position: relative; top:38px;left:-5px;" class="button button1 "
        (click)="hokreturn()">סמן כחזר</button>
    </div>
    <div class="column" style="background-color:#bbb;width:100%;">
      <input #num value="1" type="number" style="width:15%; position: relative; left: -10px;">מס חודשי השהייה
      <br>
      <button class="button button5" (click)='StopHafkadot(num.value)' style="width:60%;"><img src="/assets/1/stop.png">
      </button>
    </div>
    <div class="column" style="background-color:#aaa;width:100%;height: 95px;">

      <select style=" width: 60%;position: relative; top:-5px;
    height: 35px;
    border-radius: 25px;" #val5>
        <option> מזומן</option>
        <option> שיק</option>
        <option> העברה</option>
        <option> אשראי</option>
        <option> הוק</option>

        <!-- <option> אשראי</option> -->
      </select>
      <p style="width:20%; position: relative; top:-40px;left:297px;color: white  ;  height: 30px;
border-radius: 25px; ">:התקבל ב</p>
      <button (click)="pay(val5.value)" style="width:60%;position: relative; top:-50px;left: 0px;">שולם מראש </button>
    </div>
    <div class="column" style=" background-color:#bbb;width:100%;display: flex;margin: auto;height: 38px; ">
        <i style=" border-radius: 16px;width:25%;margin: auto;position: absolute; left: 318px; font-size: 142%;" onclick="document.getElementById('id03').style.display='block'" class="fa fa-trash"></i>
       
     <img style=" border-radius: 16px;position: absolute; left: 323px; width: 25px; height: 25px; "(click)="PrintReport()"
 src="/assets/1/send.png" >
    </div>
  </div>
  <!-- מודל מחיקה -->
  <div id="id03" class="modal" style="position: absolute; left: 283px; top: 114px;">

    <form class="modal-content animate" action="/action_page.php">
      <div class="imgcontainer">
        <span style="position: relative;left: -402px; top: -25px;"
          onclick="document.getElementById('id03').style.display='none'" class="close" title="סגור">&times;</span>

      </div>

      <div class="container">
        <p
          style="position: relative; top: -50px;text-align: center;left: 3px; font-size: 23px;background-color: white;color: black;">
          ?האם אתה בטוח שברצונך למחוק</p>
        <button onclick="document.getElementById('id03').style.display='none'"
          style="position: relative;  top: -34px;left: 115px; font-size: 17px; width: 173px;" (click)="delete()"
          class="btn">מחק</button>

      </div>

    </form>
  </div>

</div>
<!-- מודל עדכון הפקדה -->
<div id="id05" class="modal1" style="display: none">
  <span onclick="document.getElementById('id05').style.display='none'" class="close" title="סגור">&times;</span>
  <form class="modal-content1" action="/action_page.php">
    <div class="container" style="text-align: center">
      <div class="row">

        <div class="titel">
          <h6>Update hafkda</h6>
          <br>
          <br>
          <br>
          <br>

          <!-- <b class="right" style="position: absolute; ; top: 152px;right: -10px;">סוג הו"ק</b>
          <input type="text" class="form-control right" value="{{h.TypeHok}}" disabled
            style="position: absolute;  top: 169px" #TypeHok> -->

          <b class="right" style="position: absolute; ; top: 152px;right: -10px;">שם מפקיד</b>
          <ng-select class="ng-select1"
          style="position: absolute;  top: 169px"
            [items]="items" bindValue="MAINID" placeholder="הפקדות של" [(ngModel)]="h.MAINID" [disabled]="h.idhokhelp!=null"
            [ngModelOptions]="{standalone: true}" ngDefaultControl bindLabel="Name">
          </ng-select>
          <br>
          <br>
          <!-- left:360px;   -->
          <b style="position: absolute;  top: 157px; left: 232px;">אסמכתא</b>
          <input type="number" class="form-control left" [(ngModel)]="h.ASMACTA" [ngModelOptions]="{standalone: true}"
            ngDefaultControl style="position: absolute; top: 172px" #ASMACTA>
          <!-- left:65px;  -->
          <br>
          <br>
          <!-- left:755px; -->
          <b class="right" style="position: absolute;  top: 285px;right: 13px;">תאריך לועזי</b>
          <input type="date" class="form-control right" (ngModelChange)="h.DateForiegnHAfkada= $event"
            [ngModel]="h.DateForiegnHAfkada| date:'yyyy-MM-dd'" [ngModelOptions]="{standalone: true}" ngDefaultControl
            style="position: absolute; top: 309px" #DateForiegnHAfkada>
          <!-- left:460px;  -->
          <br>
          <br>
          <!-- left:347px; -->
          <b class="left" style="position: absolute;  top: 285px; left: 197px;">תאריך הפקדה</b>
          <input type=" text" class="form-control left" value="{{h.DateHafkada}}" style="position: absolute; top: 309px"
            ngDefaultControl disabled #DateHafkada>
          <!-- left:45px; -->
          <br>
          <br>
          <b style="position: absolute; left: 529px; top: 415px;">סכום ההפקדה</b>
          <input type=" number" class="form-control right" (change)="changescum()" [(ngModel)]="h.Amount"
            [ngModelOptions]="{standalone: true}" ngDefaultControl style="position: absolute; top: 439px; " #Amount>
          <br>
          <br>
          <b style="position: absolute; left:257px; top: 416px">עמלה</b>
          <input type="number" class="form-control left" (change)="changescum()" [(ngModel)]="h.Amla"
            [ngModelOptions]="{standalone: true}" ngDefaultControl style="position: absolute; top: 432px;" #Amla>
          <br>
          <br>
          <b style="position: absolute; left: 560px; top: 555px;">סוג מטבע</b>
          <ng-select
          style="position: absolute;  top: 581px;
          left: 425px;   direction: rtl;width: 30%;"
          [items]="listcoin" bindValue="Id"  [(ngModel)]="h.Coin"
          [ngModelOptions]="{standalone: true}" ngDefaultControl #val bindLabel="name">
        </ng-select>
          <!-- <select type="text"  style="position: absolute;  top: 581px;
          left: 425px;   direction: rtl;width: 30%;" class="form-control right" [(ngModel)]="h.CoinName" [ngModelOptions]="{standalone: true}"
            ngDefaultControl   #Coin>
            <option *ngFor='let item of listcoin'>{{item.name}}</option>
          </select> -->
          <br>
          <br>
          <b style="position: absolute; left: 219px; top: 556px;">סכום לגביה</b>
          <input type=" number" class="form-control left" [(ngModel)]="h.SUMTOGVIA"
            [ngModelOptions]="{standalone: true}" ngDefaultControl disabled style="position: absolute; top: 578px"
            #SUMTOGVIA>
          <br>
          <br>
          <b style="position: absolute; left: 590px; top: 683px;">מטרה </b>
          <input type=" text" class="form-control right" [(ngModel)]="h.TypeOperation"
            [ngModelOptions]="{standalone: true}" ngDefaultControl style="position: absolute; top: 708px"
            #TypeOperation>
          <br>
          <br>
          <b style="position: absolute; left:191px; top: 683px"> סטטוס הפקדה</b>
          <input type=" text" class="form-control left" value="{{h.StatusHafkada}}"
            style="position: absolute;  top: 708px" #StatusHafkada disabled>
          <br>
          <br>
          <b style="position: absolute; left:589px; top: 816px">הערה</b>
          <input type=" text" class="form-control right" style="position: absolute;  top: 838px" [(ngModel)]="h.Notice"
            [ngModelOptions]="{standalone: true}" ngDefaultControl #Notice>
          <br>
          <br>
          <b style="position: absolute; left:200px; top: 816px"> אמצעי תשלום</b>
          <input type="text" class="form-control left" value="{{h.getbyway}}" style="position: absolute; top: 830px"
            #MASAV disabled>
          <br>
          <br>
          <b style="position: absolute; left: 540px; top: 954px;">תאריך יצירה</b>
          <input type=" date" class="form-control right" value="{{h.CreateDate|date:'dd/MM/yy'}}" disabled
            style="position: absolute; top: 976px" #CreateDate>

          <br>
          <br>
          <b style="position: absolute; left:241px; top: 954px">נוצר ע"י</b>
          <input type=" text" class="form-control left" value="{{h.Createby}}" disabled
            style="position: absolute; top: 976px;" #Createby>
          <br>
          <br>
          <b style="position: absolute; left:540px; top: 1094px">תאריך עידכון</b>
          <input type=" date" class="form-control right" value="{{h.UpdateDate|date:'dd/MM/yy'}}" disabled
            style="position: absolute; top: 1117px" #UpdateDate>

          <br>
          <br>
          <b style="position: absolute; left:234px; top: 1094px">עודכן ע"י</b>
          <input type=" text" class="form-control left" value="{{h.Updateby}}" disabled
            style="position: absolute; top: 1117px" #Updateby>


          <div class="clearfix">
            <button type="button" onclick="document.getElementById('id05').style.display='none'"
              class="cancelbtn">Cancel</button>
            <button onclick="document.getElementById('id05').style.display='none'" type="submit" class="ok"
              (click)='UpdateHaf()'>אישור</button>

          </div>
        </div>
      </div>
    </div>