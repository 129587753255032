import { Component, OnInit,Input } from '@angular/core';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'app-masav',
  templateUrl: './masav.component.html',
  styleUrls: ['./masav.component.css']
})
export class MasavComponent implements OnInit {
  constructor(private hok:HokMenageService,private route:ActivatedRoute, private router: Router,) { }
  //#region 
@Input() PERMISSION;
@Input() iduser;
list;
masav;
//#endregion
  ngOnInit() {
    this.Getlisthok();
    this.Getlistmasav();
  }
  updatemasav()
  {
    
  }
  Getlisthok()
  {
    this.hok.Getlisthok().then(res=>{
      this.list=res;
    })
  }
  Getlistmasav()
  {
    this.hok.Getlistmasav().then(res=>{
      ;
      this.masav=res;
    })
  }
} 
