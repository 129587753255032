export class Task {
    Id: number;
    Datetezcoret: Date;
    Instructions: string;
    Category: number;
    statustask: boolean;
    MAINID: number;
    userkod:number;
    remark:string;
     remember:boolean;
    each :number;
     IDtotbl :number;
    date:Date;
  scum: number;
  coin:number;
    constructor()
    {
        this.Id=null;
        this.Datetezcoret=null;
        this.Instructions=null;
        this.Category=null;
        this.statustask=false;
        this.remember=false;

        this.scum=null;
        this.MAINID=null;
        this.userkod=null;
      this.date=null;
      this.coin=1;
      this.remark=null;
    }

}
