export class Hafkadot {
    ID: number;
    MAINID: number;
    IdHok: number;
    MafkidID: number;
    DateForiegnHAfkada: Date;
    DateHafkada: string;
    Amount: number;
    TypeOperation: string;
    Notice: string;
    SUMTOGVIA: number;
    Amla: number;
    ASMACTA: number;
    Coin: number;
    Updateby: string;
    UpdateDate: Date;
    CreateDate: Date;
    Createby: string;
    MASAV: number;
    StatusHafkada: string;
    TypeHok: string;
    idhokhelp:number;
    idkupa:number;
    futture :boolean;
    getbyway :string;
    OldId:number
     sendreporton:number;
     reason :number;
    CoinName: any;
    constructor(){
      this.ID=0;
      this.MAINID=null;
      this.IdHok=null; 
      this.MafkidID=null;
      this.DateForiegnHAfkada=null;
      this.DateHafkada=null;
      this.Amount=null;
      this.TypeOperation=null;
      this.Notice=null; 
      this.SUMTOGVIA=null; 
      this.Amla=null;
      this.idhokhelp=null;
      this.ASMACTA=null; 
      this.Coin=1;
      this.Updateby=null;
      this.UpdateDate=null; 
      this.CreateDate=null;
      this.Createby=null;
      this.MASAV=null;
      this.StatusHafkada=null;
      this.TypeHok=null;
    }
}

