export class WaitingLIst {
    id: number;
    DateDesire: Date;
    Amont: number;
    NumOfPayment: number;
    StatusBakasha: boolean;
    MAINID: number;
    MAINID1: number;
    MAINID2: number;
    MAINID3: number;
    Coin: string;
    OKAmount: number;
    Remarks: string;
    OkToDate: Date;
    Adress: string;
    FirstName: string;
    CellPhone: string;
    LastName: string;
    GetLoan: boolean;
     email :string;
     derug :number;
     UpdateBy :string;
     UpdateDate :Date;
     CreateBy :string;
     CreateDate :Date;
     GetLoanmaybe:boolean;
    constructor(){
        this.id=null;
        this.DateDesire=null;
        this.Amont=null;
        this.NumOfPayment=null;
        this.StatusBakasha=null;
        this.MAINID=null;
        this.Coin=null;
        this.OKAmount=null;
        this.Remarks=null;
        this.OkToDate=null;
        this.Adress=null;
        this.FirstName=null;
        this.CellPhone=null;
        this.LastName=null;
        this.GetLoan=null;
        this.email=null;
        this.derug=null;
this.GetLoanmaybe=null;
    }

}
