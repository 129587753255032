
  <html>
    <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    <body>
      <button (click)="fun()" onclick="document.getElementById('id08').style.display='block'" style="width: 20px;">+</button>
      <!-- הוספת קופב -->
<div id="id08" class="modal" style="display: none">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: relative; left:200px;" onclick="document.getElementById('id08').style.display='none'" class="close" title="Close Modal">&times;</span> 
    </div>
    <div id="mySidenav"style="position: relative; top:0px;"  class="sidenav">
</div>
<h1 style="text-align: center;position: relative;top: -20px">הכנס פעולה</h1>
    <div class="container" style="position: relative; left:400px;">
</div>
<!-- <p  style="position: relative;top: -36px;left: 496px;">קוד </p>
<input type="number" id="a" style="position: relative;top: -45px;" class="form-control"  placeholder="קוד"  #Id>(click)='saveall(Id.value,Category.value,Details.value,DateOperation.value,Amount.value,AmountEnter.value,AmountFuture.value)' -->
<p style="position: relative;top: -20px;left:464px"  >קטגוריה</p>
<input type="text"  id="aaa" class="form-control" style="position: relative;top: -30px;"  placeholder="קטגוריה"  #Category>
<p style="position: relative;top: -95px;left:203px" >פרטים</p>
<input type="text" id="bbb" class="form-control"  style="position: relative;top: -106px" placeholder="פרטים"  #Details>
<p style="position: relative;top: -81px;left: 433px" >תאריך פעולה</p>
<input class="form-control" id="aa"  style="position: relative;top: -90px;" type="date"   placeholder="תאריך"  #DateOperation>
<p style="position: relative;top: -160px;left:217px" >סכום</p>
  <input type="number" id="bb"  class="form-control" style="position: relative;top: -169px;" placeholder="סכום"  #Amount>
<p style="position: relative;top: -140px;left: 417px">סכום שהתקבל</p>
<input type="number" style="position: relative;top: -150px;"  class="form-control" id="a"   placeholder="סכום שהתקבל"  #AmountEnter>
<p style="position: relative;top: -216px;left:158px">סכום שיתקבל</p>
<input type="number" class="form-control" id="b" style="position: relative;top: -227px;"  placeholder="סכום שעתיד להכנס"  #AmountFuture>
<button  type="button" onclick="document.getElementById('id08').style.display='none'"  class="button button1" > אישור </button>
<button type="button" onclick="document.getElementById('id08').style.display='none'" class="cancelbtn">ביטול</button>
</form>
</div>
</body>
</html>

  
  