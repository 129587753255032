<table >
    <tr style="font-weight: bold;text-align: center;background-color: white !important;border: none; border-block-end-color: white;" > 
       
ערבויות      
       </tr> 
  <tr>
     <td>  <thead>ערבות שקל</thead></td>
     <td>  <thead>ערבות דולר</thead></td>
     <td>  <thead>ערבות יורו</thead></td>

     </tr>
     
   <tr >
     
 <td>
   {{shekel}}
 </td>
 <td>
  {{dolar}}

 </td>
<td>
    {{yoro}}
</td>
   </tr>
</table>
 