<input type="date"  #date style="    position: relative;left: 750px;top: 5px;"  (ngModelChange)="myDate= $event" [ngModel]="myDate|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"   (change) =" fun()">
<button (click)='send(date.value)' style="position: relative;left: 750px;top: 5px;background-color: #f44336;">סמן כנגבה</button>
<h1 style="position: absolute; left: 880px;top: 50px;width: 15px;font-size: large;">סה"כ</h1>
<h1 style="position: absolute; left: 780px;top: 50px;width: 15px;font-size: large;">{{totalheczer+totalhaf| number}}</h1>
<h1 style="position: relative; left: 1100px;top: 70px;width: 15px;">:הפקדות</h1>
<!-- <h1 style="position: relative; left: 990px;top: 20px;width: 15px;">סה"כ</h1> -->
<h1 style="position: relative; left: 970px;top: 18px;width: 15px;">{{totalhaf| number}}</h1>\
        <ag-grid-angular 
        style="width:770px; height: 580px;position: absolute; left:500px;top:160px;direction: rtl;" 
        class="ag-theme-balham"
        [rowData]="rowData" 
        [columnDefs]="columnDefs2"
        [enableRtl]="true"
        [rowSelection]="rowSelection"
        (gridReady)="onGridReady($event)"
        [floatingFilter]="true" 
        (rowDoubleClicked)='openmafkid($event)'>
    </ag-grid-angular>
    <h1 style="position: absolute;left: 310px;top: 100px;width: 15px;">:החזרים</h1>
    <!-- <h1 style="position: absolute; left: -120px;top: 70px;width: 15px;">סהכ</h1> -->
<h1 style="position: absolute; left: 250px;top: 100px;width: 15px;">{{totalheczer| number}}</h1>
    <ag-grid-angular 
    style="width:770px; height: 580px;position: absolute; left:-300px;top:160px;direction: rtl;" 
    class="ag-theme-balham"
    (gridReady)="onGridReady1($event)"
    [rowData]="rowData1" 
    [columnDefs]="columnDefs1"
    [rowSelection]="rowSelection"
    [enableRtl]="true"
    [floatingFilter]="true" 
    (rowDoubleClicked)='openloan($event)'>
</ag-grid-angular>
