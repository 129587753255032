import { Component, OnInit, Input } from '@angular/core';
import { HistoryService } from 'src/app/services/history.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  constructor(private history:HistoryService) {
   }
   //#region 
   @Input() nametbl;
   @Input() idintbl;
   @Input() id;
   @Input() mainid1;
   @Input() hafkada;
   @Input()  idmafkid
list;
//#endregion
  ngOnInit() {
  }
 viewhistory()
    {
       this.list=null;
      this.history.viewhistory(this.id,this.idmafkid,this.nametbl,this.hafkada).then( response => {
      this.list=  response;
      ;;
      // this.history.viewhistory(this.id,this.mainid1,this.idintbl,this.nametbl).then( response => {

   }  )
  }
}
