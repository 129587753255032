<table>
  <tr style="font-weight: bold;text-align: center;background-color: white !important">
    הפקדות
  </tr>
  <tr class="tr tr1 ">

    <td class="shrink">
      <thead> סכום</thead>
    </td>
    <td class="shrink">
      <thead> תרומה</thead>
    </td>
    <td class="expand" style="width: 100%;">
      <thead> הערה</thead>
    </td>
  </tr>

  <tr *ngFor='let item of list' (click)='viewdetails(item.Id)' >

    <td class="shrink">
      {{item.Totalhafkada}}
    </td>
    <td class="shrink">
      {{item.Totaltruma}}
    </td>
    <td class="expand" style="width: 100%;">
      {{item.Remark  | slice:0:40}}

    </td>

  </tr>
</table>