export class Names {

    MAINID: number;
    ID: string;
    Toar: string;
    FamilyName: string;
    FirstName: string;
    Phone: string;
    Adress: string;
    CellPhone1: string;
    CellPhone2: string;
    Fax: string;
    City: number;
    Mikud: string;
    Remarks: string;
    Email: string;
    UpdateBy: string;
    CreateBy: string;
   senddocumentsto :String;
   derug:number;
  Name: string;
    constructor(){
        
    this.MAINID=null; 
    this.ID=null; 
    this.Toar="הרב"; 
    this.FamilyName="הכל"; 
    this.FirstName=""; 
    this.Phone=null; 
    this.Adress=null; 
    this.CellPhone1=null; 
    this.CellPhone2=null; 
    this.Fax=null; 
    this.City=null; 
    this.Mikud=null; 
    this.Remarks=null; 
    this.Email=null; 
    this.UpdateBy=null; 
    this.CreateBy=null; 
this.derug=null;
this.senddocumentsto=null;
    }

}
