import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../classes/user';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  logIn(id:string,pass:string): any {
    return this.http.get(NamesService.serverUrl +"/User/Login?id="+id+"&pass="+pass).toPromise().then(
      res => { return res; })
      .catch(err => { return false;})

  }
  AddUser(u: User) {
    return this.http.post(NamesService.serverUrl +"/User/AddUser",u).toPromise().then(
      response => {
       return  response;
      })  }
  SaveUser(u: User) {
    return this.http.post(NamesService.serverUrl +"/User/SaveUser",u).toPromise().then(
      response => {
       return  response;
      })  }
  GetUsers(): any {
  
      return this.http.get(NamesService.serverUrl +"/User/GetUsers").toPromise().then(
        response => {
         return  response;
        })
     
}}
