<div style="vertical-align: middle; position: relative;top:50px">
<b style="position: absolute;right: 100px;top: 100px;"> :שלח ל </b>
  <select class="form-control" (change)='valuechange(val.value)'#val style="width: 200px; direction: rtl; position: absolute;right: 159px; top: 94px;height: 38px;"> 
    <option>כולם</option>
    <option>מפקידים</option>
    <option>לווים</option>
    <option>בעלי תכנית</option>
    <option>בחר שם</option>
  </select>
  <ng-select [hidden]="!check"  style="width: 200px;height: 35px;  position: absolute; top: 148px; right: 158px;width: 200px;direction: rtl;" 
        (change)='refreshlist($event)' [items]="listl1" bindValue="MAINID" bindLabel="name"   >
        </ng-select>
  <select [hidden]="check" class="form-control" (change)='valuechange1(val1.value)' #val1 style="background-color:black;direction: rtl;text-align: right;position: absolute;height: 38px; right: 40px; color: white; width: 105px; top: 148px; border-radius: 4px;">
    <option >יותר מ</option>
    <option >שווה ל</option>
    <option > פחות מ</option>
    </select>
    <br>
    <br>
    <select class="form-control"  (change)='valuechange1(val1.value)' #val2 style="background-color:black;direction: rtl;text-align: right;height: 38px; position: absolute; right: 157px;  color: white; width: 200px; top: 199px;  ">
      <option >מצב חשבון</option>
      <option >אחר</option>
      </select>
   
    <input type="number"#num class="form-control" placeholder="0" [hidden]="check" step="1000" style="position: absolute;top: 148px; right: 158px;width: 200px;  height: 36px;text-align: right;" #scum> 
    <br>
  <b style="position: absolute; right: 110px; top: 256px;"> נושא</b>
  <input type=" text" class=" form-control" style="position: absolute; right: 159px; height: 34px; width: 200px; top: 253px;"  #subject>
  <b style="position: absolute; right: 106px; top: 305px;"> הערה</b>
  <textarea class=" form-control" style="position: absolute; right: 159px; top: 305px; width: 200px;direction: rtl;" rows="3" #notic></textarea>
  <label style=" position: absolute; right: 162px; top: 427px; font-weight: bold;cursor: pointer;">שלח פרטי חשבון
<input type="checkbox"  class="form-control"   (click)="email11()" style="position: absolute;right: -30px; top: 4px; width: 15px; height: 15px;"></label>
<label style=" position: absolute;right: 162px; top: 458px; font-weight: bold;cursor: pointer;">שלח שטר הלוואה
  <input type="checkbox" class=" form-control"  (click)="email2()" style="position: absolute;right: -30px; top: 5px; width: 15px; height: 15px;"></label>
  <label style=" position: absolute; right: 162px; top: 397px; font-weight: bold;cursor: pointer;">שלח הו"ק
    <input type="checkbox" class=" form-control"  (click)="email3()" style="position: absolute;right: -30px; top: 5px; width: 15px; height: 15px;"></label>
   <div *ngIf='mail==true'>
    <input type="email"  [(ngModel)]="email1" [ngModelOptions]="{standalone: true}"  class="form-control" multiple  placeholder="דואר אלקטרוני" style=" position: absolute; right: 160px; top: 490px; border-radius: 4px;height: 34px;width: 200px;">  
    <button class=" form-control" (click)="sendemail(subject.value,notic.value)" style="background-color: #f44336; position: absolute;color: white; right: 159px; width: 200px; top: 538px;  border-radius: 4px;">שלח</button>
  </div>
<div *ngIf='mail==false'>
    <button class=" form-control" (click)="sendemail1(scum.value,subject.value,notic.value)" style="background-color: #f44336; position: absolute;color: white; right: 159px; width: 200px; top: 490px;  border-radius: 4px;">שלח</button>
  </div>
  <svg style="    position: absolute;
  left: 90%;
  width: 40px;
  top: 80%;
  height: 30px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-paperclip" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
  </svg>
  <h1>Upload file</h1>
<form [formGroup] = "formGroup" (ngSubmit)="onSubmit()">
 <input type="file" (change)="onFileChange($event)" />
 <input type="submit" [disabled]="formGroup.invalid" value="upload" />
</form>

<h1>Your files</h1>
<button (click)="downled()"> הורד</button>
<!-- <ul>
 <li *ngFor="let fileName of fileList$ | async" >
   {{fileName}}&nbsp;&nbsp;
   <span (click)="download(fileName)">download</span>&nbsp; -->
   <!-- <span (click)="remove(fileName)">remove</span> -->
 <!-- </li>
</ul>  -->
  </div>
  