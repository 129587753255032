<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<app-main [user]="PERMISSION" [iduser]="iduser" style="position: relative; top:-8px;"></app-main>

  <!-- סינון -->
<div style="position: relative;left: 0; top:50px;height: 250px;" id="myDIV" class="header">
    <h1 style="position: relative; top:-25px;">My To Do List</h1>
    <span  style="position: relative; left: 1665PX;font-size: 90%;top:10px"  class="addBtn">בחר קטגוריה</span>
    <select #val55 style="position: relative; width: 12%; left: 1320px;top: 10px;font-size: 90%;height: 1.09cm;" (change)='valuechange1(val55.value)'>
      <option *ngFor='let item of list1'  >{{item.NameCategory}}</option>
    </select>
<br>
<br>
<span   class="addBtn" style=" position: relative;top:-36px;left: 1260px; width: 125px;font-size: 90%" class="addBtn">בחר עובד</span>
<select #val52  value="{{user1.LastName}}"  class="form-control"  (change)='filteruser(val52.value)' style=" position: relative;top:-36px; left: 950px;width: 200px;font-size: 90%;height: 1.09cm;">
  <option *ngFor='let item of listuser' >{{item.LastName}}</option>    
</select>
<label style="position: relative;left:-795px;top:-16px">לא בוצע</label>
        <input type="checkbox" style="position: relative;left:-540px;top:-50px; block-size: 15px;" (click)='change()'checked="checked">
        <br>
        <label style="position: relative;left:-1494px;top:-77px">בוצע</label>
        <input type="checkbox"  style="position: relative;left:284px;top:-102px; block-size: 15px; background-color: black; width: 100px;" (click)='change1()'> 
        <span class="addBtn" style="position: relative;left:800px;top:-100px;font-size: 90%;height: 1.09cm;"> :מתאריך</span>
       <input type="date"  style="position: relative;left:500px;top:-108px; width: 180px;font-size: 90%;height: 1.09cm;" #d5 value="{{ myDate1 | date: 'yyyy-MM-dd'}}" >
       <span class="addBtn" style="position: relative;left:140px;top:-101px;font-size: 90%">:עד תאריך</span>
        <input type="date"style="position: relative;left:-150px;top:-109px; width: 180px;font-size: 90%;height: 1.09cm; "#d2  value="{{ today | date: 'yyyy-MM-dd' }}">
        <button class="button1" style="position: relative;left:-1130px;top:-109px; width: 190px;height: 1.09cm;" (click)=" filterbydate(d5.value,d2.value)"><span  >סנן</span></button>
        <button class="button2" style="position: relative;left:-1380px;top:-109px; width: 43px;height: 1.09cm;background-color:black"   (click)=" showall()" title="נקה סינון"><span  >&times;</span></button>
      </div>
      <!-- הצגת משימות  -->
      <ul id="myUL">
        <li id="oo" *ngFor='let item of list'(click)="FUN(item.Id)" >   <b style="position: absolute;right:2% ; ; background-color: #f44336; "*ngIf ='item.NameUser!=null'>  עובד</b><b *ngIf ='item.NameUser!=null' style="position: absolute;right:6% ;"> {{item.NameUser}}  </b> 
           <b  *ngIf ='item.remark!=null' style=" position: absolute;right:10% ; background-color: dimgrey; ">הערות</b>   <b  *ngIf ='item.remark!=null'  style="position: absolute;right:14% ;"   >{{item.remark}}</b>
          <b  *ngIf ='item.Instructions!=null' style=" position: absolute;right:35% ; background-color: dimgrey; ">הוראות</b>              
          <b  *ngIf ='item.Instructions!=null'  style="position: absolute;right:39% ;">{{item.Instructions}}</b> 
          <b *ngIf ='item.Datetezcoret!=null' style=" background-color: dimgrey; position: absolute;right:57% ; "> תאריך תזכורת</b><b  style="position: absolute;right:64% ;" *ngIf ='item.Datetezcoret!=null'>    
          {{item.Datetezcoret| date:"dd/MM/yy" }}</b>       <b style="position: absolute;right:70% ; background-color: dimgrey; ">קטגוריה </b>  <b  style="position: absolute;right:75% ;">{{item.catName}} </b> 
           <b  style="position: absolute;right:95% ;" *ngIf ='item.statustask==true'>V</b> <b style="position: absolute;right:95% ;"   *ngIf ='item.statustask==false'>X</b> <b style="position: absolute;right:87% ; background-color: #f44336; ">   סטטוס משימה </b> </li>      
      </ul>
 <!-- <div id="id01" class="modal">
  <form class="modal-content animate" style="position: absolute;width: 37%; left: 650px;height: 820px;top: 40px" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 80px;width: min-content;  background-color:rgb(254, 254, 254);" (click)='update()' class="close" title="סגור">&times;</span>   
    </div> -->
<app-addtask [hidden]="!updatet" [task1]="task1" [mainid]="null" [idcounthaf]="null" [idcountloan]="null" class="col-2" style="position: relative;  left: 30px;width: 10px;top: 4px;height: 50px;"></app-addtask>

    <!-- <span style="position: absolute;  left: 80px;width: min-content;  background-color:rgb(254, 254, 254);" onclick="document.getElementById('id01').style.display='none'" class="close" title="סגור">&times;</span>    -->
    <!-- צפיה במשימה עדכון -->
<!-- <h1 style="position: absolute;left: 270px;top: 38px; font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif">עדכון משימה</h1>
    <div class="container "  >
        <label  style="position: absolute;top: 197px; left: 259px;" for="uname"><b>ת"ז</b></label>
        <input #i  type="text"placeholder="תז"  class="form-control" style="position: absolute; left: 91px; width: 200px; top: 212px;" value="{{task1.id}}"  name="uname" >
      <label  style="position: absolute; top: 520px; left: 553px;overflow: auto;" for="uname"><b>הוראות</b></label>
      <input #i  type="text"placeholder="הוראות"  class="form-control" style="position:absolute;left: 92px; width: 520px; top: 535px; height: 50px;" value="{{task1.Instructions}}"  name="uname" >
      <label  style="position: absolute; top: 409px; left: 560px;" for="uname"  [hidden]="both"><b  [hidden]="both">הערות</b></label>
      <input #remarks  type="text"placeholder="הערות"  class="form-control" style="position: absolute; position: absolute; left: 412px;  width: 200px; top: 424px;" value="{{task1.remark}}"  name="uname" >
       <label  style="position: absolute;  top: 195px; left: 576px;" for="uname"><b>עובד</b></label>
      <select   [(ngModel)]="task1.NameUser"  class="form-control"  [ngModelOptions]="{standalone: true}" #val (change)='saveval(val.value)' style="position: absolute;top: 220px; left: 412px;  background-color: white ; border:  solid 1px #ccc; border-radius: 4px; width: 200px;direction: rtl;" > <option *ngFor='let item of listuser'  >{{item.LastName}} </option> </select>
      <input   style="position: absolute;top: 110px; left: 471px;"  class="form-control"  type="text"  value="{{task1.MAINID}}"  name="uname"  disabled> 
      <br> 
      <br>
      <label style="position: absolute; left: 548px;top: 303px;" for="psw"><b>קטגוריה</b></label>
      <select #valcat (change)='saveval(val.value)'  class="form-control"  [(ngModel)]="task1.NameCategory" [ngModelOptions]="{standalone: true}"  style="position: absolute; position: absolute; left: 412px; top: 327px; background-color: white ; border:  solid 1px #ccc; border-radius: 4px; width: 200px;direction: rtl;" >    <option *ngFor='let item of list1'  >{{item.NameCategory}}</option> </select>
      <br>
      <br>
      <label style="position: absolute; left: 181px; top: 303px;" for="psw"><b>  תאריך תזכורת
        </b></label>

      <input type="date" #d value="{{task1.Datetezcoret}}"   class="form-control"   value="{{ task1.Datetezcoret | date: 'yyyy-MM-dd' }}" style="position: absolute;left: 91px; top: 318px;  width: 200px;border-radius: 4px;" name="psw" >
      <p [hidden]="!booldate" style="position: relative;left: 134px;top:256px"> <b >הכנס תאריך החזר</b></p>
      <input type="date"  value="{{ task1.date | date: 'yyyy-MM-dd' }}" class=" form-control" style="position: relative;left: 73px;top: 235px;width: 196px;" [hidden]="!booldate" #d1> 
      <p style="position: absolute;left: 208px;top: 410px;" [hidden]="!boolscum"><b>הכנס סכום</b></p> 
      <input type="text" class="form-control" style="position: relative;left: 75px;top: 278px;width: 196px !important" value="{{task1.scum}}"  [hidden]="!boolscum" #scum> 
      <p [hidden]="!task1.remember"> <input type="checkbox" style="position: absolute;top: 626px; left: 266px; block-size: 17px;cursor: pointer;" checked="checked" (click)="rememberEveryMonth()" #month  ><b style="position: absolute; left: 518px;  top: 621px; "  for="uname">:הזכר כל</b>
      <p [hidden]="task1.remember"> <input type="checkbox" style="position: absolute;top: 626px; left: 266px; block-size: 17px;cursor: pointer;" (click)="rememberEveryMonth()" #month  ><b style="position: absolute; left: 518px;  top: 621px; "  for="uname">:הזכר כל</b></p>
      <input type="number" #each value="{{task1.each}}" style="position: absolute; width: 40px;left: 464px;top: 619px; border: 1px solid #ccc; border-radius: 4px; height: 29px;" >
      <button type="submit"  (click)="UpdateTask(d.value,remark.value,i.value,each.value)" onclick="document.getElementById('id01').style.display='none'" style="position: absolute; top: 730px; left: 32px; width: 500px;border-radius: 6px;font-size: 120%;height: 49px;">אישור</button>
      <label [hidden]="!task1.statustask" style="position: absolute;  left: 0px;top: 10px;cursor: pointer;" > <input type="checkbox" (click)="set()" checked="checked" name="remember"  style="position: absolute; left: 587px; top: 657px; width: 18px; height: 18px;cursor: pointer;"><b style="position: absolute;left: 545px; top: 652px;">בוצע</b> 
      </label>
      <label [hidden]="task1.statustask" style="position: absolute;left: 0px; top: 10px; cursor: pointer;" > <input type="checkbox"  (click)="set()"  name="remember1"  style="position: absolute; left: 587px; top: 657px; width: 18px;height: 18px;cursor: pointer;"> <b style="position: absolute;  left: 545px;top: 652px;">בוצע</b>
      </label>
    </div>
    <div class="container" >
      <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn" style="position: absolute; left: 538px; top: 731px;width: 130px; height: 49px;border-radius: 6px;">ביטול</button>-->
    <!-- </div>
  </form> -->
<!-- </div>   -->

