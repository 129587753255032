import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Loans } from '../classes/loans';
import { Heczer } from '../classes/heczer';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class LoansService {
  RestartBalance(arg0: number) {
    return this.http.get(NamesService.serverUrl +"/Loans/RestartBalance?id="+arg0).toPromise().then(
      response => {
        return response;
      })
    .catch(v => { return false; }); 
  }

  //#region 

public static scum:number;
static scumd: number;
  //#endregion

  constructor(private http: HttpClient) {}
    pay(idheczer: number, getbyway1: any) {
      return this.http.put(NamesService.serverUrl +"/Heczer/putpay", {id:idheczer, s:getbyway1}).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; }); }
      ShowShiabud(ID: number):any {
        return this.http.get(NamesService.serverUrl +"/Loans/ShowShiabud?id="+ID).toPromise().then(
          response => {
            return response;
          })
        .catch(v => { return false; });      }
    
      MergeLoans(list:Array<number>) {
        return this.http.put(NamesService.serverUrl +"/Loans/MergeLoans",list).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; })        }
        GetTypesLoan():any {
          return this.http.get(NamesService.serverUrl +"/Loans/GetTypeLoans").toPromise().then(
            response => {
              return response;
            })
          .catch(v => { return false; })   }
      updateheczer(h: Heczer) {
        return this.http.put(NamesService.serverUrl +"/Heczer/PutHeczer",h).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; })  
      }
      getListHeczers() :any{
        return this.http.get(NamesService.serverUrl +"/Loans/DeleteLoan").toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; })       }
        getloansAll() {
          return this.http.get(NamesService.serverUrl +"/Loans/GetAllLoans").toPromise().then(
            response => {
              return response;
            })
          .catch(v => { return false; })     
        }
      delLoan(ID: number) {
        return this.http.get(NamesService.serverUrl +"/Loans/DeleteLoan?id="+ID).toPromise().then(
          response => {
            return response;
          })
        .catch(v => { return false; })  }
      StopHafkadot(Id: number, num: number) {
        return this.http.put(NamesService.serverUrl +"/Heczer/StopHafkadot", {id:Id, s:num}).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; }); 
      }
       updateloan(loan1: Loans) {
        return this.http.put(NamesService.serverUrl +"/Loans/PutLoan",loan1).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; }); }
 
  ShowArev(id:number) :any{
    return this.http.get(NamesService.serverUrl +"/Loans/ShowArev?id="+id).toPromise().then(
      
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  GetHokhelpHECZER(idloan: any): any {
    return this.http.get(NamesService.serverUrl +"/HokHelp/GetHokhelpHECZER?id="+idloan).toPromise().then(
      
      response => {
       return  response;
      })
    .catch(v => { return false; });
  } 
  addheczer(h:Heczer) {
    return this.http.post(NamesService.serverUrl +"/Heczer/PostHeczer", h).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; });
  }
  enterhaf(id:Number): any {
    return this.http.get(NamesService.serverUrl +"/Loans/EnterHok?id="+id).toPromise().then(
      response => {
       return  true;
      })
    .catch(v => { return false; });

  }
  GetTotal(id: number):any {
    return this.http.get(NamesService.serverUrl +"/Loans/GetTotal?id="+id).toPromise().then(
      response => {    

 return response      })
    .catch(v => { return false; });  }
  ViewHeczers(id:number) : any {
{
 
    return this.http.get(NamesService.serverUrl +"/Heczer/GetHeczerById?id="+id).toPromise().then(
      
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }}
   getloans(id:number) : any {
    {   
        return this.http.get(NamesService.serverUrl +"/Loans/GetLoansById?id="+id).toPromise().then(
          response => {
           return  response;
          })
       .catch(v => { return false; });
      }}
  
  addLoan(pro:Loans): any {
    return this.http.post(NamesService.serverUrl +"/Loans/PostLoan", pro).toPromise().then(
        response => {
          return response;
        })
      .catch(v => { return false; });
}
getlistNames(id:number):any
{
  return this.http.get(NamesService.serverUrl +"/Loans/GetNamesMafkidim?id="+id).toPromise().then(
    response => {
     return  response;
    })
}
getlistNames1(id:number):any
{
  return this.http.get(NamesService.serverUrl +"/Loans/GetNamesMafkidim1?id="+id).toPromise().then(
    response => {
     return  response;
    })
}
HokReturn(id:number, hokreturn1: string,amla:boolean,idu:number,date1:Date,idr:number): any {
    if(hokreturn1==null)
    {
      hokreturn1="חודש הבא כפול"
    }
    if(date1==null)
    {
      date1=new Date();
    }
    return this.http.put(NamesService.serverUrl +"/Heczer/puthokreturn", {id:id, s:hokreturn1,a:amla,iduser:idu,date:date1,r:idr}).toPromise().then(
        response => {

        return true;
      })
    .catch(v => { return false; });
}
DeleteHafkada(id: any): any {
  return this.http.delete(NamesService.serverUrl +"/Heczer/DeleteHeczer?id="+id).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; })} 
      DeleteAllhafkadot(id: any) {
        return this.http.delete(NamesService.serverUrl +"/Heczer/DeleteallHeczer?id="+id).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; })  }
  DeleteJustOnehafkadot(id1: any, arg1: any) {
    return this.http.put(NamesService.serverUrl +"/Heczer/DeleteHeczerfromonehok",{id:id1,idhok:arg1}).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; })  }
    DeleteAllFuturehafkadot(id: any) {
      return this.http.delete(NamesService.serverUrl +"/Heczer/DeleteallFutureHeczer?id="+id).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; })  }
DeleteJustOneFuturehafkadot(id1: any, arg1: any) {
  return this.http.put(NamesService.serverUrl +"/Heczer/DeleteFutureHeczerfromonehok",{id:id1,idhok:arg1}).toPromise().then(
    response => {
      return true;
    })
  .catch(v => { return false; })  }
  bankreports(id: any): any {
    return this.http.get(NamesService.serverUrl +"/Loans/bankreports?id="+id).toPromise().then(
      response => {
        return true;
      })
    .catch(v => { return false; }); 
  }
reports(value1: boolean, id: any ,future:boolean): any {
  return this.http.post(NamesService.serverUrl +"/Loans/report", {id:id, report:value1, f:future, s:value1}).toPromise().then(
    response => {
      return true;
    })
  .catch(v => { return false; });  }
}
