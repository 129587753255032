import { Component, OnInit } from '@angular/core';
// import { t } from '@angular/core/src/render3';
import { NamesService } from 'src/app/services/names.service';
import { Names } from 'src/app/classes/names';
// import { element } from 'protractor';
import { EmailService } from 'src/app/services/email.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FileService } from 'src/app/file.service';
// import { FileService } from 'src/app/services/file.service';
@Component({
  selector: 'app-sendemail',
  templateUrl: './sendemail.component.html',
  styleUrls: ['./sendemail.component.css']
})
export class SendemailComponent implements OnInit {
  //#region 

who;
 s;
more;
mail:boolean;
  check: boolean;
  listl1: Names[];
  email1: any;
  bankaccount: boolean;
  loanshtar: boolean;
  hok: boolean;
  mainid: any;
//#endregion


private fileName;

public formGroup = this.fb.group({
  file: [null, Validators.required]
});
public onFileChange(event) {
  ;
  const reader = new FileReader();

  if (event.target.files && event.target.files.length) {
    this.fileName = event.target.files[0].name;
    const [file] = event.target.files;
    reader.readAsDataURL(file);
   
    reader.onload = () => {
      this.formGroup.patchValue({
        file: reader.result
      });
    };
  }
}
downled()
{
    ;

  this.fileService.download(this.fileName);
}
public onSubmit(): void {
  ;
  this.fileService.upload(this.fileName, this.formGroup.get('file').value);
}
  constructor(private name:NamesService,private m:EmailService,private fb: FormBuilder, private fileService: FileService) { }
  ngOnInit() {
    this.mail=false;
    this.shownames();
  }
  shownames() {
    this.listl1 = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.listl1 == null) {
    this.name.ShowNames()
    .then(res=>{this.listl1=res   })}
    if(this.listl1!=null)
    {
    this.listl1.forEach (element=> element['name']=element['FamilyName']+" "+element['FirstName'])
    }
  }
  email(){
    this.mail=true;

  }
  sendemail1(num:number,s:string,n:string)
  {

  }
  sendemail(subject:string,notice:string)
  {
this.m.SendEmail(this.who,subject,notice);
  }
  email11(){
    this.mail=true;
    this.s="מצב פרטי חשבון של הגמח  ";
   this.bankaccount=true;;

  }  
  email2(){
    this.mail=true;
    this.s="מצב שטר הלוואה";
this.loanshtar=true;
  }
  email3(){
    this.mail=true;
    this.s="מצב הוק ";
this.hok=true;
  }
  refreshlist(event)
  {
   
    this.email1=event.Email;
    this.mainid=event.MAINID;
  }
  valuechange(value:string)
  {
    if(value=="בחר שם")
    {
      this.mail=true;
      this.check=true;
    }
this.who=value;
  }
  valuechange1(value1:string)
  {
this.more=value1;
  }
  // sendemail( scum:number, subject:string, notice:string)
  // {
  //   if(this.mainid!=null)
  //   {
  //    this.m.sendemail(this.mainid, this.hok,this.loanshtar,this.bankaccount,this.s)
  //   }
  //   else{
  //      this.m.sendemail1(this.email1, this.hok,this.loanshtar,this.bankaccount,this.s)
  //     }
  //   }
  //   sendemail1(email:string, scum:number, subject:string, notice:string)
  //   {
  //   }
}
