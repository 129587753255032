<app-main [user]="PERMISSION" [iduser]="iduser" ></app-main>
<div id="mySidenav"style="position: relative; 
top:60px;"  class="sidenav">

  <a    (click)="sendemail( )" id="about">שליחת מיילים</a>
  <a (click)="Workers()" id="blog"> ניהול עובדים</a>
  <a (click)="setting()" id="projects">הגדרות</a>
  <a (click)="ShowContacts()" id="contact">ניהול נציגים</a>
</div>
<app-ashraytypes [hidden]="!seting"></app-ashraytypes>
<app-netzigim  [PERMISSION]="PERMISSION" [iduser]="iduser"  [hidden]="!contacts"></app-netzigim>
<app-sendemail [hidden]="!email"></app-sendemail>
<app-add-contact [hidden]="!contacts" style="position: relative;left: -1410px;top: 250px;"></app-add-contact> 
<app-workers [hidden]="!worker" style="position: relative;left: -1410px;top: 250px;width: 400px;"></app-workers>
