<app-main  [user]="PERMISSION" [iduser]="iduser" ></app-main>
<h3 style="position: absolute;top: 110px;font-size: xx-large; left: 900px; text-align: center">waiting-list</h3>
<ag-grid-angular 
style="width:1790px; height: 600px;position: absolute; left:70px;top:220px;direction: rtl;" 
class="ag-theme-balham"
[rowData]="rowData" 
[columnDefs]="columnDefs"
[enableRtl]="true"
[rowSelection]="rowSelection"
[floatingFilter]="true"  
[rowClassRules]="rowClassRules"
(cellDoubleClicked)='viewdetails($event)' >
</ag-grid-angular>
<app-add-waiting  [add]="false" [mainid]="mainid" style="position: relative;left: 490px;top:37px">  </app-add-waiting>
  <router-outlet></router-outlet>
