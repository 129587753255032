export class KupaCountcs {
    TzoverOfLoans:number;
    TzoverOfHeczer:number;
    TzoverOfHafkadot:number;
     TzoverOfTrumot :number;
    balance :number;
    futureminus :number;
    futureZcut :number;
    TzoverOfCash :number;
    TzoverOfChecks :number
    balancedolar:number
    futureminusdolar :number
    futureZcutdolar :number
    balanceyoro:number
    futureminusyoro :number
    futureZcutyoro:number
     TzoverOfLoansdolar :number
     TzoverOfHeczerdolar:number 
     TzoverOfHafkadotdolar:number
     TzoverOfTrumotdolar :number
    TzoverOfLoansyoro:number
     TzoverOfHeczeryoro:number
    TzoverOfHafkadotyoro :number
    TzoverOfTrumotyoro :number

   constructor (){
     this. TzoverOfHafkadot  =null;
     this.TzoverOfHeczer=null;
     this.TzoverOfTrumot=null;
     this.balance=null;
     this.futureZcut=null;
    this.futureZcut=null; 
    }
}
 