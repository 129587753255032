<meta name="viewport" content="width=device-width, initial-scale=1.0">
<button (click)='send(date.value)' style="position: absolute;    left: -1520px;
top: 63%;
background-color: #f44336;
width: 90px;;"> גבה</button>
<button (click)='updatesend(date.value)' style="position: absolute;    left: -1220px;
top: 63%;
background-color: #f44336;
width: 90px;;"> עדכן</button>
<button (click)='Stop(date.value)' style="position: absolute;    left: -1220px;
top: 63%;
background-color: #f44336;
width: 90px;;"> השהה לכולם</button>
<h1 style="position: absolute; left: -1350px;top: 129px;width: 15px;font-size: xx-large;">סה"כ</h1>
<h1 style="position: absolute; left: -1450px;top: 129px;width: 15px;;font-size: xx-large;">{{totalheczer+totalhaf| number}}</h1>
<img [hidden]="zerohec" src="/assets/1/s.png"  style="   width: 32px;
position: absolute;
left: -10px;
height: 32px;
top: 130px;border-radius: 5px;" >
<h1 style="position: relative; left: 1000px;top: 130px;width: 15px;font-size: x-large;;">:הפקדות</h1>
<!-- <h1 style="position: relative; left: 990px;top: 20px;width: 15px;">סה"כ</h1> -->
<h1 style="position: relative; left: 940px;top: 95px;width: 15px;font-size: x-large;">{{totalhaf| number}}</h1>
<img [hidden]="zerohaf" src="/assets/1/s.png"  style="   width: 32px;
position: absolute;
left: 850px;
height: 32px;
top: 130px;   border-radius: 5px;" >
<img (click)="excel1()"  src="/assets/1/excel.png">
<img (click)="excel()" src="/assets/1/excel.png">
<input type="date"  #date style="position: relative;left: 450px;top: -32px;" value="{{ myDate | date: 'yyyy-MM-dd'}}"   (change) =" fun(date.value)">
<input type="checkbox"   style="position: absolute;left: -1585px;top: 125%;    block-size: 17px;
cursor: pointer;"  checked="checked"  (change) =" heczer()">
<input type="checkbox"   style="position: absolute;left: -750px;top:128%;    block-size: 17px;
cursor: pointer;" checked="checked"   (change) =" hafkada()">
        <ag-grid-angular 
        style="width:770px; height: 580px;position: absolute; left:-1300px;top:230px;direction: rtl;" 
        class="ag-theme-balham"
        [rowData]="rowData" 
        [columnDefs]="columnDefs"
        [enableRtl]="true"
        [floatingFilter]="true" 
        (gridReady)="onGridReady1($event)"

        (cellClicked)='openmafkid($event)'>
    </ag-grid-angular>
    <h1 style="position: absolute;left: -1700px;top: 182px;width: 15px;font-size: x-large;">:החזרים</h1>
    <!-- <h1 style="position: absolute; left: -120px;top: 70px;width: 15px;">סהכ</h1> -->
    <h1 style="position: absolute; left: -1830px;top: 182px;width: 15px;font-size: x-large;">{{totalheczer| number}}</h1>
    <ag-grid-angular 
    style="width:770px; height: 580px;position: absolute; left:-2100px;top:230px;direction: rtl;" 
    class="ag-theme-balham"
    [rowData]="rowData1" 
    [columnDefs]="columnDefs1"
    (gridReady)="onGridReady($event)"

    [enableRtl]="true"
    [floatingFilter]="true" 
    (cellClicked)='openloan($event)'>
</ag-grid-angular>
