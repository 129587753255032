import { Component, OnInit, Input } from '@angular/core';
import { Task } from 'src/app/classes/task';
import { triggerAsyncId } from 'async_hooks';
import { TasksService } from 'src/app/services/tasks.service';
import { UserService } from 'src/app/services/user.service';
import { NamesService } from 'src/app/services/names.service';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';

@Component({
  selector: 'app-addtask',
  templateUrl: './addtask.component.html',
  styleUrls: ['./addtask.component.css']
})
export class AddtaskComponent implements OnInit {

  constructor(private task: TasksService, private user: UserService, private maagar: MaagarMenageService) {
  }
  //#region 
  @Input() task1;
  @Input() mainid;
  @Input() idcounthaf;
  @Input() idcountloan;
  expir: boolean;
  view: boolean;
  both: boolean;
  instruction: boolean;
  list2: any;
  num5: number = 0;
  update: boolean;
  date: Date = new Date();
  settask: boolean = false;
  list;
  to: number = 0;
  from: number = 0;
  boolhaf: boolean;
  boolloan: boolean;
  listcoin: any;
  coin2: any;
  list1;
  listtaskhaf;
  listtaskloan;
  remembereach: boolean;
  listuser;
  remember: boolean;
  valueuser;
  booldate: boolean = false;
  boolscum: boolean = false;
  valuecat;
  task2: Task = new Task();
  id1;
  //#endregion
  ngOnInit() {
    this.mainid = this.mainid;
    this.booldate = false;
    this.task1 = new Task();

    this.boolscum = false;
  }
  Get() {

    this.getusers();
    this.GetCoins();

    if (this.idcounthaf == null && this.idcountloan == null) {
      this.update = true;
      document.getElementById('id012').style.display = 'block';
      this.task1 = this.task1;
      this.getcat();
      this.saveval1(this.task1.NameCategory);
    }
    else {
      this.update = false;
      this.task1 = new Task();
      this.task1.Datetezcoret = this.date;
      if (this.idcounthaf != null) {
        this.boolhaf = true;

        this.showcathaf();

        if (this.idcounthaf != -1) {
          this.task.ViewTasksHaf(this.idcounthaf).then(res => {
            this.listtaskhaf = res
            this.showhokdup(0);
          })

        }

      }
      else {
        if (this.idcountloan != null) {
          this.boolloan = true;
          this.showcatloan();


          if (this.idcountloan != -1) {
            this.task.ViewTasksloan(this.idcountloan).then(res => {
              this.listtaskloan = res
              this.showhokdup(0);
            });
          }
        }
      }
    }
  }
  //#region show
  updatetask(task3: Task) {
    this.mainid = this.mainid;
    this.booldate = false;
    this.boolscum = false;

    this.update = true;
    document.getElementById('id012').style.display = 'block';
    this.task1 = task3;
    if (this.task1.Category != null) {
      this.task1['NameCategory'] = this.list1.find(a => a.CategoryId == this.task1.Category).NameCategory;
    }
    if (this.task1.userkod != null) {

      this.task1['UserName'] = this.listuser.find(a => a.IdUser == this.task1.userkod).LastName;
    }
    if (this.task1.coin != null) {
      this.task1['coinName'] = this.listcoin.find(a => a.Id == this.task1.coin).name;
    }
  
    this.settask = this.task1.statustask;
    this.remember = this.task1.remember;
    this.saveval1(this.task1.NameCategory);
  }
  showcatloan() {
    ;;
    this.maagar.Showcatloan().then(res => {
      this.list1 = res;
      this.valuecat = this.list1[0].NameCategory
    })
  }
  del() {
    ;
    this.task.delTask(this.task1.Id
    )
  }
  add() {
    this.task1 = new Task();
    this.num5 = 0;
    this.update = false;
    this.view = false;
  }
  // del()
  // {

  //   this.task.delTask(this.task1.Id); 
  //   ;;
  //     if(this.update)
  //     {
  //     document.getElementById('id012').style.display='none';
  //     }
  //     else{
  //       this.num5=1;
  //       this.showhokdup(0);
  //     }
  // }
  getcat() {
    this.maagar.Showcat("משימה").then(res => {
      this.list1 = res;
    })
  }
  GetCoins() {
    this.listcoin = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoin == null) {
    this.maagar.GetCoins().then(res => {
      this.listcoin = res;
      sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));
      if (!this.update) {
        this.task1.coin = this.listcoin[0].name
      }
    })}
    else{
      if (!this.update) {
        this.task1.coin = this.listcoin[0].name
    }
  }
  }
  showcathaf() {
    this.maagar.Showcathaf().then(res => {
      this.list1 = res;
      this.valuecat = this.list1[0].NameCategory;
    })
  }
  getusers() {
    this.user.GetUsers().then(res => {
      this.listuser = res;
      if (!this.update) {
        this.task1.UserName = this.listuser[0].LastName
      }
    })
  }
  showhokdup(num: number) {
    ;
    if (this.listtaskhaf != null) {
      if (this.listtaskhaf.length > 0) {
        this.from = this.listtaskhaf.length;
        this.list2 = this.listtaskhaf;
        this.num5 = 1
      }
    }
    else {
      if (this.listtaskloan != null) {
        if (this.listtaskloan.length > 0) {
          this.from = this.listtaskloan.length;
          this.list2 = this.listtaskloan;
          this.num5 = 1
        }
      }
    }
    if (this.num5 == 1) {
      this.view = true;
      if (num + this.to >= 0 && num + this.to < this.from) {
        if (this.from == 1) {
          document.getElementById('a').style.backgroundColor = 'lightgray';
          document.getElementById('b').style.backgroundColor = 'lightgray';
          this.task1 = this.list2[0]
          if (this.task1.Category != null) {
            this.task1['NameCategory'] = this.list1 && this.list1.find(a => a.CategoryId == this.task1.Category) ? this.list1.find(a => a.CategoryId == this.task1.Category).NameCategory : null;
          }
          if (this.task1.userkod != null) {
            this.task1['UserName'] = this.listuser && this.listuser.find(a => a.IdUser == this.task1.userkod) ? this.listuser.find(a => a.IdUser == this.task1.userkod).LastName : null;
          }
          if (this.task1.coin != null) {
            this.task1['coinName'] = this.listcoin && this.listcoin.find(a => a.Id == this.task1.coin) ? this.listcoin.find(a => a.Id == this.task1.coin).name : null;
          }
        }
        else {
          this.task1 = this.list2[num + this.to];
          document.getElementById('a').style.backgroundColor = '#f44336';
          document.getElementById('b').style.backgroundColor = '#f44336';
          if (this.task1.Category != null) {
            this.task1['NameCategory'] = this.list1 && this.list1.find(a => a.CategoryId == this.task1.Category) ? this.list1.find(a => a.CategoryId == this.task1.Category).NameCategory : null;
          }
          if (this.task1.userkod != null) {

            this.task1['UserName'] = this.listuser && this.listuser.find(a => a.IdUser == this.task1.userkod) ? this.listuser.find(a => a.IdUser == this.task1.userkod).LastName : null;
          }
          if (this.task1.coin != null) {
            this.task1['coinName'] = this.listcoin && this.listcoin.find(a => a.Id == this.task1.coin) ? this.listcoin.find(a => a.Id == this.task1.coin).name : null;
          }
          if (this.to + num == 0) {
            document.getElementById('b').style.backgroundColor = 'lightgray';
          }
          else {
            if (this.to + num + 1 == this.from) {
              document.getElementById('a').style.backgroundColor = 'lightgray';
            }
          }
        }
        this.to += num;
        this.num5 = this.to + 1;
      }
      else {
        if (this.to + num <= 0) {
          document.getElementById('b').style.backgroundColor = 'lightgray';
          this.to = 0;
          this.num5 = this.to + 1;
          this.task1 = this.list2[0];
          if (this.task1.Category != null) {
            this.task1['NameCategory'] = this.list1 && this.list1.find(a => a.CategoryId == this.task1.Category) ? this.list1.find(a => a.CategoryId == this.task1.Category).NameCategory : null;
          }
          if (this.task1.userkod != null)
            this.task1['UserName'] = this.listuser && this.listuser.find(a => a.IdUser == this.task1.userkod) ? this.listuser.find(a => a.IdUser == this.task1.userkod).LastName : null;
          if (this.task1.coin != null) {
            this.task1['coinName'] = this.listcoin && this.listcoin.find(a => a.Id == this.task1.coin) ? this.listcoin.find(a => a.Id == this.task1.coin).name : null;
          }
        }
        else {
          document.getElementById('a').style.backgroundColor = 'lightgray';
          this.task1 = this.list2[this.from - 1];
          if (this.task1.Category != null) {
            this.task1['NameCategory'] = this.list1.find(a => a.CategoryId == this.task1.Category).NameCategory;
          }
          if (this.task1.userkod != null) {
            this.task1['UserName'] = this.listuser.find(a => a.IdUser == this.task1.userkod).LastName;
          }
          if (this.task1.coin != null) {
            this.task1['coinName'] = this.listcoin.find(a => a.Id == this.task1.coin).name;
          }
          this.to = this.from;
          this.num5 = this.to;
        }
      }
    }
  }

  //#endregion
  //#region  save
  saveval(value1: string) {
    this.valueuser = value1;
  }

  saveval1(value1: string) {
    ;;
    this.instruction = false;
    this.expir = false;
    this.valuecat = value1;
    if (value1 == "החזר הלוואה" || value1 == "משיכת פקדון") {
      this.boolscum = true;
      this.booldate = false;
      this.both = true;
      this.instruction = true;
    }
    else {
      if (value1 == "הכנס החזר הלוואה") {
        this.booldate = true;
        this.boolscum = false;
        this.both = true;
        this.instruction = true;

      }
      else {
        if (value1 == "החלפת תוקף אשראי") {
          this.expir = true;
          this.instruction = true;
        }
        this.booldate = false;
        this.boolscum = false;
        this.both = false;

      }
    }
  }
  save() {
    ;;

    if (this.idcounthaf != null) {
      this.task1.IDtotbl = this.idcounthaf;
    }

    else {
      if (this.idcountloan != null) {
        this.task1.IDtotbl = this.idcountloan;
      }
    }
    this.task1.MAINID = this.mainid;
    this.task1.statustask = this.settask;
    if (this.task1.Category != null) {
      this.task1.Category = this.list1.find(a => a.NameCategory == this.task1.NameCategory).CategoryId;
    }
    if (this.task1.coinName != null) { this.task1.coin = this.listcoin.find(a => a.name == this.task1.coinName).Id; }
    if (this.task1.userkod != null) {
      this.task1.userkod = this.listuser.find(a => a.LastName == this.task1.UserName).IdUser;
    }
    if (this.update == true || this.view == true) {
      this.task.UpdateTask(this.task1);
    }
    else {
      this.task.addTask(this.task1);
    }
  }
  //#endregion
  rememberEveryMonth() {
    this.remember = !this.remember;
  }
  ChangeStatus() {
    ;;
    this.settask = !this.settask;
  }
}
