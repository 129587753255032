<table  style="width: 1000px;position: absolute;left: -1800px !important">
  <tr>
      <td >  <thead>  שם קוד</thead></td>
     <td >  <thead class="col-3">שם</thead></td>
     <td >  <thead>קוד הוק</thead></td>

     <td>  <thead>מס בנק</thead></td>
     <td>  <thead> סניף</thead></td>
     <td>  <thead> חשבון</thead></td>
     <td>  <thead class=col-3>מס כרטיס</thead></td>
     <td>  <thead>תוקף</thead></td>
     <td>  <thead>CVV</thead></td>
     <td>  <thead>סוג הוק</thead></td>
     <td>  <thead>התקבל</thead></td>   
     <td>  <thead>קוד מוסד חדש</thead></td>   

     </tr>
     
   <tr *ngFor='let item of list'>
<td>
   {{item.MAINID}}
        </td>
        <td class="col-3">
            {{item.Notice}}
          </td>
 <td >
   {{item.ID}}
 </td>
 <td>
 
   {{item.BANK}}
 </td>
 
 <td>
   {{item.SNIF}}
 </td>
 <td>
 
   {{item.BANKACCOUNT}}
 </td>
 

 <td class="col-3">
 
   {{item.CardNumber}}
 </td>
 <td>
   {{item.Valid}}
 </td>
 <td>
   {{item.CVV}}
 </td>
 <td>
 
   {{item.TypeHok}}
 </td>
 <td>
    {{item.Accepted}}
  </td>
  <td>
  <select  class="custom-select" style="background-color: black; color: white">
      <div class="custom-select" style="width:400px;"> </div> 
      <option *ngFor='let status of masav'>{{status.MOSADNUMBER}}</option>
    </select>
     </td>
   </tr>
</table>
<button (click)='updatemasav()' type="button" class="block" style="position: absolute;left: -1800px !important;top: 53px; width:1000px;">שמור שינויים</button>
