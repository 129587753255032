<app-main [user]="PERMISSION" [iduser]="iduser"></app-main>
<h6>insert excel</h6>
<img (click)="insert()" src="assets/1/99.jpg" style="position: absolute ;    left: 50px;
top: 110px;
width: 85px;
height: 45px;" />
<div [hidden]="!wait" style="position: absolute;top: 50%;left: 50%; overflow: auto; " class="loader">כבר יוצג....
  סבלנות!!</div>
<table id='customers' id="mytab" style="overflow-y: auto;  scroll-behavior: smooth;">
  <tbody>
    <tr class='row'>
      <td class='col-1' style=" text-align: left;">
        <thead style=" text-align: left;position: absolute;left: -2px;">תאריך</thead>
      </td>
      <td class='col-1' style=" text-align: left;">
        <thead style=" text-align: left;position: absolute;left: 0px;"> חובה</thead>
      </td>
      <td class='col-1'>
        <thead style=" text-align: center;position: absolute;left:0px;"> זכות </thead>
      </td>
      <td class='col-1'>

        <thead>יתרה</thead>
      </td>
      <td class='col-2'>

        <thead>הערות</thead>
      </td>

      <td class='col-2'>
        <thead>שם</thead>
      </td>
      <td class='col-1'>

        <thead>קטגוריה</thead>
      </td>
      <!-- <td class='col-1' >
      <thead>תת קטגוריה</thead>
    </td> -->
      <td class='col-1'>
        <thead></thead>
      </td>

    </tr>
  <tbody class="tbody" style="border: 5px solid ;">
    <tr class='row' *ngFor='let item of list; let ii = index '>
      <td class='col-1' style="text-align: left;">

        {{item.dateoperation |  date:"dd/MM/yy"}}
      </td>
      <td class='col-1'>

        {{item.SCUM}}
      </td>
      <td class='col-1'>

        {{item.SCUMz}}
      </td>
      <td class='col-1'>
        {{item.Totalscum}}
      </td>
      <td class='col-2'>

        {{item.Remark}}
      </td>
      <td class='col-2'>

        <ng-select [(ngModel)]="item.Mainid" (change)="changevalue($event)" [ngModelOptions]="{standalone: true}"
          [items]="items" bindValue="MAINID" aria-placeholder="הכנס שם" #val2 bindLabel="Name">
        </ng-select>
      </td>
      <td class='col-1'>

        <ng-select [(ngModel)]="item.insertto" (change)="changevalue2($event)" [ngModelOptions]="{standalone: true}"
          [items]="listcat" bindValue="CategoryId" aria-placeholder="הכנס שם" #val1 bindLabel="NameCategory">
        </ng-select>
      </td>
      <td class="col-1">
        <img style="width: 30px;height: 30px" src="assets/1/23.jpg"
          onclick="document.getElementById('id018').style.display='block'" (click)="Open(item)">
      <td class="col-1">
        <svg (click)="Addrows(item)" onclick="document.getElementById('id019').style.display='block'"
          style="width: 32px;height: 40px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle"
          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
          <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        </svg>
      </td>
      <td style="text-align: right;">
        <input [attr.id]="ii+100000000" type="checkbox" style="   block-size: 18px;" (change)="DONTASK()">
      </td>
      <td style="text-align: right;">
        <label class="container" style="text-align: right;padding-left: 50px;">
          <input type="checkbox" style="position: absolute;left: 8px;" (change)="save(item)">
          <span [attr.id]="ii" class="checkmark"></span>
        </label>
      </td>
    <tr>
  </tbody>
</table>
<div id="id018" class="modal" style="display:none">
  <form class="modal-content" style="overflow-x: hidden;" action="/action_page.php">
    <div class="container">
      <span style="position: absolute; left: 37px; top: 5px;"
        onclick="document.getElementById('id018').style.display='none'" class="close1" title="סגור">&times;</span>
    </div>
    <div class="container" style="text-align: center;">
      <div class="row" style="position: absolute;top: 202px;">
        <b [hidden]="!degelhaf">בחר חן הפקדה:</b>
        <table [hidden]="!degelhaf" style="position: relative; left: -58px; top:5px; overflow: visible;">
          <tr>
            <td>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> תרומה</thead>
            </td>
            <td>
              <thead>הערה </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listAccounts' (click)='CooseMafkis(item.Id)'>
            <td>
              <input type="radio" name="b">
            </td>
            <td class="shrink">
              {{item.Totalhafkada}}
            </td>
            <td class="shrink">
              {{item.Totaltruma}}
            </td>
            <td class="expand" style="width: 100%;">
              {{item.Remark  | slice:0:40}}

            </td>
          </tr>
        </table>
        <b [hidden]="!degelhal">בחר הלוואה:</b>
        <table [hidden]="!degelhal" style="position: relative; left: -58px; top:5px; overflow: visible;">

          <tr>
            <td></td>
            <td>
              <thead> סכום</thead>
            </td>
            <td>
              <thead> יתרה</thead>
            </td>
            <td>
              <thead>תאריך </thead>
            </td>
          </tr>

          <tr *ngFor='let item of listloans' (click)='CooseLoan(item.ID)'>
            <td>
              <input type="radio" name="b">
            </td>
            <td>
              {{item.LoanAmount}}
            </td>
            <td>
              {{item.Balance}}
            </td>
            <td>
              {{item.loanDateforien|date:"dd/MM/yy"}}
            </td>
          </tr>
        </table>
        <!-- <button style="width: 80%;" (click)="Save(SCUM5.value,Coin5.value)">שמור</button> -->
        <br>
      </div>
    </div>
  </form>
</div>
    <div id="id019" class="modal1" style="display:none">
      <form class="modal-content1" action="/action_page.php">
        <!-- <div  class="container"> -->
          <span style="position: absolute; left: 14px; top: -1px;left: 30px; top: 11px; font-size: 30px;"
            onclick="document.getElementById('id019').style.display='none'" class="close1" title="סגור">&times;</span>
        <!-- </div> -->
        <div class="container"  style="position: absolute;top: 49px;" style="text-align: center;">
          <div class="row " style="text-align: right;">
            <b style="font-size: medium; position: absolute; right: 2%; top: 3%;">מס שורות:</b>
            <input type="number" #val class="form-control"
              style="position: absolute; right: 14%; top: 3%;  width: 8%;  height: 30px;opacity: 1;padding: .375rem .5rem;">

            <button class="btn" style="position:absolute;   right: 23%; width: 12%; height: 30px;  font-size: small;top: 1.5%;"
              (click)="Addrows1(val.value)">הוסף</button>
          </div>
          <br>
          <br>
          <div id="88" *ngFor="let item of list5; let i = index" fxLayout="row" fxLayoutAlign="center start"
            style="width:  100%; height: 7vh;">
            <label class="container" style="text-align: right;">

              <input type="number" class="form-control"
                style="height: 35px; opacity: 1; position: absolute;  right: 2%; top: 52%; width: 15%;padding: .375rem .5rem;"
                [(ngModel)]="listNew[i].SCUMO" [ngModelOptions]="{standalone: true}"> </label>

            <ng-select class="col-4" style="font-size: medium; position: absolute; left: 57%;"
              (change)="changevalue($event)" [items]="items" bindValue="MAINID" [(ngModel)]="listNew[i].Mainid"
              [ngModelOptions]="{standalone: true}" aria-placeholder="הכנס שם" #val2 bindLabel="Name">
            </ng-select>
            <ng-select class="col-4" style="font-size: medium;   position: absolute; left: 25%;"
              (change)="changevalue2($event)" [(ngModel)]="listNew[i].insertto" [ngModelOptions]="{standalone: true}"
              [items]="listcat" bindValue="CategoryId" aria-placeholder="הכנס שם" #val1 bindLabel="NameCategory">
            </ng-select>

            <img style="width: 30px;height: 30px;position: absolute;  left: 6%;cursor: pointer;" src="assets/1/23.jpg"
              onclick="document.getElementById('id018').style.display='block'" (click)="Open(OneExcel)">
            <label class="container" style="text-align: right;">
              <input type="checkbox" class="form-control"
                style="opacity: 1;width: 18px;height: 18px;position: absolute; top: 9px;left: 24%;"
                (change)="save1(i)">
            </label>
          </div>
          <br>

        </div>
      </form>
    </div>