
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <div id="mySidenav" class="sidenav"> 
    <a  id="blog1"  (click)='Press()'  >  פניות</a> 
 </div>   
  <div id="id36"  class="modal1" style="display: none">
    <span style="position: absolute;left: 44px;" onclick="document.getElementById('id36').style.display='none'" class="close" title="סגור">&times;</span>
    <form class="modal-content1" action="/action_page.php">
   <div  class="row" >
    <div class="col-75">
        <form action="/action_page.php">
          <div class="row">
            <div class="col-50">
                <h3 > פניות</h3>
              <div style="top: 20px;">
          <button type="button" onclick="document.getElementById('id36').style.display='none'" class="btn btn-primary btn-lg" (click)='savepniot()'>שמור</button>

                <button  (click)="addmore()" style="position: absolute;top: 76px; left: 71px; border-radius: 300px;font-weight: bolder; border: 3px solid #888;">+</button>
   <div *ngFor="let item of PniotList">
    <label >תאריך התקשרות    {{item.CraeteDate| date: 'dd/MM/yyyy'}} </label>
  
             <label> תאריך עדכון
                {{item.UpdateDate| date: 'dd/MM/yyyy'}}</label>  
               
                    <label >הערות</label>
                    <textarea type="text" class="Remarks"  [(ngModel)]="item.Remark" [ngModelOptions]="{standalone: true}" style=" width: 540px;  " rows="3" placeholder="הערות"  #Remarks ></textarea>
                    <br/>

                </div>
                   
                  </div>
          </div>
        </div>
            </form>

    </div>
</div>
    </form>