export class MAfkidCount {
    Id: number;
    MAINID: number;
    IdMaslul: number;
    ToatalScumHafkada: number;
    ToatalScumTruma: number;
    Remark: string;
    sendreporton:Number;
    DateBornBigBoy:Date;
     man :boolean;
     NUMYACHIDOT:number;
     StartDate :Date;
     EndDate :Date;
     SHTAR :boolean;
     JoinBy:string
     pail:boolean
    MAINIDMenege :number;
    contact:number;
    numofchildren:number;
    NameMaslul: string;
    constructor()
    {
        this.Id=null;
        this.numofchildren=null;
        this.MAINID=null;
        this.IdMaslul=null;
        this.ToatalScumHafkada=null;
        this.ToatalScumTruma=null;
        this.Remark=null;
        this.sendreporton=null;
        this.SHTAR=null;
        this.EndDate=null;
        this.DateBornBigBoy=null;
        this.StartDate=null;
        this.NUMYACHIDOT=null;
        this.MAINIDMenege=null;
        this.contact=null;
    }
}
