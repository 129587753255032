<img style="width:50px;height: 50px;position: absolute;left: 450px;" src="/assets/1/history.png" (click)="viewhistory()" onclick="document.getElementById('id01').style.display='block'">
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">  
  </head>  
<div id="id01" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: relative; left: -1230px" onclick="document.getElementById('id01').style.display='none'" class="close" title="סגור">&times;</span> 
    </div>
      <button style="position: relative;    top: -500px;left: -108px; width: 88%;" type="submit" onclick="document.getElementById('id01').style.display='none'">סגור</button>
    <div class="container" style="background-color:#f1f1f1">
   
      <table  style="position: relative;top:-520px;width: 920px;left: -90px;">
        <tr>
          <td> <b> <thead>קוד </thead></b></td>
          <td ><b> <thead>קוד בטבלה</thead></b> </td>
          <td > <b> <thead>סכום</thead></b></td>
          <td> <b> <thead>הערות</thead></b></td>
          <td> <b><thead>תאריך יצירה</thead></b> </td>
          <td > <b> <thead>תאריך עידכון</thead></b></td>
          <td  ><b> <thead >שם הטבלה</thead></b> </td>
          </tr>     
          
        <tr *ngFor='let item of list'>
        <td >
        {{item.mainid}}
        </td>
        <td >
          {{item.idintable}}
        </td>
        <td  > 
          {{item.scum}}
        </td>
        <td >
          {{item.remark}}
        </td>
        <td>
          {{item.createdate |date:"dd/MM/yy"}}
        </td>
        <td >
          {{item.updatedate |date:"dd/MM/yy"}}
        </td>
        <td  >
          {{item.typeoperation}}
        </td>
       
        </tr>
        </table>
    </div>
  </form>
</div>
</html>