export class User {
     IdUser:number;
        LastName :string;
        FirstName:string;
         UserPassword :string;
        permission :string;
        constructor()
{
    this.FirstName=null;
    this.LastName="הכל";
    this.UserPassword=null;
    this.permission=null;
    this.IdUser=null;
}
}



