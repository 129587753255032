<meta name="viewport" content="width=device-width, initial-scale=1">
<img [hidden]="update" (click)="Get()" src="/assets/1/note.png" onclick="document.getElementById('id012').style.display='block'" 
  style="width: 20px;     width: 58px; position: relative;left: -463px; height: 51px; top: 72px;height: 51px;  ">
<div id="id012" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute; left: -62px; top: -14px;width: 200px;"
        onclick="document.getElementById('id012').style.display='none'" class="close" title="סגור">&times;</span>
    </div>
    <h1
      style="position: absolute; left: 265px; top: 70px; font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif">
      הוסף משימה</h1>
    <svg [hidden]="!update" *ngIf='update==true' onclick="document.getElementById('id012').style.display='none'"
      (click)="del()" style="position: absolute; ; left: 95px;top: 80px;;width: 32px;height:40px;" width="1em"
      height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
    </svg>
    <input [(ngModel)]="num5" [hidden]="update" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl
      name="something1"
      style="border:none;width: 40px;position: absolute; left: 32px; top: 120px;font-size: 120%;background-color: white;">
    <label [hidden]="update" style="position: absolute;left: 81px; top: 120px; font-size: 120%">/</label>
    <input [hidden]="update" [(ngModel)]="from" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl
      name="something"
      style="border: none; width: 23px; position: absolute; left: 88px;  top: 120px; font-size: 120% ; background-color: white;">
    <div class="btnnum " [hidden]="update" style="position: absolute; left:200px; top:10px; width: 200px">

      <svg onclick="document.getElementById('id012').style.display='none'" (click)="del()"
        style="position: absolute; ;left:-40px;top:70px;width: 32px;height:40px;" width="1em" height="1em"
        viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
      </svg>
      <svg width="1em" height="1em" viewBox="0 0 16 16" (click)="showhokdup(1)" [hidden]="update"
        style="position: absolute;left:-111px;top: 71px;width: 28px;height: 35px;" class="bi bi-caret-right-square-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z" />
      </svg>
      <svg width="1em" height="1em" (click)="showhokdup(-1)" viewBox="0 0 16 16" [hidden]="update"
        style="position: absolute; left:-151px;top:71px ;width: 28px;height: 35px;" class="bi bi-caret-left-square-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.5 10a.5.5 0 0 1-.832.374l-4.5-4a.5.5 0 0 1 0-.748l4.5-4A.5.5 0 0 1 10.5 4v8z" />
      </svg>
      <svg (click)="add()" style="position: absolute;  height: 30px;  ;width: 32px;height: 40px;top:70px;left:-75px"
        width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
        <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      </svg>
    </div>
    <div class="container">
      <label style="position: absolute; top: 470px; left: 545px;" [hidden]="instruction"
        for="uname"><b>הוראות</b></label>
      <textarea #INSTRUCTION data-type="message" [hidden]="instruction" class=" form-control"
        [(ngModel)]="task1.Instructions" [ngModelOptions]="{standalone: true}" rows="4" placeholder="הוראות"
        style="position: absolute;left: 101px; width: 500px; top: 493px;  height: 78px;direction: rtl;"
        name="uname"></textarea>
      <label style="position: absolute; top: 159px; left: 569px;" for="uname"><b>עובד</b></label>
      <select class="form-control" [(ngModel)]="task1.UserName" [ngModelOptions]="{standalone: true}"
        style="position: absolute; top: 182px; left: 403px; width: 200px;  background-color: white ; ">
        <option *ngFor='let item of listuser'>{{item.LastName}}</option>
      </select>
      <br>
      <br>
      <label style="position: absolute; left: 541px; top: 291px;" for="psw"><b>קטגוריה</b></label>
      <select [(ngModel)]="task1.NameCategory" [ngModelOptions]="{standalone: true}" #cat (change)="saveval1(cat.value)"
        type="text" class=" form-control" style="  position: absolute; left: 403px; width: 200px; top: 314px;">
        <option *ngFor='let item of list1'>{{item.NameCategory}}</option>
      </select>
      <label> <input type="checkbox" *ngIf='task1.remember==null'
          style="position: absolute;top: 702px; left: 493px; block-size: 19px;" (click)="rememberEveryMonth()"><input
          type="checkbox" *ngIf='task1.remember==false'
          style="position: absolute;top: 702px; left: 493px; block-size: 19px;" (click)="rememberEveryMonth()"><input
          type="checkbox" *ngIf='task1.remember==true' checked="checked"
          style="position: absolute;top: 702px; left: 493px; block-size: 19px;" (click)="rememberEveryMonth()" #month><b
          style="position: absolute;left: 512px; top: 698px; ">:הזכר כל </b></label>
      <input type="number" #each [(ngModel)]="task1.each" [ngModelOptions]="{standalone: true}"
        style="  position: absolute; width: 40px;left: 70%; top: 80.3%; " #month>
      <br>
      <br>
      <label style="position: absolute;top: 159px; left: 190px;" for="psw"><b> תאריך תזכורת </b></label>
      <input type="date" class=" form-control" #d (ngModelChange)="task1.Datetezcoret= $event"
        [ngModel]="task1.Datetezcoret|date:'y-MM-dd'" [ngModelOptions]="{standalone: true}"
        style="position: absolute;left: 97px; width: 200px; top: 182px;" name="psw">
      <p [hidden]="!booldate" style="position: absolute;left: 163px; top: 290px;"> <b> הכנס תאריך החזר</b></p>
      <input [hidden]="!booldate" (ngModelChange)="task1.date= $event" [ngModel]="task1.date|date:'y-MM-dd'"
        [ngModelOptions]="{standalone: true}" type="date" class=" form-control"
        style="position: absolute;left: 97px; top: 314px;" #d1>
      <p [hidden]="!boolscum" style="position: absolute;left: 152px; top: 427px;"> <b> הכנס תאריך משיכה</b></p>
      <input type="date" (ngModelChange)="task1.date= $event" [ngModel]="task1.date|date:'y-MM-dd'"
        [ngModelOptions]="{standalone: true}" [hidden]="!boolscum" class=" form-control"
        style="position: absolute;left: 97px; top: 450px;" #d1>
      <p style="position: absolute;left: 248px; top: 291px;" [hidden]="both"><b [hidden]="both">הערות</b></p>
      <p style="position: absolute;left: 215px; top: 292px;" [hidden]="!boolscum"><b>הכנס סכום</b></p>
      <input [hidden]="!boolscum" class=" form-control" style="position: absolute;left: 97px; top: 314px;"
        [(ngModel)]="task1.scum" [ngModelOptions]="{standalone: true}" type="number" #scum>
      <label [hidden]='!both' style="position: absolute;left: 530px; top: 426px;" for="psw"><b> סוג מטבע</b></label>
      <select [hidden]='!both' value="{{task1.coinName}]" class=" form-control"
        style=" position: absolute;left: 403px; top: 450px;" placeholder="סוג מטבע" #Coin>
        <option *ngFor='let item of listcoin'>{{item.name}}</option>
      </select>

      <input [hidden]="both" class=" form-control" style="position: absolute;left: 97px; top: 314px;"
        [(ngModel)]="task1.remark" [ngModelOptions]="{standalone: true}" type="text" #notice>
      <label style="position: absolute; top: 564px; left: 545px;" [hidden]="!instruction"
        for="uname"><b>הוראות</b></label>
      <textarea #INSTRUCTION data-type="message" [hidden]="!instruction" class=" form-control"
        [(ngModel)]="task1.Instructions" [ngModelOptions]="{standalone: true}" [hidden]="!instruction"
        class=" form-control" rows="4"
        style="position: absolute;left: 101px; width: 500px; top: 587px;  height: 68px;direction: rtl;"
        name="uname"></textarea>
      <label [hidden]="!expir" style="position: absolute;left: 500px; top: 426px;" for="psw"><b>תוקף הכרטיס </b></label>
      <input [hidden]="!expir" type="date" (ngModelChange)="task1.date= $event" [ngModel]="task1.date|date:'y-MM-dd'"
        [ngModelOptions]="{standalone: true}" class=" form-control" #expeir
        style=" position: absolute;left: 403px; top: 450px;" placeholder="תוקף הכרטיס" name="psw">

      <label [hidden]="!task1.statustask" style="position: absolute; left: -180px;top: 26px">
        <label>
          <input type="checkbox" (click)="ChangeStatus()" checked="checked"
            style="position: absolute;top: 707px; left: 673px; block-size: 18px;"><b
            style="position: absolute; top: 702px; left: 721px; ">בוצע</b></label>
      </label>
      <label [hidden]="task1.statustask" style="position: absolute; left: -180px;top: 26px">
        <label>
          <input type="checkbox" (click)="ChangeStatus()"
            style="position: absolute;top: 707px; left: 673px; block-size: 18px;"> <b
            style="position: absolute; top: 702px; left: 721px; ">בוצע</b></label>
      </label>
    </div>
    <button onclick="document.getElementById('id012').style.display='none'" (click)="save()"
      style="position: absolute; top: 767px; left: 100px; width: 399px; font-size: 102%; height: 46px;"
      type="submit">אישור</button>
    <button type="button" onclick="document.getElementById('id012').style.display='none'" class="cancelbtn"
      style="position: absolute; left: 506px; top: 767px; width: 96px; font-size: 100%; height: 46px;">ביטול</button>
  </form>
</div>