import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: HttpClient) { }
  viewhistory(pro:number,  idmafkid:Number, id:string,hafkada:boolean): any {
    return this.http.put(NamesService.serverUrl +"/History/ViewHistory",{name:pro,id: idmafkid,hokh: id,h:hafkada}).toPromise().then(
        response => {
          return response;
        }) 
      .catch(v => { return false; });}
      // viewhistory(pro:number, mainid:number, idmafkid:Number, id:string): any {
      //   return this.http.put(NamesService.serverUrl +"/History/ViewHistory",{name:pro,m1:mainid,id: idmafkid,hokh: id}).toPromise().then(
      //       response => {
      //         return response;
      //       }) 
      //     .catch(v => { return false; });}
}
