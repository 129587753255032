import { Component, OnInit } from '@angular/core';
import { KupaService } from 'src/app/services/kupa.service';
import { KupaCountcs } from 'src/app/classes/kupa-countcs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Categories } from 'src/app/classes/categories';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
// import { Label } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';
import { cash } from 'src/app/classes/cash';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { Groups } from 'src/app/classes/groups';
import { group } from 'console';
@Component({
  selector: 'app-kupamenage',
  templateUrl: './kupamenage.component.html',
  styleUrls: ['./kupamenage.component.css']
})
export class KupamenageComponent implements OnInit {
  listcoins: any;
  viewdiv: boolean;

  constructor(private kupa: KupaService, private router: Router, private maagar: MaagarMenageService, private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }
  //#region 
  futture: boolean;
  PERMISSION: any;
  iduser: any;
  columnDefs: any;
  localeText: any;
  rowSelection: string;
  rowData: any;
  listUndercat: any;
  listGroups: Array<Groups>=new  Array<Groups>();
  // public pieChartLabels: Label[] = [['הפקדות'], ['החזרים'], ['הלוואות'], 'תרומות'];
  public pieChartData: number[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  filter: boolean;
  dolar: boolean;
  s: boolean = true;
  list;
  list1;
  myDate;
  list2;
  kod: number;
  value; value1; value2;
  degel: boolean;
  d: Date = new Date();
  year: number;
  month: number;
  valcat;
  cat: Categories = new Categories();
  cash: cash = new cash();
  kupacount: KupaCountcs = new KupaCountcs();
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartColors = [
    {
      backgroundColor: ['#f44336', 'gray', 'lightgray', 'rgb(177, 111, 111)'],
    },
  ];
  //#endregion
  ngOnInit() {
    this.futture = true;
    this.myDate = new Date();
    this.year = this.d.getFullYear();
    this.month = this.d.getMonth() + 1;
    this.GetKupa();
    this.SHOWGROUPS();
    this.degel = false;
    this.filter = false;
    this.columnDefs = [
      {
        headerName: 'תאריך לועזי', field: 'DateOperation', filter: "agNumberColumnFilter", width: 100, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
      }
      },
      { headerName: 'סכום', field: 'Amount', filter: "agNumberColumnFilter", width: 100 },
      { headerName: 'מטבע', field: 'CoinName', filter: "agNumberColumnFilter", width: 100 },

      { headerName: 'פרטים', field: 'Details', filter: "agNumberColumnFilter", width: 200 },
      { headerName: 'קטגוריה', field: 'namecat', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'תת קטגוריה', field: 'UnderCategoryName', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'קבוצה', field: 'groupname', filter: "agTextColumnFilter", width: 110 },
      { headerName: 'נכנס', field: 'AmountEnter', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'יכנס', field: 'AmountFuture', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'יצא', field: 'Moneycameout', filter: "agTextColumnFilter", width: 80 },
      { headerName: 'ייצא', field: 'Moneycameoutfuture', filter: "agTextColumnFilter", width: 80 },
    ];
    this.pieChartData = [3000, 4000, 4000, 2000]
  }
  //#region slice

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  changeLabels() {
    const words = ['hen', 'variable', 'embryo', 'instal', 'pleasant', 'physical', 'bomber', 'army', 'add', 'film',
      'conductor', 'comfortable', 'flourish', 'establish', 'circumstance', 'chimney', 'crack', 'hall', 'energy',
      'treat', 'window', 'shareholder', 'division', 'disk', 'temptation', 'chord', 'left', 'hospital', 'beef',
      'patrol', 'satisfied', 'academy', 'acceptance', 'ivory', 'aquarium', 'building', 'store', 'replace', 'language',
      '#f44336eem', 'honest', 'intention', 'silk', 'opera', 'sleep', 'innocent', 'ignore', 'suite', 'applaud', 'funny'];
    const randomWord = () => words[Math.trunc(Math.random() * words.length)];
  }
  addSlice() {
    this.pieChartData.push(400);
    this.pieChartColors[0].backgroundColor.push('rgba(196,79,244,0.3)');
  }
  removeSlice() {
    this.pieChartData.pop();
    this.pieChartColors[0].backgroundColor.pop();
  }
  changeLegendPosition() {
    this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }
  plusSlides(year1: number) {
    this.year = this.year + year1;
    this.ShowKupaOperation();
  }
  showbalance(date: Date) {
    ;;
    this.kupa.showbalance(date).then(
      response => {
        this.kupacount = response;
        this.pieChartData = [this.kupacount.TzoverOfHafkadot, this.kupacount.TzoverOfHeczer, this.kupacount.TzoverOfLoans, this.kupacount.TzoverOfTrumot]
      })
  }
  //#endregion
  //#region bool

  dollar() {
    this.s = false;
    this.dolar = true;
    this.pieChartData = [this.kupacount.TzoverOfHafkadotdolar, this.kupacount.TzoverOfHeczerdolar, this.kupacount.TzoverOfLoansdolar, this.kupacount.TzoverOfTrumotdolar]
  }
  filterlist() {
    this.filter = !this.filter;

  }
  valuechange2(value) {
    this.value2 = value;

  }
  showbutton() {
    this.degel = true;
  }
  sh() {
    this.s = true;
    this.dolar = false;
    this.pieChartData = [this.kupacount.TzoverOfHafkadot, this.kupacount.TzoverOfHeczer, this.kupacount.TzoverOfLoans, this.kupacount.TzoverOfTrumot]
  }
  valuechange(value) {
    this.value = value;
  }
  valuechange1(value) {
    this.value1 = value;
  }
  changeval(val: string) {
    if (val == "משיכת פקדון" || val == "עמלות בנק" || val == "הלוואה" || val == "פרסום") {
      ;;
      this.futture = false
    }
    else {
      ;;

      this.futture = true


    }
  }
  //#endregion

  //#region show
  SHOWGROUPS() {
    this.maagar.GetGroups().then(res => {
      ;
      this.listGroups = res;
      this.Undercat();
    })
  }
  showdiv()
  {
    this.viewdiv=true;
    this.filter=false;
    this.kupa.ShowOpConnect();
  }
  Undercat() {
    this.maagar.Undercat().then(res => {
      this.listUndercat = res;
      this.SowCoins();
    })
  }
  SowCoins() {
    this.listcoins = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoins == null) {
    this.maagar.GetCoins().then(res => {
      this.listcoins = res;
      sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoins));

    this.showcat();
  })}
  else{
    this.showcat();

  }
  }
  view(event)
  {
this.cash=event.data;
;
document.getElementById('id08').style.display='block';
  }
  buildgrid() {
    this.localeText = LOCALE_TEXT;
    this.rowSelection = 'single';
    this.rowData = this.list;
    this.columnDefs = this.columnDefs;
  }
  ShowKupaOperation() {
    this.kupa.ShowKupaOperation(this.year, this.month).then(
      response => {
        this.list = response;
        debugger
        if (this.list!=null){
        this.list.forEach(element => {
          element['UnderCategoryName'] = this.listUndercat && this.listUndercat.find(a => a.Id == element.UnderCategory) ? this.listUndercat.find(a => a.Id == element.UnderCategory).name : '';
          element.groupname = this.listGroups && this.listGroups.find(a => a.Id == element.group) ? this.listGroups.find(a => a.Id == element.group).name : '';
         element.groupname=   element.groupname.replace(/\s/g, "")
          element.CoinName = this.listcoins && this.listcoins.find(a => a.Id == element.Coin) ? this.listcoins.find(a => a.Id == element.Coin).name : '';

          element.namecat = this.list2 && this.list2.find(a => a.CategoryId == element.Category) ? this.list2.find(a => a.CategoryId == element.Category).NameCategory : '';
        })}
        ;
        this.buildgrid()
      })
  }
  showcat() {
    this.maagar.Showcat("קופה").then(res => {
      this.list2 = res;
      this.ShowKupaOperation();

    })
  }
  ShowKupaOperationall() {
    this.kupa.ShowKupaOperationall(this.year, this.month).then(
      response => {
        this.list1 = response;
        
        this.list1.forEach(element => {
          element['UnderCategoryName'] = this.listUndercat && this.listUndercat.find(a => a.Id == element.UnderCategory) ? this.listUndercat.find(a => a.Id == element.UnderCategory).name : '';
          element.groupname = this.listGroups && this.listGroups.find(a => a.Id == element.group) ? this.listGroups.find(a => a.Id == element.group).name : '';
          element.groupname=   element.groupname.replace(/\s/g, "")
          element.CoinName = this.listcoins && this.listcoins.find(a => a.Id == element.Coin) ? this.listcoins.find(a => a.Id == element.Coin).name : '';
          element.namecat = this.list2 && this.list2.find(a => a.CategoryId == element.Category) ? this.list2.find(a => a.CategoryId == element.Category).NameCategory : '';
        })
        this.buildgrid()
      })
  }
  GetKupa() {
    this.kupa.getkupa().then(
      response => {
        this.kupacount = response;
        this.pieChartData = [this.kupacount.TzoverOfHafkadot, this.kupacount.TzoverOfHeczer, this.kupacount.TzoverOfLoans, this.kupacount.TzoverOfTrumot]

      })
  }
  currentSlide(month: number) {
    this.month = month;
    this.ShowKupaOperation();
  }
  //#endregion
  //#region save
  changeUNDER() { }
  changegroup() {
  }
  Add()
  {
this.cash=new cash();
this.cash.DateOperation=this.myDate

  }
  saveall() {
    ;
  this.cash.UnderCategory = this.listUndercat && this.listUndercat.find(a => a.name ==this.cash.UnderCategoryName ) ? this.listUndercat.find(a => a.name ==this.cash.UnderCategoryName).Id : '';
  this.cash.group = this.listGroups && this.listGroups.find(a => a.name.includes(this.cash.groupname) ) ? this.listGroups.find(a => a.name.includes(this.cash.groupname)).Id : null;
  this.cash.Category= this.list2 && this.list2.find(a => a.NameCategory == this.cash.namecat) ? this.list2.find(a => a.NameCategory == this.cash.namecat).CategoryId : '';
  this.cash.Coin = this.listcoins && this.listcoins.find(a => a.name == this.cash.CoinName) ? this.listcoins.find(a => a.name == this.cash.CoinName).Id : null;

  if(this.cash.Id==null|| this.cash.Id==0)
  {
  this.kupa.AddKupa(this.cash)
}
else{
  this.kupa.UpdateKupa(this.cash);
}
  }
}

