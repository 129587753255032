<table id='customers'>
  <tr class='row'>
    <td class='col-2'>
      <thead>קוד</thead>
    </td>
    <td class='col-4'>
      <thead>  סוג כרטיס</thead>
    </td>
    <td class='col-3'>
        <thead> אחוז עמלה </thead>
      </td>
      <td class='col-1'>
        <thead> שמור</thead>
      </td>


  </tr>
  <tr class='row' *ngFor='let item of list' >
    <td class='col-2'>

      {{item.Id}}
    </td>
    <td class='col-3'>
      <input type="text" value="{{item.name}}" #name>
    </td>
    <td class='col-5'>
<input type="number" value="{{item.price}}" #num> 
        
      </td>
      <td>
        <button (click)="save(item.Id, name.value, num.value)" class="button button5">V</button>
      </td>
      </tr>
    </table>
    <button class="button button3">+</button>
