import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WaitingLIst } from '../classes/waiting-list';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class WaitingService {

  constructor(private http: HttpClient) { }
  ShowWaiting(): any {
    return this.http.get(NamesService.serverUrl +"/WaitingList/GetWaitingList").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }    
  Del(id: number) {
    return this.http.get(NamesService.serverUrl +"/WaitingList/Del?id="+id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  addwaitdesire(w: WaitingLIst) {
    return this.http.post(NamesService.serverUrl +"/WaitingList/PostWaiting",w).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  update(w: WaitingLIst) {
    return this.http.put(NamesService.serverUrl +"/WaitingList/updateWaiting",w).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  ShowWaitbymainid(id: number) :any{
    return this.http.get(NamesService.serverUrl +"/WaitingList/GetWaitByMainid?id="+id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
      }
  ShowWait(id:number):any {
    return this.http.get(NamesService.serverUrl +"/WaitingList/GetWait?id="+id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
  }
  // PutWaitingok(id: number, date: Date, scum: number,b:boolean) {
  //   return this.http.put(NamesService.serverUrl +"/WaitingList/PutWaitingok",{id:id, d:date,s:scum,b1:b}).toPromise().then(
  //     response => {
  //      return  response;
  //     })
  // }
}


