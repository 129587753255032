export class Contant {
    
     MAINID:number;
     ID :string;
     Toar :string;
     FamilyName :string
     FirstName :string
     Phone :string
     Adress :string
     CellPhone1:string
     CellPhone2 :string
     Fax :string
     City :number
     Mikud :string
     Remarks :string
     Email :string
     leader :number
     senddocumentsto:string
     group :number
     NumOfJoiners :number
     NumOfYecidot :number
     chov :number
  aria: number;
constructor(){
      
     this.MAINID=null; 
     this.ID=null; 
     this.Toar="הרב"; 
     this.FamilyName; 
     this.FirstName=null; 
     this.Phone=null; 
     this.Adress=null; 
     this.CellPhone1=null; 
     this.CellPhone2=null; 
     this.Fax=null; 
     this.City=null; 
     this.Mikud=null; 
     this.Remarks=null; 
     this.Email=null; 
     this.NumOfYecidot=null; 
     this.NumOfJoiners=null; 
 this.group=null;
 this.leader=null; 
 this.chov=null;
 this.senddocumentsto="מייל";
     }
 }