import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { HokgviaService } from 'src/app/services/hokgvia.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MafkidService } from 'src/app/services/mafkid.service';
import { MAfkidCount } from 'src/app/classes/mafkid-count';
import { Names } from 'src/app/classes/names';
import { NamesService } from 'src/app/services/names.service';
import { Loans } from 'src/app/classes/loans';
import { LoansService } from 'src/app/services/loans.service';
import { error } from 'protractor';
@Component({
  selector: 'app-viewhoktgvia',
  templateUrl: './viewhoktgvia.component.html',
  styleUrls: ['./viewhoktgvia.component.css'],

})
export class ViewhoktgviaComponent implements OnInit {
  listnames: any;
  hec: boolean;
  haf: boolean;
  rowSelection: string;
  IdMasv: any;
  Showmasav: boolean = false;
  Error: boolean = false;
  gridApi: any;
  gridApi1: any;
  listloans: any;
  listmafkids: any;
  constructor(private hok: HokgviaService, private hok1: HokMenageService, private maf: MafkidService, private route: ActivatedRoute, private router: Router, private name: NamesService, private l: LoansService) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
      if (params.idmasav != null) {
        this.IdMasv = params.idmasav;
        this.Showmasav = true;
      }
    })
  }
  //#region 
  listhok;
  columnDefs;
  zerohaf: boolean = true;
  zero: boolean = true;
  zerohec: boolean = true
  gridOptions;
  listhokheczer;
  @Input() PERMISSION;
  @Input() iduser;
  loan: Loans = new Loans();
  defaultColDef;
  rowData: Array<any>;
  columnDefs1;
  gridOptions1;
  mafkid: MAfkidCount = new MAfkidCount();
  defaultColDef1;
  rowData1: Array<any>;
  listhokhafkadot;
  myDate: Date = new Date();
  totalhaf: number = 0;
  totalheczer: number = 0;
  //#endregion
  ngOnInit() {
    this.haf = true;
    this.hec = true;
    this.myDate = new Date();
    this.shownames();
    this.haf = true;
    this.hec = true;
    this.columnDefs = [

      { headerName: 'שם מפקיד', field: 'Name', width: 160, filter: "agTextColumnFilter", sort: 'asc' },
      // {headerName: 'עבור חשבון', field: 'NameM',width: 160,filter: "agTextColumnFilter",editable: true},
      { headerName: 'עבור', field: 'NameL', sortable: true, filter: "agTextColumnFilter", sort: 'asc', width: 160 },
      {
        headerName: 'תאריך לועזי', field: 'DateForiegnHAfkada', filter: "agTextColumnFilter", width: 100, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך הפקדה', field: 'DateHafkada', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'סכום ההפקדה', field: 'Amount', filter: "agNumberColumnFilter", width: 100,sortable:true },
      { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 100,sortable:true },
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: "agNumberColumnFilter", width: 100 ,sortable:true},
      { headerName: 'מטרה', field: 'TypeOperation', filter: "agTextColumnFilter", width: 90 },
      { headerName: 'סטטוס הפקדה', field: 'StatusHafkada', filter: "agTextColumnFilter", width: 90 },
      { headerName: 'אסמכתא', field: 'ASMACTA', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'הערה', field: 'Notice', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'מס"ב', field: 'MASAV', filter: "agTextColumnFilter", width: 60 },
      // {headerName: 'סוג הו"ק', field: 'TypeHok',filter: true ,width: 100},
      { headerName: 'נוצר ע"י', field: 'Createby', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'עודכן ע"י', field: 'Updateby', filter: "agTextColumnFilter", width: 100 }
    ];
    this.columnDefs1 = [
      { headerName: 'שם מחזיר', field: 'Name', sortable: true, filter: "agTextColumnFilter", sort: 'asc', width: 160 },
      { headerName: 'שם לווה', field: 'NameL', sortable: true, filter: "agTextColumnFilter", sort: 'asc', width: 160 },
      // {headerName: 'עבור הלוואה', field: 'NameM',width: 160,filter: "agTextColumnFilter",editable: true},
      {
        headerName: 'תאריך לועזי', field: 'DateForiegnHeczer', filter: "agTextColumnFilter", width: 110, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך החזר', field: 'DateHeczer', filter: "agTextColumnFilter", width: 110 },
      { headerName: 'סכום', field: 'Scum',  filter: "agNumberColumnFilter" , width: 100,sortable:true },
      { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 100 ,sortable:true},
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: "agNumberColumnFilter", width: 110 ,sortable:true},

      { headerName: 'סטטוס החזר', field: 'StatusHeczer', filter: "agTextColumnFilter", width: 110 },
      { headerName: 'צורת החזר', field: 'TypeOperation', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'הערה', field: 'Notice', filter: "agTextColumnFilter", width: 100 },
    ];
  }
  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  onGridReady1(event: any) {
    this.gridApi1 = event.api;
  }
  excel() {

    this.gridApi.exportDataAsCsv(this.gridApi.rowModel.rowsToDisplay);
  }
  excel1() {
    this.gridApi1.exportDataAsCsv(this.gridApi1.rowModel.rowsToDisplay);
  }
  shownames() {
    this.name.ShowNames().then(res => {
      this.listnames = res
      if (this.IdMasv != null) {
        this.showhafMasav(this.IdMasv);
      }
      else {
        this.showHaf(this.myDate);
      }
    })
  }
  showheczerHOK(myDate: Date) {
    this.totalheczer = 0;
    this.hok.ViewHeczerHok(myDate).then(res => {
      this.listhokheczer = res;
      this.listhokheczer.forEach(element => {
        this.totalheczer += element.Scum + element.Amla;
        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        element['SUMTOGVIA'] = element.Scum + element.Amla;
        let s = this.listloans?.find(b => b.ID == element.IdLoan)?.MAINID
        if (s) {
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';
        }

      });


      this.buildgrid1();
    });
  }
  NotShowmasav() {
    this.Showmasav = false;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(["/gvia/" + this.iduser, this.PERMISSION]);
    })
  }
  buildgrid() {
    this.columnDefs = this.columnDefs,
      this.rowSelection = 'single',
      this.rowData = this.listhokhafkadot;
    if (this.totalhaf > 0) {
      this.zerohaf = false;
      this.zero = false;
    }
    else {
      this.zerohaf = true;
    }
  }
  buildgrid1() {
    this.columnDefs1 = this.columnDefs1,
      this.rowSelection = 'single',
      this.rowData1 = this.listhokheczer;
    if (this.totalhaf > 0) {
      this.zero = false;
      this.zerohec = false
    }
    else {
      this.zerohec = true
    }
  }
  openmafkid(event) {
    this.maf.getmafkid(event.data.MafkidID).then(res => {
      this.mafkid = res
      // this.router.navigate(["/Hafkadotall/", this.iduser, this.PERMISSION, this.mafkid.MAINID, event.data.MafkidID]);;

      const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.mafkid.MAINID, event.data.MafkidID]));
      window.open(url, '_blank');
    })
    // })
  }
  openloan(event) {
    this.l.getloans(event.data.IdLoan).then(res => {
      this.loan = res;
      const url = this.router.serializeUrl(this.router.createUrlTree(["/loans/" + this.PERMISSION, this.iduser, this.loan.MAINID, event.data.IdLoan]));
      window.open(url, '_blank');
    });
  }
  showhafMasav(id: any) {
    this.showheczermasav(id);
    this.totalhaf = 0
    this.maf.GetAllMaf().then(res => {
      this.listmafkids = res;
    this.hok.ViewHafkadotMasav(id).then(res => {
      ;
      this.listhokhafkadot = res;
      this.listhokhafkadot.forEach(element => {
        this.totalhaf += element.Amount + element.Amla;
        element['SUMTOGVIA'] = element.Amount + element.Amla;
        debugger
        let s = this.listmafkids?.find(b => b.Id == element.MafkidID)?.MAINID
        if (s) {
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';
        }
        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
      });
      this.buildgrid();
    })
  })

  }
  showheczermasav(id: any) {
    this.totalheczer = 0;
    this.l.getloansAll().then(res => {
      this.listloans = res;
    this.hok.ViewHeczerMasav(id).then(res => {
      this.listhokheczer = res;
      this.listhokheczer.forEach(element => {
        this.totalheczer += element.Scum + element.Amla;
        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        element['SUMTOGVIA'] = element.Scum + element.Amla;
        let s = this.listloans?.find(b => b.ID == element.IdLoan)?.MAINID
        if (s) {
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';
        }
      });
      this.buildgrid1();
    });
  });
}
  showAlsoerrors(date: Date) {

    if (this.Error == false) {
      this.totalhaf = 0;
      ;
      this.Error = true;
      this.showheczerHOKWithError(date);
      this.hok.ViewHafkadotHokWithErrors(date).then(res => {
        this.listhokhafkadot = res;
        this.listhokhafkadot.forEach(element => {
          this.totalhaf += element.Amount + element.Amla;
          element['SUMTOGVIA'] = element.Amount + element.Amla;

          element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        });
        this.buildgrid();
      })
    }
    else {
      this.Error = false;
      this.showhafHOK(date);
    }
  }
  showheczerHOKWithError(date: Date) {

    this.Error = true;;

    this.totalheczer = 0;
    this.hok.ViewHeczerHokWithErrors(date).then(res => {
      this.listhokheczer = res;
      this.listhokheczer.forEach(element => {
        this.totalheczer += element.Scum + element.Amla;
        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        element['SUMTOGVIA'] = element.Scum + element.Amla;
      });
      this.buildgrid1();
    });
  }
  showhafHOK(date: Date) {
    
    (document.getElementById("e") as HTMLInputElement).checked = false;
    this.Error = false;
    this.totalhaf = 0;
    this.showloans(date)
    this.hok.ViewHafkadotHok(date).then(res => {
      this.listhokhafkadot = res;
      this.listhokhafkadot.forEach(element => {
        this.totalhaf += element.Amount + element.Amla;
        element['SUMTOGVIA'] = element.Amount + element.Amla;
        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        debugger
        let s = this.listmafkids?.find(b => b.Id == element.MafkidID)?.MAINID
        if (s) {
          element['NameL'] = this.listnames && this.listnames.find(a => a.MAINID == s) ? this.listnames.find(a => a.MAINID == s).FamilyName + ' ' + this.listnames.find(a => a.MAINID == s).FirstName : '';
        }
      });
      this.buildgrid();

    })
  }
  showloans(Date) {
    this.l.getloansAll().then(res => {
      this.listloans = res;
      this.showheczerHOK(Date);
    })
  }
  showHaf(Date) {

    this.maf.GetAllMaf().then(res => {
      this.listmafkids = res;
      this.showhafHOK(Date);
    })
  }
  send(date: Date) {
    if (!this.Error) {
      if (this.haf && this.hec) {
        this.hok.sendtomasav(date);
      }
      else {
        if (this.haf) {
          this.hok.sendtomasavhaf(date);
        }
        else {
          this.hok.sendtomasavhec(date);

        }
      }
    }
    else {
      alert("לא ניתן לשלוח שגויים נא לטפל לפני או לשלוח בלעדיהם")
    }
  }
  showhaf() {
    this.haf = !this.haf;
  }
  showheczer() {
    this.hec = !this.hec;
  }
  gvia(s: string) {
    this.hok1.UpdateListHok(this.listhokhafkadot, s);
  }
}