import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  ShowAll() {
    return this.http.get(NamesService.serverUrl +"/Report/ShowAll").toPromise().then(
      response => {
       return  response;
      }) 
  }
  ShowAllFutureTzefi() :any{
    return this.http.get(NamesService.serverUrl +"/Report/ShowAllFutureTzefi").toPromise().then(
      response => {
       return  response;
      })  }
  ShowAllLoans() :any{
  
  return this.http.get(NamesService.serverUrl +"/Report/ShowAllLoans").toPromise().then(
    response => {
     return  response;
    })

  }
  ShowAllTrumaHac() :any{
    return this.http.get(NamesService.serverUrl +"/Report/GetHecTrumaAll").toPromise().then(
      response => {
       return  response;
      })
  }
  ShowAllTrumaHaf() :any{
    return this.http.get(NamesService.serverUrl +"/Report/ShowAllTruma").toPromise().then(
      response => {
       return  response;
      })
       }
  ShowAllHec():any {
    return this.http.get(NamesService.serverUrl +"/Report/GetHeczerAll").toPromise().then(
      response => {
       return  response;
      })
     }
  ShowAllHaf() :any{
    return this.http.get(NamesService.serverUrl +"/Report/GetHafkadotAll").toPromise().then(
      response => {
       return  response;
      })
    }
 

  constructor(private http: HttpClient) { }
  ShowJoiners(numofyears:number): any {
    return this.http.get(NamesService.serverUrl +"/Report/GetJoiners?id="+numofyears).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });}
    calculate(arr:number[],arr1:number[],year:number):any {
      return this.http.post(NamesService.serverUrl +"/Report/calculate",{arrjoiners:arr,arrloans:arr1,y:year}).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });}  
    exportexcel(numofyears:number) {
      return this.http.get(NamesService.serverUrl +"/Report/exportexcel?id="+numofyears).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });}  
      getNumbers(year: number):any {
        return this.http.get(NamesService.serverUrl +"/Report/getNumbers?id="+year).toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });}  
    ShowJoinersMis(numofyears:number): any {
      return this.http.get(NamesService.serverUrl +"/Report/GetJoinerssum?id="+numofyears).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });}  
      GetJoinersDrag(numofyears:number): any {
        return this.http.get(NamesService.serverUrl +"/Report/GetJoinersDrag?id="+numofyears).toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });}
        GetJoinersDragsum(numofyears:number): any {
          return this.http.get(NamesService.serverUrl +"/Report/GetJoinersDragsum?id="+numofyears).toPromise().then(
            response => {
             return  response;
            })
          .catch(v => { return false; });}  
          NumOfJoinersWithChildren(numofyears:number): any {
            return this.http.get(NamesService.serverUrl +"/Report/NumOfJoinersWithChildren?id="+numofyears).toPromise().then(
              response => {
               return  response;
              })
            .catch(v => { return false; });} 
            ShowHafkadot(numofyears:number): any {
              return this.http.get(NamesService.serverUrl +"/Report/Gethafkadot?id="+numofyears).toPromise().then(
                response => {
                 return  response;
                })
              .catch(v => { return false; });} 
              ShowJoinersm(numofyears:number): any {
                return this.http.get(NamesService.serverUrl +"/Report/GetJoinersmonth?id="+numofyears).toPromise().then(
                  response => {
                   return  response;
                  })
                .catch(v => { return false; });}
              
                ShowJoinersMism(numofyears:number): any {
                  return this.http.get(NamesService.serverUrl +"/Report/GetJoinerssummonth?id="+numofyears).toPromise().then(
                    response => {
                     return  response;
                    })
                  .catch(v => { return false; });}  
                  GetJoinersDragm(numofyears:number): any {
                    return this.http.get(NamesService.serverUrl +"/Report/GetJoinersDragmonth?id="+numofyears).toPromise().then(
                      response => {
                       return  response;
                      })
                    .catch(v => { return false; });}
                    GetJoinersDragsumm(numofyears:number): any {
                      return this.http.get(NamesService.serverUrl +"/Report/GetJoinersDragsummonth?id="+numofyears).toPromise().then(
                        response => {
                         return  response;
                        })
                      .catch(v => { return false; });}  
                      NumOfJoinersWithChildrenm(numofyears:number): any {
                        return this.http.get(NamesService.serverUrl +"/Report/NumOfJoinersWithChildrenmonth?id="+numofyears).toPromise().then(
                          response => {
                           return  response;
                          })
                        .catch(v => { return false; });} 
                        ShowHafkadotm(numofyears:number): any {
                          return this.http.get(NamesService.serverUrl +"/Report/Gethafkadotmonth?id="+numofyears).toPromise().then(
                            response => {
                             return  response;
                            })
                          .catch(v => { return false; });} }
                

              
    

            