
<div class="intp" style="direction: rtl;border-radius: 12px;position: absolute; left: 12px;font-weight: bold;background-color: gray;width: 178px;height: 750px;">

  <p  style="position: absolute;left:75px;top: 20px;" class="labl"> הערה </p>
    <input type=" text" style="height:30px;text-align:start;position: absolute;left: 25px;top: 40px;" [(ngModel)]="maf.Remark" #kodhok >
    <div >
    <p style="position: absolute; top: 268px;left: 69px;"> מסלול</p>  
    <select #val style="width: 80% ;height: 30px;position: absolute; top: 292px;left:19px;"  [(ngModel)]="maf.NameMaslul" [ngModelOptions]="{standalone: true}" ><option *ngFor='let item of listmaslul'>{{item.remark}}</option></select>
    <!-- <input type="number" value="{{maf.IdMaslul}}" #typehok > -->
  <p class="labl" style="position: absolute;  left: 59px; top: 380px;"> בחר נציג</p>
  <ng-select  style="position: absolute; width: 141px;   left: 19px; top: 408px;direction: rtl; background-color: gray; color: white;" 
    [items]="listL" bindValue="MAINID" #val bindLabel="FamilyName" [(ngModel)]="maf.contact" [ngModelOptions]="{standalone: true}"  >
    <!-- יופיע רק אם הקוד מסלול שונה מריק וכן בעדכון צריך לבדוק -->
    </ng-select>
    <p class="labl" style="position: absolute;  left: 45px; top: 330px;">  אחראי הפקדה</p>
    <ng-select  style="position: absolute; width: 141px;   left: 19px; top: 356px;direction: rtl; background-color: gray; color: white;" 
      [items]="items" bindValue="MAINID" #val bindLabel="Name" [(ngModel)]="maf.MAINIDMenege" [ngModelOptions]="{standalone: true}"  >
      </ng-select>
    <p class="labl" style="position: absolute;left: 99px;top: 80px;"> מס יחידות </p> 
     <input type="number"  min="0.5" step="0.25" style="width: 30%;position: absolute;left:109px;top: 110px;" [(ngModel)]="maf.NUMYACHIDOT" [ngModelOptions]="{standalone: true}"  #num >
     <p class="labl" style="position: absolute;left: 19px;top: 80px;"> מס ילדים </p> 
     <input type="number"   min="0" step="1" style="width:30%;position: absolute;left:29px;top: 110px;" [(ngModel)]="maf.numofchildren" [ngModelOptions]="{standalone: true}"  #num1 >
    <p class="labl" style="position: absolute;left: 39px;top: 138px;">תאריך לידה בכור </p>
    <input  type="date" style="position: absolute;left: 19px;top: 158px;"  (ngModelChange)="maf.DateBornBigBoy= $event" [ngModel]="maf.DateBornBigBoy|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}" #DateBornBigBoy >
   <br><br> <br><br>
   <div *ngIf='w==true'  >
   <label style="position: absolute; left: 116px; top: 505px;"><input  checked="checked" (click)="man()" type="radio" name="a" > זכר</label>
   <label style="position: absolute;top: 505px;left: 45px;"><input  type="radio" (click)="man()"  name="a">נקבה</label>
 </div> 
 <div *ngIf='w==false'> 
  <label style="position: absolute; left: 116px; top: 505px;"><input  (click)="man()" type="radio" name="a" >  זכר</label>
  <label style="position: absolute;top: 505px;left: 45px;"><input  checked="checked" type="radio" (click)="man()"  name="a">נקבה</label>
 </div>
 <div *ngIf='w==null'> 
  <label style="position: absolute; left: 116px; top: 505px;"><input  (click)="man()" type="radio" name="a" > זכר</label>
  <label style="position: absolute;top: 505px;left: 45px;"><input   type="radio" (click)="man()"  name="a">נקבה</label>
 </div>
 <label class="labl" style="cursor: pointer;position: absolute; top: 440px;left: 49px;" > <input [hidden]="!maf.SHTAR" (change)="ShtarC()"  type="checkbox" checked="checked" #SHTAR > <input (change)="ShtarC()" [hidden]="maf.SHTAR"  type="checkbox"  #SHTAR >  שטר הפקדה</label>
</div>
 <p class="labl" style="position: absolute;left: 49px;top: 198px;">שליחת דוח</p>
    <input  type="number" #sendreporton  style="position: absolute;left: 19px;top: 228px;"   [(ngModel)]="maf.sendreporton" [ngModelOptions]="{standalone: true}" >
    <p>פעיל</p>
    <input type="checkbox" [hidden]="!mafpail" checked="checked" (click) ="pail()">
    <input type="checkbox" [hidden]="mafpail"  (click) ="pail()">
    <p class="labl" style="position: absolute;  left: 29px; top: 570px;"> הצטרף ע"י</p>
    <select  style="position: absolute; width: 141px;   left: 19px; top: 594px;direction: rtl; background-color: gray; color: white;" 
      [(ngModel)]="maf.JoinBy" [ngModelOptions]="{standalone: true}" ><option> משרד </option><option> טלפנית </option><option> נציג</option></select>  
  <!-- <p class="labl" style="position: absolute; top: 570px;left:29px;">תאריך התחלה</p>
    <input  type="date"  (ngModelChange)="maf.StartDate= $event" [ngModel]="maf.StartDate|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}" style="position: absolute; top: 594px;left: 19px;"  #StartDate  >
    <p class="labl" style="position: absolute; top: 630px;left: 49px;">תאריך סיום</p>
    <input  type="date" style="position: absolute; top: 654px;left: 19px;" (ngModelChange)="maf.EndDate= $event" [ngModel]="maf.EndDate|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}" #EndDate  > -->
 <!-- <button  class="button button5"  type="button" style="position: absolute; top: 704px;left: 3px;" >הוספה </button> -->
  <svg  (click)="delhaf()" style="position: absolute;left:40px;top: 700px;width: 32px;height:40px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
  </svg>
  <svg width="1em" height="1em" viewBox="0 0 16 16" (click)="current(-1)"  style="position: absolute;left:130px;top: 703px;width: 28px;height: 35px;" class="bi bi-caret-right-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z"/>
  </svg>
  <svg width="1em" height="1em" (click)="current(1)"viewBox="0 0 16 16"  style="position: absolute;left:14px;top: 703px;width: 28px;height: 35px;" class="bi bi-caret-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.5 10a.5.5 0 0 1-.832.374l-4.5-4a.5.5 0 0 1 0-.748l4.5-4A.5.5 0 0 1 10.5 4v8z"/>
  </svg>
  <svg  (click)="Addhaf()" style="position: absolute;left:70px;top: 700px;width: 32px;height: 40px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
    <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  </svg>
  <svg style="position: absolute;left:100px;top: 700px;width: 32px;height: 40px;" (click)='addMafkid()' width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-earmark-check-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zm1.854 2.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
  </svg>
</div>
 <!-- <div class="intp"[hidden]="maslul" style="direction: rtl;border-radius: 12px;position: absolute; left: 12px;font-weight: bold;background-color: gray;width: 178px;height: 700px;">
    <p  class="labl"> הערה </p>
    <input type=" text" style="height:30px;text-align:start;" [(ngModel)]="maf.Remark" #kodhok >
 <p class="labl" >שליחת דוח</p>
    <input  type="number" #sendreporton     [(ngModel)]="maf.sendreporton" [ngModelOptions]="{standalone: true}" >
  <p class="labl" >תאריך התחלה</p>
    <input  type="date" (ngModelChange)="maf.StartDate= $event" [ngModel]="maf.StartDate|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}" #StartDate  >
    <p class="labl">תאריך סיום</p>
    <input  type="date" (ngModelChange)="maf.EndDate= $event" [ngModel]="maf.EndDate|date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"  #EndDate  >
  <button  class="button button5"  type="button" style="position: absolute; top: 658px;left: 3px;" (click)='addMafkid()'>הוספה </button>
</div> -->
 <router-outlet></router-outlet>
