export class Hok {
        ID: number;
        BANK: string;
        SNIF: string;
        BANKACCOUNT: string;
        Notice: string;
        Amount: number;
        StatusHOK: string;
        UpdateBy: string;
        UpdateDate: Date;
        CreateBy: string;
        CreateDate: Date;
        MAINID: number;
        CardNumber: string;
        Valid: Date;
        CVV: string;
        TypeHok: string;
        Accepted: boolean;
        kodtypeash:number;
        DateLimit:Date
        ScumLimit:number
    
        constructor(){
    
            this.ID=null;
            this.BANK=null;
            this.SNIF=null;
            this.BANKACCOUNT=null;
            this.Notice=null;
            this.Amount=null;
            this.StatusHOK=null;
            this.UpdateBy=null;
            this.UpdateDate=null;
            this.CreateBy=null;
            this.CreateDate=null;
            this.MAINID=null;
            this.CardNumber=null;
            this.Valid=null;
            this.CVV=null;
            this.TypeHok=null;
            this.Accepted=null;
            this.kodtypeash=null;
        }
    
    }
    
    

