import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { NamesService } from 'src/app/services/names.service';
import { Router } from '@angular/router';
import { MafkidService } from 'src/app/services/mafkid.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ShowbalanceComponent } from '../showbalance/showbalance.component';
import { Totalscumcount } from 'src/app/classes/totalscumcount';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { makeBindingParser } from '@angular/compiler';

@Component({
  selector: 'app-showhafkadotcount',
  templateUrl: './showhafkadotcount.component.html',
  styleUrls: ['./showhafkadotcount.component.css']
})
export class ShowhafkadotcountComponent implements OnInit {
  index: number=0;
  constructor( private cd: ChangeDetectorRef, private maf:MafkidService,private router: Router,private name: NamesService) { }
  //#region 
  @ViewChild(ShowbalanceComponent) appShowbalance:ShowbalanceComponent;
  @Input() PERMISSION;
  @Input() iduser; 
  @Input() mainid;
id1;
list1;
list2;
listtotal;
list;
t:Totalscumcount=new Totalscumcount();
t1:Totalscumcount=new Totalscumcount();
haf:number=0;
haf1:number=0;
//#endregion
  ngOnInit() {
    this.id1=this.mainid;
    this.GetTotal(this.id1);
  }
  GetTotal(id:number):any
{
  if(id){
  this.maf.GetTotalBymainid(id).then( 

   response => {
    this.listtotal=response;
    ;
    return  response;

   })
 .catch(v => { return false; });
}
}
  showhafkadot(id:number)
  {  
      this.mainid=id;
      ;
      if(id!=null)
      {
       this.GetTotal(id);
       this.GetTotal(id);
   this.maf.showhafkadacount(id).then(res=>{
    this.list=res; 
    ;
    this.list.forEach(element => {
      element['Totalhafkada'] =this.listtotal && this.listtotal.find(a => a.idmafkid == element.Id&&a.type=="הפקדה")?this.listtotal.find(a => a.idmafkid == element.Id&&a.coin==1&&a.type=="הפקדה").scum:'';
      element['Totaltruma'] =this.listtotal && this.listtotal.find(a => a.idmafkid == element.Id&&a.type=="תרומה")?this.listtotal.find(a => a.idmafkid == element.Id&&a.coin==1&&a.type=="תרומה").scum:'';
  });
  if(this.listtotal!=null)
  {
    if(this.listtotal.length>0)
    {  
      this.listtotal=this.listtotal.filter(a=>a.Coin==1);
    
      this.listtotal.forEach(element => {      
     this.haf+=element.Amount;});
    this.listtotal=this.listtotal.filter(a=>a.Coin==2);
    this.listtotal.forEach(element => {    
      this.haf1+=element.Amount;}); 
    MafkidService.total=this.haf;
    MafkidService.totald=this.haf1; 
  this.appShowbalance=new ShowbalanceComponent(this.name,this.maf);
  this.appShowbalance.fun(this.haf,this.haf1);
  this.cd.detectChanges();
     }
  ;
    }
    else{
      this.GetTotal(id);
    }
})}}
showhafkadottocontact(id:number)
  {
   
   this.maf.showhafkadacountcontact(id).then(res=>{
    this.list=res; 
  
  this.list.forEach(element => {
    element['Totalhafkada'] =this.listtotal && this.listtotal.find(a => a.idmafkid == element.Id&&a.type=="הפקדה")?this.listtotal.find(a => a.idmafkid == element.Id&&a.coin==1&&a.type=="הפקדה").scum:'';
    element['Totaltruma'] =this.listtotal && this.listtotal.find(a => a.idmafkid == element.Id&&a.type=="תרומה")?this.listtotal.find(a => a.idmafkid == element.Id&&a.coin==1&&a.type=="תרומה").scum:'';
});
if(this.listtotal!=null)
{ 
  if(this.listtotal.length>0)
  {  
    this.listtotal=this.listtotal.filter(a=>a.Coin==1);
  
    this.listtotal.forEach(element => {      
   this.haf+=element.Amount;});
  this.listtotal=this.listtotal.filter(a=>a.Coin==2);
  this.listtotal.forEach(element => {    
    this.haf1+=element.Amount;}); 
  this.cd.detectChanges();
}
}
else{
  this.GetTotal(id);
}})}
  viewdetails(idmafkid:number)
  {  
    this.maf.getmafkid(idmafkid).then(res=>{
    this.mainid =res.MAINID;
    this. index=this.list.findIndex(x => x.Id==idmafkid);

    // this.router.navigate(["/Hafkadotall1/"+this.iduser,this.PERMISSION,this.mainid,idmafkid,this.index]);

    const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall1/"+this.iduser,this.PERMISSION,this.mainid, idmafkid,this.index]));
    window.open(url, '_blank');  })
}
  
//   viewdetails1(idmafkid:number)
// {
//    this.index=this.list.findIndex(x => x.Id==idmafkid);

//    // this.router.navigate();
//     const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall1/"+this.iduser,this.PERMISSION,this.mainid, idmafkid,this.index]));
//     window.open(url, '_blank');
// }
}

