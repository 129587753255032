import { Component, OnInit } from '@angular/core';
import { ExcelService } from 'src/app/services/excel.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Categories } from 'src/app/classes/categories';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { NamesService } from 'src/app/services/names.service';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { Excel } from 'src/app/classes/excel';
import { Excel1 } from 'src/app/classes/Excel1';

@Component({
  selector: 'app-excellist',
  templateUrl: './excellist.component.html',
  styleUrls: ['./excellist.component.css']
})
export class ExcellistComponent implements OnInit {
list;
listfilter;
list1;
d: Date = new Date();
year:number;
month:number;
value;
value1;
value2;
listnames;
kod:number;
filter:boolean;
cat:Categories=new Categories();
  lists: any;
  PERMISSION;
  iduser: any;
  Notice: any;
  columnDefs;
  localeText: any;
  rowSelection: string;
  rowData: any;
  excel1: Excel1=new Excel1();
  constructor(private excel:ExcelService, private router:Router,private maagar:MaagarMenageService, private name:NamesService, private route:ActivatedRoute) {      
    this.route.params.subscribe((params:Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
   }
  ngOnInit() {
    this.columnDefs = [
      {
        headerName: 'תאריך ', field: 'C_dateoperation', filter: "agNumberColumnFilter", width: 100, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
      }
      },

// this.Mainid =0;
// this.Status=null;
      { headerName: 'חובה', field: 'C_SCUMChova_', filter: "agNumberColumnFilter", width: 100 },
      { headerName: 'זכות', field: 'C_SCUMZcut_', filter: "agNumberColumnFilter", width: 100 },  
      { headerName: 'יתרה', field: 'Totalscum', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'הערה', field: 'Remark', filter: "agTextColumnFilter", width: 400 },
      { headerName: 'נכנס ל', field: 'categoryName', filter: "agTextColumnFilter", width: 200,editable:true },
      { headerName: 'שם', field: 'Name', filter: "agTextColumnFilter", width: 200,editable:true },
      { headerName: 'סטטוס', field: 'Status', filter: "agTextColumnFilter", width: 200,editable:true },


    ];
    this.filter=false;
 this.year= this.d.getFullYear();
this.month= this.d.getMonth()+1;
this.GetStatus();
this.showcat();
  }
  cellEditingStarted(event)
  {
    this.excel1=event.date;
  }
  cellEditingStopped(event)
  {
    this.excel1.Mainid=event.date.Mainid;
    this.excel1.insertto=event.date.insertto;
    this.excel1.Status=event.date.Status;
    this.excel.PostExcelOne(this.excel1);
  }
  GetStatus()
  {
    this.excel.GetStatus().then(res=>{
      this.lists=res
    });
  }
  filterdata( datestart:Date, dateend:Date,scum:number)
  {
this.kod=this.list1.find(t=>t.NameCategory==this.value2).CategoryId;

    this.excel.filter(datestart, dateend,this.value,this.value1, scum,this.kod).then(res=>{
      this.list=res;
      this.list.forEach(element => {
         element['Name'] =this.listnames && this.listnames.find(a => a.MAINID == element.Mainid)?this.listnames.find(a => a.MAINID == element.Mainid).FamilyName :'';
         element['fName'] =this.listnames && this.listnames.find(a => a.MAINID == element.Mainid)?this.listnames.find(a => a.MAINID == element.Mainid).FirstName :'';
         element['Status'] =this.lists&& this.lists.find(a => a.Id == element.Mainid)?this.lists.find(a => a.Id == element.Mainid).Namestatus :'';
         element['categoryName'] =this.list1 && this.list1.find(a => a.CategoryId == element.insertto)?this.list1.find(a => a.CategoryId == element.insertto).NameCategory:'';

       });})
  }
  buildgrid() {
    this.localeText = LOCALE_TEXT;
    this.rowSelection = 'single';
    ;
    this.rowData = this.list;
    this.columnDefs = this.columnDefs;
  }
  valuechange(value){
  this.value=value;
  }
  valuechange1(value){
    this.value1=value;
    
    }
    valuechange2(value){
      this.value2=value;
      
      }
  ShowAllPeulot()
  {
  this.excel.ShowAllPeulot(  this.year, this.month).then(res=>{
  this.list=res;

  this.list.forEach(element => {
    element['Name'] =this.listnames && this.listnames.find(a => a.MAINID == element.Mainid)?this.listnames.find(a => a.MAINID == element.Mainid).FamilyName +' '+this.listnames.find(a => a.MAINID == element.Mainid). FirstName:'';
    element['Status'] =this.lists&& this.lists.find(a => a.Id == element.Mainid)?this.lists.find(a => a.Id == element.Status).Namestatus :'';
  
  });
  this.list.forEach(element => {
    element['categoryName'] =this.list1 && this.list1.find(a => a.CategoryId == element.insertto)?this.list1.find(a => a.CategoryId == element.insertto).NameCategory:'';
  });
  this.buildgrid();
})}
  currentSlide(month:number )
  {
    this.month=month;
    this.ShowAllPeulot();
  }
    plusSlides(year1: number)
    {
      this.year=this.year+year1;
      this.ShowAllPeulot();
    }
    excelinsertCheck()
    {
    this.router.navigate(["/excelinsert/"+this.iduser,  this.PERMISSION]);

    }
  excelinsert()
  {
    this.excel.INSERTEXCEL().then
    (res=>
      this.Notice=res)
      ;
  } 
  showcat(){
    this.maagar.Showcat("אקסל").then(res=>{
      this.list1=res;
  })
  this.showNames();
}
  filterlist()
 {
   this.filter=!this.filter;
 }
 showNames() {
  this.listnames = JSON.parse(sessionStorage.getItem('ListNames'));
  if (this.listnames == null) {
  this.name.ShowNames().then(res => {
    this.listnames = res;
    sessionStorage.setItem('ListNames', JSON.stringify(this.list));
  })}
  if(this.listnames!=null)
  {
  this.ShowAllPeulot();
  }
 }
 
}
