export class Loans {
    
    ID: number;
    MAINID: number;
    loanDate: string;
    loanDateforien: Date;
    LoanAmount: number;
    Payments: number;
    Balance: number;
    notice: string;
    Pay: number;
    loanDateHeczer: string;
    loanDateforienHeczer:Date;
    Arev1: number;
    Arev2: number;
    MAINIDMenege:number;
    Arev3: number;
    Arev1cell: string;
    Arev2cell: string;
    Arev3cell: string;
    city:string;
    typeloan:number;
    HockReturn: number;
    Coin: number;
    getbyway:string;
    UpdateBy: string;
    UpdateDate: Date;
    CreateBy: string;
    CreateDate: Date;
    typename: any;
    constructor(){
        this.ID=-1;
        this.MAINID=null;
        this.loanDate=null;
        this.loanDateforien=null;
        this.LoanAmount=40000;
        this.Payments=40;
        this.Balance=40000;
        this.notice=null;
        this.Pay=0;
        this.loanDateHeczer=null;
        this.loanDateforienHeczer=null;
        this.Arev1=null;
        this.Arev2=null;
        this.Arev3=null;
        this.Arev1cell=null;
        this.Arev2cell=null;
        this.Arev3cell=null;
        this.city=null;
        this.HockReturn=null;
        this.Coin=null;
        this.UpdateBy=null;
        this.UpdateDate=null;
        this.CreateBy=null;
        this.CreateDate=null;
        this.getbyway=null;
    }
}
