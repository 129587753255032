import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";
import {ICellEditorAngularComp} from "ag-grid-angular";
@Component({
  selector: 'app-drop-down-editor',
  templateUrl: './drop-down-editor.component.html',
  styleUrls: ['./drop-down-editor.component.css']
})
export class DropDownEditorComponent implements  ICellEditorAngularComp, AfterViewInit {

  constructor() { }
  private params: any;
  public value: number;
  public options: any;

  @ViewChild('input', {read: ViewContainerRef}) public input;


  agInit(params: any): void {
      this.params = params;
      this.value = this.params.value;
      this.options = params.options;

  }

  getValue(): any {

      return this.value;
  }

  ngAfterViewInit() {
    ;

      window.setTimeout(() => {
          this.input.element.nativeElement.focus();
      })
  }


}
