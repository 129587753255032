import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchnames',
  templateUrl: './searchnames.component.html',
  styleUrls: ['./searchnames.component.css']
})
export class SearchnamesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
