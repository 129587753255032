import { Component, OnInit } from '@angular/core';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';

@Component({
  selector: 'app-maslul',
  templateUrl: './maslul.component.html',
  styleUrls: ['./maslul.component.css']
})
export class MaslulComponent implements OnInit {
list;
  constructor(private maslul:MaagarMenageService) { }

  ngOnInit() {
  this.  ShowMaslul()
  }
ShowMaslul()
{
  this.maslul.ShowMaslul().then(res=>{
    this.list=res;
})}}

