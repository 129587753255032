<head></head>
<div id="mySidenav" class="sidenav">
  <a id="contact1" (click)='showhok()' onclick="document.getElementById('id30').style.display='block'">הו"ק</a>
</div>
<div id="id30" class="modal1" style="display: none">
  <span onclick="document.getElementById('id30').style.display='none'" class="close" title="סגור">&times;</span>
  <form id="ii" class="modal-content1" action="/action_page.php">
    <div class="row">
      <div class="col-75">
        <div class="container"
          style="position: absolute;  top:25px ;left: 56px;width: 640px;height: 752px;    border-radius: 13px; ">
          <!-- <div style="height: 20px; background-color: dimgray   ; height: 30px;      border-radius: 15px;   width: 100%; top: 0px; position: absolute; left: 1px;"></div> -->
          <app-history [nametbl]="nametbl" [id]="id" [mainid1]="null" [idintbl]="idintbl" class="col-2"
            style="position: absolute;    left: 12px; top: 36px; width: 60px"></app-history>
          <form action="/action_page.php">
            <h3 style="position: absolute; left: 290px; top: 40px;">Hok</h3>
            <div *ngIf='listbank'>
              <div style="text-align:center ;position: relative; top: 76px; left: 350px;"
                *ngFor='let item of listbank ; let i = index' [attr.data-index]="i">

                <span class="dot" id="i" #v (click)="currentSlide(item)"> <img id="img2"
                    style="width: 90%;height: 90%;border-radius: 50%;" src="/assets/1/{{item[0]}}{{item[1]}}.jpg"></span>
                <!-- <span   class="dot"  #v (click)="currentSlide(item)"> <img  id="i">{{item}}</span> </div></div>            -->
              </div>
            </div>
            <div *ngIf='listash'>
              <div style="text-align:center ;position: relative;top:76px; left: -348px;" *ngFor='let item of listash'>
                <span class="dot" #v1 (click)="currentSlide1(item)"><img id="img2"
                    style="width: 90%;height: 90%;border-radius: 50%;" src="/assets/1/{{item}}.jpg"></span> </div>
            </div>
            <div class="num">
              <input [(ngModel)]="num5" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl
                name="something1"
                style="background-color:#a1a1a1;border:none;width: 40px;position: absolute; left: 40px;top: 150px;font-size: 120%;">
              <label style="position: absolute;left: 79px; top: 156px; font-size: 120%">/</label>
              <input [(ngModel)]="num3" disabled [ngModelOptions]="{standalone: true}" ngDefaultControl name="something"
                style="background-color: #a1a1a1 ;border: none; width: 31px; position: absolute; left: 87px;  top: 150px;font-size: 120% ">
              <div class="btnnum " style="position: absolute; left:200px; top:10px; width: 200px">
                <button class="bb" style="position: absolute;  height: 35px;  width: 17%;background-color: #f44336;border: solid 1px black;
         border-radius: 10px; left:-51px;top:101px" (click)="addhok1()" class="previous">+
                </button>
                <button class="bb" [hidden]='new' (click)='del()'
                  style="position: absolute;  height: 35px;  width: 15%;background-color: #f44336;border: solid 1px black;left:-11px;top:101px"><i
                    style="    width: 90%; height: 90%;font-size: 180%; position: absolute; left: 2px;    top: 2px;"
                    class="fa fa-trash"></i></button>

                <button class="bb"
                  style="position: absolute;  height: 35px;color: black;  width: 17%; border: solid 1px black;border-radius: 10px; left:-111px;top:101px"
                  (click)="showhokdup(1)" class="previous" id="a">&laquo; </button>
                <button class="bb"
                  style="position: absolute; left:-151px;  height: 35px;color: black;  width: 17%; border: solid 1px black; border-radius:10px;top:101px;"
                  (click)="showhokdup(-1)" class="next" id="b">&raquo;</button>
              </div>
            </div>
            <div class="row">
              <div class="col-50">

                <input type=" text" value="{{mainid}}" style="width: 130px;position: absolute; right: 43px; top: 170px;"
                  #MainId disabled>
                <div >
                  <label *ngIf='h==true'
                    style="position: absolute;left: 414px;top: 109px;font-size: 120%;block-size: 15px;font-weight: bold;cursor: pointer">
                    <input type="radio" checked="checked" (click)="hokshow()" name=ashray
                      style="position: absolute;left:  48px;top: 7px;width: 1em; height: 1em;cursor: pointer">
                    הו"ק</label>
                  <label *ngIf='h==false'
                    style="font-size: 120%; font-weight: bold;position: absolute;left: 504px;top: 109px;cursor: pointer"><input
                      type="radio" (click)="ashshow()" checked="checked" name=ashray
                      style="position: absolute;left: 68px; top: 7px; width: 1em; height: 1em;cursor: pointer"> אשראי
                  </label>
                  <label *ngIf='h==false'
                    style="position: absolute;left: 414px;top: 109px;font-size: 120%;block-size: 15px;font-weight: bold;cursor: pointer">
                    <input type="radio" (click)="hokshow()" name=ashray
                      style="position: absolute;left:  48px;top: 7px;width: 1em; height: 1em;cursor: pointer">
                    הו"ק</label>
                  <label *ngIf='h==true'
                    style="font-size: 120%; font-weight: bold;position: absolute;left: 504px;top: 109px;cursor: pointer"><input
                      type="radio" (click)="ashshow()" name=ashray
                      style="position: absolute;left: 68px; top: 7px; width: 1em; height: 1em;cursor: pointer"> אשראי
                  </label>
                </div> <br>
                <div [hidden]="!h">
                  <b [hidden]="!h" style="position: absolute;left: 560px; top: 246px;"> בנק </b>
                  <ng-select style="position: absolute;height: 40px;
  left: 341px;
  top: 274px;
  width: 40%;" placeholder="מספר  בנק"  [(ngModel)]="hok2.BANK"
                    [ngModelOptions]="{standalone: true}" [items]="listBankTypes" #val1>
                  </ng-select>

                  <b [hidden]="!h" style="position: absolute; left: 234px;top: 248px;">מס סניף</b>
                  <input [hidden]="!h" type="text" [(ngModel)]="hok2.SNIF" [ngModelOptions]="{standalone: true}"
                    style="position: absolute;left: 48px;top: 273px;" placeholder="סניף" #Snif>

                  <b style="position: absolute; left: 520px;top: 344px;"> מס חשבון </b>
                  <input [hidden]="!h" type="text" [(ngModel)]="hok2.BANKACCOUNT" (change)="CheckedBankAccount()" [ngModelOptions]="{standalone: true}"
                    style=" position: absolute;left: 342px;top:370px;" placeholder="חשבון">
                    <label [hidden]="!ErrorAccount" style=" position: absolute;left: 522px;top:410px;color: red;">חשבון שגוי </label>
                    
                </div>
                <div [hidden]="!ash">

                  <b [hidden]="!ash" style="position: absolute;  left: 495px;top: 251px;">מספר כרטיס</b>
                  <input [hidden]="!ash" type="text" class="CardNumber" [(ngModel)]="hok2.CardNumber"
                    [ngModelOptions]="{standalone: true}" style="position: absolute; left: 341px; top: 276px;"
                    placeholder="מספר כרטיס" #CardNumber>

                  <b style="position: absolute;left: 195px;top: 249px;">תוקף הכרטיס</b>
                  <input [hidden]="!ash" type="date" class="Valid" placeholder="תוקף"
                    style="position: absolute;  left: 47px;top: 275px;" (ngModelChange)="hok2.Valid=$event"
                    [ngModel]="hok2.Valid|date:'y-MM-dd'" [ngModelOptions]="{standalone: true}" #Valid>

                  <b style="position: absolute; left: 558px;top: 344px;">CVV</b>
                  <input [hidden]="!ash" type="text" class="CVV" [(ngModel)]="hok2.CVV"
                    [ngModelOptions]="{standalone: true}" placeholder="CVV"
                    style="position: absolute;left: 342px;top: 370px;" #CVV>
                </div>
                <b style="position: absolute;left: 255px; top: 344px;">הערה</b>
                <input type="text" [(ngModel)]="hok2.Notice" [ngModelOptions]="{standalone: true}" placeholder="הערה"
                  style=" position: absolute;left: 47px;  top: 370px;" #Notice>

                <b style="position: absolute; left: 432px;top: 443px;">תאריך פקיעת הרשאה</b>
                <input type="date" class="CreateBy" style="position: absolute;left: 341px; top: 468px;"
                class="Date"   (ngModelChange)="hok2.DateLimit=$event" [ngModel]="hok2.DateLimit| date:'yyyy-MM-dd'"
                [ngModelOptions]="{standalone: true}">

             
                <b style="position: absolute;left: 205px;top: 536px;">תאריך עדכון</b>
                <input type="date" class="UpdateDate" style="position: absolute; left: 47px; bottom: 131px;"
                  (ngModelChange)="hok2.UpdateDate=$event" [ngModel]="hok2.UpdateDate| date:'yyyy-MM-dd'"
                  [ngModelOptions]="{standalone: true}" placeholder="תאריך עידכון" #UpdateDate>
                  <b style="position: absolute;left: 193px; top: 442px;">תאריך הוספה</b>
                  <input type="date" class="Date"  [ngModel]="hok2.CreateDate| date:'yyyy-MM-dd'"
                    [ngModelOptions]="{standalone: true}" style="position: absolute;left: 47px;  top: 467px;"
                    placeholder="תאריך הצטרפות" #CreateDate>
  
                <b style="position: absolute;left: 505px;top: 537px;">הגבלת סכום</b>
                <input type="number" class="UpdateBy" style="position: absolute; left: 342px;top: 563px;"
                  [(ngModel)]="hok2.ScumLimit" [ngModelOptions]="{standalone: true}" placeholder="התעדכן עי" #UpdateBy>
                <div *ngIf='h==true'>
                  <p style="font-size: 20px;position: absolute; top: 653px; right: 65px;"><b>התקבל?</b></p>
                  <input type="checkbox"  [(ngModel)]="hok2.Accepted" [ngModelOptions]="{standalone: true}"
                    style="position: absolute; left: 461px; top: 661px; block-size: 17px;cursor: pointer" #c
                    checked="checked" name="sameadr">
                  <!-- <input type="checkbox" (click)="changevalue()" [hidden]="hok2.Accepted"
                    style="position: absolute; left: 461px; top: 661px; block-size: 17px;cursor: pointer" #c
                    name="sameadr"> -->
                </div>
                <input type="submit" [hidden]="ash" value="אישור" (click)='addHok(MainId.value)' class="btn" #1
                  style="height: 44px; border-radius: 7px;  width: 86%;  bottom: 8px; position: absolute; left: 48px; font-size: 17px;">
                <input type="submit" [hidden]="!ash" value="אישור" (click)='addHokash(MainId.value)' class="btn" #11
                  style=" height: 44px; border-radius: 7px;  width: 86%;  bottom: 8px; position: absolute; left: 48px; font-size: 17px;">
              </div>
            </div>
          </form>
        </div>
      </div>
       