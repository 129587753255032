export class Excel1 {
Id: number; 
idline: number; 
C_dateoperation: Date; 
C_SCUMZcut_: number;
C_SCUMChova_: number;
Totalscum: number;  
Remark: string;
Mainid;
Status;
idloanormafkid :number;
insertto :number;
Idkupa:number;
  notask: boolean;
  validate: any;
constractor()
{
this.Id=null
this.idline=null;
this.C_dateoperation =null;
this.C_SCUMZcut_ =null;
this.C_SCUMChova_=null;
this.Totalscum =null;
this.Remark =null;
this.idloanormafkid=null;
this.insertto=null;
}
}
