import { Component, OnInit, Output } from '@angular/core';
import { Router,ActivatedRoute,Params } from '@angular/router';

@Component({
  selector: 'app-main1',
  templateUrl: './main1.component.html',
  styleUrls: ['./main1.component.css']
})
export class Main1Component implements OnInit {
   user;
  iduser: any;

  constructor(private route:ActivatedRoute, private router:Router) { 
    this.route.params.subscribe((params:Params) => {
      this.user = params.id;
      this.iduser=params.iduser;
   
      })
  }
  ngOnInit() {
  }

}
