import { Component, OnInit } from '@angular/core';
import { YearClass } from 'src/app/classes/YearClass';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-calcuter',
  templateUrl: './calcuter.component.html',
  styleUrls: ['./calcuter.component.css']
})
export class CalcuterComponent implements OnInit {
  ListHaf: any;
  ListTrumaHaf: any;
  ListTrumaHec: any;
  ListLoans: any;
  ListHec: any;
  listYears: Array<YearClass> = new Array<YearClass>()
  listYears1: any;
  listYears2: any;
  constructor(private Report: ReportsService) { }

  ngOnInit(): void {
this.ShowAllFutureTzefi()
    // this.ShowAllHaf();
  }
  ShowAllHaf() {
    

    this.Report.ShowAllHaf().then(
      res => {
        

        this.ListHaf = res
        this.ShowAllHec();

      }
    )
  }
  ShowAllHec() {
    this.Report.ShowAllHec().then(
      res => {
        this.ListHec = res
        this.ShowAllTrumaHaf();

      }
    )
  }
  ShowAllTrumaHaf() {
    this.Report.ShowAllTrumaHaf().then(
      res => {
        this.ListTrumaHaf = res
        this.ShowAllTrumaHac();

      }
    )
  }
  ShowAllTrumaHac() {
    this.Report.ShowAllTrumaHac().then(
      res => {
        this.ListTrumaHec = res
        this.ShowAllFutureTzefi();

      }
    )
  }
  ShowAllFutureTzefi() {
    this.Report.ShowAllFutureTzefi().then(
      res => {
        this.listYears1 = res
        // this.ShowAllLoans();
        this.ShowAll();

      }
    )
  }
  ShowAll() {
    this.Report.ShowAll().then(
      res => {
        this.listYears2 = res
        // let d1 = new Date(2017, 1, 1);
            
            for (let i = 0; i < 1200; i++) {
                let year = new YearClass();
                // 
                year.d =  this.listYears2[i ].d
                // year.loam =  this.listYears2[i ].loam + this.listYears1[i ].loam
                // year.hafmonth =  this.listYears2[i ].hafmonth + this.listYears1[i ].hafmonth
                // year.heczer =  this.listYears2[i ].heczer + this.listYears1[i ].heczer
                // year.Truma =  this.listYears2[i ].Truma + this.listYears1[i ].Truma
                // year.total = year.hafmonth + year.Truma + year.heczer - year.loam
                year.loam =  this.listYears2[i ].loam
                year.hafmonth =  this.listYears2[i ].hafmonth 
                year.heczer =  this.listYears2[i ].heczer
                year.Truma =  this.listYears2[i ].Truma
                year.loanf =   this.listYears1[i ].loanf
                year.haff =   this.listYears1[i ].haff
                year.heczerf =  this.listYears1[i ].heczerf
                year.Trumaf =  this.listYears1[i ].Trumaf
                year.total = year.hafmonth + year.Truma + year.heczer+year.haff + year.Trumaf + year.heczerf - year.loam-year.loanf
                if (this.listYears.length > 0) {
                  year.total += this.listYears[this.listYears.length - 1].total
                }
                this.listYears.push(year)
                
                // if (d1.getUTCMonth() + 1 == 12) {
                //   d1 = new Date(d1.getFullYear() + 1, 1, d1.getDay());
    
                // }
                // else {
                  // d1 = new Date(d1.getFullYear(), d1.getUTCMonth() + 1, d1.getDay());
    
                // }
              
            }
          })
      }
  ShowAllLoans() {
    let d1 = new Date(2017, 1, 1);
    this.Report.ShowAllLoans().then(
      res => {
        this.ListLoans = res
        for (let i = 0; i < 100; i++) {
          for (let j = 0; j < 12; j++) {
            let year = new YearClass();
            
            year.d = d1
            year.loam = this.ListLoans[i][j] + this.listYears1[i * j].loam
            year.hafmonth = this.ListHaf[i][j] + this.listYears1[i * j].hafmonth
            year.heczer = this.ListHec[i][j] + this.listYears1[i * j].heczer
            year.Truma = this.ListTrumaHaf[i][j] + this.ListTrumaHec[i][j] + this.listYears1[i * j].Truma
            year.total = year.hafmonth + year.Truma + year.heczer - year.loam
            if (this.listYears.length > 0) {
              year.total += this.listYears[this.listYears.length - 1].total
            }
            this.listYears.push(year)
            
            if (d1.getUTCMonth() + 1 == 12) {
              d1 = new Date(d1.getFullYear() + 1, 1, d1.getDay());

            }
            else {
              d1 = new Date(d1.getFullYear(), d1.getMonth() + 1, d1.getDay());

            }
          }
        }
      })
  }
}
