import { Component, OnInit } from '@angular/core';
import { AshraiService } from 'src/app/services/ashrai.service';
import { AshrayType } from 'src/app/classes/ashray-type';

@Component({
  selector: 'app-ashraytypes',
  templateUrl: './ashraytypes.component.html',
  styleUrls: ['./ashraytypes.component.css']
})
export class AshraytypesComponent implements OnInit {

  constructor( private ash:AshraiService) { }
list;
ashrai:AshrayType=new AshrayType();
  ngOnInit() {
    this.showash();
  }
showash()
{
  this.ash.ShowAllAshray().then(res=>{
    this.list=res;
  })}
  save(id:number,name:string, num:number)
  {
this.ashrai.Id=id;
this.ashrai.name=name;
this.ashrai.price=num;
this.ash.SavePrice(this.ashrai);
  }
}

