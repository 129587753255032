import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class HokHelpService {
  GetAll() {
    return this.http.get(NamesService.serverUrl +"/HokHelp/GetAll").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  merge(idmafkid: number) {
    return this.http.get(NamesService.serverUrl +"/HokHelp/merge?id="+idmafkid).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
 

 
  constructor(private http: HttpClient) { }
  
  GetMistashlumim(date1:Date, date2:Date,diff:number) : any {
    {
     
        return this.http.put(NamesService.serverUrl +"/HokHelp/PutMistashlumim", { start:date1, end:date2, diffrence:diff}).toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });
        }}
        GetDateStart(date: Date, num5: number,diff:number):any {
          return this.http.put(NamesService.serverUrl +"/HokHelp/GetDateStart", { start:date, num:num5, diffrence:diff}).toPromise().then(
            response => {
             return  response;
            })
          .catch(v => { return false; });
          }
        getmisafterChangestart(date: Date, date1: any) :any{
          return this.http.put(NamesService.serverUrl +"/HokHelp/PutMistashlumim", { start:date1, end:date1}).toPromise().then(
            response => {
             return  response;
            })
          .catch(v => { return false; });        }
      
        GetDateEnd(date1:Date, mis:number,diff:number) : any {
          {
           ;
              return this.http.put(NamesService.serverUrl +"/HokHelp/PutDateEnd", { start:date1, num:mis, diffrence:diff}).toPromise().then(
                response => {
                 return  response;
                })
              .catch(v => { return false; });
              }}
              GetHebroDate(id:Date): any {
                ;;
                return this.http.post(NamesService.serverUrl +"/Help/GetHebroDate",{pro:id} ).toPromise().then(
                  response => {
                   return  response;
                  })
                .catch(v => { return false; });
              }
            
}
