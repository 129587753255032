import { Component, OnInit, EventEmitter, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Names } from 'src/app/classes/names';
import { NamesService } from 'src/app/services/names.service';
// import { P } from '@angular/core/src/render3';
import { NgForOf, NgIf } from '@angular/common';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { UserService } from 'src/app/services/user.service';
import { linkSync } from 'fs';
import { Cities } from 'src/app/classes/cities';
import { ViewNamesComponent } from '../view-names/view-names.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-name',
  templateUrl: './add-name.component.html',
  styleUrls: ['./add-name.component.css']
})
export class AddNameComponent implements OnInit {


constructor(  private name:NamesService, private cityid:MaagarMenageService, private router: Router) { }
//#region 
degel:boolean;
@Input() permission;

id1;
 update: boolean;
phone:boolean=true;
serchfind: boolean=true;;
phone2: boolean=true;;
phone1: boolean=true;;
n:Names =new Names();
n2:Names=new Names();
kod:number;
@Input() iduser;
list:Array<Cities>=new Array<Cities>();
//#endregion
  ngOnInit() {
 this.n2=new Names();
 this.n2.FamilyName="";
   this. ShowNames()
  }
  ShowNames()
  {
    this.cityid.ShowCity().then(res=>{
      this.list=res;
    })
  }
  SelectCity(event)
  {
this.kod=event.CityId;
  }
  addName() {
  this.n2.City=this.kod;
      this.name.addName(this.n2).then(res=>{
             if(!res)
             {
             
              //   alert("נפלה שגיאה בהוספה"  );                     
             }
       
     } )
     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(["/name/" + this.iduser, this.permission])
    });;} 
 
     checkTel() {
      
      var regexp;
      regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im);
  
      this.phone = regexp.test(this.n2.Phone);
    }
    checkTel1() {
      
      var regexp;
      regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  
      this.phone1 = regexp.test(this.n2.CellPhone1);
    }
    checkTel2() {
      
      var regexp;
      regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  
      this.phone2 = regexp.test(this.n2.CellPhone2);
    }
    checkemail() {
      ;
      var regexp;
      regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      this.serchfind = regexp.test(this.n2.Email);
    }
    }
    