<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <div id="mySidenav" class="sidenav"> 
    <a  id="blog" [hidden]="!add" (click)='shownames()' onclick="document.getElementById('id35').style.display='block'" > לרשימת המתנה</a> 
 </div>   
  <div id="id35" *ngIf="m==true" class="modal1" style="display: none">
    <span style="position: absolute;left: 44px;" onclick="document.getElementById('id35').style.display='none'" class="close" title="סגור">&times;</span>
    <form class="modal-content1" action="/action_page.php">
   <div  class="row" >
    <div class="col-75">
      <!-- <div class="container" style="position: absolute;  top:30px;left :10px ;width:800px;height: 800px;"> -->
        <form action="/action_page.php">
          <div class="row">
            <div class="col-50">
                <h3 style="position: absolute; left: 235px; top: 71px;"> הוספה לרשימת המתנה</h3>
              <div style="top: 20px;">
                <button  (click)="addmore()" style="position: absolute;top: 76px; left: 71px; border-radius: 300px;font-weight: bolder; border: 3px solid #888;" [hidden]="!exist">+</button>
                <button  (click)="del()" onclick="document.getElementById('id35').style.display='none'" class="close"   style="position: absolute;  top: 75px;
                left: 991px !important; height: 33px; width: 30px;  border-radius: 300px; border: 3px solid #888;color:#f44336
                ;" [hidden]="!exist"><i style="width: 100%;position: absolute; top: 4px; left: 1px; font-size: 18px;" class="fa fa-trash"></i></button>
                <label style="margin-top: -20px;"> דירוג</label>
                <input type="number" style="width: 120px;"  [(ngModel)]="n.derug" [ngModelOptions]="{standalone: true}">
                <label style="position: absolute;  left: 90px; top:116px;  width: 258px;">תאריך בקשה</label>
                <input type="date" style="position: absolute; left: 92px; top: 190px; height: 40px;"  (ngModelChange)="w.DateDesire=$event" [ngModel]="w.DateDesire| date:'yyyy-MM-dd'"  [ngModelOptions]="{standalone: true}" class="DateDesire"  
                 placeholder="תאריך בקשה" value="{{ myDate | date: 'yyyy-MM-dd' }}"  #DateDesire>
                <label >שם פרטי</label>
                <input type="text"   [(ngModel)]="n.FirstName" disabled [ngModelOptions]="{standalone: true}">
                <label style="position: absolute;  left: 90px;top: 243px;  width: 258px; ">שם משפחה</label>
                <input type="text" style="position: absolute; left: 92px;  height: 40px;"   [(ngModel)]="n.FamilyName" disabled [ngModelOptions]="{standalone: true}">

                     <label  >סכום</label>                                
                    <input type="number" class="Amont"  [(ngModel)]="w.Amont" [ngModelOptions]="{standalone: true}"  placeholder="סכום"  #Amont>
                    <label style="position: absolute;  left: 234px; top: 376px;" >מספר תשלומים</label>
                    <input style="position: absolute; left: 210px; top: 448px; height: 40px; width: 140px;" type="number" [(ngModel)]="w.NumOfPayment" [ngModelOptions]="{standalone: true}" class="NumOfPayment" placeholder="מספר תשלומים"  #NumOfPayment>
                    <label style="position: absolute;  top: 374px; left: 123px;" >סוג מטבע</label>
                    <select (change)="coin1(Coin.value)" style="width: 106px; position: absolute;  top: 448px; left: 91px;" [(ngModel)]="coin2" [ngModelOptions]="{standalone: true}" class="Coin"  placeholder="סוג מטבע"  #Coin><option *ngFor='let item of listcoin'>{{item.name}}</option></select> 
                    <label >הערות</label>
                    <textarea type="text" class="Remarks"  [(ngModel)]="w.Remarks" [ngModelOptions]="{standalone: true}" style="position: absolute;left: 91px; width: 540px;  " rows="3" placeholder="הערות"  #Remarks ></textarea>
                    <label style="margin-top: 154px;">שם ערב 1</label>
                    <ng-select  style="width: 350px;"
                    (change)='save($event)' [items]="listN1" bindValue="MAINID" #val1 bindLabel="Name"   >
                    </ng-select>  
                   <label style="position: absolute; top: 680px; left: 180px;">דירוג ערב 1</label>
                    <input type="number" style="position: absolute; left: 96px;   width: 170px; top: 752px;" [(ngModel)]="n2.derug" [ngModelOptions]="{standalone: true}">
                    <label style="margin-top: 60px;">שם ערב 2</label>
                    <ng-select   style="width: 350px;"   (change)='save1($event)' [items]="listN2" bindValue="MAINID" #val2 bindLabel="Name"   >
                    </ng-select> 
                    <label style="position: absolute; top: 802px; left: 180px;">דירוג ערב 2</label>
                    <input type="number" style="position: absolute; left: 96px;   width: 170px;top:  875px;" [(ngModel)]="n1.derug" [ngModelOptions]="{standalone: true}">
                    <label style="margin-top: 60px;">שם ערב 3</label>
                    <ng-select  style="width: 350px;"   (change)='save2($event)' [items]="listN3" bindValue="MAINID" #val3 bindLabel="Name"   >
                    </ng-select>         
                    <label style="position: absolute; top: 922px; left: 180px;">דירוג ערב 3</label>
                    <input type="number"  [(ngModel)]="n3.derug" style="position: absolute; left: 96px;   width: 170px;top:  995px;" [ngModelOptions]="{standalone: true}">
                    <label style="position: absolute; top: 1060px; left: 508px;" >מאושר לתאריך</label>       
                    <input  type="date" (ngModelChange)="w.OkToDate=$event" [ngModel]="w.OkToDate| date:'yyyy-MM-dd'"  [ngModelOptions]="{standalone: true}" class="OkToDate" style="position: absolute;      left: 372px;
                    bottom: -329px" placeholder="מאושר לתאריך"  #OkToDate>            
                    <label style="position: absolute; top: 1060px; left: 262px;">סכום מאושר</label>
                    <input type="number"  [(ngModel)]="w.OKAmount" [ngModelOptions]="{standalone: true}" class="OKAmount"style="position: absolute; left: 96px; top:1133px; height: 42px;"  placeholder="סכום מאושר"  #OKAmount>              
                    <label style="position: absolute;  left: 535px;  bottom: -349px;cursor: pointer;" for="status">סטטוס הבקשה</label>
                    <input [hidden]="!w.StatusBakasha" type="checkbox"style="position: absolute; left: 658px; bottom: -361px; width: 16px;   height: 16px;" class="StatusBakasha" id="status" (click)="CStatusBakasha()" checked="checked"   #StatusBakasha>

                    <input [hidden]="w.StatusBakasha" type="checkbox"style="position: absolute; left: 658px; bottom: -361px; width: 16px;   height: 16px;" class="StatusBakasha" id="status" (click)="CStatusBakasha()"    #StatusBakasha>
                    <label for="get" style="position: absolute; left: 528px;  bottom: -370px; cursor: pointer;" for="get">להכניס הלוואה? </label>
                    <input [hidden]="w.GetLoan"  type="checkbox" (click)="changevalue()" id="get1" style="position: absolute;    left: 659px; bottom: -383px; width: 15px; height: 15px;"class="GetLoan"    >
                    <input [hidden]="!w.GetLoan" type="checkbox" (click)="changevalue()" id="get" style="position: absolute;   left: 659px; bottom: -383px; width: 15px; height: 15px;" class="GetLoan" checked="checked"   >  
                  </div>
          </div>
        </div>
          <button type="button" onclick="document.getElementById('id35').style.display='none'" class="btn btn-primary btn-lg" (click)='savedesire()'>שמור</button>
            </form>

    </div>
</div>
    </form>