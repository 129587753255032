import { Component, OnInit, Input } from '@angular/core';
import { HokgviaService } from 'src/app/services/hokgvia.service';
import { Hok } from 'src/app/classes/hok';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { Router } from '@angular/router';
import { MafkidService } from 'src/app/services/mafkid.service';
import { LoansService } from 'src/app/services/loans.service';
import { NamesService } from 'src/app/services/names.service';
import { LOCALE_TEXT } from 'src/app/classes/constance';
@Component({
  selector: 'app-checks',
  templateUrl: './checks.component.html',
  styleUrls: ['./checks.component.css']
})
export class ChecksComponent implements OnInit {
  //#region 
  @Input() PERMISSION;
  nunhaf: Array<number> = new Array<number>()
  nunloan: Array<number> = new Array<number>()
  @Input() iduser;
  totalheczer: number = 0
  totalhaf: number = 0
  myDate: Date = new Date();
  hafkadac: boolean = true;
  heczerc: boolean = true;
  list;
  columnDefs;
  gridOptions;
  defaultColDef;
  rowData: Array<any>;
  columnDefs1;
  gridOptions1;
  defaultColDef1;
  rowData1: Array<any>;
  listhec;
  listc: any;
  mafkid: any;
  loan: any;
  rowSelection: string;
  rowSelection1: string;
  localeText: any;
  listnames: any;
  columnDefs2: any;
  gridApi: any;
  gridApi1: any;
  list1: any;
  listhec1: any;
  //#endregion
  constructor(private hok: HokgviaService, private maagar: MaagarMenageService, private maf: MafkidService, private router: Router, private name: NamesService, private l: LoansService) {
  }
  ngOnInit() {
    this.myDate = new Date();
    this.shownames()
    this.columnDefs2 = [
      {
        headerName: "",
        width: 60,
        field: "athlete",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      { headerName: 'שם מפקיד', field: 'Name', filter: "agTextColumnFilter", width: 110 },
      { headerName: 'שם בעל השיק', field: 'Notice', filter: true, width: 110 },
      {
        headerName: ' לועזי', field: 'DateForiegnHAfkada', filter: true, width: 100, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך ', field: 'DateHafkada', filter: true, width: 120 },
      { headerName: 'סכום ', field: 'Amount', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'עמלה', field: 'Amla', filter: true, width: 100 },
      { headerName: 'סה"כ', field: 'SUMTOGVIA', filter: true, width: 100 },
      // {headerName: 'מטרה', field: 'TypeOperation',filter: "agTextColumnFilter",width: 90},
    ]
    this.columnDefs1 = [
      {
        headerName: "",
        width: 60,
        field: "athlete",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      { headerName: 'שם מחזיר', field: 'Name', sortable: true, width: 110, filter: "agTextColumnFilter", },
      { headerName: 'שם בעל השיק', field: 'Notice', width: 110, filter: "agTextColumnFilter", },
      {
        headerName: ' לועזי', field: 'DateForiegnHeczer', filter: true, width: 100, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'עברי', field: 'DateHeczer', filter: true, width: 120 },
      { headerName: 'סכום', field: 'Scum', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'עמלה', field: 'Amla', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'סה"כ', field: 'SUMTOGVIA', filter: true, width: 100 },
    ];
  }
  shownames() {
    this.listnames = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.listnames == null) {
    this.name.ShowNames().then(res => {
      this.listnames = res })}
      if(this.listnames!=null)
      {
      this.showcheckshaf(this.myDate);
      this.ViewHeczerchecks(this.myDate);
      }
   
  }
  getcoins() {
    this.listc = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listc == null) {
      this.maagar.GetCoins().then(res => {
        this.listc = res
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listc));

      })
    }
  }
  buildgrid() {
    ;
    this.localeText = LOCALE_TEXT,
      this.columnDefs = this.columnDefs2,
      this.rowSelection = 'multiple',
      this.rowData = this.list;
  }
  buildgrid1() {
    this.localeText = LOCALE_TEXT,

      this.columnDefs = this.columnDefs1,
      this.rowSelection1 = 'multiple',
      this.rowData1 = this.listhec;
  }
  openmafkid(event) {
    ;
    this.maf.getmafkid(event.data.MafkidID).then(res => {
      this.mafkid = res
      const url =this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall/", this.iduser, this.PERMISSION, this.mafkid.MAINID, event.data.MafkidID]));
      window.open(url, '_blank');;

    })
  }

  openloan(event) {

    this.l.getloans(event.data.IdLoan).then(res => {
      this.loan = res;
      const url =  this.router.serializeUrl(this.router.createUrlTree(["/loans/" + this.PERMISSION, this.iduser, this.loan.MAINID, event.data.IdLoan]));
      window.open(url, '_blank');
    });
  }
  send(d: Date) {
    ;
    this.list1 = this.gridApi.getSelectedNodes()
    this.listhec1 = this.gridApi1.getSelectedNodes()
    this.list1.forEach(element => {
      this.nunhaf.push(element.data.ID)
    });
    this.listhec1.forEach(element => {
      this.nunloan.push(element.data.Id)
    });
    this.hok.sendChecksHaf(this.nunhaf)
    this.hok.sendChecksHal(this.nunloan)

  }
  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  onGridReady1(event: any) {
    this.gridApi1 = event.api;
  }
  heczer() {
    this.heczerc = !this.heczerc;

  }
  hafkada() {
    this.hafkadac = !this.hafkadac;
  }
  fun() {

    this.showcheckshaf(this.myDate);
    this.ViewHeczerchecks(this.myDate);
  }
  showcheckshaf(date: Date) {

    this.hok.ViewHafkadachecks(date).then(res => {
      this.list = res;
      this.list.forEach(element => {
        this.totalhaf += element.Amount + element.Amla;
        //           this.maf.getmafkid(element.MafkidID).then(res1=>{var b=res1 
        // ;
        //           element['NameM']=this.listnames&&this.listnames.find(a=>a.MAINID==b.MAINID)?this.listnames.find(a=>a.MAINID==b.MAINID).FamilyName +' '+this.listnames.find(a=>a.MAINID==b.MAINID).FirstName:'';
        element['SUMTOGVIA'] = element.Amount + element.Amla;

        element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
      });
      this.buildgrid();
    })
  }

  ViewHeczerchecks(date: Date) {
    this.hok.ViewHeczerchecks(date).then(res => {
      this.listhec = res;
      ;

      if (this.listhec != null) {
        this.listhec.forEach(element => {
          this.totalheczer += element.Scum + element.Amla;
          //           this.maf.getmafkid(element.MafkidID).then(res1=>{var b=res1 
          // ;
          //           element['NameM']=this.listnames&&this.listnames.find(a=>a.MAINID==b.MAINID)?this.listnames.find(a=>a.MAINID==b.MAINID).FamilyName +' '+this.listnames.find(a=>a.MAINID==b.MAINID).FirstName:'';

          element['SUMTOGVIA'] = element.Scum + element.Amla;


          element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + ' ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : '';
        });
      }
      this.buildgrid1();
    })
  }
}
