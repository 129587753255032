import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kupa',
  templateUrl: './kupa.component.html',
  styleUrls: ['./kupa.component.css']
})
export class KupaComponent implements OnInit {
  constructor( private router:Router) { }
  ngOnInit(
    
  ) {  
  }
  addToKupa(Id:number,Category:string,Details:string,DateOperation:Date,Amount:number,AmountEnter:number,AmountFuture:number) {
    //     // this.n.MAINID=idp;
    //     this.kup.Id=Id;
    //     this.kup.Category=Category;
    //     this.kup.Details=Details;
    //     this.kup.DateOperation=DateOperation;
    //     this.kup.Amount=Amount;
    //     this.kup.AmountEnter=AmountEnter;  
    //     this.kupa.addToKupa(this.kup).then(res=>{  
    //          if(res)
    //          {
    //            this.refresh.emit(this.kup);
    //            alert("התוסף בהצלחה"  );
    //                 // location.href = "/Menu";                         
    //          }
    //  } )
     }
     fun()
     {
     }
}
