import { Component, OnInit, Input } from '@angular/core';
import { Names } from 'src/app/classes/names';
import { NamesService } from 'src/app/services/names.service';

@Component({
  selector: 'app-showname',
  templateUrl: './showname.component.html',
  styleUrls: ['./showname.component.css']
})
export class ShownameComponent implements OnInit {

  constructor(private name1:NamesService) { }
  //#region 
  @Input() id
  @Input() name

n:Names=new Names();
  //#endregion
  ngOnInit() {
    // if(this.name)
    // {
    //   this.
    // }
    this.showname(this.id);
    this.n.CellPhone1
  }
  showname(id:number) {
    if(id!=null)
    {
     
    this.name1.GetName(id).then(res => {  
      this.n=res;
    })}}
}
