
<app-main  [user]="PERMISSION" [iduser]="iduser" ></app-main>

 <span  class="label">{{month}}/{{year}}</span>
 <br>
 <button type="button" class="button" (click)='excelinsert()' >+</button>
 <br>
 <!-- <button type="button" class="button button1" (click)='filterlist()' >סנן</button> -->


 <button type="button" class="button button1" (click)='excelinsertCheck()' >++</button>
<br>

 <h6 >excel</h6>
 <br>
 <div [hidden]="!filter" style="height:300px;width: 240px;border-bottom-color: black; position: relative; top:65px;left:10px; background-color: gray" >
  <span class="label1" style="position:relative; left: 163px;width: 100% !important;font-size: 14.5px;  border: 0.8px solid white">:מתאריך</span> 
  <input type="date"#d class="input special" style="position:relative; left:-71px">  
  <br>
  <span class="label1" style="position:relative; top:33px; left: 161px;border: 0.8px solid white;
  ">:עד תאריך</span> 
  <!-- ;position: relative; left:350px; top:100px -->
<br>
 <input type="date"#d1 class="input special"> 
<br>
<br>

<select class="custom-select" (change)='valuechange1(val.value)' #val style="background-color:black;direction: rtl;text-align: right;position: relative; left:158px; color: white;  width: 80px ; ">
<option >יותר מ</option>
<option >שווה ל</option>
<option > פחות מ</option>
</select>
<br>
<br>
<input type="number"#num placeholder="0"  step="1000" style="position:relative; top: -64px"> 
<br>
<br>
<select class="custom-select" #select1 (change)='valuechange2(select1.value)' style="background-color:black;position: relative;direction: rtl; left:3px;top:-60px;text-align: center; color: white;  width: 140px ; ">
  <option *ngFor='let item of list1'  >{{item.NameCategory}}</option>
  </select>
<select class="custom-select" #select (change)='valuechange(select.value)' style="background-color:black;position: relative;direction: rtl; left:18px;top:-60px;text-align: center; color: white;  width: 80px ; ">
<option >זכות</option>
<option >חובה</option>

</select>
<br>
<br>
<button type="button" class="button" (click)='filterdata(d.value, d1.value,num.value)' style="position:relative;width: 238px; top: -60px ;left: 1px; border: 0.8px solid white">הצג</button>
</div>
<div style="text-align:center; position: absolute;top:190px ;left: 755px;">
          
  <span class="dot" (click)="currentSlide(12)">12</span> 
  <span class="dot" (click)="currentSlide(11)">11</span> 
  <span class="dot" (click)="currentSlide(10)">10</span> 
  <span class="dot" (click)="currentSlide(9)">9</span> 
  <span class="dot" (click)="currentSlide(8)">8</span> 
  <span class="dot" (click)="currentSlide(7)">7</span> 
  <span class="dot" (click)="currentSlide(6)">6</span> 
  <span class="dot" (click)="currentSlide(5)">5</span>          
  <span class="dot" (click)="currentSlide(4)">4</span> 
  <span class="dot" (click)="currentSlide(3)">3</span> 
  <span class="dot" (click)="currentSlide(2)">2</span> 
  <span class="dot" (click)="currentSlide(1)">1</span> 
</div>
<ag-grid-angular 
style="  width:62%;height: 500px;
position: absolute;
left: 320px;
top:300px;direction: rtl;" 
class="ag-theme-balham"
[rowData]="rowData" 
[columnDefs]="columnDefs"
[enableRtl]="true"
[localeText]="localeText" 
[rowSelection]="rowSelection"
[floatingFilter]="true"  
(cellEditingStarted)='cellEditingStarted($event)'
(cellEditingStopped)="cellEditingStopped($event)"
>
</ag-grid-angular>
  <!-- <table id='customers'>
    (cellClicked)='view($event)'

      <tr class='row'>
        <td class='col-1'>
          <thead>תאריך</thead>
        </td>
        <td class='col-1'>
          <thead>  חובה</thead>
        </td>
        <td class='col-1'>
            <thead> זכות </thead>
          </td>
        <td class='col-1'>
      
          <thead>יתרה</thead>
        </td>
        <td class='col-4'>
          <thead>הערות</thead>
        </td>
        <td class='col-1'>
          <thead>שם</thead>
        </td>
        <td class='col-1' >
          <thead>סטטוס</thead>
        </td>
        <td class='col-2'>
          <thead>קטגוריה</thead>
        </td>
      <tr class='row' *ngFor='let item of list' >
        <td class='col-1'>
    
          {{item.C_dateoperation|  date:"dd/MM/yy"}}
        </td>
        <td class='col-1'>
    
          {{item.C_SCUMChova_}}
        </td>
        <td class='col-1'>
    
            {{item.C_SCUMZcut_}}
          </td>
        <td class='col-1'>
          {{item.Totalscum}}
        </td>
        <td class='col-4'>
    
          {{item.Remark}}
        </td>
        <td class='col-1'>
    
              {{item.Name}}  {{item.fName}}
            </td> <td class='col-1'>
    
                {{item.Status}}
              </td>
              <td class='col-2'>
    
                {{item.categoryName}}
              </td>
            <td class='col-2'>
    
                  {{item.idloanormafkid}}
                </td> -->
              
                
              <tr>
            <!-- </table> -->
            <div class="slideshow-container" style="text-align:center; position: absolute;top: 700px ">

             
              <a class="prev" (click)="plusSlides(-1)" title="{{year-1}}">&#10095;</a>
              <a class="next" (click)="plusSlides(1)" title="{{year+1}}">&#10094;</a>
            </div>
            <br>
            
            <!-- The dots/circles -->
            
