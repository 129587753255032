import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import "ag-grid-enterprise";
import { UserService } from 'src/app/services/user.service';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { User } from 'src/app/classes/user';
@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.css'],

})
export class WorkersComponent implements OnInit {
  rowData: any;
  list: any;
  n2:User=new User();
  rowSelection: string;
  localeText: any;
  Update: boolean;
  constructor(private u:UserService) { }
  columnDefs;
  gridOptions;
  ngOnInit() {
    this.ShowUsurs();
    this.columnDefs = [
      {headerName: 'שם משפחה', field: 'LastName',editable: true  ,width: 120,filter: "agTextColumnFilter"},
      {headerName: 'שם פרטי', field: 'FirstName',sortable: true ,editable: true ,width: 120,filter: "agTextColumnFilter"},
      {headerName: 'סיסמא', field: 'UserPassword',sortable: true ,editable: true ,filter: "agTextColumnFilter",width: 100},
      {headerName: 'הרשאה', field: 'permission',width: 130,editable: true,cellEditor: "agRichSelectCellEditor",filter: "agTextColumnFilter", cellEditorParams: { values:["מנהל","מזכירה","צפיה"] }}
    ]
  }
  openWorker(event)
  {
this.n2=event.data;
;
  }
  ShowUsurs() {
  this.u.GetUsers().then(res=>{this.list=res
    if(this.list.length>0)
    {
    this.n2=this.list[0];
    this.Update=true;}
    this.buildgrid()}
    )

  }
  Save()
  {
    if(this.Update==true)
    {

    this.u.SaveUser(this.n2); }
    else{
      this.u.AddUser(this.n2); }

    
  }
  Add()
  {
    this.Update=false;
  }
  buildgrid() {
    this.localeText = LOCALE_TEXT,
    this.rowSelection= 'single',
    this.rowData = this.list;
     this. columnDefs= this.columnDefs
 
    }  

}
