import { Component, OnInit, Input } from '@angular/core';
import { MAfkidCount } from 'src/app/classes/mafkid-count';
import { MafkidService } from 'src/app/services/mafkid.service';
import { NamesService } from 'src/app/services/names.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NeztigimService } from 'src/app/services/neztigim.service';
import { Maslul } from 'src/app/classes/maslul';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  //#region params
// mafkid:MAfkidCount=new MAfkidCount();
maf:MAfkidCount=new MAfkidCount();
  listL;
  listmaslul:Array<Maslul>;
id1:number;
@Input() id;
@Input() idmafkid;
maslul:boolean;
degel:boolean;
  w: boolean;
  idc: any;
  iduser: any;
  PERMISSION: any;
  list: any;
  index: number;
  index1: any;
  items: any;
  mafpail: boolean=true;
  //#endregion
  constructor(private mafkid1:MafkidService,private router: Router,private netzigim:NeztigimService,private route:ActivatedRoute,private name:NamesService) { 
   this.route.params.subscribe((params:Params) => {
    this.iduser = params.iduser;
    this.PERMISSION = params.id;
    this.id = params.mainid;
    if (params.index!=null)
    {
      this.index1=params.index;
      var x =this.index1;
var index2: number = +x;
this.index=index2;
      ;
    }
  ;})
  }
  ngOnInit() {
    this.showContacts();
    this.showNames();

    this.showmaslul();
    this.id1=this.id;
  
  }
  showNames() {
    this.items = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.items == null) {
    this.name.ShowNames().then(res => {
      this.items = res;
        this.items.forEach(element => {
                if(element['CellPhone1']!=null&& element['FirstName']!=null)
                {
                element['Name'] ="שם: "+ element['FamilyName']+" "+element['FirstName']+" "+ "פל': "+element['CellPhone1']}
              else{
                if(element['CellPhone2']!=null&& element['FirstName']!=null)
                {
                element['Name'] =element['FamilyName']+" "+element['FirstName']+" "+element['CellPhone2']}
                else{
                  if(element['Phone']!=null&& element['FirstName']!=null)
                  {
                  element['Name'] =element['FamilyName']+" "+element['FirstName']+" "+element['Phone']}
                  else{
                  if(element['CellPhone1']!=null)
                  {
                  element['Name'] =element['FamilyName']+" "+element['CellPhone1']}
                else{
                  if(element['CellPhone2']!=null)
                  {
                  element['Name'] =element['FamilyName']+" "+element['CellPhone2']}
                  else{
                    if(element['Phone']!=null)
                    {
                    element['Name'] =element['FamilyName']+" "+element['Phone']}
                    else{
                    element['Name'] =element['FamilyName']+" "+element['FirstName']}              
                }}}}}
      })
      })}
      
  }
  ShowMafkidim(id: any) {
    this.mafkid1.showhafkadacount(id).then(res=>{this.list=res
      if  (this.idmafkid!=-1)
      {  
        this.getmafkid(this.idmafkid)   ;
      //  this.current(this.index)
      } 
      else{
     this.Addhaf()}})
  }
  pail()
  {
    this.mafpail=!this.mafpail
  }
  showmaslul() {
this.mafkid1.getmasluls().then(res=>{
 this.listmaslul= res 
 ;

 this.listmaslul.push(new Maslul())
    this.ShowMafkidim(this.id);

 })}
 current(num:number)
 {
   ;
   if((this.index+num<this.list.length)&&(this.index+num>0))
   {
  this.maf=this.list[num+this.index];
  this.index+=num;
   this.w=this.maf.man;;     
   if(this.maf.IdMaslul==null)
   {
     this.maf.IdMaslul=0;
   }
   this.mafpail=this.maf.pail;
   if(this.mafpail==null)
   {
   this.mafpail=true

   }
   this.maf.NameMaslul=this.listmaslul&&this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul)?this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul).remark:null 
   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  this.router.navigate(["/Hafkadotall1/" + this.iduser, this.PERMISSION, this.maf.MAINID, this.maf.Id,this.index]);
});
}
else{
  this.index=0;
  this.maf=this.list[this.index];
   this.w=this.maf.man;;   
   this.mafpail=this.maf.pail;
   if(this.mafpail==null)
   {
   this.mafpail=true

   }
   if(this.maf.IdMaslul==null)
   {
     this.maf.IdMaslul=0;
   }
         this.maf.NameMaslul=this.listmaslul&&this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul)?this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul).remark:null 
   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate(["/Hafkadotall1/" + this.iduser, this.PERMISSION, this.maf.MAINID, this.maf.Id,0]);
});
}
 }
    //#region bool
  man()
  {
   this.w=!this.w;
  }    
  savecontact(event)
  {
   this.idc=event.MAINID;
  }
  //#endregion
  getmafkid(idmafkid: number){
    this.mafkid1.getmafkid(idmafkid).then(res=>{
      this.maf=res;
        this.w=this.maf.man;; 
        this.mafpail=this.maf.pail;
        if(this.mafpail==null)
        {
        this.mafpail=true

        }
    
if(this.maf.IdMaslul==null)
{
  this.maf.IdMaslul=0;
}
      this.maf.NameMaslul=this.listmaslul&&this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul)?this.listmaslul.find(a=>a.KodMaslul ==this.maf.IdMaslul).remark:null } )}

delhaf()
{
 if(window.confirm("האם הינך בטוח שברצונך למחוק חשבון הפקדה זה?"))
 {
   this.mafkid1.delMaf(this.maf.Id).then(res=>{
     if(!res)
    {
alert("לא ניתן למחוק חשבון עם הפקדות");
    }
    else{
      this.router.navigate(["/name/"+this.iduser,this.iduser]);

    }
  });
 }
}
    Addhaf()
    {
      // this.router.navigate(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.maf.MAINID, -1]);;
      this.maf=new MAfkidCount();
      this.maf.MAINID=this.id;
      ;
      this.maf.pail=this.mafpail;
      this.w= null;
    
      this.mafkid1.addName(this.maf).then(res=>{
           this.idmafkid=res 
       this.maf.Id=this.idmafkid;
       this.maf.IdMaslul=0;
       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.maf.MAINID, this.idmafkid]);
    });
  });
    }
  showContacts() {
    this.netzigim.Shownetzigim().then(res => {
      this.listL = res;});
    }
  hafkadot(id:number)
  {
     this.router.navigate(["/hafkadot"]);
  }
 ShtarC()
 {
   ;
   this.maf.SHTAR=!this.maf.SHTAR;
 }
  addMafkid() {
    if(this.maf.NameMaslul)
    {
    this.maf.IdMaslul= this.listmaslul.find(a=>a.remark ==this.maf.NameMaslul).KodMaslul;
    }
    else
    {
      this.maf.IdMaslul=null;

    }
    this.maf.man=this.w;
    this.maf.pail=this.mafpail;
    if(this.maf.Id==-1||this.maf.Id==null||this.maf.Id==0)
    {
    this.mafkid1.addName(this.maf).then(res=>{
       this.idmafkid=res 
     this.router.navigate(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.maf.MAINID, this.idmafkid]);});}
     else{
       ;
      this.mafkid1.update(this.maf)
     }

    
}}
