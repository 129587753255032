import { Component, OnInit, Input } from '@angular/core';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { NamesService } from 'src/app/services/names.service';
import { MafkidService } from 'src/app/services/mafkid.service';
import { Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans.service';

@Component({
  selector: 'app-showhok',
  templateUrl: './showhok.component.html',
  styleUrls: ['./showhok.component.css']
})
export class ShowhokComponent implements OnInit {


  constructor(private hok:HokMenageService, private router:Router,private mafkid:MafkidService,private loan:LoansService) { }
  //#region 
list;
@Input() user
@Input() iduser;
list1;
id1;
mainid;
//#endregion
  ngOnInit() {
  }
  showhafkadot(id:number)
  {
    this.mainid=id;
   if (this.mainid!=null)
   {
   this.hok.showhafkadacount(id).then(res=>{
    this.list=res;
  })}
  }
  showloans(id:number)
  {

    this.mainid=id;
    if (this.mainid!=null)
    {
   this.hok.Showoans(id).then(res=>{
    this.list1=res;
  })}}
  viewdetails(id:number)
  {
    //this. index=this.list.findIndex(x => x.ID==id);
this.mainid=this.mafkid.getmafkid(id).then(res=>{
  const s=res;
this.mainid=s.MAINID
    // this.router.navigate(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]);
    //  const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall1/"+this.iduser,this.user,this.mainid ,id,0]));
    const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall/"+this.iduser,this.user,this.mainid ,id]));

     window.open(url, '_blank');});
   // }
     // this.router.navigate(["/Hafkadotall/"+this.iduser,  this.user,this.mainid ,id]);
      
  
  }
  viewdetails1(id:number)
  {
    ;
    //this. index=this.list.findIndex(x => x.ID==id);
    this.mainid=this.loan.getloans(id).then(res=>{
      const s=res;
    this.mainid=s.MAINID
    // this.router.navigate(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]);
     const url = this.router.serializeUrl(this.router.createUrlTree(["/loans1/"+this.iduser,this.user,this.mainid,id,0]));
     window.open(url, '_blank');
   // }
  })
    //  this.router.navigate(["/loans/"+this.iduser,this.user,this.mainid ,id]);
  }
}
