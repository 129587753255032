import { Component, OnInit } from '@angular/core';
import { LoansService } from 'src/app/services/loans.service';
import { NamesService } from 'src/app/services/names.service';

@Component({
  selector: 'app-arevtotalscum',
  templateUrl: './arevtotalscum.component.html',
  styleUrls: ['./arevtotalscum.component.css']
})
export class ArevtotalscumComponent implements OnInit {
  list: any;
  shekel;
  dolar;
  id1: any;
yoro;
  constructor(private loan:LoansService) { }

  ngOnInit() {
    this.id1=NamesService.id;

   // this.Showarev();
  }
 Showarev(id:number) {
  
   this.loan.ShowArev(id).then(res => {
    this.list = res;
    this.shekel=this. list[0];
    this.dolar=this. list[1];
this.yoro=this.list[2];
    })}}
