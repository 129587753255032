import { Coin } from "./coin";
import { type } from "os";

export class Totalscumcount {
     Id :number;
     idmafkid :number;
     coin :number;
     scum :number;
    type :string;
    constructor()
    {
        this.Id=null;
        this.idmafkid=null;
        this.coin=1;
        this.scum=null;
        this.type=null;
    }
}
