
 <img src="/assets/1/person.png" onclick="document.getElementById('id05').style.display='block'" style="width: 20px; width: 58px; position: relative;left: 95px; height: 51px; top: 58px;height: 51px;  " >
  <div id="id05" class="modal1" style="display: none">
    <span onclick="document.getElementById('id05').style.display='none'" class="close" title="סגור">&times;</span>
    <form class="modal-content1" action="/action_page.php">
      <div class="container" style="text-align: center">
        <div class="row">
    <div class="titel">
        <h4 style="position: relative;top:30px">Add Name</h4>
        <p style="position: relative;left: -38px;top: 110px;"> תז </p>
        <input type=" text"   class=" form-control"  style="position: relative;left: -110px;top: 100px;" [(ngModel)]="n2.ID" [ngModelOptions]="{standalone: true}" #ID > 
        <br>
        <br>
        <p  style="position: relative;left: -378px;top:  -9px;"> תואר</p>
        <input type=" text" class=" form-control"  style="position: relative;left: -110px;top: 31px;"  [(ngModel)]="n2.Toar" [ngModelOptions]="{standalone: true}" style="position: relative;left: -450px;top: -22px;" value="n2.Toar" #Toar>
        <p style="position: relative;left: -55px;top:  41px;"> שם פרטי</p>
        <input type=" text" class=" form-control"  style="position: relative;left: -110px;top: 31px;"[(ngModel)]="n2.FirstName" [ngModelOptions]="{standalone: true}"  #Fname>
        <p style="position: relative;left: -402px;top: -33px"> שם משפחה</p>
        <input type=" text" class=" form-control"   style="position: relative;left: -450px;top: -43px;" [(ngModel)]="n2.FamilyName" [ngModelOptions]="{standalone: true}" #Lname>
        <p style="position: relative;left: -63px;top:  18px;"> מס' פלאפון  1 </p>
        <input type=" text" class=" form-control"  style="position: relative;left: -110px;top: 11px;" [(ngModel)]="n2.CellPhone1"   (change)="checkTel1()"[ngModelOptions]="{standalone: true}"  #Phone1>
        <label [hidden]="phone1"  style="position: relative;left: -110px;top: 21px;color: red;"> מס פלאפון שגוי</label>

        <p style="position: relative;left: -402px;top: -53px">מס' פלאפון  2</p>
        <input type=" text" class=" form-control"  style="position: relative;left: -450px;top: -63px;" [(ngModel)]="n2.CellPhone2"   (change)="checkTel2()" [ngModelOptions]="{standalone: true}"  #Phone2>
        <label [hidden]="phone2" style="position: relative;left: -450px;top: -53px;color: red"> מס פלאפון שגוי</label>
        <p  style="position: relative;left: -55px;top:  0px;"> מס' טלפון</p>
        <input type=" text" class=" form-control" style="position: relative;left: -110px;top: -10px;"[(ngModel)]="n2.Phone" [ngModelOptions]="{standalone: true}" (change)="checkTel()" #TEL>
        <label [hidden]="phone"  style="position: relative;left: -110px;top: 0px;color: red"> מס טלפון שגוי</label>
        <p style="position: relative;left: -376px;top: -73px"> פקס</p>
        <input type=" text" class=" form-control" style="position: relative;left: -450px;top: -84px;" [(ngModel)]="n2.Fax" [ngModelOptions]="{standalone: true}"   #Fax>
        <p  style="position: relative;left: -37px;top:  -19px;"> מייל</p>
        <input type=" text" class=" form-control" style="position: relative;left: -110px;top: -30px;"  [(ngModel)]="n2.Email"    (change)="checkemail()" [ngModelOptions]="{standalone: true}"   #Email>
        <label [hidden]="serchfind"  style="position: relative;left: -110px;top: 0px;color: red"> מייל שגוי</label>
        <p style="position: relative;left: -376px;top: -95px"> עיר</p>
        <!-- <select  class="custom-select" style="position: relative;left: -450px;top: -105px;"  #City (change)='SelectCity(City.value)' >
          <option   *ngFor='let status of list'>{{status.NameCity}}</option> 
         </select> -->
         <ng-select  style="position: relative;left: -450px;top: -105px;" 
         (change)='SelectCity($event)' [items]="list" bindValue="CityId" #val bindLabel="NameCity"   >
         </ng-select>
        <p style="position: relative;left: -37px;top:  -41px;"> כתובת </p>
        <input type="text" class=" form-control"  style="position: relative;left: -110px;top: -52px;"[(ngModel)]="n2.Adress" [ngModelOptions]="{standalone: true}" #Adress>
        <p style="position: relative;left: -376px;top: -115px"> מיקוד </p>
        <input type="text" class=" form-control" style="position: relative;left: -450px;top: -125px;" [(ngModel)]="n2.Mikud" [ngModelOptions]="{standalone: true}" #Mikud>
        <p style="position: relative;left: -37px;top:  -63px;"> הערות </p>
        <input type="text" class=" form-control"   style="position: relative;left: -110px;top: -74px;" [(ngModel)]="n2.Remarks" [ngModelOptions]="{standalone: true}"  #Remarks>
        <p style="position: relative;left: -400px;top: -137px"> שלח טופס ל </p>
        <input type="text" class=" form-control" style="position: relative;left: -450px;top: -147px;" [(ngModel)]="n2.senddocumentsto" [ngModelOptions]="{standalone: true}"  #senddocumentsto>
        <div class="clearfix">
          <button type="button" onclick="document.getElementById('id05').style.display='none'"  style="position: relative;left: 15px;top: -52px;width: 150px;" class="cancelbtn">ביטול</button>
          <button  type="submit" onclick="document.getElementById('id05').style.display='none'" class="ok"  style="position: relative;left: -190px;top: -120px;" (click)='addName()'>אישור</button>
      
        </div>
      </div>
  </div>
  </div>
    </form>
  </div>
  
  
  