import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation, ViewRef } from '@angular/core';
import { NamesService } from 'src/app/services/names.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoansService } from 'src/app/services/loans.service';
import { MafkidService } from 'src/app/services/mafkid.service';
import { Names } from 'src/app/classes/names';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { ShowhafkadotcountComponent } from '../showhafkadotcount/showhafkadotcount.component';
import { DropDownEditorComponent } from '../drop-down-editor/drop-down-editor.component';
import { ShowloansComponent } from '../showloans/showloans.component';
import { ShowhokComponent } from '../showhok/showhok.component';
import { HOKComponent } from '../hok/hok.component';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { ShownameComponent } from '../showname/showname.component';
import { LOCALE_TEXT } from 'src/app/classes/constance';
@Component({
  selector: 'app-view-names',
  templateUrl: './view-names.component.html',
  styleUrls: ['./view-names.component.css'
  ],
})
export class ViewNamesComponent implements OnInit {
  //#region params
  public list: Array<Names>;
  public list2: Array<Names>;
  boolhaf: boolean;
  boolloan: boolean;
  idintbl
  listloans;
  items;
  filter1: boolean;
  listnames;
  bool;
  justhaf: boolean = false;
  justloan: boolean = false;
  degel: boolean;
  columnDefs;
  nameupdate: Names = new Names();
  nameafterupdate: Names = new Names();
  cityBefor;
  gridOptions;
  defaultColDef;
  PERMISSION;
  permissionview;
  rowData: Array<any>;
  listNemesCity: Array<string> = new Array<string>();
  mainid: any;
  iduser: any;
  length: number;
  num: number = 1;
  rowSelection: string;
  localeText: any;
  Derug: any;
  DerugH: boolean = false;
  //#endregion
  constructor(private cd: ChangeDetectorRef, private h: HokMenageService, private name: NamesService, private maf: MafkidService, private route: ActivatedRoute, private router: Router, private maagar: MaagarMenageService, private haf: MafkidService, private loan: LoansService) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    })
  }
  //#region 
  @ViewChild(ShowloansComponent) appShowloans: ShowloansComponent;
  @ViewChild(ShowhokComponent) appHok: ShowhokComponent;
  @ViewChild(ShowhafkadotcountComponent) appShowhafkadotcount: ShowhafkadotcountComponent;
  @ViewChild(HOKComponent) appHok1: HOKComponent;
  @ViewChild(ShownameComponent) apps: ShownameComponent;
  //#endregion
  ngOnInit() {
    
    this.viewcities();
    this.columnDefs = [
      { headerName: 'תז', field: 'ID', width: 90, filter: "agTextColumnFilter", editable: true },
      { headerName: 'תואר', field: 'Toar', width: 90, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'משפחה', field: 'FamilyName', sortable: true, sort: 'asc', width: 100, filter: "agTextColumnFilter", editable: true },
      { headerName: 'פרטי', field: 'FirstName', sortable: true, filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'טלפון', field: 'Phone', width: 100, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'פלאפון 1', field: 'CellPhone1', width: 100, editable: true, filter: "agTextColumnFilter" },
      { headerName: ' פלאפון2', field: 'CellPhone2', filter: "agTextColumnFilter", width: 100, editable: true },
      { headerName: 'כתובת', field: 'Adress', filter: "agTextColumnFilter", width: 100, editable: true },
      {
        headerName: 'עיר', field: 'cityName', width: 90, editable: true, filter: "agTextColumnFilter", cellEditorFramework: DropDownEditorComponent, cellEditorParams: {
          options: [{
            value: [this.listnames]
          }]
        },
      },
      { headerName: 'מיקוד', field: 'Mikud', width: 100, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'מייל', field: 'Email', filter: "agTextColumnFilter", width: 150, editable: true },
      { headerName: 'פקס', field: 'Fax', width: 100, editable: true, filter: "agTextColumnFilter" },
      { headerName: 'לשלוח', width: 90, field: 'senddocumentsto', filter: "agTextColumnFilter", editable: true },
      { headerName: 'הערות', field: 'Remarks', filter: "agTextColumnFilter", width: 100, editable: true },
      {
        headerName: 'דירוג', field: 'derug', filter: "agNumberColumnFilter",
        width: 90, editable: true
      }
    ];
  }
  //#region aggrid
  cellEditingStarted(event) {
    this.nameupdate = event.data;
    this.cityBefor = event.data.cityName;
  }
  cellEditingStopped(event) {
    this.nameafterupdate.MAINID = event.data.MAINID;
    this.nameafterupdate.FamilyName = event.data.FamilyName;
    this.nameafterupdate.FirstName = event.data.FirstName;
    this.nameafterupdate.ID = event.data.ID;
    this.nameafterupdate.CellPhone1 = event.data.CellPhone1;
    this.nameafterupdate.CellPhone2 = event.data.CellPhone2;
    this.nameafterupdate.Phone = event.data.Phone;
    this.nameafterupdate.Adress = event.data.Adress;
    this.nameafterupdate.Email = event.data.Email;
    this.nameafterupdate.Remarks = event.data.Remarks;
    this.nameafterupdate.Toar = event.data.Toar;
    this.nameafterupdate.City = event.data.City;
    this.nameafterupdate.MAINID = event.data.MAINID;
    this.nameafterupdate.Mikud = event.data.Mikud;
    this.nameafterupdate.Fax = event.data.Fax;
    this.nameafterupdate.derug = event.data.derug;
    this.nameafterupdate.senddocumentsto = event.data.senddocumentsto;
    if (this.cityBefor != event.data.cityName) {
      this.nameafterupdate.City = this.listnames.find(a => a.NameCity == event.data.cityName).CityId;
      ;;
    }
    this.name.UpdateName(this.nameafterupdate);
  }
  buildgrid() {

    this.columnDefs = this.columnDefs,
      this.rowSelection = 'single',
      this.rowData = this.list;
    this.localeText = LOCALE_TEXT

  }
  CalculateDerug() {
    if (this.mainid != null) {
      this.name.CalculateDerug(this.mainid).then(res => {
        this.Derug = res
        this.DerugH = true
      })
    }
    else {
      alert("לא נבחר שם");
    }
  }
  buildgrid1(event) {
      this.columnDefs = this.columnDefs,
      this.rowSelection = 'single',
      this.rowData = this.list;
  }
  viewdetails1() {
    this.appShowloans = new ShowloansComponent(this.cd, this.name, this.router, this.maf, this.loan);
    this.appHok1 = new HOKComponent(this.h, this.router, this.name);
    this.appHok = new ShowhokComponent(this.h, this.router, this.maf, this.loan);
    this.appShowhafkadotcount = new ShowhafkadotcountComponent(this.cd, this.maf, this.router, this.name);
    this.apps = new ShownameComponent(this.name);
    this.degel = true;
    this.mainid = NamesService.mainid;

    if (!(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }
  onGridReady(params) {
  }
  viewdetails(event) {
    this.num = event.rowIndex + 1;
    this.buildgrid1(event);
    this.degel = true;
    this.DerugH = false
    this.mainid = event.data.MAINID;
    NamesService.mainid = this.mainid;
    if (this.apps != null) {
      this.apps.showname(event.data.MAINID);
    }
    this.appShowhafkadotcount.showhafkadot(event.data.MAINID);
    this.appShowloans.showloans(event.data.MAINID);
    this.appHok.showhafkadot(event.data.MAINID);
    this.appHok.showloans(event.data.MAINID);
    this.appHok1.show(event.data.MAINID);
    this.apps.showname(event.data.MAINID);
    this.appShowhafkadotcount.showhafkadot(event.data.MAINID);
    this.appShowloans.showloans(event.data.MAINID);
    this.appHok.showhafkadot(event.data.MAINID);
    this.appHok.showloans(event.data.MAINID);
    this.appHok1.show(event.data.MAINID);
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();

    }
  }
  //#endregion
  //#region   function
  deletename() {
    if (this.mainid != null) {
      alert("האם אתה בטוח שברצונך למחוק?");
      this.name.delete(this.mainid).then(res => {
        if (!res) {
          alert("לא ניתן למחוק");
        }
        else {
          this.showNames();
        }
      })
    }
    else {
      alert("לא נבחר שם");
    }
  }
  PRINTENVELOPE() {
    if (this.mainid != null) {
      this.name.PRINTENVELOPE(this.mainid);
    }
    else {
      alert("לא נבחר שם");
    }
  }
  openfolder() {
    if (this.mainid != null) {
      this.name.openfolder(this.mainid);
    }
    else {
      alert("לא נבחר שם");
    }
  }
  report() {
    if (this.boolhaf && this.boolloan) {
      this.name.bankreports(this.mainid);
    }

    else {
      if (this.boolloan) {
        this.loan.bankreports(this.mainid);
      }
      else {
        if (this.boolhaf) {
          this.haf.bankreports(this.mainid);
        }
      }
    }
  }
  //#endregion
  //#region //shownames
  ShowJustmafkidim() {
    ;;
    this.justhaf = !this.justhaf;
    this.Filternames();
  }
  ShowJustloans() {
    this.justloan = !this.justloan;
    this.Filternames();
  }
  Filternames() {
    if (this.justhaf && this.justloan) {
      this.Showboth();
    }
    else {
      if (this.justhaf && !this.justloan) {
        this.list = this.list2;
        this.ShowJustmafkidim1()
      }
      else {
        if (!this.justhaf && this.justloan) {
          this.list = this.list2;
          this.ShowJustloans1();
        }
        else {
          this.list = this.list2;
          this.rowData = this.list;
          this.num = 1;
          this.length = this.list.length;
        }
      }
    }
  }

  Showboth() {
    this.name.ShowBoth().then(res => {
      this.list = res;
      this.num = this.list.length / this.list.length;
      this.length = this.list.length;
      this.list.forEach(element => {
        element['cityName'] = this.listnames && this.listnames.find(a => a.CityId == element.City) ? this.listnames.find(a => a.CityId == element.City).NameCity : '';
        this.rowData = this.list;
        ;;
      });
    })
    this.buildgrid();
  }
  ShowJustmafkidim1() {

    this.name.ShowNamesmafkidim().then(res => {
      this.list = res;
      this.num = this.list.length / this.list.length;
      this.length = this.list.length;
      this.list.forEach(element => {
        element['cityName'] = this.listnames && this.listnames.find(a => a.CityId == element.City) ? this.listnames.find(a => a.CityId == element.City).NameCity : '';
        this.rowData = this.list;
      }); this.buildgrid();

    })
  }
  ShowJustloans1() {
    this.name.ShowNamesLoans().then(res => {
      this.list = res;
      this.num = this.list.length / this.list.length;
      this.length = this.list ? this.list.length : 0;
      this.list.forEach(element => {
        element['cityName'] = this.listnames && this.listnames.find(a => a.CityId == element.City) ? this.listnames.find(a => a.CityId == element.City).NameCity : '';
        this.rowData = this.list;
      });
      this.buildgrid();
    })

  }
  viewcities() {
    this.maagar.ShowCity().then(res => {
      this.listnames = res;
      this.listnames.forEach(element => {
        this.listNemesCity.push(element.NameCity)

      });
      this.showNames();
    })
  }
  showNames() {
  
      this.name.ShowNames().then(res => {
        this.list = res;
        sessionStorage.setItem('ListNames', JSON.stringify(this.list));
        this.length = this.list.length;
        this.list.forEach(element => {
          element['cityName'] = this.listnames && this.listnames.find(a => a.CityId == element.City) ? this.listnames.find(a => a.CityId == element.City).NameCity : '';})
          this.rowData = this.list;
          this.list2 = this.list;
          NamesService.mainid == this.list[0].MAINID;
          this.buildgrid();
          this.viewdetails1();
      });
      
}
  filter(d: Date, d1: Date) {
    this.name.filter(d, d1, "יותר מ", "הפקדות", 500, 1);
  }
  //#endregion
  //#region bool
  halvaa() {
    this.boolloan = true;
  }
  hafkada() {
    this.boolhaf = true;
  }
  //#endregion
  //#region add
  viewtools() {
    // this.list=new Array<Names>();
    // this.list.push(new Names)
    this.router.navigate(["/Tools/" + this.iduser, this.PERMISSION, -1]);

  }
  viewcalculator()
  {
    this.router.navigate(["/calculator/"]);

  }
  AddToWaitingList(id: number) {
    if (this.mainid != null) {
      // NamesService.id = id;
      this.router.navigate(["/waiting/" + this.iduser, this.PERMISSION, this.mainid]);
    }
    else {
      alert("לא נבחר שם");
    }
  }

  AddLoan() {
    if (this.mainid != null) {
      // this.router.navigate(["/loans/" + this.iduser, this.PERMISSION, this.mainid, -1]);
      const url = this.router.serializeUrl(this.router.createUrlTree(["/loans1/"+this.iduser,this.PERMISSION,this.mainid ,-1,0]));
      window.open(url, '_blank');
    }
    else {
      alert("לא נבחר שם");

    }
  }
  AddhafkadaCount() {
    if (this.mainid != null) {
      // this.router.navigate(["/Hafkadotall/" + this.iduser, this.PERMISSION, this.mainid, -1]);
    const url = this.router.serializeUrl(this.router.createUrlTree(["/Hafkadotall1/"+this.iduser,this.PERMISSION,this.mainid, -1,0]));
    window.open(url, '_blank');  
    }
    else {
      alert("לא נבחר שם");

    }

  }
  AddHok() {
    if (this.mainid != null) {
      this.router.navigate(["/hok/" + this.iduser, this.PERMISSION]);
    }
    else {
      alert("לא נבחר שם");
    }
  }
  //#endregion
}

