export class HecExtra {
    id:number
 
    // Yechidot:number=1;
    Num:number;
    // FirstAmount:number=1;
    // totalloan:number=240000
    heczer:number;
    // numofheczers:number=300;
}
