<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align:center" >

  <h1>   
     <!-- <app-login> </app-login> -->
<!-- <app-hok> </app-hok> -->
    <!-- <app-charts1></app-charts1> -->
    <!-- <app-excelmenage></app-excelmenage> -->
    <!-- <app-maslul></app-maslul> -->
    <!-- <app-add></app-add> -->
    <!-- <app-heczers></app-heczers> -->
    <!-- <app-loans></app-loans> -->     <!-- <app-view-names></app-view-names> -->
     <!-- <app-hok-gvia></app-hok-gvia> -->
     <!-- <app-tassks></app-tassks> -->
    <!-- <app-add-name></app-add-name> -->
<!-- <app-masav></app-masav> -->
   <!-- <app-main>  </app-main> -->
   <!-- <app-excellist></app-excellist> -->
   <!-- <app-view-names></app-view-names> -->
   <!-- <app-add-hafkadot></app-add-hafkadot>   -->
   <!-- <app-masav></app-masav> -->
   <!-- <app-excellist></app-excellist> -->
<!-- <app-kupamenage></app-kupamenage> -->
  </h1>
  <!-- <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="> -->
</div>

<router-outlet></router-outlet>


