import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MasavFiles } from '../classes/masav-files';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class HokgviaService {
  Stop(id: Date) {
    return this.http.put(NamesService.serverUrl +"/Ash/StopHafAndHeczers",{d:id}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }



  constructor(private http: HttpClient) { }
  SubmitJsonDataHEC(id: Date) {
    return this.http.put(NamesService.serverUrl +"/Ash/SubmitJsonDataHEC",{d1:id}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
    updatesend(id: Date) {     
      return this.http.post(NamesService.serverUrl +"/Gviot/UpdateListASHhecnigva",id).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  
    }
    sendChecksHaf(id:Array<number>) {
      return this.http.post(NamesService.serverUrl +"/Gviot/sendChecksHaf",id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });  
    }
    sendChecksHal(id:Array<number>) {
      return this.http.post(NamesService.serverUrl +"/Gviot/sendChecksHal",id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });  }
    
  
  SubmitJsonDataHaf(id: Date) {
    return this.http.put(NamesService.serverUrl +"/Ash/SubmitJsonDataHaf",{d1:id}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  SubmitJsonData(id: Date) {
    ;
    return this.http.put(NamesService.serverUrl +"/Ash/SubmitJsonData",{d1:id}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
    } 
  sendtomasav(id: Date) {
    return this.http.put(NamesService.serverUrl +"/Gviot/sendtomasav",{d1:id}).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });
    } 
    updatemasav(id: MasavFiles) {
      return this.http.post(NamesService.serverUrl +"/Gviot/Updatenigva",id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });    }
    DelMasav(id: any) {
      return this.http.get(NamesService.serverUrl +"/Gviot/DelMasav?id="+id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });    }
    ViewHeczerMasav(id: any):any {
      return this.http.get(NamesService.serverUrl +"/Gviot/ViewHeczerMasav?id="+id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
    ViewHafkadotMasav(id: any) :any{
      return this.http.get(NamesService.serverUrl +"/Gviot/ViewHafkadotMasav?id="+id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });    }
    ViewListMasav():any {
      return this.http.get(NamesService.serverUrl +"/Gviot/GetListMasav").toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
      } 
    
    sendtomasavhec(id: Date) {
      return this.http.put(NamesService.serverUrl +"/Gviot/sendtomasavhec", {d1:id}).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
    sendtomasavhaf(id: Date) {
      return this.http.put(NamesService.serverUrl +"/Gviot/sendtomasavhaf",{d1:id}).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
   
    
  
  ViewHafkadotHok(id:Date) : any {
    {
     
        return this.http.put(NamesService.serverUrl +"/Gviot/GetHafkadotsHok", {d1:id}).toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });
        }}
        ViewHeczerHok(id:Date) : any {
          {
           
              return this.http.put(NamesService.serverUrl +"/Gviot/GetHeczersHok", {d1:id}).toPromise().then(
                response => {
                 return  response;
                })
              .catch(v => { return false; });
              }}
              ViewHafkadotHokWithErrors(id:Date) : any {
                {
                 
                    return this.http.put(NamesService.serverUrl +"/Gviot/GetHafkadotsHokWithErrors", {d1:id}).toPromise().then(
                      response => {
                       return  response;
                      })
                    .catch(v => { return false; });
                    }}
                    ViewHeczerHokWithErrors(id:Date) : any {
                      {
                       
                          return this.http.put(NamesService.serverUrl +"/Gviot/GetHeczersWithErrors", {d1:id}).toPromise().then(
                            response => {
                             return  response;
                            })
                          .catch(v => { return false; });
                          }}
              ViewHafkadotAshrai(date) : any {
                {
                 ;;
                    return this.http.put(NamesService.serverUrl +"/Gviot/GetHafkadotsAshrai", {d1:date}).toPromise().then(
                      response => {
                       return  response;
                      })
                    .catch(v => { return false; });
                    }}
                    Viewcheck(): any {
                      return this.http.get(NamesService.serverUrl +"/Gviot/GetChecksheczer").toPromise().then(
                        response => {
                         return  response;
                        })
                      .catch(v => { return false; });
                      
                      }
                  
                    ViewHeczerAshrai(date) : any {
                      {
                 ;;
                       
                          return this.http.put(NamesService.serverUrl +"/Gviot/GetHeczersAshrai", {d1:date}).toPromise().then(
                            response => {
                             return  response;
                            })
                          .catch(v => { return false; });
                          }}
                          ViewHafkadachecks(id:Date) : any {
                            {
                             
                                return this.http.put(NamesService.serverUrl +"/Gviot/GetChecks",{d1:id}).toPromise().then(
                                  response => {
                                   return  response;
                                  })
                                .catch(v => { return false; });
                                }}
                                ViewHeczerchecks(id:Date) : any {
                                  {
                                   
                                      return this.http.put(NamesService.serverUrl +"/Gviot/GetChecksheczer",{d1:id}).toPromise().then(
                                        response => {
                                         return  response;
                                        })
                                      .catch(v => { return false; });
                                      }}
                          GetHokById(id:number): any {
                            return this.http.get(NamesService.serverUrl +"/?id="+id).toPromise().then(
                              res => { return res; })
                              .catch(err => { return false;})}
                              
}
