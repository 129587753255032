import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { NamesService } from 'src/app/services/names.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ShowbalanceComponent } from '../showbalance/showbalance.component';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { ArevtotalscumComponent } from '../arevtotalscum/arevtotalscum.component';
import { MafkidService } from 'src/app/services/mafkid.service';

@Component({
  selector: 'app-showloans',
  templateUrl: './showloans.component.html',
  styleUrls: ['./showloans.component.css']
})
export class ShowloansComponent implements OnInit {
  index: any;
  constructor(private cd: ChangeDetectorRef, private name: NamesService, private router: Router,private maf:MafkidService,private loan: LoansService) { }
  //#region params
  @ViewChild(ShowbalanceComponent) appShowbalance: ShowbalanceComponent;
  @ViewChild(ArevtotalscumComponent) arevtotalscumComponent: ArevtotalscumComponent;
  @Input() user
  @Input() mainid
  @Input() iduser;
  id;
  list;
  loans: number = 0;
  list1;
  list2;
  excel: boolean;
  loans1: number = 0;
  //#endregion
  ngOnInit() {
 }
    showloans(id:number) {
    
    this.loans = 0;
    this.mainid=id;
    if(id!=null)
    {
    this.name.Showoans(id).then(res => {
      if (res) {
      this.list = res;
      this.list1 = this.list.filter(a => a.Coin == 1);
      this.list1.forEach(element => {
        this.loans += element.Balance;
      });
      this.list2 = this.list.filter(a => a.Coin == 2);
      this.list2.forEach(element => {

        this.loans1 += element.Balance;
      });
      LoansService.scum = this.loans;
      LoansService.scumd = this.loans1;
        this.appShowbalance=new ShowbalanceComponent(this.name,this.maf);
      this.appShowbalance.fun(this.loans,this.loans1);
      this.arevtotalscumComponent=new ArevtotalscumComponent(this.loan);
      this.arevtotalscumComponent.Showarev(id);
      this.cd.detectChanges();
      }
    });}}
  
  viewdetails(id: number) {
    // if (!ExcelService.bool) {
      this. index=this.list.findIndex(x => x.ID==id);

      //this.router.navigate(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]);
      const url = this.router.serializeUrl(this.router.createUrlTree(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]));
      window.open(url, '_blank');
    // }
  }
  viewdetails1(id: number) {
    this. index=this.list.findIndex(x => x.ID==id);

   // this.router.navigate(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]); 
    const url = this.router.serializeUrl(this.router.createUrlTree(["/loans1/"+this.iduser,this.user,this.mainid ,id,this.index]));
    window.open(url, '_blank');

   }
}

