import { NamesService } from 'src/app/services/names.service';
import { MafkidService } from 'src/app/services/mafkid.service';
import { LoansService } from 'src/app/services/loans.service';
import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-showbalance',
  templateUrl: './showbalance.component.html',
  styleUrls: ['./showbalance.component.css']
})
export class ShowbalanceComponent implements OnInit {

  constructor(private name:NamesService,private maf:MafkidService) { }
//#region 
id;
list;
list1;
@Input() loans
@Input() loans1
haf:number;
haf1:number;
//#endregion
  ngOnInit() {
  }
  fun(id:number,id1:number)
  {
    
    // this.loans=this.loans;
    // this.loans1=this.loans1;
    this.haf=id;
    this.haf1=id1;
  }
  }

