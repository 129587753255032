<meta name="viewport" content="width=device-width, initial-scale=1.0">
  <div *ngIf ='bool==true'>       
        <button class="tablink tablink3" (click)='logout()' >  <img src="/assets/1/logout.png"style="width:60px;position: relative;  top:-18px;"></button>
        <button  class="tablink tablink1" (click)='showreport()'>דוחות</button>
        <button  class="tablink tablink1" (click)='showsetting()'>הגדרות וניהול</button>
        <button class="tablink tablink1" (click)='gviot()'>ניהול גביות</button>       
        <button class="tablink tablink1" (click)='kupa()'>קופה</button>
        <button class="tablink tablink1" (click)='excel()'>תנועות בחשבון</button>
        <button class="tablink tablink1" (click)='task()'>רשימת מטלות</button>
        <button class="tablink tablink1" (click)='waiting()'>רשימת המתנה</button>
 <button class="tablink tablink1" (click)='shownames()' >הצג שמות</button>
  </div>
  <div *ngIf ='bool==false||bool1==true'>
        <button class="tablink tablink3" (click)='logout()' >  <img src="/assets/1/logout.png"style="width:60px;position: relative;  top:-18px;"></button>       
        <button class="tablink tablink2" (click)='gviot()'>גביית הוק</button>
        <button class="tablink tablink2" (click)='kupa()'>קופה</button>
        <button class="tablink tablink2" (click)='excel()'>תנועות בחשבון</button>
        <button class="tablink tablink2" (click)='task()'>רשימת מטלות</button>
        <button class="tablink tablink2" (click)='waiting()'>רשימת המתנה</button>
<button class="tablink tablink2" (click)='shownames()' >הצג שמות</button>
  </div>
 
