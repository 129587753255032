
<table >
    <tr style="font-weight: bold;text-align: center;background-color: white !important" >    
        הוק      
       </tr>    <tr>
  
       <td>  <thead> סכום</thead></td>
       <td>  <thead> עבור</thead></td>
       </tr>
     <tr *ngFor='let item of list1' (click)='viewdetails1(item.kodloan)'>

   <td>
     {{item.Scum}}
   </td>
 
   <td>
   הלוואה
  </td>
     </tr>

       <tr *ngFor='let item of list' (click)='viewdetails(item.Kodmafkidcount)'>
 
     <td>
       {{item.Scum}}
     </td>
     <td>
     הפקדה
    </td>
   
       </tr>
    </table>
    