import { Component, OnInit, ViewChild } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
// import { v } from '@angular/core/src/render3';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { Task } from 'src/app/classes/task';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/classes/user';
// import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Categories } from 'src/app/classes/categories';
import { AddtaskComponent } from '../addtask/addtask.component';
@Component({
  selector: 'app-tassks',
  templateUrl: './tassks.component.html',
  styleUrls: ['./tassks.component.css']
})
export class TassksComponent implements OnInit {
  both: boolean;
  updatet: boolean;
  id: number;

  
   constructor(private task:TasksService, private maagar:MaagarMenageService,private user:UserService,private router:Router ,private route:ActivatedRoute) {

    this.showTasks();
     this.route.params.subscribe((params:Params) => {
       this.iduser = params.iduser;
       this.PERMISSION = params.id;
         })}
    //#region 
  list;
  kod: any;
 myDate:Date;
  settask: boolean;
  scum: number;
  d1: Date;
    remember: boolean;
  date: string;
  num: number;
  valuecat: string;
  boolscum: boolean;
  booldate: boolean;
   PERMISSION: any;
  iduser: any;
  list1;
  task1:Task=new Task();
  list2;
  listuser;  
  myDate1: Date=new Date();
  @ViewChild(AddtaskComponent)appadd:AddtaskComponent;

  today:Date=new Date();
  valueuser;
  valueuser1;
  user1:User=new User();
  value;
  value1:boolean=false;
  value2:boolean=true;
  //#endregion
    ngOnInit() {
      this.myDate=new Date();
     this.myDate1=new Date( this.myDate.getFullYear(),this.myDate.getMonth() ,this.myDate.getDate()-7)
     ;;
    this.today=new Date( this.myDate.getFullYear(),this.myDate.getMonth() ,this.myDate.getDate()+7)
      this.showcat();
      this.getusers();
      this.value="הכל";
      this.booldate=false;
      this.boolscum=false;
    }
    //#region  filter
    filtertask()
    {
      ;;
     if(this.value!="הכל")
     {
     this.kod= this.list1.find(a=>a.NameCategory==this.value).CategoryId;
     this.list=this.list2;
     this.list= this.list.filter(a=>a.Category==this.kod)
   }
    else
    {
      this.list=this.list2;
    }
    if(this.valueuser1!="הכל")
    {
    this.list=  this.list.filter(a=>a.userkod==this.user1.IdUser);
     } 
       if(this.value1 &&!this.value2)
          {
           
           this.list= this.list.filter(t=>t.statustask==true)
          }
        else
        {
       if(this.value2&&!this.value1)
       {
  this.list= this.list.filter(t=>t.statustask==false);
  }
}
    }
    filteruser(val:string)
    {
      ;;
  this.valueuser1=  val;
  this.user1= this.listuser.find(a=>a.LastName==val);
  this.filtertask();
    }
  
    filtertask1()
    {   
      ;;
      if(this.value1 && this.value2)
       {
        this.list= this.list2;   
       }
       else 
       {
         if(this.value1 &&!this.value2)
            {
              this.list= this.list2;   
             this.list= this.list.filter(t=>t.statustask==true)
            }
          else
          {
        this.list= this.list2;   
         if(this.value2&&!this.value1)
         {
    this.list= this.list.filter(t=>t.statustask==false);
    }
    else{
         this.list= this.list2;    
    } }

  }  
  ;;
  if(this.value!="הכל")
  {
  this.kod= this.list1.find(a=>a.NameCategory==this.value).CategoryId;
  this.list= this.list.filter(a=>a.Category==this.kod)
}
 if(this.valueuser1!="הכל")
 {
 this.list=  this.list.filter(a=>a.userkod==this.user1.IdUser);
  } 
   }
   filterbydate(d:Date, d1: Date)
   {
     ;;
     this.task.filterbydate(d,d1).then(res=>{
     this.list2=res;
     this.list2.forEach(element => {
      element['catName'] = this.list1.find(a => a.CategoryId == element.Category).NameCategory;
      element['NameUser']=this.listuser.find(a=>a.IdUser==element.userkod).LastName;})
     this.list=this.list2;
     this.filtertask();})
   }
   //#endregion
   //#region  bool

   rememberEveryMonth()
   {
     this.remember=!this.remember;
   }
   saveval(val:string)
   {
     this.valueuser=val;
   }
   change1(){
  this.value1=!this.value1;
  this.filtertask1();
   }
   change(){
    this.value2=!this.value2;
    this.filtertask1();
     }
    valuechange1(value:string){
  this.value=value;
  this.filtertask();
    }
    set()
    {
      this.settask=!this.settask;
    }
    //#endregion
 //#region one task
 update()
 {
  this.updatet=true;
 }
    FUN(id:number)
  {
    this.id=id;
  this.task1=this.list.find(a=>a.Id==id);
  
  this.updatet=true;
  this.remember=this.task1.remember;
this.appadd.updatetask(this.task1);

}
UpdateTask(datew:Date,remark:string,instruction:string,each:number)
    {
  this.task1.statustask=this.settask;
  this.task1.Datetezcoret=datew;
  this.task1.Instructions=instruction;
  this.task1.remark=remark;
  ;;
  if(this.booldate)
  {
    this.task1.date=this.d1;
  }
  if(this.boolscum)
  {
    this.task1.scum=this.scum ;
  }
  this.task1.remember=this.remember;
  if(this.remember)
  {
   this.task1.each= each;
  }
  this.task1.userkod=this.listuser.find(a=>a.LastName==this.valueuser).IdUser;
 this.task.addTask(this.task1);
  this.task.UpdateTaskall(this.task1)
}
//#endregion
 //#region show
  showall ()
  {
    this.showTasks();
  }
  showcat(){
    this.maagar.Showcat("משימה").then(res=>{
    this.list1=res;
    this.list1.push( this.list1[0]);
    this.list1[0]=new Categories();
 this.value=this.list1[0].NameCategory;

    this.showTasks()
  })}
getusers()
{
this.user.GetUsers().then(res=>{
this.listuser=res; 
 this.listuser.push( this.list1[0]);
 this.listuser[0]=new User();
this.user1=this.listuser[0];
 this.valueuser1=this.listuser[0].LastName;

})
}
    showTasks()
    {
      this.task.ViewTasks().then(res=>{
      this.list=res; 
        this.list.forEach(element => {
         element['catName'] =   this.list1&&  this.list1.find(a => a.CategoryId == element.Category)?this.list1.find(a => a.CategoryId == element.Category).NameCategory:null;
         element['NameUser']=this.listuser&&this.listuser.find(a=>a.IdUser==element.userkod)? this.listuser.find(a=>a.IdUser==element.userkod).LastName:null;
         this.list2= this.list;
       
   
         ;
       });
       this.list=this.list.filter(a=>a.statustask==false);
       this.list=this.list.filter(a=>a.userkod==this.iduser);
       ;

    })   
  }
  //#endregion
}
  